import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import lodash, { maxBy } from 'lodash';

import { curriculumSelector } from 'pages/CurriculumManagement/Curriculum/selectors';
import { Wrapper as WrapperColumnRoot } from './TreeNodeRenderer/styles';
import { CustomDragLayer } from './NodeRenderer/CustomDragLayer';
import { authSelector } from 'containers/Auth/selectors';
import ColumnRenderer from './TreeNodeRenderer';
import NodeRenderer from './NodeRenderer';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import * as Types from 'types';
import {
  memoizedGetChildrenItemIDFromTree,
  memoizedGetFlatDataFromTree,
} from 'libs/utils/curriculum/memoized-tree-data-utils';
import {
  createLinkQuestionAssignLevel,
  deleteLinkQuestion,
} from 'containers/CreateEditQuestion/thunk';
import {
  createCurriculum,
  createFileAttach,
  createLevelCurriculum,
  deleteLevelCurriculum,
  getCurriculum,
  updateCurriculum,
} from 'pages/CurriculumManagement/Curriculum/thunk';
import ModalCopyCurriculum from 'containers/Curriculum/NodeTreeView/ModalCopyCurriculum';
import { copyFileMinIO } from 'services/minioService';
import { CurriculumItemType, TreeItem } from 'types';

const NODE_HEIGHT = 56;

type Props = {
  curricullum: TreeItem<CurriculumItemType>[];
  tabActive: number;
  columnClosed?: number;
  treeViewIndex: number;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  treeData: any;
  setItemMoveCopySelected: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
};

const TreeView: React.FC<Props> = ({
  treeData,
  tabActive,
  columnClosed,
  treeViewIndex,
  itemMoveCopySelected,
  setItemMoveCopySelected,
  curricullum,
}) => {
  const [data, setData] = useState<{ [key: string]: Types.TreeItem<Types.CurriculumItemType>[] }>(
    {}
  );
  const [openConfirmCopyCurriculum, setOpenConfirmCopyCurriculum] = useState(false);
  const [selectedCurriculum, setSelectedCurriculum] =
    useState<Types.TreeItem<Types.CurriculumItemType>>();
  const [loading, setLoading] = useState(false);

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const onDrop = async (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => {
    const listChildItemID = memoizedGetChildrenItemIDFromTree({
      treeData: currentItem.node,
    });
    const minSortOrderTargetChild = targetItem.children
      ? lodash.min(targetItem.children.map((obj) => obj.sort_order)) || 0
      : 1;
    for (let index = 0; index < listChildItemID.length; index++) {
      const item: Types.FlatChildrenItemID<Types.CurriculumItemType> = listChildItemID[index];
      if (item.columnIndex < 5) {
        const resultAction = await dispatch(
          createLevelCurriculum({
            level: item.columnIndex,
            item: {
              company_id: userInfo?.company_id,
              name: item.name,
              sort_order:
                currentItem.node && item.i_id === currentItem.node.i_id
                  ? minSortOrderTargetChild - 1
                  : item.sort_order,
              [`level${item.columnIndex - 1}_code`]:
                index === 0
                  ? targetItem.code
                  : listChildItemID
                      .slice(0, index)
                      .findLast(
                        (i: Types.FlatChildrenItemID<Types.CurriculumItemType>) =>
                          i.columnIndex === item.columnIndex - 1
                      ).code,
            },
            return_item_result: true,
            return_display_id: true,
          })
        );
        if (createLevelCurriculum.fulfilled.match(resultAction)) {
          switch (item.columnIndex) {
            case 1:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 2:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 3:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 4:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            default:
              break;
          }
          if (type === 'move') {
            await dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
              })
            );
          }
        }
      } else {
        const resultAction = await dispatch(
          createLinkQuestionAssignLevel({
            item: {
              company_id: userInfo?.company_id,
              level4_code:
                listChildItemID
                  .slice(0, index)
                  .findLast(
                    (i: Types.FlatChildrenItemID<Types.CurriculumItemType>) =>
                      i.columnIndex === item.columnIndex - 1
                  )?.code || targetItem.code,
              code: item.code,
              sort_order: item.sort_order,
            },
          })
        );
        if (createLinkQuestionAssignLevel.fulfilled.match(resultAction) && type === 'move') {
          await dispatch(
            deleteLinkQuestion({
              id: item.question_assign_level_i_id!,
            })
          );
        }
      }
    }
    await dispatch(
      getCurriculum({
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    setItemMoveCopySelected(undefined);
  };

  const onDropQuestion = async (
    currentItem: Types.DropQuestionType,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => {
    if (currentItem.type === 'move-question') {
      await dispatch(
        createLinkQuestionAssignLevel({
          item: {
            company_id: userInfo?.company_id,
            level4_code: targetItem.code,
            code: currentItem.item.code,
            sort_order: targetItem.sort_order,
          },
        })
      );
      Promise.all([
        currentItem.onSubmit(),
        dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  };

  useEffect(() => {
    const column: { [key: string]: Types.TreeItem<Types.CurriculumItemType>[] } = {};
    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData,
    });

    dataPrimitive.forEach((item: Types.TreeItem<Types.CurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });
    setData(column);
  }, [treeData]);

  const onConfirmCopyCurriculum = (node: Types.TreeItem<Types.CurriculumItemType>) => {
    setSelectedCurriculum(node);
    setOpenConfirmCopyCurriculum(true);
  };

  const onCopyCurriculum = async () => {
    if (!selectedCurriculum) {
      setOpenConfirmCopyCurriculum(false);
      return;
    }
    const listChildItemID = memoizedGetChildrenItemIDFromTree({
      treeData: selectedCurriculum.node,
    }).slice(1);
    const itemNode = selectedCurriculum.node;
    if (!itemNode) {
      setOpenConfirmCopyCurriculum(false);
      return;
    }
    setLoading(true);
    let fileId = itemNode.fileID;
    if (fileId) {
      const uploadToMinIo = await copyFileMinIO(fileId);
      const fileName = uploadToMinIo.length > 36 ? uploadToMinIo.substring(37) : uploadToMinIo;
      const strs = fileName.split('.');
      await dispatch(
        createFileAttach({
          item: {
            fileID: uploadToMinIo,
            filename: fileName,
            file_location: '1',
            file_extension: strs[strs.length - 1],
            file_size: '100',
            company_id: userInfo?.company_id,
            createdat: new Date(),
            createdby: userInfo?.login_id,
          },
        })
      );
      fileId = uploadToMinIo;
    }
    const curriculumAfters = curricullum.filter(
      (obj) => obj.code !== itemNode.code && obj.sort_order! >= itemNode.sort_order!
    );

    if (curriculumAfters.length) {
      const promises = curriculumAfters.map((obj, index) =>
        dispatch(
          updateCurriculum({
            id: obj.i_id!,
            data: {
              item: {
                sort_order: (itemNode.sort_order || 0) + index + 2,
                updatedat: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        )
      );
      Promise.all(promises);
    }
    const createCurriculumResultAction = await dispatch(
      createCurriculum({
        item: {
          company_id: userInfo?.company_id,
          name: itemNode.name,
          description: itemNode.description,
          publish: 0,
          required_curriculum: 0,
          sort_order: (itemNode.sort_order || 0) + 1,
          fileID: fileId,
          createdby: userInfo?.login_id,
          archive_flg: 0,
        },
        return_item_result: true,
        return_display_id: true,
      })
    );

    if (
      createCurriculum.fulfilled.match(createCurriculumResultAction) &&
      createCurriculumResultAction.payload.item['code']
    ) {
      const curricullumCode = createCurriculumResultAction.payload.item['code'];
      for (let index = 0; index < listChildItemID.length; index++) {
        const item: Types.FlatChildrenItemID<Types.CurriculumItemType> = listChildItemID[index];
        if (item.columnIndex < 5) {
          const resultAction = await dispatch(
            createLevelCurriculum({
              level: item.columnIndex,
              item: {
                company_id: userInfo?.company_id,
                curricullum_code: curricullumCode,
                name: item.name,
                sort_order: item.sort_order,
                [`level${item.columnIndex - 1}_code`]:
                  item.columnIndex === 1
                    ? undefined
                    : listChildItemID
                        .slice(0, index)
                        .findLast(
                          (i: Types.FlatChildrenItemID<Types.CurriculumItemType>) =>
                            i.columnIndex === item.columnIndex - 1
                        ).code,
              },
              return_item_result: true,
              return_display_id: true,
            })
          );
          if (createLevelCurriculum.fulfilled.match(resultAction)) {
            switch (item.columnIndex) {
              case 1:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 2:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 3:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 4:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              default:
                break;
            }
          }
        } else {
          await dispatch(
            createLinkQuestionAssignLevel({
              item: {
                company_id: userInfo?.company_id,
                level4_code:
                  listChildItemID
                    .slice(0, index)
                    .findLast(
                      (i: Types.FlatChildrenItemID<Types.CurriculumItemType>) =>
                        i.columnIndex === item.columnIndex - 1
                    )?.code || selectedCurriculum.node?.code,
                code: item.code,
                sort_order: item.sort_order,
              },
            })
          );
        }
      }
    }
    setTimeout(async () => {
      await dispatch(
        getCurriculum({
          conditions: [
            ...filter_conditions.conditions,
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      setSelectedCurriculum(undefined);
      setOpenConfirmCopyCurriculum(false);
      setLoading(false);
    }, 1000);
  };

  if (!data[0]) {
    return null;
  }

  return (
    <Wrapper className="rst__tree">
      <WrapperColumnRoot nodeHeight={NODE_HEIGHT} className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            index={0}
            onDrop={onDrop}
            node={data[0][0]}
            tabActive={tabActive}
            nodeHeight={NODE_HEIGHT}
            treeViewIndex={treeViewIndex}
            accept={data[0][0].node!.i_id!}
            maxSortOrder={(maxBy(data[1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1}
            onCopyCurriculum={onConfirmCopyCurriculum}
          />
        </div>
      </WrapperColumnRoot>
      <div className="wrap_node_tree">
        {Array.from({
          length: columnClosed !== undefined ? columnClosed + 1 : 6,
        }).map((_, index) =>
          index > 0 ? (
            <ColumnRenderer
              key={index}
              onDrop={onDrop}
              columnIndex={index}
              column={data[index]}
              tabActive={tabActive}
              nodeHeight={NODE_HEIGHT}
              treeViewIndex={treeViewIndex}
              onDropQuestion={onDropQuestion}
              accept={data[0][0].node!.i_id!}
              isPublish={!!data[0][0].node?.publish}
              itemMoveCopySelected={itemMoveCopySelected}
              setItemMoveCopySelected={setItemMoveCopySelected}
              maxSortOrder={
                (maxBy(data[index + 1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1
              }
            />
          ) : (
            <div className="column" key={index} />
          )
        )}
      </div>
      <CustomDragLayer nodeHeight={NODE_HEIGHT} />
      <ModalCopyCurriculum
        open={openConfirmCopyCurriculum}
        setOpen={setOpenConfirmCopyCurriculum}
        onOk={onCopyCurriculum}
        loading={loading}
      />
    </Wrapper>
  );
};

export default TreeView;
