import React, { useState } from 'react';
import { UploadChangeParam, UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { CSVLink } from 'react-csv';
import { Upload } from 'antd';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  fileName: string;
  onSubmit: (file: File) => Promise<boolean> | void;
  onFileChange?: () => void;
  headersTemplate?: Array<{ label: string; key: string }>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  errors?: string[];
  title?: string;
}

const UploadCSV: React.FC<Props> = ({
  visible,
  fileName,
  onSubmit,
  setVisible,
  headersTemplate,
  title = 'カリキュラムマスタ インポート',
  errors = [],
  onFileChange = () => {},
}) => {
  const [isUploadSuccessfully, setUploadSuccessfully] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const onClose = () => {
    setVisible(false);
    setFile(undefined);
    setUploadSuccessfully(false);
  };

  const handleDelete = () => {
    setFile(undefined);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const res = await onSubmit(file as File);
    setLoading(false);
    if (res) {
      setUploadSuccessfully(true);
      setFile(undefined);
    }
  };

  return (
    <Modal
      title={title}
      width={720}
      open={visible}
      okButton={
        file || isUploadSuccessfully
          ? {
              text: isUploadSuccessfully ? 'OK' : 'インポート',
              onClick: isUploadSuccessfully ? onClose : handleSubmit,
              loading,
            }
          : undefined
      }
      cancelButton={
        !isUploadSuccessfully
          ? {
              text: 'キャンセル',
              onClick: onClose,
            }
          : undefined
      }
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {!isUploadSuccessfully ? (
          <div className="form-upload">
            <div className="form-upload-border">
              <div className="file-upload">
                {file ? (
                  <div className="info-file">
                    <p className="file-name">{file.name} </p>
                  </div>
                ) : (
                  <>
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      インポートするCSVファイルをここにドロップ
                      <br />
                      または
                    </p>
                  </>
                )}
                <div className="flex">
                  <Upload
                    accept=".csv"
                    beforeUpload={() => false}
                    onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                      onFileChange();
                      setFile(info.file as unknown as File);
                    }}
                  >
                    <button type="button" className="btn-upload">
                      ファイルを選択
                    </button>
                  </Upload>
                  {file && (
                    <button className="btn-delete" type="button" onClick={handleDelete}>
                      <DeleteOutlined className="icon-delete-outlined" />
                      <span className="label-delete">ファイルを削除</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <CSVLink headers={headersTemplate || []} data={[]} filename={fileName}>
              <p className="text-download-template">
                <FileOutlined className="icon" />
                テンプレートをダウンロード
              </p>
            </CSVLink>
            {!!errors?.length && (
              <div className="error-message">
                <div className="error-message-title">
                  <p className="text-title">
                    <ExclamationCircleOutlined className="icon" />
                    インポートに失敗しました
                  </p>
                  <p className="text-subtitle">
                    エラー項目をご確認のうえ、修正して再度実行してください。
                  </p>
                </div>
                <div className="error-message-content">
                  <ul>
                    {errors.map((err) => (
                      <li key={err} className="text-content">
                        {err}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadCSV;
