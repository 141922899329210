import React, { useEffect, useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import dayjs from 'dayjs';

import { getStatus } from 'pages/Dashboard/OfficialCurriculum';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import ReleaseNoteEditing from '../ReleaseNoteEditing';
import { loadingRef } from 'components/Loading';
import ReleaseNewNote from '../ReleaseNewNote';
import { exportManageRelease, getManageRelease } from '../../thunk';
import ManageReleaseStyled from './styles';
import ReleaseNote from '../ReleaseNote';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT } from 'constant/header.export.constant';
import saveAs from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import PDFManageReleaseTableDocument from 'pages/PublicManagement/PDFManageReleaseTableDocument';

interface Props {
  visible: boolean;
  selectedRows: Array<Types.OfficialPublicRes>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  permissionNumber?: number;
}
const PER_PAGE = 10;

const ManageRelease: React.FC<Props> = ({
  visible,
  setVisible,
  selectedRows,
  permissionNumber,
}) => {
  const [selectedRelease, setSelectedRelease] = useState<Types.ManageReleaseRes>();
  const [visibleReleaseNote, setVisibleReleaseNote] = useState<boolean>(false);
  const [visibleReleaseEditing, setVisibleEditing] = useState<boolean>(false);
  const [visibleNewNote, setVisibleNewNote] = useState<boolean>(false);
  const [publish, setPublish] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { releaseData, loading, totalReleaseNote } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  const handleToggleModal = () => {
    setVisible(false);
  };

  const columns: ColumnsType<Types.ManageReleaseRes> = [
    {
      title: 'No.',
      dataIndex: '',
      key: 'No',
      width: '3.5%',
      render: (_value, _record, index) => <span className="no">{index + 1}</span>,
    },
    {
      title: (
        <span>
          OFFICIALカリキュラム
          <br />
          公開日時（更新日時）
        </span>
      ),
      width: '13%',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      render: (record) => dayjs(record).locale('ja').format('YYYY/MM/DD (dddd) HH:mm'),
    },
    {
      title: 'バージョン',
      dataIndex: 'publish_version',
      align: 'center',
      width: '7%',
    },
    {
      title: '公開種類',
      dataIndex: 'publish_type',
      key: 'publish_type',
      align: 'center',
      width: '8%',
      render: (record) =>
        record === 0 ? '未更新' : record === 1 ? '初回公開' : record === 2 ? '更新' : '最終更新',
    },
    {
      title: (
        <span>
          リリースノート
          <br />
          作成状況
        </span>
      ),
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      width: '8%',
      render: (record) => (!record ? '未作成' : '作成済'),
    },
    {
      title: 'リリースノートタイトル',
      dataIndex: 'release_note_title',
      key: 'release_note_title',
      render: (record) => (!record ? '_' : <div className="release_note_title">{record}</div>),
      align: 'left',
      width: '18%',
    },
    {
      title: '作成・更新日時',
      dataIndex: 'updatedat',
      key: 'updatedat',
      align: 'center',
      render: (record) =>
        !record ? '_' : dayjs(record).locale('ja').format('YYYY/MM/DD (dddd) HH:mm'),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      align: 'center',
      width: '7%',
      render: (record) => (!record ? '_' : record),
    },
    {
      title: (
        <span>
          リリースノート
          <br /> 公開ステータス
        </span>
      ),
      dataIndex: 'publish_status',
      key: 'publish_status',
      align: 'center',
      width: '10%',
      render: (record) => (!record ? '_' : getStatus(record)),
    },
    {
      title: 'アクション',
      dataIndex: 'release_note_id',
      key: 'release_note_id',
      align: 'center',
      width: '12.3%',
      render: (_text, record) =>
        record?.release_note_id ? (
          <div className="wrap-button-table">
            <Button
              className="list-release release-left"
              onClick={() => {
                setPublish(record?.public_status);
                setVisibleReleaseNote(true);
                setSelectedRelease(record);
              }}
            >
              閲覧
            </Button>
            <Button
              disabled={permissionNumber === 1}
              className={`list-release release-right ${permissionNumber === 1 ? 'disabled' : ''}`}
              onClick={() => {
                setPublish(record?.public_status);
                setVisibleEditing(true);
                setSelectedRelease(record);
              }}
            >
              編集・削除
            </Button>
          </div>
        ) : (
          <Button
            className="list-release create-new"
            onClick={() => {
              setPublish(record?.public_status);
              setVisibleNewNote(true);
              setSelectedRelease(record);
            }}
          >
            新規作成
          </Button>
        ),
    },
  ];

  useEffect(() => {
    if (visible && selectedRows.length > 0) {
      dispatch(
        getManageRelease({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'curriculum_code',
              search_value: [selectedRows[0]?.curriculum_code],
            },
          ],
          page: page,
          per_page: PER_PAGE,
          omit_total_items: false,
          include_item_ref: true,
        })
      );
    }
  }, [dispatch, visible, userInfo, page]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  async function handleExport(type: string) {
    const resultAction = await dispatch(
      exportManageRelease({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'curriculum_code',
            search_value: [selectedRows[0]?.curriculum_code],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    if (exportManageRelease.fulfilled.match(resultAction)) {
      const items = resultAction.payload.report_results;
      const reportTitle = resultAction.payload.report_title;
      if (type === 'csv') {
        const listCsv = items.map((item) => ({
          i_id: item.i_id,
          provider_id: item.provider_id,
          official_curriculum_code: item.official_curriculum_code,
          history_id: item.history_id,
          publish_start_date: item.publish_start_date,
          publish_type: item.publish_type,
          release_note_id: item.release_note_id,
          updatedat: item.updatedat,
          release_note_title: item.release_note_title,
          release_version: item.release_version,
          release_note_version: item.release_note_version,
          release_note_publish_status: item.release_note_publish_status,
          createdby: item.createdby,
          official_curriculum_name: item.official_curriculum_name,
        }));

        const csvString = [
          HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, `${reportTitle}.csv`);
      } else {
        const blob = await pdf(
          <PDFManageReleaseTableDocument releaseData={items} selectedRows={selectedRows} />
        ).toBlob();
        saveAs(blob, `${reportTitle}.pdf`);
      }
    }
    setVisiblePopupConfirmExportFile(false);
  }

  return (
    <Modal
      title={<span className="title">リリースノート管理</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1600}
      open={visible}
      cancelButton={{
        text: '閉じる',
        onClick: handleToggleModal,
      }}
      onCancel={handleToggleModal}
    >
      <ManageReleaseStyled isEmptyData={!releaseData.length}>
        <p className="sub-title">
          OFFICIALカリキュラムの公開・編集日時別のリリースノート作成状況の一覧画面です。
        </p>
        <div>
          <label>対象OFFICAILカリキュラム： </label>
          <div className="wrap-targer-official">
            <div className="wrap-left-side">
              <div className="text-left">
                <span>{releaseData[0]?.curriculum_name}</span>
                <span>（ID：{releaseData[0]?.curriculum_code})</span>
              </div>
              <div className="wrap-creator">
                <span className="label-creator">作成者：</span>
                <span>{releaseData[0]?.curriculum_registered_person}</span>
              </div>
            </div>
            <div className="wrap-right-side">{getStatus(selectedRows[0]?.publish)}</div>
          </div>
        </div>
        <div className="wrap-button">
          <Button
            className="btn-active"
            icon={
              <CloudDownloadOutlined
                style={{
                  fontSize: 20,
                }}
              />
            }
            onClick={() => setVisiblePopupConfirmExportFile(true)}
          >
            エクスポート
          </Button>
        </div>
        <Table
          pagination={{
            pageSize: 10,
            position: ['topCenter'],
            total: totalReleaseNote,
            showTotal: () => (
              <span>
                {page * PER_PAGE > totalReleaseNote ? totalReleaseNote : page * PER_PAGE} 件表示 /{' '}
                {totalReleaseNote} 件中
              </span>
            ),
            onChange: setPage,
          }}
          loading={loading}
          className="table"
          columns={columns}
          dataSource={releaseData}
          scroll={{
            y: '35vh',
          }}
        />
        <ReleaseNewNote
          selected={selectedRelease}
          publish={publish}
          visible={visibleNewNote}
          setVisible={setVisibleNewNote}
        />
        <ReleaseNote
          publish={publish}
          visible={visibleReleaseNote}
          setVisible={setVisibleReleaseNote}
          selected={selectedRelease}
        />
        <ReleaseNoteEditing
          publish={publish}
          visible={visibleReleaseEditing}
          setVisible={setVisibleEditing}
          selected={selectedRelease}
          page={page}
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExport}
          subTitle="役職マスタをエクスポートします。"
        />
      </ManageReleaseStyled>
    </Modal>
  );
};

export default ManageRelease;
