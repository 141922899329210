import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';

import * as Types from 'types';
import {
  getQuestionSearch,
  getUnrelatedQuestions,
  getCreatorQuestionOption,
  getCurriculumLevelOption,
  getCurriculumQuestionOption,
} from './thunk';

type InitialState = {
  loading: boolean;
  nodeLevel4Selected?: Types.TreeItem<Types.CurriculumItemType>;
  questionSearch: Array<Types.QuestionSearchType>;
  unrelatedQuestions: Array<Types.UnrelatedQuestionsType>;
  creatorOption: Array<Types.CreatorOptionType>;
  curriculumOption: Array<Types.CurriculumOptionType>;
  level1Option: Array<Types.CurriculumOptionType>;
  level2Option: Array<Types.CurriculumOptionType>;
  level3Option: Array<Types.CurriculumOptionType>;
  level4Option: Array<Types.CurriculumOptionType>;
};

const initialState: InitialState = {
  loading: false,
  questionSearch: [],
  unrelatedQuestions: [],
  creatorOption: [],
  curriculumOption: [],
  level1Option: [],
  level2Option: [],
  level3Option: [],
  level4Option: [],
};

const questionSearchSlice = createSlice({
  name: 'questionSearch-Slice',
  initialState,
  reducers: {
    selectNodeLevel4(
      state,
      action: PayloadAction<Types.CurriculumItemType & { maxSortOrder?: number }>
    ) {
      state.nodeLevel4Selected = action.payload;
    },
    removeNodeLevel4Selected(state) {
      state.nodeLevel4Selected = undefined;
    },
    resetData(state) {
      state.questionSearch = [];
      state.unrelatedQuestions = [];
    },
  },
  extraReducers: (builder) => {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getQuestionSearch.pending, startLoading)
      .addCase(getUnrelatedQuestions.pending, startLoading)
      .addCase(getCreatorQuestionOption.pending, startLoading)
      .addCase(getCurriculumQuestionOption.pending, startLoading)
      .addCase(getCurriculumLevelOption.pending, startLoading);
    builder.addCase(getQuestionSearch.fulfilled, (state, action) => {
      state.questionSearch = uniqBy(
        action.payload.report_results.filter((item) => item.question_code),
        'question_code'
      );
      stopLoading(state);
    });
    builder.addCase(getUnrelatedQuestions.fulfilled, (state, action) => {
      state.unrelatedQuestions = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getCreatorQuestionOption.fulfilled, (state, action) => {
      state.creatorOption = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getCurriculumQuestionOption.fulfilled, (state, action) => {
      state.curriculumOption = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getCurriculumLevelOption.fulfilled, (state, action) => {
      switch (action.payload.level) {
        case 1:
          state.level1Option = action.payload.data.report_results;
          break;
        case 2:
          state.level2Option = action.payload.data.report_results;
          break;
        case 3:
          state.level3Option = action.payload.data.report_results;
          break;
        default:
          state.level4Option = action.payload.data.report_results;
          break;
      }
      stopLoading(state);
    });
    builder
      .addCase(getQuestionSearch.rejected, stopLoading)
      .addCase(getUnrelatedQuestions.rejected, stopLoading)
      .addCase(getCreatorQuestionOption.rejected, stopLoading)
      .addCase(getCurriculumQuestionOption.rejected, stopLoading)
      .addCase(getCurriculumLevelOption.rejected, stopLoading);
  },
});

export const { resetData, selectNodeLevel4, removeNodeLevel4Selected } =
  questionSearchSlice.actions;

export default questionSearchSlice.reducer;
