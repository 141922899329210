import styled from 'styled-components';

export const SectionStyled = styled.div`
  padding: 30px 30px 0 30px;
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    color: #424242;
    .icon {
      color: #08a3a5;
      margin-right: 12px;
      font-size: 28px;
    }
  }
`;
