import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  CURRICULUM_PUBLISHED_HISTORY,
  CURRICULUMS,
  OFFICAL_CURRICULUM_PUBLISHING_SETTINGS,
  RELEASE_NOTE,
  RELEASE_NOTE_DETAIL,
  RELEASE_NOTE_MANAGEMENT,
  RELEASE_NOTE_SECTION,
} from 'configs';

export const getOfficialPublish = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk.getOfficialPublic', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICAL_CURRICULUM_PUBLISHING_SETTINGS.id, req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getManageRelease = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(RELEASE_NOTE_MANAGEMENT.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const exportManageRelease = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/exportReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(RELEASE_NOTE_MANAGEMENT.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getArchiveList = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/getArchiveList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICAL_CURRICULUM_PUBLISHING_SETTINGS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCurriculumPublishedHistory = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.CurriculumPublishedHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/CreateCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(CURRICULUM_PUBLISHED_HISTORY.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculumPublishedHistory = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.CurriculumPublishedHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUM_PUBLISHED_HISTORY.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReleaseNoteSection = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(RELEASE_NOTE_DETAIL.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReleaseNote = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(RELEASE_NOTE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReleaseNoteSection = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ReleaseNoteSection.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/createReleaseNoteSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(RELEASE_NOTE_SECTION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeReleaseSectionNote = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/removeReleaseSectionNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(RELEASE_NOTE_SECTION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReleaseNote = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ReleaseNote.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(RELEASE_NOTE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReleaseNoteSection = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ReleaseNoteSection.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/updateReleaseNote', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(RELEASE_NOTE_SECTION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('public/thunk/removeCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(RELEASE_NOTE_SECTION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
