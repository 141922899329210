import React, { useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';

import ConfirmExportFile from 'components/Modal/ConfirmExportFile';
import OfficialCurriculum from './OfficialCurriculum';
import CompanyUser from './CompanyUser';
import DashboardStyled from './styles';
import { Header } from 'components';

const { TabPane } = Tabs;

const Dashboard: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <>
      <Header title=" HOME / Dashboard" />
      <DashboardStyled>
        <div className="tabs">
          <Tabs
            defaultActiveKey="1"
            style={{
              height: '100%',
            }}
          >
            <TabPane
              className="tabpen-container"
              tab="企業 / ユーザー"
              key="1"
              style={{
                minHeight: '100%',
              }}
            >
              <CompanyUser />
            </TabPane>
            <TabPane tab="OFFICIALカリキュラム" key="2">
              <OfficialCurriculum />
            </TabPane>
          </Tabs>
          <Button
            className="btn-upload"
            onClick={() => setVisible(true)}
            icon={<CloudDownloadOutlined className="icon" />}
          >
            エクスポート
          </Button>
        </div>
      </DashboardStyled>
      <ConfirmExportFile
        visible={visible}
        setVisible={setVisible}
        subTitle="エクスポートを実行します。"
      />
    </>
  );
};

export default Dashboard;
