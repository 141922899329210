import styled from 'styled-components';
import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

type Props = {
  collapsedMenu: boolean;
  isEmptyData: boolean;
};
export default styled.section<Props>`
  position: relative;
  background: #f9f8f8;
  padding: 30px;
  min-height: 100vh;
  .text-note {
    color: #424242;
    font-size: 12px;
    line-height: 180%;
  }
  .line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  @media (max-width: 1440px) {
    .form-search {
      justify-content: flex-start !important;
      .btn-search {
        width: 100px;
      }
    }
    .wrap-button {
      .btn-active {
        width: 160px;
      }
    }
    .item {
      width: 240px !important;
      margin-right: 8px;
    }
    .btn-search,
    .label-reset {
      margin-left: 12px;
    }
    .table {
      .ant-table-thead {
        height: 44px;
      }
      .ant-table-tbody {
        .ant-table-cell {
          height: 44px;
        }
      }
    }
  }
  .form-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-form-item-label > label {
      font-size: 12px;
    }
    .item {
      width: 22%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
    }
    .icon-search {
      font-size: 18px;
      margin-right: 12px;
    }
  }
  .wrap-button {
    position: absolute;
    right: 0;
    margin: 12px 30px;
    z-index: 9;
    .btn {
      padding: 0 30px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      margin-left: 10px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      .icon {
        display: inline-flex;
        align-items: center;
        font-size: 18px;
        margin: 1px 8px 0 0;
      }
    }
  }
  .table {
    padding-bottom: 95px;
    margin-top: ${(props) => (props.isEmptyData ? 64 : 0)}px;
    .text-count {
      font-family: 'Lato';
      font-size: 14px;
      color: #424242;
    }
    .ant-table .ant-table-container::before {
      content: 'ALL';
      margin-left: 11px;
      margin-top: 3px;
      font-size: 11px;
      position: absolute;
    }
    .ant-table-thead .ant-checkbox-wrapper {
      position: relative;
      margin-top: 10px;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        padding: 5.5px;
        background-color: #ebebeb;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: 'Lato';
        font-size: 13px;
        text-align: center;
        color: #424242;
        padding: 10px 12px;
        word-wrap: break-word;
      }
      .icon {
        color: #c4c4c4;
        font-size: 20px;
        cursor: pointer;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
    }
  }
  .wrap-bottom {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
    padding-left: 44px;
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
  }
`;
