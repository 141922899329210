import { AxiosInstance } from 'axios';

import curriculumHierachy from './mocks/curriculumHierarchy.json';
import officicalCurriculum from './mocks/officialCurriculum.json';
import officialRanking from './mocks/officialRanking.json';
import employeeNumber from './mocks/employeeNumber.json';
import chartCompany from './mocks/chartCompany.json';
import archiveList from './mocks/archiveList.json';
import noteRelease from './mocks/noteRelease.json';
import companyUser from './mocks/CompanyUser.json';
import authority from './mocks/authorityData.json';
import official from './mocks/officialPublic.json';
import curriculum from './mocks/curriculum.json';
import MockAdapter from 'axios-mock-adapter';
import employee from './mocks/employee.json';
import question from './mocks/question.json';
import jobTitle from './mocks/jobTitle.json';
import release from './mocks/release.json';
import { config } from 'configs';

export const mockAdapter = (instance: AxiosInstance) => {
  const mock = new MockAdapter(instance, { delayResponse: 1500 });
  mock
    .onPost(`applications/${config.APP_ID}/reports/employeeNumber/filter`)
    .reply(200, employeeNumber);
  mock.onPost(`applications/${config.APP_ID}/reports/employee/filter`).reply(200, employee);
  mock
    .onPost(`/applications/${config.APP_ID}/datastores/authority/items/search`)
    .reply(200, authority);
  mock
    .onPost(`/applications/${config.APP_ID}/datastores/jobTitle/items/search`)
    .reply(200, jobTitle);
  mock.onPost(`applications/${config.APP_ID}/reports/companyUser/filter`).reply(200, companyUser);
  mock.onPost(`applications/${config.APP_ID}/reports/chartCompany/filter`).reply(200, chartCompany);
  mock
    .onPost(`applications/${config.APP_ID}/reports/officialCurriculum/filter`)
    .reply(200, officicalCurriculum);
  mock.onPost(`applications/${config.APP_ID}/reports/ranking/filter`).reply(200, officialRanking);
  mock.onPost(`applications/${config.APP_ID}/reports/official/filter`).reply(200, official);
  mock.onPost(`applications/${config.APP_ID}/reports/release/filter`).reply(200, release);
  mock.onPost(`applications/${config.APP_ID}/reports/noteRelease/filter`).reply(200, noteRelease);
  mock.onPost(`applications/${config.APP_ID}/reports/archiveList/filter`).reply(200, archiveList);
  mock.onPost(`applications/${config.APP_ID}/reports/curriculum/filter`).reply(200, curriculum);
  mock
    .onPost(`applications/${config.APP_ID}/reports/curriculum_hierarchy/filter`)
    .reply(200, curriculumHierachy);
  mock.onPost(`applications/${config.APP_ID}/reports/question_search/filter`).reply(200, question);
};
