import React, { useCallback, useEffect, useState } from 'react';
import { UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { Button, Col, Row, Select, Tooltip } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form, Radio } from 'formik-antd';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,
  SelectOutlined,
} from '@ant-design/icons';

import { Input, Modal, SelectField, SpinLoading, TextArea } from 'components';
import { ErrorModelToOpen, UploadFileToServerModel } from 'types/components';
import { handleGetQuestionDetail, handleSubmit } from 'libs/utils/question';
import { questionSelector } from 'containers/CreateEditQuestion/selectors';
import UploadFileToServer from 'components/Modal/UploadFilesToServer';
import { settingSelector } from 'containers/AppSettings/selectors';
import { UploadImageVideo, UploadImageVideoQuestion } from '../';
import { createEditQuestionSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { getDataQuestion } from '../../thunk';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import PopupComment from '../Comment';
import { NoImage2 } from 'assets';
import * as Types from 'types';
import { find } from 'lodash';
import { COMPANIES } from 'configs';
import {
  getCompany,
  updateTimeLimitCompany,
} from 'pages/CurriculumManagement/QuestionMaster/thunk';

interface Props {
  openModalCreateEditQuestion: {
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
  };
  setOpenModalCreateEditQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
    }>
  >;
}

const { Option } = Select;

const initShowActionErrorModal = {
  errorVisible: false,
  title: '',
  subTitle: '',
  description: '',
};

const CreateEditQuestionMaster: React.FC<Props> = ({
  setOpenModalCreateEditQuestion,
  openModalCreateEditQuestion: { type, visible, question_id },
}) => {
  const [uploading, setUploading] = useState<UploadFileToServerModel>({ show: false, files: [] });
  const [showPopupComment, setShowPopupComment] = useState<boolean>(false);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [isSettingTime, setIsSettingTime] = useState<boolean>(false);
  const [filesInServer, setFilesInServer] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] =
    useState<ErrorModelToOpen>(initShowActionErrorModal);
  const [showPopupUploadMedia, setShowPopupUploadMedia] = useState<{
    show: boolean;
    data: Array<UploadFileAntd<File>>;
    type: Types.UploadFileFieldType;
  }>({ show: false, data: [], type: 'attach' });
  const [loadingSetTimeLimit, setLoadingSetTimeLimit] = useState(false);

  const { time_limit, defaultTimeLimit } = useSelector(questionSelector);
  const { indexState } = useSelector(questionSelector);
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const handleOnSetVisible = (isVisible: boolean) => {
    setShowActionErrorModal({ subTitle: '', title: '', description: '', errorVisible: isVisible });
  };

  const handleOnChangeVisibleModel = (visibleUploadFileModel: boolean) => {
    setUploading((prevState: UploadFileToServerModel) => ({
      ...prevState,
      show: visibleUploadFileModel,
    }));
  };

  const handleSubmitUploadFiles = (
    data: Array<UploadFileAntd<File> | undefined>,
    fieldType: Types.UploadFileFieldType
  ) => {
    formik.setFieldValue(fieldType, data);
  };

  const handleCancel = () => {
    setOpenModalCreateEditQuestion({ visible: false, type });
    formik.resetForm();
  };

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: '',
      time_limit: defaultTimeLimit,
      comment: '',
      score: 1,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
    },
    enableReinitialize: true,
    validationSchema: createEditQuestionSchema,
    onSubmit: (values) =>
      handleSubmit({
        dispatch,
        fileIdsToDelete,
        filesInServer,
        handleCancel,
        setFileIdsToDelete,
        setFilesInServer,
        setShowActionErrorModal,
        setUploading,
        type,
        userInfo,
        values,
        i_id: question_id,
        handleFetchData: async () => {
          await dispatch(
            getDataQuestion({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: 1,
              per_page: 10,
              include_lookups: true,
              include_item_ref: true,
              omit_total_items: false,
            })
          );
        },
      }),
  });

  const close = () => {
    formik.setFieldValue('time_limit', time_limit[indexState].value);
    setIsSettingTime(true);
    if (openModal) {
      setTimeout(() => {
        setOpenModal(false);
        setIsSettingTime(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (openModal === false) {
      setTimeout(() => {
        setIsSettingTime(false);
      }, 500);
    }
  }, [openModal]);

  const handleShowModalUploadFile = (fieldType: Types.UploadFileFieldType) => {
    const data =
      fieldType === 'problems'
        ? [
            formik.values.problems1_attach[0],
            formik.values.problems2_attach[0],
            formik.values.problems3_attach[0],
          ].filter((file) => file ?? false)
        : formik.values[fieldType];

    setShowPopupUploadMedia({
      show: true,
      data: data,
      type: fieldType,
    });
  };

  useEffect(() => {
    if (!visible) return;

    handleGetQuestionDetail({
      dispatch,
      handleCancel,
      setFilesInServer,
      type,
      i_id: question_id,
      formik,
    });

    return () => {
      formik.setValues({
        name: '',
        description: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: defaultTimeLimit,
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
      });
      setFilesInServer([]);
      setFileIdsToDelete([]);
      setUploading({ show: false, files: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type, visible]);

  const handleSubmitSelectTimeLimit = async () => {
    const index = time_limit.findIndex((obj) => obj.option_id === formik.values.time_limit);
    if (index < 0) {
      return;
    }
    setLoadingSetTimeLimit(true);
    const id = find(userInfo?.item_links?.links, ({ d_id }) => d_id === COMPANIES.id)?.items[0]
      ?.i_id;

    const actionResult = await dispatch(
      updateTimeLimitCompany({
        id: id ?? '',
        data: {
          item: {
            time_limit: index,
          },
          is_force_update: true,
        },
      })
    );

    if (updateTimeLimitCompany.fulfilled.match(actionResult)) {
      close();
      fetchCompany();
    }
    setLoadingSetTimeLimit(false);
  };

  const fetchCompany = useCallback(() => {
    if (!userInfo) return;

    dispatch(
      getCompany({
        conditions: [
          {
            id: 'id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  return (
    <Modal
      title={type === 'create' ? '設問新規作成' : '設問編集'}
      width={860}
      className="btn"
      visible={visible}
      okButton={{
        text: type === 'create' ? '登録' : '更新',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.isValid || !formik.dirty,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setOpenModalCreateEditQuestion({ visible: false, type }),
      }}
      onCancel={() => setOpenModalCreateEditQuestion({ visible: false, type })}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          {type === 'create'
            ? '設問の新規作成画面です。入力後に登録ボタンをクリックしてください。'
            : '設問の内容確認と編集用の画面です。編集した場合は更新ボタンをクリックしてください。'}
        </p>
        <SpinLoading size="large" loading={loading}>
          <FormikProvider value={formik}>
            <Form colon={false} layout="vertical" className="form">
              <p className="label-note">
                <span className="number">1</span>設問内容
              </p>
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    設問名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  name="name"
                  className="input"
                  type="text"
                  showCount
                  maxLength={30}
                  placeholder="全角：最大30文字"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={
                  <span className="text-label">
                    設問内容
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="description"
                  className="input-textarea"
                  showCount
                  maxLength={480}
                  placeholder="全角：最大100文字"
                />
              </Form.Item>
              <Form.Item name="problems1_attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">設問画像・動画 </p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('problems1_attach')}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  <div className="image">
                    <img
                      src={
                        formik.values.problems1_attach[0]
                          ? URL.createObjectURL(
                              new Blob([
                                formik.values.problems1_attach[0].originFileObj as BlobPart,
                              ])
                            )
                          : NoImage2
                      }
                      className="image"
                      alt={formik.values.problems1_attach[0]?.name}
                    />
                  </div>
                  <div className="image">
                    <img
                      src={
                        formik.values.problems1_attach[1]
                          ? URL.createObjectURL(
                              new Blob([
                                formik.values.problems1_attach[1].originFileObj as BlobPart,
                              ])
                            )
                          : NoImage2
                      }
                      className="image"
                      alt={formik.values.problems1_attach[1]?.name}
                    />
                  </div>
                  <div className="image">
                    <img
                      src={
                        formik.values.problems1_attach[2]
                          ? URL.createObjectURL(
                              new Blob([
                                formik.values.problems1_attach[2].originFileObj as BlobPart,
                              ])
                            )
                          : NoImage2
                      }
                      className="image"
                      alt={formik.values.problems1_attach[2]?.name}
                    />
                  </div>
                </div>
                <div
                  className={`text-clear${formik.values.problems1_attach ? '' : '__disabled'}`}
                  onClick={() => formik.setFieldValue('problems1_attach', [])}
                >
                  クリア
                </div>
              </div>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">2</span>解答選択肢
              </p>
              <Form.Item
                name="problems1"
                label={
                  <span className="text-label">
                    選択肢A
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems1"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems2"
                label={
                  <span className="text-label">
                    選択肢B
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems2"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems3"
                label={
                  <span className="text-label">
                    選択肢C
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems3"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item name="problems2_attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">回答選択肢画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('problems2_attach')}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  <div>
                    <div className="image">
                      <img
                        src={
                          formik.values.problems2_attach[0]
                            ? URL.createObjectURL(
                                new Blob([
                                  formik.values.problems2_attach[0].originFileObj as BlobPart,
                                ])
                              )
                            : NoImage2
                        }
                        className="image"
                        alt={formik.values.problems2_attach[0]?.name}
                      />
                    </div>
                    <p className="label">選択肢A</p>
                  </div>
                  <div>
                    <div className="image">
                      <img
                        src={
                          formik.values.problems2_attach[1]
                            ? URL.createObjectURL(
                                new Blob([
                                  formik.values.problems2_attach[1].originFileObj as BlobPart,
                                ])
                              )
                            : NoImage2
                        }
                        className="image"
                        alt={formik.values.problems2_attach[1]?.name}
                      />
                    </div>
                    <p className="label">選択肢B</p>
                  </div>
                  <div>
                    <div className="image">
                      <img
                        src={
                          formik.values.problems2_attach[2]
                            ? URL.createObjectURL(
                                new Blob([
                                  formik.values.problems2_attach[2].originFileObj as BlobPart,
                                ])
                              )
                            : NoImage2
                        }
                        className="image"
                        alt={formik.values.problems2_attach[2]?.name}
                      />
                    </div>
                    <p className="label">選択肢C</p>
                  </div>
                </div>
                <div
                  className={`text-clear${formik.values.problems2_attach ? '' : '__disabled'}`}
                  onClick={() => formik.setFieldValue('problems2_attach', [])}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="answer"
                label={
                  <span className="text-label">
                    解答
                    <span className="require">*</span>
                  </span>
                }
                className="form-input form-input-radio"
              >
                <Radio.Group name="answer" className="radio_group">
                  <Radio name="answer" value="A" className="radio-text">
                    選択肢A
                  </Radio>
                  <Radio name="answer" value="B" className="radio-text">
                    選択肢B
                  </Radio>
                  <Radio name="answer" value="C" className="radio-text">
                    選択肢C
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">3</span>解説・その他設定
              </p>
              <Form.Item
                name="comment"
                label={
                  <div className="wrap-label-form-input">
                    <span className="text-label">
                      解説
                      <span className="require">*</span>
                    </span>
                    <span className="text-enlarge" onClick={() => setShowPopupComment(true)}>
                      <SelectOutlined className="icon" />
                      入力欄を拡大
                    </span>
                  </div>
                }
                className="form-input"
              >
                <TextArea
                  name="comment"
                  className="input-textarea"
                  showCount
                  maxLength={1050}
                  placeholder="全角：最大100文字"
                />
              </Form.Item>
              <Form.Item name="problems3_attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">設問画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('problems3_attach')}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  <div className="image">
                    <img
                      src={
                        formik.values.problems3_attach[0]
                          ? URL.createObjectURL(
                              new Blob([
                                formik.values.problems3_attach[0].originFileObj as BlobPart,
                              ])
                            )
                          : NoImage2
                      }
                      className="image"
                      alt={formik.values.problems3_attach[0]?.name}
                    />
                  </div>
                  <div className="image">
                    <img
                      src={
                        formik.values.problems3_attach[1]
                          ? URL.createObjectURL(
                              new Blob([
                                formik.values.problems3_attach[1].originFileObj as BlobPart,
                              ])
                            )
                          : NoImage2
                      }
                      className="image"
                      alt={formik.values.problems3_attach[1]?.name}
                    />
                  </div>
                  <div className="image">
                    <img
                      src={
                        formik.values.problems3_attach[2]
                          ? URL.createObjectURL(
                              new Blob([
                                formik.values.problems3_attach[2].originFileObj as BlobPart,
                              ])
                            )
                          : NoImage2
                      }
                      className="image"
                      alt={formik.values.problems3_attach[3]?.name}
                    />
                  </div>
                </div>
                <div
                  className={`text-clear ${formik.values.problems3_attach ? '' : '__disabled'}`}
                  onClick={() => formik.setFieldValue('problems3_attach', [])}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="time_limit"
                label={
                  <span className="text-label">
                    制限時間
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <SelectField name="time_limit" className="time_limit">
                  {time_limit.map((item, index) => (
                    <Option key={index} value={item.option_id}>
                      {item.value}
                    </Option>
                  ))}
                </SelectField>
                <Tooltip
                  visible={openModal}
                  onVisibleChange={setOpenModal}
                  trigger={['click']}
                  overlayClassName="tooltip-setup-time-limit"
                  placement="topLeft"
                  title={
                    <>
                      {isSettingTime ? (
                        <div className="text-content">
                          <CheckCircleOutlined className="icon" />
                          設定しました
                        </div>
                      ) : (
                        <>
                          <div className="text-content">
                            現在設定されている制限時間を設問新規作 <br />
                            成時の制限時間初期値として設定します。
                          </div>
                          <div className="box-center">
                            <Button
                              loading={loadingSetTimeLimit}
                              className="btn-set-time"
                              onClick={handleSubmitSelectTimeLimit}
                            >
                              設定する
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  }
                >
                  <ClockCircleOutlined className="watch-icon" />
                  <span className="time-setting">制限時間初期値設定</span>
                </Tooltip>
              </Form.Item>
              <Row>
                <Col span={24}>
                  <div className="form-input-radio">
                    <span className="text-label">
                      スコア設定（設問難易度の設定）
                      <span className="require">*</span>
                      <Tooltip
                        trigger={['click']}
                        overlayClassName="question-tooltip"
                        title={
                          <>
                            <h6
                              style={{
                                fontFamily: 'Noto Sans Javanese',
                                fontWeight: '400',
                                fontSize: 16,
                                color: '#2A2A2A',
                              }}
                            >
                              スコア設定（設問難易度の設定）について
                            </h6>
                            <p>
                              スコアは設問の難易度を設定する項目です。
                              <br />
                              設定の際は「１・２・３」の３段階からスコアを選択します。
                              <br />
                              <br />
                              スコアは設問の難易度を表すと共に、獲得スコアとして得点も表します。
                              <br />
                              スキルチェック実施の際に、正解した設問に設定されているスコアを獲得できます。
                              <br />
                              <br />
                              設定したスコアの情報や獲得したスコアの結果はレポートから確認することができます。
                              <br />
                              <br />
                              獲得したスコアから、スキルチェックの結果が難易度の高い設問を多く正解できているか、難易度の低い設問を多く
                              <br />
                              間違えているので、基礎ができていないか、ケアレスミスで間違えている等の分析ができる仕組みです。
                              <br />
                              <br />
                              正解した数や割合だけでなく、質をはかる指標です。
                              <br />
                              <br />
                              スキルチェック実施後は、ぜひスコアを活用してみてください。
                            </p>
                          </>
                        }
                      >
                        <QuestionCircleOutlined className="question-mark-icon" />
                      </Tooltip>
                    </span>
                    <Radio.Group name="score" className="radio_group">
                      <Radio name="score" value={1} className="radio-text">
                        1
                      </Radio>
                      <Radio name="score" value={2} className="radio-text">
                        2
                      </Radio>
                      <Radio name="score" value={3} className="radio-text">
                        3
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </SpinLoading>
        <UploadImageVideo
          visible={showPopupUploadMedia}
          setVisible={setShowPopupUploadMedia}
          onSubmit={handleSubmitUploadFiles}
        />
        <UploadImageVideoQuestion
          visible={showPopupUploadMedia}
          setVisible={setShowPopupUploadMedia}
          onSubmit={handleSubmitUploadFiles}
        />
        <ActionErrorModal
          visible={showActionErrorModal.errorVisible}
          description={showActionErrorModal.description}
          subTitle={showActionErrorModal.subTitle}
          title={showActionErrorModal.title}
          setVisible={handleOnSetVisible}
        />
        <PopupComment
          visible={showPopupComment}
          setVisible={setShowPopupComment}
          commentValues={formik.values.comment}
          onSubmit={(values) => {
            setShowPopupComment(false);
            formik.setFieldValue('comment', values);
          }}
        />
        <UploadFileToServer
          uploadingFiles={uploading.files}
          visible={uploading.show}
          setVisible={(isVisible: boolean) => handleOnChangeVisibleModel(isVisible)}
          title={'アップロード中'}
        />
      </SectionStyled>
    </Modal>
  );
};

export default CreateEditQuestionMaster;
