import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { getConfirmRegistration, isFirstLogin, signIn } from './thunk';
import { getUserInfo } from 'pages/Profile/thunk';
import * as Types from 'types';

export interface InitialState {
  isLogin: boolean;
  userInfo?: Types.User.ResponseType;
  signedInEmail?: string;
  isFirstLogin: boolean;
  registrationConfirm?: Types.GetConfirmRegistrationRes;
  loading: boolean;
  IDconfirming: boolean;
  err?: string;
  disableLoading?: boolean;
}

const initialState: InitialState = {
  isLogin:
    localStorage.getItem('email') && localStorage.getItem('remember_me') === 'saved' ? true : false,
  isFirstLogin: false,
  IDconfirming: false,
  loading: false,
  disableLoading: false,
};

export const authSlice = createSlice({
  name: 'auth-container',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogin = false;
      state.userInfo = undefined;
      state.signedInEmail = undefined;
      localStorage.clear();
    },
    updateUserInfoReducer: (state, action: PayloadAction<Types.User.ResponseType>) => {
      state.userInfo = action.payload;
    },
    updateDisableLoadingReducer: (state, action: PayloadAction<boolean>) => {
      state.disableLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.signedInEmail = action.payload.signInUrl;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.err = action.error.message;
      state.isLogin = false;
    });
    builder.addCase(isFirstLogin.pending, (state) => {
      if (!state.disableLoading) {
        state.loading = true;
      }
    });
    builder.addCase(isFirstLogin.fulfilled, (state, action) => {
      if (action.payload.totalItems) {
        state.userInfo = omit(action.payload.items[0], ['password']) as Types.User.ResponseType;
        state.isFirstLogin = action.payload.items[0].isFistLogin;
        state.isLogin = true;
      } else {
        state.isLogin = false;
      }
      state.loading = false;
      state.disableLoading = false;
    });
    builder.addCase(isFirstLogin.rejected, (state) => {
      state.isLogin = false;
      state.loading = false;
      state.disableLoading = false;
    });
    builder.addCase(getConfirmRegistration.pending, (state) => {
      state.IDconfirming = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload.items[0];
      state.loading = false;
      state.disableLoading = false;
    });

    builder.addCase(getConfirmRegistration.fulfilled, (state, action) => {
      state.registrationConfirm = action.payload;
      state.IDconfirming = false;
    });
    builder.addCase(getConfirmRegistration.rejected, (state) => {
      state.IDconfirming = false;
    });
  },
});

export const { logout, updateUserInfoReducer, updateDisableLoadingReducer } = authSlice.actions;
export default authSlice.reducer;
