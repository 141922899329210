import { createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';

import * as Types from 'types';
import {
  getDataCompaniesCurriculumn,
  getDataNumberUserCurriculum,
  getDataOfficialCurriculum,
  getDataOfficialCompanyRanking,
  getDataOfficialUserRanking,
} from './thunk';

export type InitialState = {
  loading: boolean;
  companyUserData: Array<Types.CompanyUserRes>;
  companyUserCurriculumChartData: Array<Types.CompanyUserCurriculumnChartRes>;
  numberUserCurriculumChartData: Array<Types.NumberUserCurriculumChartRes>;
  companyUserCurriculumData: Array<Types.CompanyUserCurriculumRes>;
  numberUserCurriculumData: Array<Types.NumberUserCurriculumRes>;
  officialCurriculumData: Array<Types.OfficialCurriculumRes>;
  officialCompanyRanking: Array<Types.OfficialCompanyRankingRes>;
  officialUserRanking: Array<Types.OfficialUserRankingRes>;
  total: number;
};

const initialState: InitialState = {
  loading: false,
  companyUserData: [],
  companyUserCurriculumChartData: [],
  officialCurriculumData: [],
  officialCompanyRanking: [],
  numberUserCurriculumChartData: [],
  numberUserCurriculumData: [],
  companyUserCurriculumData: [],
  officialUserRanking: [],
  total: 0,
};

export const dashboardSlice = createSlice({
  name: 'dashboard-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stoploading = (state: InitialState) => {
      state.loading = false;
    };
    builder
      .addCase(getDataCompaniesCurriculumn.pending, startLoading)
      .addCase(getDataOfficialCurriculum.pending, startLoading)
      .addCase(getDataOfficialCompanyRanking.pending, startLoading)
      .addCase(getDataNumberUserCurriculum.pending, startLoading)
      .addCase(getDataOfficialUserRanking.pending, startLoading);

    builder.addCase(getDataCompaniesCurriculumn.fulfilled, (state, action) => {
      const arrayGroupByMonth = groupBy(action.payload.report_results, (e) =>
        dayjs(e.createdat).format('YYYY/MM')
      );
      state.companyUserCurriculumChartData = Object.keys(arrayGroupByMonth).map((e: string) =>
        arrayGroupByMonth[e].reduce(
          (result) => ({
            ...result,
            date: e,
            value: result.value + 1,
          }),
          {
            date: '',
            value: 0,
          }
        )
      );
      state.companyUserCurriculumData = action.payload.report_results.map((e) => ({
        ...e,
        createdat: dayjs(e.createdat).format('YYYY/MM'),
      }));
      stoploading(state);
    });
    builder.addCase(getDataNumberUserCurriculum.fulfilled, (state, action) => {
      const arrayGroupByMonth = groupBy(action.payload.report_results, (e) =>
        dayjs(e.implementation_date).format('YYYY/MM')
      );
      state.numberUserCurriculumChartData = Object.keys(arrayGroupByMonth).map((e: string) =>
        arrayGroupByMonth[e].reduce(
          (result) => ({
            ...result,
            date: e,
            value: result.value + 1,
          }),
          {
            date: '',
            value: 0,
          }
        )
      );
      state.numberUserCurriculumData = action.payload.report_results.map((e) => ({
        ...e,
        implementation_date: dayjs(e.implementation_date).format('YYYY/MM'),
      }));
      stoploading(state);
    });
    builder.addCase(getDataOfficialCurriculum.fulfilled, (state, action) => {
      state.officialCurriculumData = action.payload.report_results;
      stoploading(state);
    });
    builder.addCase(getDataOfficialCompanyRanking.fulfilled, (state, action) => {
      state.officialCompanyRanking = action.payload.report_results || [];
      stoploading(state);
    });

    builder.addCase(getDataOfficialUserRanking.fulfilled, (state, action) => {
      state.officialUserRanking = action.payload.report_results;
      stoploading(state);
    });

    builder
      .addCase(getDataCompaniesCurriculumn.rejected, stoploading)
      .addCase(getDataOfficialCurriculum.rejected, stoploading)
      .addCase(getDataOfficialCompanyRanking.rejected, stoploading)
      .addCase(getDataNumberUserCurriculum.rejected, stoploading)
      .addCase(getDataOfficialUserRanking.rejected, stoploading);
  },
});

// export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
