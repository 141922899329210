import React, { useEffect, useState } from 'react';
import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { Popover } from 'antd';

import { Wrapper as WrapperColumnRoot } from '../NodeTreeView/TreeNodeRenderer/styles';
import { curriculumSelector } from 'pages/CurriculumManagement/Curriculum/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { UserTreeviewType } from 'types/services/curriculum';
import { authSelector } from 'containers/Auth/selectors';
import { Wrapper, WrapperToolTip } from './styles';
import NodeRenderer from './NodeRenderer';
import { useAppDispatch } from 'hooks';
import {
  getDataUserSetting,
  deleteLinkUserAssignCurriculum,
} from 'pages/CurriculumManagement/Curriculum/thunk';

interface Props {
  columnClosed?: number;
  treeData: UserTreeviewType;
  curriculumSelected?: UserTreeviewType;
  setCurriculumSelected: React.Dispatch<React.SetStateAction<UserTreeviewType | undefined>>;
}

const UserTreeView: React.FC<Props> = ({
  treeData,
  columnClosed,
  curriculumSelected,
  setCurriculumSelected,
}) => {
  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const [, drop] = useDrop<
    { type: string; user_id: string; department_name: string },
    {
      curricullum_code?: string;
      publish?: number;
    },
    void
  >({
    accept: 'move-user',
    canDrop: (item) =>
      curriculumSelected?.i_id === treeData.i_id &&
      !treeData.children?.some(
        (c) =>
          c.department_name === item.department_name &&
          c.children?.some((user) => user.user_id === item.user_id)
      ),
    drop: () => ({
      curricullum_code: treeData.curricullum_code,
      publish: treeData.publish,
    }),
  });

  const handleRemoveLinkUser = async () => {
    const resultAction = await dispatch(
      deleteLinkUserAssignCurriculum({
        id: itemId,
      })
    );
    if (deleteLinkUserAssignCurriculum.fulfilled.match(resultAction)) {
      dispatch(
        getDataUserSetting({
          include_lookups: true,
          include_item_ref: true,
          conditions: [
            ...filter_conditions.conditions,
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  };

  const handleEditLinkUser = () => {
    console.log('edit user', itemId);
  };

  useEffect(() => {
    return () => {
      setCurriculumSelected(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      className="rst__tree"
      treeActive={
        curriculumSelected !== undefined &&
        curriculumSelected.curricullum_code === treeData.curricullum_code
      }
    >
      <WrapperColumnRoot nodeHeight={56} className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            isPublish={treeData.publish!}
            curricullum_name={treeData.curricullum_name!}
            curricullum_description={treeData.curricullum_description!}
            curriculumSelected={
              curriculumSelected !== undefined &&
              curriculumSelected.curricullum_code === treeData.curricullum_code
            }
            handleSwitch={(value: boolean) => setCurriculumSelected(value ? treeData : undefined)}
          />
        </div>
      </WrapperColumnRoot>
      {columnClosed !== 0 && (
        <div ref={drop} className="tree">
          {treeData?.children && treeData.children.length > 0 ? (
            treeData.children.map(({ department_name, children }, i) => (
              <div key={i}>
                <p className="text-name">{department_name}</p>
                <div className="wrap_node_tree">
                  {children?.map((value, index2) => (
                    <Popover
                      key={index2}
                      placement="bottomLeft"
                      overlayStyle={{
                        width: 204,
                        marginTop: '-25px',
                      }}
                      trigger="click"
                      content={
                        <WrapperToolTip>
                          <span className="text-code">{department_name}</span>
                          <br />
                          <span className="text-bold">{value.user_name}</span>
                          {!treeData.publish && (
                            <div className="text_icon">
                              <EditOutlined
                                className="icon"
                                onClick={() => {
                                  setItemId(value.i_id!);
                                  setVisibleModalEdit(true);
                                }}
                              />
                              {'  '}
                              <MinusOutlined
                                className="icon"
                                onClick={() => {
                                  setItemId(value.i_id!);
                                  setVisibleModalDelete(true);
                                }}
                              />
                            </div>
                          )}
                        </WrapperToolTip>
                      }
                    >
                      <div className="node-item">
                        <span className="text-code">{department_name}</span>
                        <span className="text-bold">{value.user_name}</span>
                      </div>
                    </Popover>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className="text-not-set">未設定</p>
          )}
        </div>
      )}
      <ConfirmDeleteModal
        title="ユーザー解除"
        visible={visibleModalDelete}
        setVisible={setVisibleModalDelete}
        onCancel={() => setItemId('')}
        onSubmit={handleRemoveLinkUser}
        subTitle="指定したユーザーの必修カリキュラム設定を解除します。"
      />
      <ConfirmDeleteModal
        title="社内ユーザー編集"
        visible={visibleModalEdit}
        setVisible={setVisibleModalEdit}
        onSubmit={handleEditLinkUser}
        onCancel={() => setItemId('')}
        subTitle="社内ユーザーマスタに移動します。"
      />
    </Wrapper>
  );
};

export default UserTreeView;
