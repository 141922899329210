import { createAsyncThunk } from '@reduxjs/toolkit';

import { ROLES, SELECT_ROLE } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getDataAuthority = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/getDataAuthority', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAuthorityCSV = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/getDataAuthorityCSV', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.id, {
      page: 1,
      per_page: 0,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchDataAuthority = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/searchDataAuthority', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateItemAuthorityByID = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/editItemAuthorityByID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getReportDataByConditions = createAsyncThunk<
  undefined,
  Types.GetItemDetailRequestType,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/GetReportDataByConditions', async (req, { rejectWithValue }) => {
  try {
    if (!req.id) return;
    const { data } = await services.detail(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createItemAuthority = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/createItemAuthority', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ROLES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemAuthority = createAsyncThunk<
  string,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/thunk/deleteItemAuthority', async (req, { rejectWithValue }) => {
  try {
    await services.delete(ROLES.id, req);

    return req.id;
  } catch (error) {
    return rejectWithValue(error);
  }
});
