import styled from 'styled-components';
import { Input, PasswordProps } from 'formik-antd';

export default styled(Input.Password)<PasswordProps>`
  font-size: 14px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
`;
