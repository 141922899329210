import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { employeeSelector } from '../../selector';
import { CurriculumCheckType } from 'types';
import SectionStyled from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: (item_id: string, hidden: 'on' | 'off') => void | Promise<void>;
}

const PER_PAGE = 10;

const CurriculumHiddenListModal: React.FC<Props> = ({
  onSubmit,
  onCancel,
  visible,
  setVisible,
}) => {
  const [data, setData] = useState<CurriculumCheckType[]>([]);
  const [page, setPage] = useState<number>(1);

  const { loading, dataCurriculumUserCheck } = useSelector(employeeSelector);

  const columns = [
    {
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      width: '80%',
      render: (text: string, _item: CurriculumCheckType, index: number) => (
        <span>
          <span className="text-index">{PER_PAGE * (page - 1) + index + 1}</span> {text}
        </span>
      ),
    },
    {
      dataIndex: 'operation',
      width: '10%',
      render: (_: string, item: CurriculumCheckType) => (
        <span
          className="text-return"
          onClick={() => onSubmit && onSubmit(item.hidden_curriculum_id, 'off')}
        >
          戻す
        </span>
      ),
    },
  ];

  const handleCloseModal = async () => {
    onCancel && (await onCancel());
    setVisible(false);
  };

  useEffect(() => {
    if (dataCurriculumUserCheck) {
      setData(dataCurriculumUserCheck.filter((item) => item.hidden_curriculum === 'on'));
    }
  }, [dataCurriculumUserCheck]);

  return (
    <Modal
      title={<h3 className="title">スキルチェック　非表示リスト</h3>}
      visible={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      cancelButton={{
        text: '閉じる',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled dataCurriculumBatch>
        <p className="sub-title">表示に戻す場合は戻すをクリックしてください。</p>
        <div className="text-count">
          <span className="text-static">
            {page * PER_PAGE > data.length ? data.length : page * PER_PAGE} 件表示 / {data.length}{' '}
            名
          </span>
        </div>
        <Table
          className="table"
          dataSource={data}
          columns={columns}
          loading={loading}
          pagination={{
            pageSize: PER_PAGE,
            showSizeChanger: false,
            total: data.length,
            current: page,
            onChange: setPage,
            position: ['topRight'],
          }}
          rowKey="hidden_curriculum_id"
        />
      </SectionStyled>
    </Modal>
  );
};

export default CurriculumHiddenListModal;
