import React, { useEffect, useMemo, useState } from 'react';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { useSelector } from 'react-redux';
import { Button, Upload } from 'antd';
import { groupBy } from 'lodash';
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  WarningFilled,
} from '@ant-design/icons';

import { createFileAttach, deleteFileAttach, editQuestion, getDataAllQuestion } from '../../thunk';
import { deletedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { questionMasterSelector } from '../../selectors';
import { FileType } from 'constant/enum.constant';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';

interface Props {
  visible: boolean;
  headersTemplate?: Array<{ label: string; key: string }>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadManyImageVideo: React.FC<Props> = ({ visible, setVisible }) => {
  const [isUploadSuccessfully, setUploadSuccessfully] = useState<boolean>(false);
  const [showErrorImages, setShowErrorImages] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [files, setFiles] = useState<
    Array<{
      file: UploadFile<File>;
      status: boolean;
    }>
  >([]);

  const dispatch = useAppDispatch();
  const { userInfo } = useSelector(authSelector);
  const { dataAllQuestion } = useSelector(questionMasterSelector);

  const onClose = () => {
    setVisible(false);
    setFiles([]);
    setUploadSuccessfully(false);
    setShowErrorImages(false);
  };

  const getItemUpdateFile = (
    file: {
      file: UploadFile<File>;
      status: boolean;
      code?: string;
      attach?: string;
    },
    minioId: string
  ) => {
    switch (file.attach) {
      case 'attach1':
        return {
          attach_fileID1: minioId,
          attach_filename1: file.file.name,
        };
      case 'attach2':
        return {
          attach_fileID2: minioId,
          attach_filename2: file.file.name,
        };
      case 'attach3':
        return {
          attach_fileID3: minioId,
          attach_filename3: file.file.name,
        };

      case 'problems1_attach':
        return {
          problems1_attach_fileID: minioId,
          problems1_attach_filename: file.file.name,
        };
      case 'problems2_attach':
        return {
          problems2_attach_fileID: minioId,
          problems2_attach_filename: file.file.name,
        };
      case 'problems3_attach':
        return {
          problems3_attach_fileID: minioId,
          problems3_attach_filename: file.file.name,
        };

      case 'comment_attach1':
        return {
          comment_attach_fileID1: minioId,
          comment_attach_filename1: file.file.name,
        };
      case 'comment_attach2':
        return {
          comment_attach_fileID2: minioId,
          comment_attach_filename2: file.file.name,
        };
      case 'comment_attach3':
        return {
          comment_attach_fileID3: minioId,
          comment_attach_filename3: file.file.name,
        };

      default:
        return {};
    }
  };

  const fileId = (
    file: {
      file: UploadFile<File>;
      status: boolean;
      code?: string | undefined;
      attach?: string | undefined;
    },
    questionFound: Types.Question.ResponseType
  ) => {
    switch (file.attach) {
      case 'attach1':
        return questionFound.attach_fileID1;
      case 'attach2':
        return questionFound.attach_fileID2;
      case 'attach3':
        return questionFound.attach_fileID3;

      case 'problems1_attach':
        return questionFound.problems1_attach_fileID;
      case 'problems2_attach':
        return questionFound.problems2_attach_fileID;
      case 'problems3_attach':
        return questionFound.problems3_attach_fileID;

      case 'comment_attach1':
        return questionFound.comment_attach_fileID1;
      case 'comment_attach2':
        return questionFound.comment_attach_fileID2;
      case 'comment_attach3':
        return questionFound.comment_attach_fileID3;

      default:
        return '';
    }
  };

  const uploadFileMinioHandler = async (
    file: {
      file: UploadFile<File>;
      status: boolean;
      code?: string | undefined;
      attach?: string | undefined;
    },
    questionFound: Types.Question.ResponseType
  ) => {
    setSubmitting(true);
    const uploadToMinIo = await uploadFileToMinIO(file.file.originFileObj as UploadFile<File>);
    if (uploadToMinIo) {
      const resultActionCreateImages = await dispatch(
        createFileAttach({
          item: {
            fileID: uploadToMinIo,
            filename: file.file.name,
            file_location: '2',
            file_extension: file.file?.type,
            file_size: `${file.file?.size}`,
            company_id: userInfo?.company_id,
            createdat: new Date(),
            createdby: userInfo?.login_id,
          },
        })
      );
      if (createFileAttach.fulfilled.match(resultActionCreateImages)) {
        const item = getItemUpdateFile(file, uploadToMinIo);

        const actionResultEditQuestion = await dispatch(
          editQuestion({
            id: questionFound.i_id,
            data: {
              item,
              return_item_result: true,
              is_force_update: true,
            },
          })
        );

        if (editQuestion.fulfilled.match(actionResultEditQuestion)) {
          await Promise.all([
            deletedFileInMinIO(fileId(file, questionFound)),
            dispatch(
              deleteFileAttach({
                conditions: [
                  { id: 'fileID', search_value: [fileId(file, questionFound)], exact_match: true },
                ],
              })
            ),
          ]);
        } else {
          await Promise.all([
            deletedFileInMinIO(uploadToMinIo),
            dispatch(
              deleteFileAttach({
                conditions: [{ id: 'fileID', search_value: [uploadToMinIo], exact_match: true }],
              })
            ),
          ]);
        }
        setSubmitting(false);
        setFiles([]);
        setUploadSuccessfully(true);
      } else {
        setShowErrorImages(false);
        return;
      }
    }
  };

  const handleUpdateFile = () => {
    const filesCode = files.filter((file) => file.status);
    const groupByCode = groupBy(filesCode, 'code');
    Object.keys(groupByCode).forEach((key) => {
      if (key) {
        const questionFound = dataAllQuestion.find((q) => q.code === key);

        if (questionFound) {
          groupByCode[key].map((file) => {
            uploadFileMinioHandler(file, questionFound);
          });
        }
      }
    });
  };

  const onChange = (info: UploadChangeParam<UploadFile<File>>) => {
    const fileList: Array<{
      file: UploadFile<File>;
      status: boolean;
      code?: string;
      attach?: string;
    }> = [];
    info.fileList.forEach(async (file) => {
      const isJpgOrPngOrMp4 =
        file.type === FileType.JPEG ||
        file.type === FileType.PNG ||
        file.type === FileType.MP4 ||
        file.type === FileType.GIF;
      const isAccept = await new Promise<boolean>((resolve) => {
        if (!file.originFileObj) return resolve(false);
        const src = URL.createObjectURL(new Blob([file.originFileObj]));

        if (file.originFileObj.type === FileType.PNG || file.originFileObj.type === FileType.JPEG) {
          const image = new Image();
          image.src = src;
          image.onload = function () {
            URL.revokeObjectURL(src);
            resolve(file.originFileObj!.size < 10485760);
          };
        } else {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(src);
            resolve(file.originFileObj!.size < 104857600);
          };
          video.src = src;
          video.load();
        }
      });
      if (!isJpgOrPngOrMp4 || !isAccept) {
        fileList.push({ file: file, status: false });
      } else {
        fileList.push({ file: file, status: true });
      }
    });
    setFiles(fileList);
  };

  const { lengthFileError, lengthFileSuccess } = useMemo(
    () => ({
      lengthFileError: files.filter((item) => item.status === false).length,
      lengthFileSuccess: files.filter((item) => item.status === true).length,
    }),
    [files]
  );

  useEffect(() => {
    if (userInfo) {
      dispatch(
        getDataAllQuestion({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, userInfo]);

  return (
    <Modal
      title="設問画像・動画 インポート"
      width={!files.length ? 720 : 860}
      visible={visible}
      okButton={
        !!files.length || isUploadSuccessfully
          ? {
              text: isUploadSuccessfully
                ? 'OK'
                : `${files.length}件のうち${lengthFileSuccess}件を インポートする`,
              onClick: isUploadSuccessfully ? onClose : handleUpdateFile,
              loading: isSubmitting,
              disabled: lengthFileError === files.length && !isUploadSuccessfully,
            }
          : undefined
      }
      cancelButton={
        !isUploadSuccessfully
          ? {
              text: 'キャンセル',
              onClick: onClose,
            }
          : undefined
      }
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {isUploadSuccessfully ? (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        ) : (
          <>
            {!files.length ? (
              <div className="form-upload">
                <div className="form-upload-border">
                  <div className="file-upload">
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      アップロードするファイルをここにドロップ
                      <br />
                      または
                    </p>

                    <div className="flex">
                      <Upload
                        accept=".jpeg, .png, .gif, .mp4"
                        beforeUpload={() => false}
                        onChange={onChange}
                        multiple
                      >
                        <button type="button" className="btn-upload">
                          ファイルを選択
                        </button>
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className="note">
                  ※画像の種別はjpegまたはpngまたはgifで、10MBまでになります。
                  <br />
                  ※動画はmp4で、100MBまでになります。
                  <br />
                  ※インポートできるファイル数は500件までです。
                </div>
              </div>
            ) : (
              <div className="upload-image">
                <div className="wrap-info">
                  <p className="text-label">
                    インポート件数：{files.length}件　<span className="border"> / </span>　
                    紐づけエラー件数：
                    <span className="text-value">{lengthFileError}件</span>
                  </p>
                  <Button
                    className="btn-outline"
                    onClick={() => setShowErrorImages((prevState) => !prevState)}
                  >
                    {showErrorImages ? 'すべて表示する' : 'エラーのみ表示する'}
                  </Button>
                </div>
                <div className="body">
                  {files
                    .filter((file) => (showErrorImages ? !file.status : true))
                    .map((item, index) => (
                      <div key={index} className={`item-image${!item.status ? ' error' : ''}`}>
                        {item.file.type === FileType.JPEG ||
                        item.file.type === FileType.PNG ||
                        item.file.type === FileType.GIF ? (
                          <img
                            src={URL.createObjectURL(
                              new Blob([item.file.originFileObj as BlobPart])
                            )}
                            alt={item.file.name}
                            className="image"
                          />
                        ) : item.file.type === FileType.MP4 ? (
                          <div className="wrap-video">
                            <video width="100%" height="100%" controls>
                              <source
                                src={URL.createObjectURL(
                                  new Blob([item.file.originFileObj as Blob])
                                )}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        ) : null}
                        <p className="name">
                          {!item.status && <CloseOutlined className="icon" />}
                          {item.file.name}
                        </p>
                        <div className="status">
                          {item.status ? (
                            <CheckCircleFilled className="icon-successful" />
                          ) : (
                            <WarningFilled className="icon-warning" />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadManyImageVideo;
