import React, { useEffect, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-pro-react';
import { useSelector } from 'react-redux';
import { Button, Radio } from 'antd';
import { differenceBy } from 'lodash';
import { Form } from 'formik-antd';

import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import Completed from 'components/Modal/Completed';
import TemporarilySave from '../TemporarilySave';
import { loadingRef } from 'components/Loading';
import ExecuteDelete from '../ExecuteDelete';
import { Input, Modal, TextArea } from 'components';
import { routes } from 'navigations/routes';
import UpdateStatus from '../UpdateStatus';
import ManageReleaseStyled from './styles';
import { getNoteText } from '../../index';
import { useAppDispatch } from 'hooks';
import Published from '../Published';
import Warning from '../Warning';
import * as Types from 'types';
import {
  getManageRelease,
  getReleaseNoteSection,
  updateReleaseNote,
  createReleaseNoteSection,
  removeReleaseSectionNote,
  updateReleaseNoteSection,
} from '../../thunk';

interface Props {
  selected?: Types.ManageReleaseRes;
  publish: number;
  visible: boolean;
  page: number;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const PER_PAGE = 10;

const NoteReleaseNew: React.FC<Props> = ({ visible, setVisible, publish, selected, page }) => {
  const [visiblePublishWarning, setVisiblePublishWarning] = useState<boolean>(false);
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<boolean>(false);
  const [visibleTemporaly, setVisibleTemporaly] = useState<boolean>(false);
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleDeleted, setVisibleDeleted] = useState<boolean>(false);
  const [visibleWarning, setVisibleWarning] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);

  const { loading, releaseNoteDetail } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik<Types.ReleaseNote.ReleaseNoteFormik>({
    initialValues: releaseNoteDetail ?? {
      title: '',
      description: '',
      version: 1.0,
      curriculum_code: '',
      release_note_id: '',
      sections: [
        {
          section_name: '',
          text: '',
        },
      ],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (userInfo && selected && values) {
        const resultAction = await dispatch(
          updateReleaseNote({
            id: selected?.item_ref.release_note_id.i_id,
            data: {
              item: {
                release_note_title: values.title,
                publish_status: 0,
                description: values.description,
                version: values.version,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        );

        if (updateReleaseNote.fulfilled.match(resultAction)) {
          const listSectionDeleted = differenceBy(
            releaseNoteDetail?.sections,
            values.sections,
            'i_id'
          );
          const resultAction2 = await Promise.all([
            ...values.sections.map(({ i_id, section_name, text }, index) =>
              i_id
                ? dispatch(
                    updateReleaseNoteSection({
                      id: i_id,
                      data: {
                        item: {
                          section_name: section_name,
                          release_note_id: values.release_note_id,
                          display_order: index,
                          text: text,
                          version: values.version,
                          updatedby: userInfo.login_id,
                          updatedat: new Date(),
                        },
                        is_force_update: true,
                        realtime_auto_link: true,
                      },
                    })
                  )
                : dispatch(
                    createReleaseNoteSection({
                      item: {
                        company_id: userInfo?.company_id,
                        section_name: section_name,
                        release_note_id: values.release_note_id,
                        text: text,
                        display_order: index,
                        createdby: userInfo.login_id,
                        createdat: new Date(),
                      },
                      realtime_auto_link: true,
                    })
                  )
            ),
            ...listSectionDeleted.map(({ i_id }) =>
              dispatch(
                removeReleaseSectionNote({
                  id: i_id!,
                })
              )
            ),
          ]);
          if (
            resultAction2.every(
              (result) =>
                createReleaseNoteSection.fulfilled.match(result) ||
                updateReleaseNoteSection.fulfilled.match(result) ||
                removeReleaseSectionNote.fulfilled.match(result)
            )
          ) {
            setVisibleComplete(true);
            setVisibleTemporaly(false);
          }
        }

        await dispatch(
          getManageRelease({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'curriculum_code',
                search_value: [selected.curriculum_code],
              },
            ],
            page: page,
            per_page: PER_PAGE,
            include_item_ref: true,
          })
        );
      }
    },
  });

  const updateStatus = async (type: 'publish' | 'publishWarning') => {
    if (!userInfo || !selected) return;

    const resultAction = await dispatch(
      updateReleaseNote({
        id: selected?.item_ref!.release_note_id.i_id,
        data: {
          item: {
            release_note_title: formik.values.title,
            publish_status: type === 'publish' ? 2 : 3,
            description: formik.values.description,
            version: formik.values.version,
            updatedby: userInfo.login_id,
            updatedat: new Date(),
          },
          is_force_update: true,
          realtime_auto_link: true,
        },
      })
    );

    if (updateReleaseNote.fulfilled.match(resultAction)) {
      const listSectionDeleted = differenceBy(
        releaseNoteDetail?.sections,
        formik.values.sections,
        'i_id'
      );
      const resultAction2 = await Promise.all([
        ...formik.values.sections.map((section, index) =>
          section.i_id
            ? dispatch(
                updateReleaseNoteSection({
                  id: section.i_id,
                  data: {
                    item: {
                      section_name: section.section_name,
                      display_order: index,
                      text: section.text,
                      updatedby: userInfo.login_id,
                      updatedat: new Date(),
                    },
                    is_force_update: true,
                    realtime_auto_link: true,
                  },
                })
              )
            : dispatch(
                createReleaseNoteSection({
                  item: {
                    company_id: userInfo?.company_id,
                    section_name: section.section_name,
                    release_note_id: formik.values.release_note_id,
                    text: section.text,
                    display_order: index,
                    createdby: userInfo.login_id,
                    createdat: new Date(),
                  },
                  realtime_auto_link: true,
                })
              )
        ),
        ...listSectionDeleted.map((i) => dispatch(removeReleaseSectionNote({ id: i.i_id! }))),
      ]);
      if (
        resultAction2.every(
          (result) =>
            createReleaseNoteSection.fulfilled.match(result) ||
            updateReleaseNoteSection.fulfilled.match(result) ||
            removeReleaseSectionNote.fulfilled.match(result)
        )
      ) {
        type === 'publish' ? setVisiblePublished(true) : setVisibleWarning(true);
      }
    }
  };

  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (userInfo && visible) {
      dispatch(
        getReleaseNoteSection({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [selected?.release_note_id],
            },
          ],
          sort_fields: [{ id: 'section_name', order: 'asc' }],
          page: 1,
          per_page: 0,
          include_item_ref: true,
          omit_total_items: false,
        })
      );
    }
  }, [dispatch, visible, userInfo, selected]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title={<span className="title">リリースノート 編集</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1400}
      visible={visible}
      onCancel={handleToggleModal}
    >
      <ManageReleaseStyled isEmptyData={!formik.values.sections.length}>
        <div className="information">
          <div>
            <span className="content">ここから始めるプログラミングの基礎</span>
            <span className="id">（ID：K0002)</span>
          </div>
          <div className="version">
            <span className="label">バージョン：</span>
            <span>1.001（2022/01/10 10:00　初回公開）</span>
          </div>
        </div>
        <FormikProvider value={formik}>
          <Form>
            <div className="while-editing">
              <img
                src={
                  !publish
                    ? WhiteEditing
                    : publish === 2
                    ? TickComplete
                    : publish === 1
                    ? UnPublished
                    : StopPublic
                }
                alt=""
              />
              <div className="wrap-input">
                <div className="wrap-input-version">
                  <Form.Item className="item" name="title">
                    <Input name="title" placeholder="【タイトル】最大60文字" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <FieldArray
              name="sections"
              render={({ remove, push }) => (
                <div className="wrap-editor">
                  <div className="left-side">
                    <div className="title-editor">
                      <div className="no-editor">{indexSection + 1}</div>
                      <Form.Item name="section_name" className="item input-title">
                        <Input
                          value={formik.values.sections[indexSection].section_name}
                          name={`sections.${indexSection}.section_name`}
                          placeholder="セクション名を入力..."
                          onBlur={() => {}}
                        />
                      </Form.Item>
                    </div>
                    <JoditEditor
                      value={formik.values.sections[indexSection].text}
                      config={defaultConfig}
                      onBlur={(newContent) =>
                        formik.setFieldValue(`sections.${indexSection}.text`, newContent)
                      }
                    />
                    <div className="wrap-bottom-editor">
                      <div className="wrap-button-editor">
                        <Button
                          className="btn button-prev"
                          disabled={indexSection === 0}
                          onClick={() => setIndexSection((prevState) => prevState - 1)}
                        >
                          <CaretLeftOutlined />
                          <span>前のセクション</span>
                        </Button>
                        <Button
                          className="btn button-next"
                          disabled={indexSection === formik.values.sections.length - 1}
                          onClick={() => setIndexSection(indexSection + 1)}
                        >
                          <span>次のセクション</span>
                          <CaretRightOutlined />
                        </Button>
                      </div>
                      <div className="delete-editor" onClick={() => setVisibleConfirmDelete(true)}>
                        <DeleteOutlined
                          className="icon-delete"
                          style={{
                            color: '#00989A',
                          }}
                        />
                        <span>このセクションを削除</span>
                      </div>
                      <DeleteCompleted
                        onSubmit={() => {
                          setIndexSection((prevState) => (prevState > 0 ? prevState - 1 : 0));
                          remove(indexSection);
                        }}
                        visible={visibleDeleted}
                        setVisible={setVisibleDeleted}
                      />
                    </div>
                  </div>
                  <div className="right-side">
                    <Form.Item name="description" label="概要：" className="text-area">
                      <TextArea name="description" rows={5} placeholder="最大480文字" />
                    </Form.Item>
                    <span className="title-right">セクション</span>
                    <div className="dashed-line" />
                    <div className="wrap-radio">
                      <Radio.Group
                        value={indexSection}
                        onChange={(e) => setIndexSection(e.target.value)}
                      >
                        {formik.values.sections.map((val, index) => (
                          <div
                            className={`input-radio ${index === indexSection && 'section-checked'}`}
                            key={index}
                          >
                            <div className="label-radio">{index + 1}</div>
                            <Input
                              value={val.section_name}
                              placeholder="セクション名を入力..."
                              bordered={false}
                              name={`sections.${indexSection}.section_name`}
                              onClick={() => setIndexSection(index)}
                              suffix={<Radio value={index} />}
                            />
                          </div>
                        ))}
                      </Radio.Group>
                    </div>
                    <div className="wrap-button-add">
                      {formik.values.sections.length < 6 && (
                        <Button
                          className="add-section"
                          onClick={() =>
                            push({
                              section_name: '',
                              text: '',
                            })
                          }
                        >
                          ＋ セクションの追加
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            />
            <div className="wrap-submit">
              <div className="wrap-submit">
                <div className="wrap-button">
                  <Button className="btn-outline" onClick={() => setVisibleTemporaly(true)}>
                    一時保存
                  </Button>
                  <Button className="btn btn_submit" onClick={() => setVisibleUpdate(true)}>
                    公開
                  </Button>
                  <Button className="btn-outline" onClick={() => setVisiblePublishWarning(true)}>
                    公開停止
                  </Button>
                  <Button className="btn btn_close" onClick={() => setVisible(false)}>
                    キャンセル
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          <Completed
            title="リリースノートを保存しました。"
            visible={visibleComplete}
            setVisible={setVisibleComplete}
            onSubmit={() => setVisible(false)}
          />
          <UpdateStatus
            publish={publish}
            status="publish"
            title="リリースノートを公開します"
            from={
              publish === 0
                ? WhiteEditing
                : publish === 2
                ? TickComplete
                : publish === 1
                ? UnPublished
                : StopPublic
            }
            to={TickComplete}
            description={
              <span>
                リリースノートを公開すると、SKILL FAMILIARで一般のユーザー様が
                <br /> 修正後のリリースノートを閲覧できる状態になります。、
              </span>
            }
            visible={visibleUpdate}
            setVisible={setVisibleUpdate}
            onSubmit={() => updateStatus('publish')}
          />
          <UpdateStatus
            publish={publish}
            status="publishWarning"
            title="公開中のOFFICIALカリキュラムを公開停止します"
            from={TickComplete}
            to={StopPublic}
            description={
              <span>
                OFFICIALカリキュラムを公開停止にすると、
                <br /> 一般ユーザー様の新規の利用は不可になります。
              </span>
            }
            noteText={getNoteText('publishWarning')}
            visible={visiblePublishWarning}
            setVisible={setVisiblePublishWarning}
            onSubmit={() => updateStatus('publishWarning')}
          />
          <Published
            visible={visiblePublished}
            setVisible={setVisiblePublished}
            onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
          />
          <ExecuteDelete
            visible={visibleConfirmDelete}
            setVisible={setVisibleConfirmDelete}
            onSubmit={() => {}}
          />
          <DeleteCompleted visible={visibleDeleted} setVisible={setVisibleDeleted} />
          <Warning visible={visibleWarning} setVisible={setVisibleWarning} />
          <TemporarilySave
            visible={visibleTemporaly}
            setVisible={setVisibleTemporaly}
            onSubmit={formik.handleSubmit}
          />
        </FormikProvider>
      </ManageReleaseStyled>
    </Modal>
  );
};

export default NoteReleaseNew;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];

const defaultConfig = {
  license: '%LICENSE_KEY%',
  placeholder: '本文を入力...',
  readonly: false,
  enableDragAndDropFileToEditor: true,
  uploader: {
    url: 'https://xdsoft.net/jodit/finder/files/',
    baseUrl: 'sdadafaf',
    insertImageAsBase64URI: true,
    withCredentials: false,
    format: 'json',
    data: {
      dir: '',
    },
    filesVariableName: function (t: any) {
      return 'files[' + t + ']';
    },

    process: function (resp: any) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = [];
      files.unshift(resp.data);
      return { files: resp.data, error: resp.msg, msg: resp.msg };
    },

    isSuccess: () => {
      return true;
    },
    getMessage: function (e: any) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : '';
    },
    pathVariableName: 'path',
  },
  filebrowser: {
    ajax: {
      url: 'url',
    },
  },
  buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
};
