import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Wrapper, WrapperFirstLoginModal } from './styles';
import { HeaderInformationRegister } from 'components';
import { useAppDispatch } from 'hooks';
import SecondStep from './SecondStep';
import { getPlans } from './thunk';
import { Logo } from 'assets';
import { isFirstLogin, updateLoginTime } from 'containers/Auth/thunk';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { authSelector } from 'containers/Auth/selectors';

const ModalInformationRegister: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { signedInEmail } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const nextStep = useCallback(() => {
    if (currentStep < 4) {
      setCurrentStep((prevState) => prevState + 1);
    }
  }, [currentStep]);

  const fistLoginModal = useMemo(
    () => (
      <WrapperFirstLoginModal>
        <img src={Logo} className="logo" alt="logo" />
        <h1 className="title">SKILL FAMILIARへようこそ!</h1>
        <p className="text-content">
          はじめに利用者情報の登録を行います。
          <br />
          会社情報をご用意ください。
        </p>
        <button
          className="btn-submit"
          onClick={() => {
            nextStep();
          }}
        >
          ご利用開始
        </button>
      </WrapperFirstLoginModal>
    ),
    []
  );

  const endStep = () => (
    <WrapperFirstLoginModal>
      <img src={Logo} className="logo" alt="logo" />
    </WrapperFirstLoginModal>
  );

  useEffect(() => {
    dispatch(getPlans());
  }, [dispatch]);

  const screenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    screenRef.current?.scrollTo(0, 0);
  }, [currentStep]);

  const content = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <HeaderInformationRegister currentStep={currentStep} />
            <SecondStep nextStep={nextStep} />
          </>
        );
      case 2:
        setTimeout(async () => {
          const resultActionIsFirstLogin = await dispatch(
            isFirstLogin({
              conditions: [
                {
                  id: 'email',
                  search_value: [signedInEmail],
                  exact_match: true,
                },
              ],
              include_lookups: true,
              page: 1,
              per_page: 1,
            })
          );
          if (
            isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
            resultActionIsFirstLogin.payload.totalItems
          ) {
            await dispatch(
              updateLoginTime({
                id: resultActionIsFirstLogin.payload.items[0].i_id,
                data: {
                  item: {
                    last_login_at: dayjs().toISOString(),
                  },
                  is_force_update: true,
                },
              })
            );
          }
        }, 2000);
        return endStep();
      default:
        return fistLoginModal;
    }
  };

  return (
    <Wrapper ref={screenRef}>
      <div className="container">{content()}</div>
    </Wrapper>
  );
};

export default ModalInformationRegister;
