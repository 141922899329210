import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authorityMasterSelector } from '../pages/UserManagement/AuthorityMaster/selectors';
import { getDataAuthority } from '../pages/UserManagement/AuthorityMaster/thunk';
import { authSelector } from '../containers/Auth/selectors';
import { useAppDispatch } from './useAppDispatch';
import {
  ROUTER_WITH_PERMISSIONS,
  ROUTER_WITH_SCREEN_PERMISSIONS,
} from '../constant/screenPermission.constant';

export const usePermission = () => {
  const { listAuthority } = useSelector(authorityMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const location = useLocation();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      getDataAuthority({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        include_lookups: true,
        use_display_id: true,
        include_item_ref: true,
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo, userInfo?.company_id]);

  const authSettings = listAuthority.find(
    (auth: { title: string | undefined }) => auth.title === userInfo?.lookup_items?.role_code?.title
  );
  const permissionName = Object.keys(ROUTER_WITH_SCREEN_PERMISSIONS).find((key) =>
    ROUTER_WITH_SCREEN_PERMISSIONS[key as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS]?.includes(
      location.pathname.split(':')[0]
    )
  );

  const permissionNumber = authSettings
    ? Number(authSettings[permissionName as keyof typeof ROUTER_WITH_PERMISSIONS])
    : userInfo?.lookup_items?.role_code
    ? Number(
        userInfo?.lookup_items?.role_code[
          permissionName as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS
        ]
      )
    : 2;

  return { permissionNumber, permissionName };
};
