import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { CurriculumBatchModal, CurriculumHiddenListModal } from '../Modal';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { Header, Input, SelectField } from 'components';
import CompletedModal from 'components/Modal/Completed';
import { getUserDetail } from 'containers/Auth/thunk';
import { updateUserSchema } from 'libs/validations';
import { loadingRef } from 'components/Loading';
import { employeeSelector } from '../selector';
import { CREDIT_CARD_MONTH } from 'constant';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import EditUserStyled from './styles';
import * as Types from 'types';
import {
  getAffiliationAssignRole,
  getDataEmployeeDetail,
  getSelectAffiliation,
  getSelectPosition,
  getSelectRole,
  updateAffiliationPosition,
  // updateAffiliationPosition,
  updateCurriculum,
  updateEmployee,
} from '../thunk';

const { Option } = Select;

const EditEmployee: React.FC = () => {
  const [visibleCurriculumBatchModal, setVisibleCurriculumBatchModal] = useState<boolean>(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [selected, setSelected] = useState<Types.AffiliationAssignRole.ResponseType>();
  const [userDetail, setUserDetail] = useState<Types.UserDetailType>();
  const [visibleCurriculumHiddenListModal, setVisibleCurriculumHiddenListModal] =
    useState<boolean>(false);
  const [dataCurriculumBatch, setDataCurriculumBatch] = useState<Array<Types.CurriculumBatchType>>(
    []
  );
  const [dateJoinedCompany, setDateJoinedCompany] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });
  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });

  const { collapsedMenu } = useSelector(settingSelector);
  const { loading, dataSelectRole, dataSelectAffiliation, dataSelectPosition } =
    useSelector(employeeSelector);
  const { userInfo } = useSelector(authSelector);

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formik = useFormik<Types.EditEmployeeFormFormik>({
    initialValues: {
      employee_code: '',
      email: '',
      name: '',
      name_furigana: '',
      dob: '',
      role_code: '',
      doj: '',
      authority: '',
      main_position: {
        affiliation_id: '',
        positions_code: '',
      },
    },
    validationSchema: updateUserSchema,
    onSubmit: async (values) => {
      if (!id || !selected) return;
      await Promise.all([
        dispatch(
          updateEmployee({
            id,
            data: {
              item: {
                dob: values.dob,
                doj: values.doj,
                name: values.name,
                name_furigana: values.name_furigana,
                employee_code: values.employee_code,
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        ),
        dispatch(
          updateAffiliationPosition({
            id: selected.i_id,
            data: {
              item: {
                positions_code: formik.values.main_position.positions_code,
                affiliation_id: formik.values.main_position.affiliation_id,
              },
              is_force_update: true,
            },
          })
        ),
      ]);
      setVisibleCompleted(true);
    },
  });

  const handleSelectDateJoinedCompany = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setDateJoinedCompany((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('doj', `${dateJoinedCompany.year}${e}`);
    } else {
      setDateJoinedCompany((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('doj', `${e}${dateJoinedCompany.month}`);
    }
  };

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (e: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: e, day: '' }));
      formik.setFieldValue('dob', `${birthDay.year}${e}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('dob', `${e}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: e }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${e}`);
    }
  };

  const handleUpdateCurriculum = async (item_id: string, hidden: 'on' | 'off') => {
    const resultAction = await dispatch(
      updateCurriculum({
        id: item_id,
        data: {
          item: {
            hidden,
          },
          is_force_update: true,
        },
      })
    );
    if (updateCurriculum.fulfilled.match(resultAction)) {
      dispatch(
        getDataEmployeeDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userDetail?.company_id],
            },
            {
              id: 'email',
              search_value: [userDetail?.email],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
        })
      );
    }
  };

  useEffect(() => {
    if (id && userInfo) {
      (async () => {
        const resultAction = await Promise.all([
          dispatch(
            getUserDetail({
              id,
            })
          ),
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAffiliation({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectRole({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        if (getUserDetail.fulfilled.match(resultAction[0])) {
          const user_detail: Types.UserDetailType = resultAction[0].payload.field_values.reduce(
            (a, v) => ({ ...a, [v.field_id]: v.value }),
            {}
          );
          setUserDetail(user_detail);
          const resultAction2 = await Promise.all([
            dispatch(
              getDataEmployeeDetail({
                conditions: [
                  {
                    id: 'company_id',
                    search_value: [user_detail.company_id],
                  },
                  {
                    id: 'login_id',
                    search_value: [user_detail.login_id],
                  },
                ],
                page: 1,
                per_page: 0,
                include_lookups: true,
                include_item_ref: true,
              })
            ),
            dispatch(
              getAffiliationAssignRole({
                conditions: [
                  {
                    id: 'company_id',
                    search_value: [user_detail?.company_id],
                  },
                  {
                    id: 'login_id',
                    search_value: [user_detail.login_id],
                  },
                ],
                page: 1,
                per_page: 0,
                include_lookups: true,
              })
            ),
          ]);
          if (getDataEmployeeDetail.fulfilled.match(resultAction2[0])) {
            if (user_detail.dob) {
              setBirthDay({
                year: dayjs(user_detail.dob).year().toString(),
                month:
                  dayjs(user_detail.dob).month() < 9
                    ? `0${dayjs(user_detail.dob).month() + 1}`
                    : (dayjs(user_detail.dob).month() + 1).toString(),
                day:
                  dayjs(user_detail.dob).date() < 10
                    ? `0${dayjs(user_detail.dob).date()}`
                    : dayjs(user_detail.dob).date().toString(),
              });
            }
            if (user_detail.doj) {
              setDateJoinedCompany({
                year: dayjs(user_detail.doj).year().toString(),
                month:
                  dayjs(user_detail.doj).month() < 9
                    ? `0${dayjs(user_detail.doj).month() + 1}`
                    : (dayjs(user_detail.doj).month() + 1).toString(),
              });
            }

            formik.setValues({
              employee_code: user_detail.employee_code,
              name: user_detail.name,
              email: user_detail.email,
              name_furigana: user_detail.name_furigana,
              dob: user_detail.dob ? dayjs(user_detail.dob).format('YYYYMMDD') : '',
              doj: user_detail.doj ? dayjs(user_detail.doj).format('YYYYMMDD') : '',
              role_code: user_detail.role_code?.item_id,
              authority: user_detail?.authority?.item_id,
              main_position: { ...formik.values.main_position },
            });
          }
          if (getAffiliationAssignRole.fulfilled.match(resultAction2[1])) {
            setSelected(resultAction2[1].payload.items[0]);
            const position = resultAction2[1].payload.items.map((item) => ({
              position_id: item.i_id,
              affiliation_id: item.lookup_items?.affiliation_id.i_id || '',
              positions_code: item.lookup_items?.positions_code?.i_id || '',
              sort_order: item.sort_order,
            }));
            formik.setFieldValue('main_position', {
              affiliation_id: position[0]?.sort_order === '1' ? position[0].affiliation_id : '',
              positions_code: position[0]?.sort_order === '1' ? position[0].positions_code : '',
            });
          }
        } else {
          navigate(generatePath(routes.Employee.path, { entity: 'receiving' }));
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title="社内ユーザー編集" />
      <EditUserStyled collapsedMenu={collapsedMenu}>
        <p>
          社内ユーザーマスタの新規登録を行う画面です。
          <br />
          情報を入力後に登録ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form layout="vertical" autoComplete="off">
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="form-left">
                  <Form.Item
                    name="employee_code"
                    label={
                      <span className="text-label">
                        社員番号 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="employee_code"
                      className="text-input"
                      placeholder="半角数字：最大10文字"
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input name="name" className="text-input" placeholder="全角：最大100文字" />
                  </Form.Item>
                  <Form.Item
                    name="name_furigana"
                    label={
                      <span className="text-label">
                        氏名（フリガナ） <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_furigana"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={<span className="text-label">メールアドレス</span>}
                    className="form-input"
                  >
                    <Input
                      name="email"
                      type="email"
                      className="text-input"
                      autoComplete="off"
                      readOnly
                    />
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="dob"
                    label={
                      <span className="text-label">
                        生年月日 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      value={birthDay.year || undefined}
                      className="pull_down input_small"
                      onSelect={handleSelectBirthDay('year')}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      value={birthDay.month || undefined}
                      onSelect={handleSelectBirthDay('month')}
                      placeholder="--"
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                    <Select
                      className="pull_down input-month-day"
                      value={birthDay.day || undefined}
                      onSelect={handleSelectBirthDay('day')}
                      placeholder="--"
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option key={index} value={`${index < 9 ? '0' : ''}${index + 1}`}>
                          {index < 9 ? '0' : ''}
                          {index + 1}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                  <Form.Item
                    name="doj"
                    label={
                      <span className="text-label not-require">
                        入社年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      onSelect={handleSelectDateJoinedCompany('year')}
                      className="pull_down input_small"
                      value={dateJoinedCompany.year || undefined}
                      placeholder="----"
                    >
                      {Array.from(
                        {
                          length: 21,
                        },
                        (_, i) => (i + new Date().getFullYear() - 20).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      onSelect={handleSelectDateJoinedCompany('month')}
                      className="pull_down input-month-day"
                      value={dateJoinedCompany.month || undefined}
                      placeholder="--"
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                  <Form.Item
                    name="role_code"
                    className="pull_down"
                    label={
                      <span className="text-label">
                        権限 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="role_code"
                      className="pull_down"
                      placeholder="選択してください"
                    >
                      {dataSelectRole &&
                        dataSelectRole.map(({ i_id, name }) => (
                          <Option value={i_id} key={i_id}>
                            {name}
                          </Option>
                        ))}
                    </SelectField>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">所属・役職情報</div>
              <div className="body">
                <p className="description">所属・役職情報を設定・編集することができます。</p>
                <span className="label">メイン所属・役職</span>
                <div className="wrap-main-position">
                  <div className="form-row">
                    <div className="item">
                      <Form.Item
                        name="main_position.affiliation_id"
                        label={
                          <span className="text-label">
                            所属 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.affiliation_id"
                          className="pull_down"
                          placeholder="選択してください"
                        >
                          {dataSelectAffiliation &&
                            dataSelectAffiliation.map(({ i_id, name }) => (
                              <Option value={i_id} key={i_id}>
                                {name}
                              </Option>
                            ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                    <div className="item">
                      <Form.Item
                        name="main_position.positions_code"
                        label={
                          <span className="text-label">
                            役職 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.positions_code"
                          className="pull_down"
                          placeholder="選択してください"
                        >
                          {dataSelectPosition &&
                            dataSelectPosition.map(({ i_id, name }) => (
                              <Option value={i_id} key={i_id}>
                                {name}
                              </Option>
                            ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton className="btn btn_submit" disabled={!formik.dirty}>
                  更新
                </SubmitButton>
                <button className="btn btn_close" onClick={() => navigate(routes.Employee.path)}>
                  キャンセル
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <CompletedModal
          title="更新が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() => navigate(generatePath(routes.Employee.path, { entity: 'receiving' }))}
        />

        <CurriculumBatchModal
          visible={visibleCurriculumBatchModal}
          setVisible={setVisibleCurriculumBatchModal}
          onSubmit={(item) => setDataCurriculumBatch([...dataCurriculumBatch, ...item])}
        />
        <CurriculumHiddenListModal
          visible={visibleCurriculumHiddenListModal}
          setVisible={setVisibleCurriculumHiddenListModal}
          onSubmit={handleUpdateCurriculum}
        />
      </EditUserStyled>
    </>
  );
};

export default EditEmployee;
