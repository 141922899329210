import { createSlice } from '@reduxjs/toolkit';
import { findIndex, unionBy } from 'lodash';

import * as Types from 'types';
import {
  getOfficialPublish,
  getManageRelease,
  getArchiveList,
  getReleaseNoteSection,
  updateCurriculum,
  updateCurriculumPublishedHistory,
} from './thunk';

export interface InitialState {
  loading: boolean;
  error?: string;
  officialPublishData: Array<Types.OfficialPublicRes>;
  releaseData: Array<Types.ManageReleaseRes>;
  archiveList: Array<Types.ArchiveRes>;
  releaseNoteDetail?: Types.ReleaseNote.ReleaseNoteFormik;
  sectionsLocal: Array<Types.SectionDataRes>;
  totalReleaseNote: number;
  total: number;
}

const initialState: InitialState = {
  loading: false,
  error: '',
  officialPublishData: [],
  releaseData: [],
  archiveList: [],
  sectionsLocal: [],
  totalReleaseNote: 0,
  total: 0,
};
export const PublicManagementSlice = createSlice({
  name: 'publicManagement-slice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };
    builder
      .addCase(getOfficialPublish.pending, startLoading)
      .addCase(getManageRelease.pending, startLoading)
      .addCase(getArchiveList.pending, startLoading)
      .addCase(getReleaseNoteSection.pending, startLoading)
      .addCase(updateCurriculum.pending, startLoading)
      .addCase(updateCurriculumPublishedHistory.pending, startLoading);

    builder.addCase(getOfficialPublish.fulfilled, (state, action) => {
      const newOfficialData: Array<Types.OfficialPublicRes> = [];
      action.payload.report_results.forEach((e) => {
        const index = findIndex(
          newOfficialData,
          (val) => val.curriculum_code === e.curriculum_code
        );
        if (index >= 0) {
          newOfficialData[index] =
            newOfficialData[index].history_id < e.history_id ? e : newOfficialData[index];
        } else {
          newOfficialData.push(e);
        }
      });
      state.officialPublishData = newOfficialData;
      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getManageRelease.fulfilled, (state, action) => {
      state.releaseData = action.payload.report_results;
      state.totalReleaseNote = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getArchiveList.fulfilled, (state, action) => {
      state.archiveList = unionBy(action.payload.report_results, (item) => item.curriculum_code);
      stopLoading(state);
    });
    builder.addCase(getReleaseNoteSection.fulfilled, (state, action) => {
      state.releaseNoteDetail = {
        i_id: action.payload.report_results[0]?.i_id,
        title: action.payload.report_results[0]?.release_note_title,
        release_note_id: action.payload.report_results[0]?.release_note_id,
        curriculum_code: action.payload.report_results[0]?.curriculum_code,
        description: action.payload.report_results[0]?.description,
        version: action.payload.report_results[0]?.version,
        sections: action.payload.report_results.map((value) => ({
          i_id: value.item_ref?.section_id?.i_id,
          section_name: value.section_name,
          text: value.text,
        })),
      };
      stopLoading(state);
    });
    builder.addCase(updateCurriculumPublishedHistory.fulfilled, stopLoading);
    builder
      .addCase(updateCurriculumPublishedHistory.rejected, stopLoading)
      .addCase(getOfficialPublish.rejected, stopLoading)
      .addCase(getManageRelease.rejected, stopLoading)
      .addCase(getArchiveList.rejected, stopLoading)
      .addCase(updateCurriculum.rejected, stopLoading);
    builder.addCase(updateCurriculum.fulfilled, stopLoading);
  },
});
export default PublicManagementSlice.reducer;
