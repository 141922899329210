import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';

import { administratorMasterSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { administratorMasterSelector } from './selectors';
import CompletedModal from 'components/Modal/Completed';
import { updateUser } from 'containers/Auth/thunk';
import { AdministratorMasterFormik } from 'types';
import { updateInformation } from './thunk';
import { useAppDispatch } from 'hooks';
import { Input } from 'components';

interface Props {
  goBack: () => void;
}

const ConfirmAdministratorMaster: React.FC<Props> = ({ goBack }) => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const { admin_detail } = useSelector(administratorMasterSelector);
  const dispatch = useAppDispatch();

  const { information } = useSelector(administratorMasterSelector);

  const formik = useFormik<AdministratorMasterFormik>({
    initialValues: information,
    validationSchema: administratorMasterSchema,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        updateInformation({
          id: admin_detail[0]?.i_id,
          data: {
            item: values,
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
      if (updateInformation.fulfilled.match(resultAction)) {
        dispatch(
          updateUser({
            id: admin_detail[0].item_ref.login_id.i_id,
            data: {
              item: {
                company_id: admin_detail[0].company_id,
                name: values.admin_name,
                email: values.admin_email,
                user_type: 'admin',
                login_id: values.admin_email,
                admin_phone: values.admin_phone,
              },
            },
          })
        );
        setShowCompleteModal(true);
      } else {
        setShowActionErrorModal(true);
      }
    },
  });

  return (
    <div className="container confirm">
      <div className="description">
        <p>編集内容に問題がなければ、更新ボタンをクリックしてください。</p>
      </div>
      <FormikProvider value={formik}>
        <Form
          layout="vertical"
          labelCol={{
            flex: '22%',
          }}
          colon={false}
        >
          <div className="form">
            <Form.Item
              name="numberOfUsers"
              label={<span className="text-label">利用ユーザー数</span>}
              className="form-input wrap-number-users"
            >
              <Input
                name="numberOfUsers"
                className="input__disabled number-users"
                type="text"
                disabled
              />
              <span className="suffix">人</span>
            </Form.Item>
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  法人名 <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="name" className="input__disabled input" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="name_furigana"
              label={
                <span className="text-label">
                  法人名 （フリガナ）
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="name_furigana"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="postal_code"
              label={
                <span className="text-label">
                  郵便番号
                  <span className="require">*</span>
                </span>
              }
              className={`form-input wrap-input-zip-code ${
                formik.touched.postal_code && formik.errors.postal_code
                  ? 'error-input-zip-code'
                  : ''
              }`}
            >
              <Input
                name="postal_code"
                className="input__disabled input-confirm"
                type="text"
                placeholder="数字：７文字（ハイフンなし）"
                disabled
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || formik.values.postal_code!.toString().length > 6) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="prefecture"
              label={
                <span className="text-label">
                  都道府県
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="prefecture"
                type="text"
                placeholder="全角：最大100文字"
                className="input input__disabled"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="municipalities"
              label={
                <span className="text-label">
                  市区町村
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="municipalities"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="address"
              label={
                <span className="text-label">
                  番地
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="address"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="building_name"
              label={<span className="text-label">建物名・部屋番号</span>}
              className="form-input"
            >
              <Input
                name="building_name"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="admin_name"
              label={
                <span className="text-label">
                  管理者氏名
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_name"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={
                <span className="text-label">
                  管理者氏名 （フリガナ）
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_name_furigana"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="affiliation"
              label={
                <span className="text-label">
                  所属
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="affiliation"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="admin_position"
              label={
                <span className="text-label">
                  役職
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_position"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
            <Form.Item
              name="admin_phone"
              label={
                <span className="text-label">
                  管理者電話番号
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_phone"
                className="input input__disabled"
                type="text"
                placeholder="数字：最大11文字（ハイフン無し）"
                disabled
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || formik.values.admin_phone!.toString().length > 10) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="admin_email"
              label={
                <span className="text-label">
                  管理者メールアドレス
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_email" className="input input__disabled" type="text" disabled />
            </Form.Item>

            <Form.Item
              name="email_address_confirmation"
              label={
                <span className="text-label">
                  管理者メールアドレス（確認）
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="email_address_confirmation"
                className="input input__disabled"
                type="text"
                placeholder="全角：最大100文字"
                disabled
              />
            </Form.Item>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">更新</SubmitButton>
            <button disabled={formik.isSubmitting} className="cancel-btn" onClick={goBack}>
              戻る
            </button>
          </div>
        </Form>
      </FormikProvider>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="更新が完了しました"
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="カード情報の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </div>
  );
};

export default ConfirmAdministratorMaster;
