import React, { useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Select, Table } from 'antd';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { Header, Input, SelectField, TextArea } from 'components';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { CreateEditBelongingMaster } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { belongingMasterSelector } from './selectors';
import { useAppDispatch } from 'hooks';
import { resetSearch } from './slice';
import WrapperStyled from './styles';
import * as Types from 'types';
import {
  createBelongingMaster,
  deleteBelongingMaster,
  getBelongingMasterCSV,
  getBelongingMaster,
  searchBelongingMaster,
  updateBelongingMaster,
} from './thunk';

const { Option } = Select;

const PER_PAGE = 10;

const BelongingMaster: React.FC = () => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [completedModalTitle, setCompletedModalTitle] = useState<string>('');
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<Types.BelongingMasterType>();
  const [itemDeleted, setItemDeleted] = useState<Types.BelongingMasterType>();
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const { loading, total, items } = useSelector(belongingMasterSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formikSearch = useFormik<Types.BelongingSearchFormik>({
    initialValues: {
      affiliation_search: '',
    },
    onSubmit: ({ affiliation_search }, { setSubmitting }) => {
      if (!affiliation_search) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する権限を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        dispatch(
          getBelongingMaster({
            page: 1,
            per_page: PER_PAGE,
            conditions: [
              {
                id: 'name',
                search_value: [affiliation_search],
                exact_match: true,
              },
            ],
            omit_total_items: false,
          })
        );
      }
      setSubmitting(false);
    },
    onReset: () => {
      dispatch(resetSearch());
      dispatch(
        getBelongingMaster({
          page: 1,
          per_page: PER_PAGE,
          omit_total_items: false,
        })
      );
    },
  });

  const isEditing = (record: Types.BelongingMasterType) => record.i_id === itemSelected?.i_id;

  const columns: any = [
    {
      key: 'affiliation_id',
      title: 'コード',
      dataIndex: 'affiliation_id',
      width: '13%',
      align: 'center',
      sorter: (a: Types.BelongingMasterType, b: Types.BelongingMasterType) =>
        Number(a.code) - Number(b.code),
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
    },
    {
      title: '編集',
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: any, record: Types.BelongingMasterType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              更新
            </SubmitButton>
            <button
              type="button"
              className="btn btn_close"
              onClick={() => setItemSelected(undefined)}
            >
              キャンセル
            </button>
          </div>
        ) : (
          <FormOutlined className="icon" onClick={() => setItemSelected(record)} />
        );
      },
    },
    {
      title: '削除',
      width: '7%',
      dataIndex: 'operation',
      align: 'left',
      render: (_: any, record: Types.BelongingMasterType) =>
        itemSelected?.i_id !== record.i_id ? (
          <DeleteOutlined
            className="icon"
            onClick={() => {
              setItemDeleted(record);
              setOpenModalConfirmDeleteItem(true);
            }}
          />
        ) : null,
    },
  ];

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => (
    <Formik<Types.CreateEditAnalysisGroupFormik>
      initialValues={{
        name: itemSelected?.name || '',
      }}
      validationSchema={CreateEditBelongingMaster}
      onSubmit={async (values, { resetForm }) => {
        try {
          if (!itemSelected) return;
          const resultAction = await dispatch(
            updateBelongingMaster({
              id: itemSelected.i_id,
              data: {
                item: {
                  name: values.name,
                  updatedby: userInfo?.login_id,
                  updatedat: new Date(),
                },
                is_force_update: true,
              },
            })
          );
          if (updateBelongingMaster.fulfilled.match(resultAction)) {
            dispatch(
              getBelongingMaster({
                page,
                per_page: PER_PAGE,
                omit_total_items: false,
              })
            );
            setCompletedModalTitle('更新が完了しました');
            setShowCompleteModal(true);
          } else {
            setActionModalState({
              subTitle: '役職の更新に失敗しました',
              description: (
                <p className="text-content">
                  役職の更新に失敗しました。
                  <br />
                  もう一度情報を入力し、再度お試しください。
                </p>
              ),
            });
            setShowActionErrorModal(true);
          }
        } finally {
          setItemSelected(undefined);
          resetForm();
        }
      }}
    >
      <tr {...restProps}>{children}</tr>
    </Formik>
  );

  const EditableCell: React.FC<Types.TypeCell> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <Form>
          {editing ? (
            <Form.Item
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: '※役職名称が未入力です',
                },
              ]}
            >
              <Input showCount maxLength={30} name="name" />
            </Form.Item>
          ) : (
            children
          )}
        </Form>
      </td>
    );
  };

  const mergedColumns = columns.map((col: Types.TypeCell) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Types.BelongingMasterType) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSearch = (value: string) => {
    dispatch(
      searchBelongingMaster({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'name',
            search_value: [value],
          },
        ],
      })
    );
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv' && userInfo) {
      const resultAction = await dispatch(
        getBelongingMasterCSV({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getBelongingMasterCSV.fulfilled.match(resultAction)) {
        const listCsv: Array<Omit<Types.BelongingMasterType, 'i_id'>> =
          resultAction.payload.items.length > 0
            ? resultAction.payload.items.map((item: Types.BelongingMasterType) => ({
                company_id: item.company_id,
                code: item.code,
                name: item.name,
                updatedby: item.updatedby,
                createdat: item.createdat,
                createdby: item.createdby,
                updatedat: item.updatedat,
                deletedat: item.deletedat,
              }))
            : [];
        const csvString = [
          Object.keys(listCsv[0]),
          ...listCsv.map((item: any) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '分析グループマスタ.csv');
      }
    }
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    if (!userInfo) return;

    dispatch(
      getBelongingMaster({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        sort_fields: [{ id: 'code', order: 'asc' }],
        page,
        per_page: PER_PAGE,
        omit_total_items: false,
      })
    );
  }, [dispatch, page]);

  return (
    <WrapperStyled collapsedMenu={collapsedMenu}>
      <Header title="所属マスタ" className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            所属マスタの作成・管理を行う画面です。
            <br />
            作成した所属マスタはユーザーに設定します。
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="affiliation_search"
                label={<span className="label">所属検索</span>}
                className="form-input"
              >
                <SelectField
                  name="affiliation_search"
                  showSearch
                  className="select-input"
                  placeholder="選択してください"
                  onSearch={handleSearch}
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {items?.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>
          {total > 0 ? (
            <div className="text-count">
              {page * PER_PAGE > total ? total : page * PER_PAGE} 件表示 / {total} 件中
            </div>
          ) : null}
          <div className="btn-div">
            <button
              className="btn btn-active btn-download"
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </button>
          </div>
          <Table
            dataSource={items}
            columns={mergedColumns}
            loading={loading}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            className="table"
            pagination={{
              showSizeChanger: false,
              total,
              current: page,
              pageSize: 10,
              onChange: setPage,
              position: ['bottomCenter', 'topCenter'],
            }}
            rowKey="code"
          />
        </div>
        <div className="wrap-create">
          <p className="title">所属新規登録</p>
          <Formik<Types.CreateEditAnalysisGroupFormik>
            initialValues={{
              name: '',
            }}
            validationSchema={CreateEditBelongingMaster}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
              await dispatch(
                createBelongingMaster({
                  item: {
                    company_id: userInfo?.company_id,
                    name: values.name,
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                })
              );
              dispatch(
                getBelongingMaster({
                  page,
                  per_page: PER_PAGE,
                  omit_total_items: false,
                })
              );
              resetForm();
            }}
          >
            <Form colon={false} layout="vertical" className="form">
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    役職名称
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="name"
                  showCount
                  maxLength={120}
                  placeholder="最大120文字"
                  className="text-area"
                />
              </Form.Item>
              <SubmitButton className="btn_submit">
                <PlusOutlined className="size-icon" />
                新規登録
              </SubmitButton>
            </Form>
          </Formik>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
        subTitle="役職マスタをエクスポートします。"
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title={completedModalTitle}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        visible={openModalConfirmDeleteItem}
        onSubmit={async () => {
          if (!itemDeleted) return;
          const resultAction = await dispatch(deleteBelongingMaster({ id: itemDeleted.i_id }));
          if (deleteBelongingMaster.fulfilled.match(resultAction)) {
            setCompletedModalTitle('削除が完了しました');
            setShowCompleteModal(true);
          } else {
            setActionModalState({
              subTitle: '削除に失敗しました',
              description: (
                <p className="text-content">マスタの削除に失敗しました。 再度お試しください。</p>
              ),
            });
            setShowActionErrorModal(true);
          }
          setItemDeleted(undefined);
        }}
        onCancel={() => {
          setItemDeleted(undefined);
        }}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </WrapperStyled>
  );
};

export default BelongingMaster;
