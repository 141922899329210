import styled from 'styled-components';

type Props = {
  isEmptyData: boolean;
};
export const ManageReleaseStyled = styled.div<Props>`
  width: 100%;
  @media (max-width: 1440px) {
    .information {
      height: 48px;
      padding: 12px 30px !important;
    }
    .wrap-editor {
      .left-side {
        .wrap-bottom-editor .wrap-button-editor .btn {
          width: 120px;
          font-size: 12px;
          padding: 4px;
        }
        .jodit-toolbar__box .jodit-toolbar-editor-collection {
          height: 40px;
          justify-content: space-around;
          .jodit-toolbar-button__button {
            min-width: 25px !important;
          }
        }
        .jodit-toolbar-button {
          min-width: 30px;
        }
      }
      .right-side {
        padding: 0 0 0 20px !important;
        label {
          font-size: 12px;
        }
        .add-section {
          width: 190px;
        }
      }
    }
    .delete-editor {
      font-size: 12px;
    }
    .wrap-submit {
      box-shadow: none !important;
      background-color: #f9f8f8 !important;
      .btn {
        width: 180px !important;
        &_submit {
          font-weight: 700;
        }
      }
      .btn-outline {
        width: 180px !important;
        font-weight: 700;
      }
    }
  }
  .information {
    display: flex;
    padding: 18px 30px;
    background: #efeeee;
    justify-content: space-between;
    .id {
      font-size: 12px;
      color: #999999;
    }
    .content {
      font-size: 15px;
    }
    .version span {
      font-size: 12px;
      &:nth-of-type(1) {
        color: #777777;
      }
    }
  }
  .while-editing {
    display: flex;
    background: #f9f8f8;
    align-items: center;
    padding: 24px 30px;
    img {
      height: 50px;
    }
    .wrap-input {
      width: 96.5%;
      .logo-label {
        height: 44px;
      }
      .ant-input-affix-wrapper {
        height: 50px;
      }
      .item {
        margin-bottom: 0 !important;
        height: 50px;
        margin-left: 8px;
      }
    }
  }
  .wrap-editor {
    display: flex;
    margin: 24px 30px 40px;
    .left-side {
      height: auto;
      width: 70%;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 5px;
      .input-title {
        margin: 0;
        border: none;
        width: 100%;
        .ant-input-affix-wrapper {
          border: none;
          box-shadow: none;
        }
      }
      .title-editor {
        display: flex;
        background: #ffffff;
        align-items: center;
        border-radius: 5px;
        .input {
          width: 100%;
          padding: 15px 10px;
          box-shadow: unset;
          border: unset;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          &:disabled {
            background: #ffffff;
            color: #000000;
          }
        }
      }
      .title {
        padding-left: 16px;
      }
      .no-editor {
        border-right: 1px solid #dddddd;
        padding: 20px 14px;
      }
      .jodit-wysiwyg {
        background: #ffffff;
        height: 49vh;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
      .jodit-container {
        border: none !important;
        border-top: 1px solid #efefef !important;
        border-bottom: 1px solid #efefef !important;
        border-radius: 0;
      }
      .jodit-status-bar {
        display: none;
      }
      .wrap-button-editor {
        display: flex;
        align-items: center;
      }
      .wrap-bottom-editor {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        border: 1px solid #dddddd;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .wrap-button-editor {
          display: flex;
          align-items: center;
          .btn {
            border-radius: 5px;
            border: none;
            &:disabled {
              background: #eeeeee;
              color: #bbbbbb;
            }
            color: #ffffff;
          }
          .button-prev {
            background: #08a3a5;
          }
          .button-next {
            background: #08a3a5;
            margin-left: 8px;
          }
        }
        .delete-editor {
          display: flex;
          align-items: center;
          color: #00989a;
          border: unset;
          background: unset;
          cursor: pointer;
          &:disabled {
            color: #bbbbbb;
            cursor: not-allowed;
          }
          .icon-delete {
            font-size: 19px;
          }
          span {
            text-decoration: underline;
            margin-left: 5px;
          }
        }
        .disabled {
          color: #00989a;
        }
      }
    }
    .right-side {
      width: 30%;
      padding: 0 20px;
      .text-area {
        display: block;
      }
      .section-name {
        width: 100%;
        display: inline-flex;
        margin-bottom: 0;
        align-items: center;
        .ant-input-affix-wrapper {
          padding: 0 5px;
        }
        .ant-row {
          width: 100%;
        }
      }
      .add-section {
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        background: #ffffff;
        color: #00a3a5;
        font-weight: 700;
      }
      .display {
        font-size: 10px;
        color: #999999;
        font-family: Lato;
        text-align: right;
        margin: 8px 0 6px;
      }
      .title-right {
        font-size: 13px;
        color: #424242;
        font-weight: 400;
      }
      .dashed-line {
        border: 1px dashed #cccccc;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 24px;
      }
      .input-radio {
        display: flex;
        border: 1px solid #cccccc;
        height: 40px;
        background: #ffffff;
        border-radius: 3px;
        margin-bottom: 10px;
        .ant-input-affix-wrapper-borderless {
          border: none;
        }
      }
      .section-checked {
        border: 1px solid #00a3a5;
        & .label-radio {
          background: #00a3a5;
          color: #ffffff;
          border-radius: 0;
        }
      }
      .ant-radio-wrapper-checked .add-section {
        border-color: #00a3a5;
        border-radius: 5px;
        font-size: 12px;
        color: #00a3a5;
        font-weight: 700;
      }
      .wrap-button-add {
        text-align: center;
        margin-top: 28px;
      }
      .ant-radio-group {
        width: 100%;
      }
      .label-radio {
        display: inline-flex;
        align-items: center;
        padding: 0 15px;
        background: #ffffff;
        color: #777777;
        border-right: 1px solid #cccccc;
        border-radius: 4px 0 0 4px;
        font-size: 13px;
      }
    }
  }
  .wrap-submit {
    height: 95px;
    width: 100%;
    transition: width 0.3s;
    background-color: #ffffff;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .wrap-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .btn {
        border-radius: 5px;
        border: none;
        width: 16%;
        height: 40px;
        cursor: pointer;
        &_submit {
          margin-right: 12px;
          color: #ffffff;
          background-color: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        }
        &_close {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          color: #777777;
        }
      }
    }
    .btn-outline {
      background-color: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #f1a900;
      height: 40px;
      width: 14%;
      margin-right: 10px;
    }
  }
`;
