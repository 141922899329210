import React from 'react';
import { Modal } from 'components';
import { SectionStyled } from './styles';
import { CopyOutlined } from '@ant-design/icons';
interface ModalCopyCurriculumProps {
  loading: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
  onOk: () => void;
}
const ModalCopyCurriculum: React.FC<ModalCopyCurriculumProps> = (props) => {
  const { open, setOpen, onOk, loading } = props;
  return (
    <Modal
      title="カリキュラム コピー追加"
      open={open}
      onCancel={() => setOpen(false)}
      onOk={onOk}
      okButton={{
        text: 'OK',
        onClick: onOk,
        loading: loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setOpen(false),
        loading: loading,
      }}
    >
      <SectionStyled>
        <div className="messageTitle">
          <CopyOutlined />
          カリキュラムのコピー追加を実行します。
        </div>
        <div className="container">
          <div className="label">コピー元：</div>
          <div className="message">まずはここから経理の基礎</div>
        </div>
        <div className="label text-center">※コピー元のすぐ下にペーストされます</div>
      </SectionStyled>
    </Modal>
  );
};

export default ModalCopyCurriculum;
