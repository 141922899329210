import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { confirmCreditCardThunk, getPlans } from './thunk';
import * as Types from 'types';

export interface InitialState {
  loading: boolean;
  err?: string;
  information: Types.RegisterInformationFormik;
  creditCard: Types.RegisterInformationPaymentFormFormik;
  plans_selection: Types.Plans;
  plans: Array<Types.Plans>;
  idUserInformation: string;
  idCreditCard: string;
  idCompany: string;
}

const initialState: InitialState = {
  loading: false,
  plans: [],
  plans_selection: {} as Types.Plans,
  err: '',
  information: {
    name: '',
    name_furigana: '',
    postal_code: '',
    prefecture: '',
    address: '',
    plot_number: '',
    building_name: '',
    admin_name: '',
    admin_name_furigana: '',
    admin_department: '',
    admin_position: '',
    admin_phone: '',
    admin_email: '',
    admin_email_confirm: '',
  },
  creditCard: {
    companyName: 'VISA',
    cardNumber: '',
    customerName: '',
    expirationDate: '',
    securityNumber: '',
  },
  idUserInformation: '',
  idCreditCard: '',
  idCompany: '',
};

export const InformationRegisterSlice = createSlice({
  name: 'informationRegister-container',
  initialState,
  reducers: {
    setInformationRegister(state, action: PayloadAction<Types.RegisterInformationFormik>) {
      state.information = action.payload;
      const { postal_code, admin_phone } = state.information;
      state.information = {
        ...state.information,
        admin_phone: admin_phone.toString(),
        postal_code: postal_code.toString(),
      };
    },
    setCreditCardInfo(state, action: PayloadAction<Types.RegisterInformationPaymentFormFormik>) {
      state.creditCard = action.payload;
    },
    setPlansSelection(state, action: PayloadAction<Types.Plans>) {
      state.plans_selection = action.payload;
    },
    resetInformation(state) {
      state.information = {
        name: '',
        name_furigana: '',
        postal_code: '',
        prefecture: '',
        address: '',
        plot_number: '',
        building_name: '',
        admin_name: '',
        admin_name_furigana: '',
        admin_department: '',
        admin_position: '',
        admin_phone: '',
        admin_email: '',
        admin_email_confirm: '',
      };
      state.creditCard = {
        companyName: 'VISA',
        cardNumber: '',
        customerName: '',
        expirationDate: '',
        securityNumber: '',
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(getPlans.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPlans.fulfilled, (state, action) => {
      state.loading = false;
      state.plans = orderBy(action.payload.items, ['sort_order'], ['asc']);
    });
    builder.addCase(getPlans.rejected, (state) => {
      state.loading = false;
      state.plans = [];
    });
    builder.addCase(confirmCreditCardThunk.pending, (state) => {
      state.err = '';
    });
    builder.addCase(confirmCreditCardThunk.rejected, (state, action) => {
      const { details } = action.payload as Types.ConfirmCreditCardError;
      if (details.result[0].errCode === 'EX1' || details.result[0].errInfo === 'EX1000301') {
        state.err = '決済処理に失敗しました。もう一度カード番号を入力してください。';
      } else if (details.result[0].errCode === 'E61' && details.result[0].errInfo === 'E61010002') {
        state.err = 'ご利用出来ないカードをご利用になったもしくはカード番号が誤っております。';
      } else if (details.result[0].errCode === 'E61' && details.result[0].errInfo === 'E61010003') {
        state.err =
          '決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。';
      } else if (details.result[0].errCode === 'E61' && details.result[0].errInfo === 'E61020001') {
        state.err = '指定の決済方法は利用停止になっています。';
      } else if (details.result[0].errCode === 'E61' && details.result[0].errInfo === 'E61040001') {
        state.err = '現在のご契約では、カード番号を指定した決済処理は許可されていません。';
      } else if (details.result[0].errCode === 'E91' && details.result[0].errInfo === 'E91099999') {
        state.err =
          '決済処理に失敗しました。申し訳ございませんが、しばらく時間をあけて購入画面からやり直してください。';
      }
    });

    builder.addCase(confirmCreditCardThunk.fulfilled, (state, action) => {
      state.idCreditCard = action.payload.item_id;
    });
  },
});

export const { setInformationRegister, setCreditCardInfo, setPlansSelection, resetInformation } =
  InformationRegisterSlice.actions;
export default InformationRegisterSlice.reducer;
