import React, { useCallback, useEffect, useState } from 'react';
import { Button, Popover, Select, Table } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { parse, ParseResult } from 'papaparse';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import lodash, { find } from 'lodash';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { createQuestion, getOptionLimit } from 'containers/CreateEditQuestion/thunk';
import {
  HEADER_QUESTION_MASTER_CSV,
  HEADER_QUESTION_MASTER_REQUIRED_CSV,
} from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { CreateQuestion, UploadManyImageVideo } from './Modal';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { questionMasterSelector } from './selectors';
import UploadCSV from 'components/Modal/UploadCSV';
import { Header, SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import QuestionMasterStyled from './styles';
import { useAppDispatch } from 'hooks';
import { COMPANIES } from 'configs';
import * as Types from 'types';
import {
  deleteAttachQuestion,
  deleteFileQuestion,
  deleteQuestion,
  getAttachQuestion,
  getCompany,
  getDataQuestion,
  getQuestionDetail,
  getSelectCurriculum,
  getSelectCurriculumCreator,
  getSelectQuestion,
  getSelectTypes,
  updateTimeLimitCompany,
} from './thunk';
import { uploadQuestionCsvSchema } from 'libs/validations/questtion';
import { convertToFull, getIndexFromYupErrorPath } from 'libs/utils';
import { ValidationError } from 'yup';
import { pdf } from '@react-pdf/renderer';
import PDFQuestionMasterTable from 'pages/CurriculumManagement/QuestionMaster/PDFQuestionMasterTable';
import { setIndexState } from 'containers/CreateEditQuestion/slice';

const { Option } = Select;

const PER_PAGE = 10;

const DEFAULT_FILTER = {
  curriculum_name: '',
  required_curriculum: '',
  question_name: '',
  createdby: '',
  score: '',
};

const QuestionMaster: React.FC = () => {
  const [visiblePopupUploadImageVideo, setVisiblePopupUploadImageVideo] = useState<boolean>(false);
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [visibleDeletePopupError, setVisibleDeletePopupError] = useState<boolean>(false);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.QuestionSearchType[]>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [statusTime, setStatusTime] = useState<boolean>(false);
  const [indexTime, setIndexTime] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [csvErrors, setCsvErrors] = useState<string[]>([]);
  const [filters, setFilters] = useState<Types.QuestionFormSearchFormik>(DEFAULT_FILTER);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });

  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    curriculumCreatorSelect,
    curriculumNameSelect,
    curriculumTypeSelect,
    defaultTimeLimit,
    questionSelect,
    dataQuestion,
    scoreSelect,
    timeLimit,
    loading,
    total,
  } = useSelector(questionMasterSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.QuestionFormSearchFormik>({
    initialValues: {
      curriculum_name: '',
      required_curriculum: '',
      question_name: '',
      createdby: '',
      score: '',
    },
    onSubmit: (values) => {
      setFilters(values);
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      fetchQuestion(conditions);
    },
    onReset: () => {
      setFilters(DEFAULT_FILTER);
      fetchQuestion();
    },
  });

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (_: string, _Item: Types.QuestionSearchType, index: number) => (
        <span>{index + 1}</span>
      ),
    },
    {
      title: '設問コード',
      dataIndex: 'question_code',
      key: 'question_code',
      width: '8%',
    },
    {
      title: '設問名',
      dataIndex: 'question_name',
      key: 'question_name',
      width: '15%',
    },
    {
      title: '設問内容抜粋',
      dataIndex: 'question_description',
      key: 'question_description',
      width: '35%',
    },
    {
      title: '製作者',
      dataIndex: 'createdby',
      key: 'createdby',
      width: '20%',
    },
    {
      title: 'スコア',
      dataIndex: 'score',
      key: 'score',
      width: '7.5%',
    },
    {
      title: '詳細・編集',
      dataIndex: 'operation',
      width: '10%',
      render: (_: string, item: Types.QuestionSearchType) => (
        <FormOutlined
          className="icon"
          onClick={() => {
            setOpenModalCreateQuestion({
              visible: true,
              type: 'edit',
              question_id: item.item_ref?.question_code?.i_id,
            });
          }}
        />
      ),
    },
  ];

  const handleSubmitSelectTimeLimit = async (time: number) => {
    dispatch(setIndexState(time));
    dispatch(startLoading());
    const id = find(userInfo?.item_links?.links, ({ d_id }) => d_id === COMPANIES.id)?.items[0]
      ?.i_id;

    const actionResult = await dispatch(
      updateTimeLimitCompany({
        id: id ?? '',
        data: {
          item: {
            time_limit: time,
          },
          is_force_update: true,
        },
      })
    );

    if (updateTimeLimitCompany.fulfilled.match(actionResult)) {
      setStatusTime(true);
      fetchCompany();
    }
    setTimeout(() => {
      setStatusTime(false);
    }, 1000);
    dispatch(stopLoading());
  };

  const fetchQuestion = useCallback(
    (conditions?: Types.ConditionsType[]) => {
      if (userInfo) {
        dispatch(
          getDataQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              ...(conditions || []),
            ],
            page: page,
            per_page: PER_PAGE,
            include_lookups: true,
            include_item_ref: true,
            omit_total_items: false,
          })
        );
      }
    },
    [dispatch, page, userInfo]
  );

  const fetchCompany = useCallback(() => {
    if (!userInfo) return;

    dispatch(
      getCompany({
        conditions: [
          {
            id: 'id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleButtonDelete = () => {
    if (!selectedRow.length) {
      setVisibleDeletePopupError(true);
    } else {
      setVisiblePopupConfirmDelete(true);
    }
  };

  const handleSubmitDelete = async () => {
    const resultAction = await dispatch(
      getQuestionDetail({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'i_id',
            search_value: selectedRow.map((item) => item.item_ref?.question_code?.i_id),
          },
        ],
      })
    );
    if (getQuestionDetail.fulfilled.match(resultAction)) {
      await Promise.all(
        resultAction.payload.items
          .map(async (item) => {
            const listFileID: string[] = [
              item.attach_fileID1,
              item.attach_fileID2,
              item.attach_fileID3,
              item.comment_attach_fileID1,
              item.comment_attach_fileID2,
              item.comment_attach_fileID3,
              item.problems1_attach_fileID,
              item.problems2_attach_fileID,
              item.problems3_attach_fileID,
              item.problems4_attach_fileID,
            ];
            const resultActionGetAttachIDQuestion = await dispatch(
              getAttachQuestion({
                page: 1,
                per_page: 0,
                conditions: [
                  {
                    id: 'fileID',
                    search_value: [listFileID.filter((file_id) => file_id).join('|')],
                  },
                ],
              })
            );
            return [
              dispatch(
                deleteQuestion({
                  id: item.i_id,
                })
              ),
              ...listFileID.map(
                (file_id) =>
                  file_id &&
                  dispatch(
                    deleteFileQuestion({
                      file_id,
                    })
                  )
              ),
              ...(getAttachQuestion.fulfilled.match(resultActionGetAttachIDQuestion)
                ? resultActionGetAttachIDQuestion.payload.items.map((file) =>
                    dispatch(
                      deleteAttachQuestion({
                        id: file.i_id,
                      })
                    )
                  )
                : []),
            ];
          })
          .flat()
      );
      fetchQuestion();
    }
  };

  const handleExportCSV = async (value: string) => {
    const resultAction = await dispatch(
      getQuestionDetail({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'i_id',
            search_value: selectedRow.map((item) => item.item_ref?.question_code?.i_id),
          },
        ],
      })
    );
    if (getQuestionDetail.fulfilled.match(resultAction)) {
      if (value === 'csv') {
        const listCsv =
          resultAction.payload.items.length > 0
            ? resultAction.payload.items.map((item) => ({
                name: item.name,
                i_id: item.i_id,
                description: item.description,
                attach_fileID1: convertAttachFileId(item.attach1, item.attach_fileID1),
                attach_fileID2: convertAttachFileId(item.attach2, item.attach_fileID2),
                attach_fileID3: convertAttachFileId(item.attach3, item.attach_fileID3),
                problems1: item.problems1,
                problems2: item.problems2,
                problems3: item.problems3,
                problems1_attach_fileID: convertAttachFileId(
                  item.problems1_attach,
                  item.problems1_attach_fileID
                ),
                problems2_attach_fileID: convertAttachFileId(
                  item.problems2_attach,
                  item.problems2_attach_fileID
                ),
                problems3_attach_fileID: convertAttachFileId(
                  item.problems3_attach,
                  item.problems3_attach_fileID
                ),
                answer: item.answer,
                comment: item.comment,
                comment_attach_fileID1: convertAttachFileId(
                  item.comment_attach1,
                  item.comment_attach_fileID1
                ),
                comment_attach_fileID2: convertAttachFileId(
                  item.comment_attach2,
                  item.comment_attach_fileID2
                ),
                comment_attach_fileID3: convertAttachFileId(
                  item.comment_attach3,
                  item.comment_attach_fileID3
                ),
                time_limit: item.time_limit,
                score: item.score,
                attach_file_name1: `${item.code}_attach1`,
                attach_file_name2: `${item.code}_attach2`,
                attach_file_name3: `${item.code}_attach3`,
                problems1_attach_file_name: `${item.code}_problems1_attach`,
                problems2_attach_file_name: `${item.code}_problems2_attach`,
                problems3_attach_file_name: `${item.code}_problems3_attach`,
                comment_attach_file_name1: `${item.code}_comment_attach1`,
                comment_attach_file_name2: `${item.code}_comment_attach2`,
                comment_attach_file_name3: `${item.code}_comment_attach3`,
              }))
            : [];
        const csvString = [
          HEADER_QUESTION_MASTER_CSV.map(({ label }) => label),
          ...listCsv.map((item: any) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '設問マスタ.csv');
      } else {
        const timeLimitText = timeLimit[indexTime]?.value;
        const blob = await pdf(
          <PDFQuestionMasterTable
            dataQuestion={resultAction.payload.items}
            statusTime={statusTime}
            timeLimit={timeLimitText}
            total={total}
            {...filters}
          />
        ).toBlob();
        saveAs(blob, '設問マスタ.pdf');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleCreate = () => setOpenModalCreateQuestion({ visible: true, type: 'create' });

  const handleUploadCSV = (file: File) => {
    setCsvErrors([]);
    return new Promise<boolean>((resolve) => {
      parse(file, {
        complete: async ({ data }: ParseResult<string[]>) => {
          const errs: string[] = [];
          const resultsDataCSV = data
            .map((item) =>
              Object.fromEntries(HEADER_QUESTION_MASTER_CSV.map(({ key }, i) => [key, item[i]]))
            )
            .splice(1);
          const csvHeaders = data[0];
          if (lodash.difference(HEADER_QUESTION_MASTER_REQUIRED_CSV, csvHeaders).length !== 0) {
            errs.push('１行目　カラム数が不正です。');
          }
          try {
            await uploadQuestionCsvSchema.validate(resultsDataCSV, {
              abortEarly: false,
            });
            if (errs.length) {
              return resolve(false);
            }
            await Promise.all(
              resultsDataCSV.map(async (record) => {
                const resultActionGetOptionLimit = await dispatch(getOptionLimit());
                const resultAction = await dispatch(
                  createQuestion({
                    item: {
                      name: record.name,
                      description: record.description,
                      problems1: record.problems1,
                      problems2: record.problems2,
                      problems3: record.problems3,
                      answer: record.answer,
                      comment: record.comment,
                      time_limit: getOptionLimit.fulfilled.match(resultActionGetOptionLimit)
                        ? resultActionGetOptionLimit.payload.fields[
                            '6571ec8b8c988396fd72139f'
                          ]?.options
                            ?.filter((o: Types.TimeLimitType) => o.enabled)
                            .find((v: Types.TimeLimitType) => v.value === record.time_limit)
                            ?.option_id || ''
                        : '',
                      creator: userInfo?.name,
                      company_id: userInfo?.company_id,
                      score: Number(record.score),
                    },
                    return_item_result: true,
                  })
                );
                if (createQuestion.fulfilled.match(resultAction)) {
                  fetchQuestion();
                }
              })
            );
            resolve(true);
          } catch (err) {
            if (err instanceof ValidationError) {
              err.inner.forEach((error: any) => {
                const index = getIndexFromYupErrorPath(error.path) + 2;
                const message = `${convertToFull(`${index}`)}行目　${error.message}`;
                errs.push(message);
              });
            }
            resolve(false);
          }
          setCsvErrors(errs);
        },
        skipEmptyLines: true,
      });
    });
  };

  const handleUploadCsvImageVideo = () => setVisiblePopupUploadImageVideo(true);

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getSelectCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectTypes({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectCurriculumCreator({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'createdby',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(getOptionLimit()),
      ]);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    setIndexTime(defaultTimeLimit);
  }, [defaultTimeLimit]);

  useEffect(fetchCompany, [fetchCompany]);
  useEffect(fetchQuestion, [fetchQuestion]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <QuestionMasterStyled collapsedMenu={collapsedMenu} isEmptyData={!dataQuestion?.length}>
      <Header title="設問マスタ" />
      <div className="container">
        <div className="header-container">
          <p className="title">
            設問の作成・編集、作成した設問の一覧を確認できます。
            <br />
            設問に設定するデフォルトの制限時間も設定可能です。
          </p>
          {!statusTime ? (
            <div className="select-time-limit">
              <div className="label">
                <span>制限時間の初期値</span>
                <Popover
                  trigger={['click']}
                  placement="bottomRight"
                  title={<p style={{ color: '#2A2A2A', fontSize: 16 }}>設問制限時間の初期値設定</p>}
                  content={
                    <p style={{ color: '#424242', fontSize: 13, padding: '0 12px' }}>
                      設問制限時間の初期値設定とは、設問ごとに設定する制限時間の初期値（デフォルト値）を設定することです。
                      <br />
                      設問新規作成時に、設定した制限時間が初期表示されます。
                      <br />
                      あくまでも初期値ですので、設問ごとに制限時間は変更可能です。
                      <br />
                    </p>
                  }
                  color="#ffffff"
                >
                  <QuestionCircleOutlined className="question-mark-icon" />
                </Popover>
                ：
              </div>
              <Select
                className="select-limit"
                showSearch
                allowClear
                value={indexTime}
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onSelect={(e) => setIndexTime(e)}
              >
                {timeLimit.map((e, index) => (
                  <Option key={index} value={index}>
                    {e.value}
                  </Option>
                ))}
              </Select>
              <Button
                className="btn-select-submit"
                onClick={() => handleSubmitSelectTimeLimit(indexTime)}
              >
                設定
              </Button>
            </div>
          ) : (
            <div className="wrap-notify-successful">
              <CheckCircleOutlined className="icon-successful" />
              設定した時間を初期値として登録しました
            </div>
          )}
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="curriculum_name"
                className="curriculum_name"
                label={<span className="text-label">カリキュラム名</span>}
              >
                <SelectField
                  name="curriculum_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumNameSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="required_curriculum"
                className="required_curriculum"
                label={<span className="text-label">カリキュラム種類</span>}
              >
                <SelectField
                  name="required_curriculum"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumTypeSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="createdby"
                className="createdby"
                label={<span className="text-label">制作者</span>}
              >
                <SelectField
                  name="createdby"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumCreatorSelect?.map(({ createdby }, index) => (
                    <Option key={index} value={createdby}>
                      {createdby}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="question_name"
                className="question_name"
                label={<span className="text-label">設問名</span>}
              >
                <SelectField
                  name="question_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {questionSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="score"
                className="score"
                label={<span className="text-label">スコア（設問難易度）</span>}
              >
                <SelectField
                  name="score"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {scoreSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <Button className="btn btn-outline" onClick={handleUploadCsvImageVideo}>
            画像・動画インポート
          </Button>
          <Button
            className="btn btn-active"
            icon={<CloudUploadOutlined className="icon" />}
            onClick={() => setVisiblePopupUploadCSV(true)}
          >
            設問インポート
          </Button>
          <Button
            className="btn btn-active"
            icon={<PlusOutlined className="icon" />}
            onClick={handleCreate}
          >
            設問新規登録
          </Button>
        </div>
        <Table
          rowKey={(record) => JSON.stringify(record)}
          className="table"
          dataSource={dataQuestion}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows: Types.QuestionSearchType[]) => setSelectedRow(selectedRows),
          }}
          pagination={{
            pageSize: PER_PAGE,
            total,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="text-count">
                {page * 10 > total ? total : page * 10}
                <span className="text-static"> 件表示</span> / {total}
                <span className="text-static"> 名</span>
              </div>
            ),
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択した設問を処理：
              <Button
                className="btn btn-active"
                icon={<DeleteOutlined className="icon" />}
                onClick={handleButtonDelete}
              >
                設問削除
              </Button>
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
          </div>
        </div>
        <UploadCSV
          title="設問マスタ インポート"
          onSubmit={handleUploadCSV}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_QUESTION_MASTER_CSV}
          onFileChange={() => setCsvErrors([])}
          fileName="template_import_question_master"
          errors={csvErrors}
        />
        <UploadManyImageVideo
          visible={visiblePopupUploadImageVideo}
          setVisible={setVisiblePopupUploadImageVideo}
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
          subTitle="役職マスタをエクスポートします。"
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="設問が選択されていません"
          description={
            <>
              エクスポートを実行する対象の設問を選択し、
              <br />
              再度実行してください。
            </>
          }
        />
        <ActionErrorModal
          visible={visibleDeletePopupError}
          setVisible={setVisibleDeletePopupError}
          subTitle="設問が選択されていません"
          description={
            <>
              削除を実行する対象の設問を選択し、
              <br />
              再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="データの削除を実行します。"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={handleSubmitDelete}
          setVisible={setVisiblePopupConfirmDelete}
        />
        <CreateQuestion
          page={page}
          setVisibleSuccess={setVisibleSuccess}
          openModalCreateEditQuestion={openModalCreateQuestion}
          setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
        />
        <CompletedModal
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title="登録が完了しました"
          onSubmit={() => {
            setVisibleSuccess(!visibleSuccess);
          }}
        />
      </div>
    </QuestionMasterStyled>
  );
};

const convertAttachFileId = (fileID1: string, fileID2: string) =>
  `${fileID1 || ''}${fileID1 && fileID2 ? '/' : ''}${fileID2 || ''}`;

export default QuestionMaster;
