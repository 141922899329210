import styled from 'styled-components';

export default styled.section`
  .title {
    text-align: center;
  }
  .wrap-basic-information {
    .body {
      display: flex;
      justify-content: flex-start;
      .wrap-user {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .avatar-user {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #f0efef;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 4px solid #ffffff;
          box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
          &_confirm {
            cursor: not-allowed;
          }
          .icon-camera {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 28px;
            background: #ffffff;
            box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
            position: absolute;
            bottom: 70px;
            right: 40px;
            cursor: pointer;
          }
          .avatar {
            width: 100%;
            height: 100%;
          }
          .icon-user {
            font-size: 78px;
            color: #cccccc;
          }
          .text-content {
            font-family: Lato;
            font-size: 11px;
            color: #999999;
            text-align: center;
            display: block;
            margin-top: 15px;
          }
          .ant-upload {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .text-help {
        display: flex;
        margin: 15px 0;
        font-size: 11px;
        text-align: center;
        justify-content: center;
        color: #999999;
      }

      .form-left,
      .form-right {
        width: 70%;
        margin-left: 22px;
        .text-label {
          font-size: 13px;
          color: #424242;
        }
        .form-input {
          .text-input {
            font-size: 14px;
          }
          .input_small {
            width: 120px;
          }
          .input-month-day {
            width: 80px;
          }
          .text-label-content {
            font-size: 12px;
            color: #777777;
            margin: 0 10px 0 7px;
          }
        }
      }
    }
    .comment {
      margin-top: 33px;
      .input-textarea-group {
        height: 90px;
      }
    }
  }
`;
