import yup from '../yup';

export const secondStepSchema = yup.object().shape({
  name: yup
    .string()
    .required('法人名は必須入力項目です。')
    .max(100, '法人名100以内で入力してください。'),
  name_furigana: yup
    .string()
    .required('法人名ﾌﾘｶﾞﾅは必須入力項目です。')
    .max(100, '法人名 ﾌﾘｶﾞﾅ100以内で入力してください。'),
  postal_code: yup
    .string()
    .required('郵便番号は必須入力項目です。')
    .min(7, '郵便番号7以内で入力してください。')
    .max(7, '郵便番号7以内で入力してください。'),
  prefecture: yup
    .string()
    .required('都道府県は必須入力項目です。')
    .max(4, '都道府県4以内で入力してください。'),
  address: yup
    .string()
    .required('市区町村は必須入力項目です。')
    .max(100, '市区町村100以内で入力してください。'),
  plot_number: yup
    .string()
    .required('番地は必須入力項目です。')
    .max(100, '番地100以内で入力してください。'),
  building_name: yup.string().max(100, '建物名・部屋番号100以内で入力してください。'),
  admin_name: yup
    .string()
    .required('管理者氏名は必須入力項目です。')
    .max(100, '管理者氏名100以内で入力してください。'),
  admin_name_furigana: yup
    .string()
    .required('管理者氏名 ﾌﾘｶﾞﾅは必須入力項目です。')
    .max(100, '管理者氏名 ﾌﾘｶﾞﾅ100以内で入力してください。'),
  admin_department: yup
    .string()
    .required('部署は必須入力項目です。')
    .max(100, '部署100以内で入力してください。'),
  admin_position: yup
    .string()
    .required('役職は必須入力項目です。')
    .max(100, '役職100以内で入力してください。'),
  admin_phone: yup
    .string()
    .required('管理者電話番号は必須入力項目です。')
    .min(10, '管理者電話番号11以内で入力してください。')
    .max(11, '管理者電話番号11以内で入力してください。'),
});

export const fourStepSchema = yup.object().shape({
  companyName: yup.string().required('カード会社は必須入力項目です。'),
  cardNumber: yup
    .string()
    .required('カード番号は必須入力項目です。')
    .min(16, 'カード番号は16以上、18以内で入力してください。')
    .max(18, 'カード番号は16以上、18以内で入力してください。'),
  customerName: yup
    .string()
    .required('カード名義人は必須入力項目です。')
    .max(100, 'カード名義人100以内で入力してください。'),
  expirationDate: yup
    .string()
    .required('有効期限は必須入力項目です。')
    .test('compare', 'Invalid', (date) => {
      const expirationDate = (date ?? '').split('/');
      if (!date || expirationDate[0] === 'undefined' || expirationDate[1] === 'undefined')
        return true;
      return (
        new Date().getTime() <= new Date(`${expirationDate[1]}-${expirationDate[0]}`).getTime()
      );
    })
    .min(6, '無効な日付'),
  securityNumber: yup
    .string()
    .required('セキュリティコード人は必須入力項目です。')
    .min(3, 'セキュリティコードは3以上、4以内で入力してください。')
    .max(4, 'セキュリティコードは3以上、4以内で入力してください。'),
});
