import styled from 'styled-components';

export const SectionStyled = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.4);
  .popup {
    width: 680px;
    height: 644px;
    background-color: #ffffff;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 40px 60px 60px 60px;
    .title {
      text-align: center;
      font-size: 18px;
      color: #424242;
    }
    .content {
      height: 93%;
      border: 1px solid #d9d9d9;
      box-shadow: inset 0px 0px 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      padding: 26px 34px 30px 18px;
      font-size: 13px;
      color: #777777;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        visibility: hidden;
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #e3e3e3;
      }
      .wrap-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .btn-submit {
          background: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          color: #ffffff;
          border: none;
          width: 160px;
          height: 40px;
          margin-top: 30px;
          cursor: pointer;
        }
      }
    }
  }
`;
