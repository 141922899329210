import {
  ClipPath,
  Defs,
  Document,
  Font,
  G,
  Page,
  Path,
  Rect,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';
import dayjs from 'dayjs';
import React from 'react';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface PDFPublicManagementTableDocumentProps {
  selectedRows: Array<Types.OfficialPublicRes>;
  releaseData: Types.ManageReleaseRes[];
}

const PDFManageReleaseTableDocument = (props: PDFPublicManagementTableDocumentProps) => {
  const { releaseData, selectedRows } = props;
  const columns: { title: string; width: string | number }[] = [
    {
      title: 'No.',
      width: 30,
    },
    {
      title: 'OFFICIALカリキュラム\n公開日時（更新日時）',
      width: 100,
    },
    {
      title: 'バージョン',
      width: 60,
    },
    {
      title: '公開種類',
      width: 60,
    },
    {
      title: 'リリースノート\n' + '作成状況',
      width: 60,
    },
    {
      title: 'リリースノートタイトル',
      width: 310,
    },
    {
      title: 'リリースノート\n' + '作成・更新日時',
      width: 90,
    },
    {
      title: '作成者',
      width: 60,
    },
    {
      title: 'リリースノート\n' + '公開ステータス',
      width: 80,
    },
  ];

  const getPublicType = (publicType: number) =>
    publicType === 0
      ? '未更新'
      : publicType === 1
      ? '初回公開'
      : publicType === 2
      ? '更新'
      : '最終更新';

  const WhiteEditing = () => (
    <Svg width="12" height="12" viewBox="0 0 16 16">
      <Rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#71C131" stroke="#71C131" />
      <Path
        d="M8.08265 2.5C5.02966 2.5 2.5542 4.96272 2.5542 8C2.5542 11.0373 5.02966 13.5 8.08265 13.5C11.1356 13.5 13.6111 11.0373 13.6111 8C13.6111 4.96272 11.1356 2.5 8.08265 2.5ZM10.4705 6.20391L7.87163 9.78873C7.83531 9.83916 7.78742 9.88026 7.73195 9.90861C7.67647 9.93696 7.615 9.95175 7.55263 9.95175C7.49026 9.95175 7.4288 9.93696 7.37332 9.90861C7.31784 9.88026 7.26996 9.83916 7.23364 9.78873L5.6948 7.6673C5.64791 7.60223 5.6948 7.51138 5.77501 7.51138H6.35377C6.47964 7.51138 6.59935 7.57154 6.67339 7.67466L7.55202 8.88761L9.49191 6.21127C9.56595 6.10937 9.68442 6.04799 9.81152 6.04799H10.3903C10.4705 6.04799 10.5174 6.13884 10.4705 6.20391Z"
        fill="white"
      />
      <Rect width="16" height="16" rx="2" fill="#D48AE0" />
      <Path
        d="M12.632 7.48307H11.6922V4.40307C11.6922 3.43094 10.9002 2.64307 9.92309 2.64307H6.49545C5.5183 2.64307 4.72635 3.43094 4.72635 4.40307V7.48307H3.78651C3.54188 7.48307 3.34424 7.67969 3.34424 7.92307V13.2031C3.34424 13.4464 3.54188 13.6431 3.78651 13.6431H12.632C12.8767 13.6431 13.0743 13.4464 13.0743 13.2031V7.92307C13.0743 7.67969 12.8767 7.48307 12.632 7.48307ZM8.59626 10.7418V11.4706C8.59626 11.5311 8.54651 11.5806 8.4857 11.5806H7.93285C7.87204 11.5806 7.82228 11.5311 7.82228 11.4706V10.7418C7.70819 10.6603 7.62304 10.5449 7.57907 10.4122C7.53511 10.2795 7.5346 10.1364 7.57762 10.0034C7.62065 9.87034 7.70498 9.75435 7.81849 9.67206C7.932 9.58977 8.06883 9.54544 8.20927 9.54544C8.34972 9.54544 8.48655 9.58977 8.60006 9.67206C8.71356 9.75435 8.7979 9.87034 8.84092 10.0034C8.88395 10.1364 8.88344 10.2795 8.83948 10.4122C8.79551 10.5449 8.71035 10.6603 8.59626 10.7418ZM10.6971 7.48307H5.72147V4.40307C5.72147 3.97819 6.06838 3.63307 6.49545 3.63307H9.92309C10.3502 3.63307 10.6971 3.97819 10.6971 4.40307V7.48307Z"
        fill="white"
      />
    </Svg>
  );

  const UnPublished = () => (
    <Svg width="12" height="12" viewBox="0 0 16 16">
      <Rect width="16" height="16" rx="2" fill="#6A6ECD" />
      <G clip-Path="url(#clip0_12389_40614)">
        <Path
          d="M8 2C4.68661 2 2 4.68661 2 8C2 11.3134 4.68661 14 8 14C11.3134 14 14 11.3134 14 8C14 4.68661 11.3134 2 8 2ZM11.1446 11.8638L4.13616 4.85536C4.35045 4.59152 4.59152 4.35045 4.85536 4.13616L11.8638 11.1446C11.6496 11.4071 11.4085 11.6496 11.1446 11.8638Z"
          fill="white"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_12389_40614">
          <Rect width="12" height="12" fill="white" transform="translate(2 2)" />
        </ClipPath>
      </Defs>
    </Svg>
  );

  const TickComplete = () => (
    <Svg width="12" height="12" viewBox="0 0 16 16">
      <Rect x="0.5" y="0.5" width="15" height="15" rx="1.5" fill="#71C131" stroke="#71C131" />
      <Path
        d="M8.08265 2.5C5.02966 2.5 2.5542 4.96272 2.5542 8C2.5542 11.0373 5.02966 13.5 8.08265 13.5C11.1356 13.5 13.6111 11.0373 13.6111 8C13.6111 4.96272 11.1356 2.5 8.08265 2.5ZM10.4705 6.20391L7.87163 9.78873C7.83531 9.83916 7.78742 9.88026 7.73195 9.90861C7.67647 9.93696 7.615 9.95175 7.55263 9.95175C7.49026 9.95175 7.4288 9.93696 7.37332 9.90861C7.31784 9.88026 7.26996 9.83916 7.23364 9.78873L5.6948 7.6673C5.64791 7.60223 5.6948 7.51138 5.77501 7.51138H6.35377C6.47964 7.51138 6.59935 7.57154 6.67339 7.67466L7.55202 8.88761L9.49191 6.21127C9.56595 6.10937 9.68442 6.04799 9.81152 6.04799H10.3903C10.4705 6.04799 10.5174 6.13884 10.4705 6.20391Z"
        fill="white"
      />
    </Svg>
  );

  const StopPublic = () => (
    <Svg width="12" height="12" viewBox="0 0 40 40">
      <Rect width="40" height="40" rx="2" fill="#989898" />
      <G clip-Path="url(#clip0_12237_420376)">
        <Path
          d="M35.4071 31.9445L20.9627 6.94447C20.7474 6.57294 20.3759 6.38892 20.0009 6.38892C19.6259 6.38892 19.2509 6.57294 19.0391 6.94447L4.59464 31.9445C4.16756 32.6875 4.70228 33.6111 5.55645 33.6111H34.4453C35.2995 33.6111 35.8342 32.6875 35.4071 31.9445ZM18.8898 16.6667C18.8898 16.5139 19.0148 16.3889 19.1676 16.3889H20.8342C20.987 16.3889 21.112 16.5139 21.112 16.6667V23.0556C21.112 23.2084 20.987 23.3334 20.8342 23.3334H19.1676C19.0148 23.3334 18.8898 23.2084 18.8898 23.0556V16.6667ZM20.0009 28.8889C19.5648 28.88 19.1495 28.7005 18.8442 28.3889C18.5389 28.0773 18.3679 27.6585 18.3679 27.2222C18.3679 26.786 18.5389 26.3672 18.8442 26.0556C19.1495 25.744 19.5648 25.5645 20.0009 25.5556C20.437 25.5645 20.8523 25.744 21.1576 26.0556C21.4629 26.3672 21.6339 26.786 21.6339 27.2222C21.6339 27.6585 21.4629 28.0773 21.1576 28.3889C20.8523 28.7005 20.437 28.88 20.0009 28.8889Z"
          fill="white"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_12237_420376">
          <Rect
            width="31.1111"
            height="31.1111"
            fill="white"
            transform="translate(4.44446 4.44446)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );

  const getStatus = (value: number) => {
    switch (value) {
      case 0:
        return (
          <View style={styles.statusItem}>
            <WhiteEditing />
            <Text style={[styles.statusText, { color: '#b57fcf' }]}>編集中</Text>
          </View>
        );
      case 1:
        return (
          <View style={styles.statusItem}>
            <UnPublished />
            <Text style={[styles.statusText, { color: '#6a6ecd' }]}>未公開</Text>
          </View>
        );
      case 2:
        return (
          <View style={styles.statusItem}>
            <TickComplete />
            <Text style={[styles.statusText, { color: '#71c131' }]}>公開中</Text>
          </View>
        );
      case 3:
        return (
          <View style={styles.statusItem}>
            <StopPublic />
            <Text style={[styles.statusText, { color: '#989898' }]}>公開停止中</Text>
          </View>
        );
      default:
        return '_';
    }
  };

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>リリースノート管理</Text>
        </View>

        <View style={styles.subscription}>
          <Text style={styles.textSubscription}>
            OFFICIALカリキュラムの公開・編集日時別のリリースノート作成状況の一覧画面です。
          </Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.targetSection}>
          <Text style={styles.targetTitle}>対象OFFICAILカリキュラム： </Text>
          <View style={styles.targetPanel}>
            <View style={styles.targetPanelSection1}>
              <View style={styles.targetPanelTextGroup}>
                <Text style={styles.targetPanelTextPrimary}>{releaseData[0]?.curriculum_name}</Text>
                <Text style={styles.targetPanelTextSecondary}>
                  （ID：{releaseData[0]?.curriculum_code})
                </Text>
              </View>
              <View style={styles.targetPanelTextGroup}>
                <Text style={styles.targetPanelTextSecondary}>作成者：</Text>
                <Text style={styles.targetPanelTextPrimary}>
                  {releaseData[0]?.curriculum_registered_person}
                </Text>
              </View>
            </View>
            <View style={styles.targetPanelSection2}>{getStatus(selectedRows[0]?.publish)}</View>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.textCountGeneral}>
            <Text style={styles.textCountNumber}>{releaseData.length}</Text>
            <Text style={styles.textCountDescription}>件表示</Text>
            <Text style={styles.textCountNumber}>/</Text>
            <Text style={styles.textCountNumber}>{releaseData.length}</Text>
            <Text style={styles.textCountDescription}>件中</Text>
          </View>

          <View style={styles.table}>
            <View style={styles.tableDivider} />
            <View style={[styles.tableHeaderRow]}>
              {columns.map((column, index) => (
                <View key={index} style={[styles.wrapCell, { width: column.width }]}>
                  <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
                </View>
              ))}
              <View style={styles.tableDividerHeader} />
            </View>

            {releaseData.map((row, rowIndex) => (
              <View
                key={rowIndex}
                style={[
                  styles.tableRow,
                  {
                    borderBottom:
                      rowIndex !== releaseData.length - 1 ? 'none' : '1px solid #e5e5e5',
                  },
                ]}
              >
                <View style={[styles.wrapCell, { width: columns[0].width }]}>
                  <Text style={styles.tableCell}>{rowIndex + 1}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[1].width }]}>
                  <Text style={styles.tableCell}>
                    {dayjs(row.publish_start_date).locale('ja').format('YYYY/MM/DD HH:mm')}
                  </Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[2].width }]}>
                  <Text style={styles.tableCell}>{row.publish_version}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[3].width }]}>
                  <Text style={styles.tableCell}>{getPublicType(row.publish_type)}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[4].width }]}>
                  <Text style={styles.tableCell}>{row.release_note_id ? '作成済' : '未作成'}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[5].width }]}>
                  <Text style={styles.tableCell}>{row.release_note_title || '_'}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[6].width }]}>
                  <Text style={styles.tableCell}>
                    {row.updatedat
                      ? dayjs(row.updatedat).locale('ja').format('YYYY/MM/DD HH:mm')
                      : '_'}
                  </Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[7].width }]}>
                  <Text style={styles.tableCell}>{row.createdby || '_'}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[8].width }]}>
                  <Text style={styles.tableCell}>
                    {row.public_status ? getStatus(row.public_status) : '_'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFManageReleaseTableDocument;
