import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { Form, Select, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { pdf } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  SearchOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { HEADER_EMPLOYEE_CSV } from 'constant/header.export.constant';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import UploadCSV from 'components/Modal/UploadCSV';
import PDFUsersDocument from './PDFUsersDocument';
import { Header, SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import PDFUserDocument from './PDFUserDocument';
import { employeeSelector } from './selector';
import { routes } from 'navigations/routes';
import UserMasterStyled from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  deleteEmployee,
  getDataEmployee,
  getSelectAffiliation,
  getSelectEmployee,
  getSelectPosition,
  getSubPosition,
} from './thunk';

const PER_PAGE = 10;
const { Option } = Select;

const UserMaster: React.FC = () => {
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.EmployeeType[]>([]);
  const [itemSelected, setItemSelected] = useState<Types.EmployeeType>();
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    dataSelectEmployeeName,
    dataSelectEmployeeCode,
    dataSelectAffiliation,
    dataSelectPosition,
    dataEmployee,
    loading,
    total,
  } = useSelector(employeeSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik<Types.EmployeeFormSearchFormik>({
    initialValues: {
      employee_code: '',
      name: '',
      department_name: '',
      position_code: '',
    },
    onSubmit: (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      dispatch(
        getDataEmployee({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            ...conditions,
          ],
          sort_fields: [
            {
              id: 'employee_code',
              order: 'asc',
            },
          ],
          page: page,
          per_page: PER_PAGE,
          include_lookups: true,
          include_item_ref: true,
          omit_total_items: false,
        })
      );
    },
    onReset: () => {
      if (userInfo) {
        dispatch(
          getDataEmployee({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: page,
            per_page: PER_PAGE,
          })
        );
      }
    },
  });

  const handleSubmitDelete = async () => {
    if (itemSelected?.i_id) {
      const resultAction = await dispatch(
        deleteEmployee({
          id: itemSelected?.i_id,
        })
      );
      if (deleteEmployee.fulfilled.match(resultAction)) {
        setVisiblePopupConfirmDelete(false);
        fetchEmployee();
      }
    }
  };

  const fetchEmployee = useCallback(() => {
    if (userInfo) {
      dispatch(
        getDataEmployee({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          sort_fields: [
            {
              id: 'employee_code',
              order: 'asc',
            },
          ],
          page: page,
          per_page: PER_PAGE,
          include_lookups: true,
          include_item_ref: true,
          omit_total_items: false,
        })
      );
    }
  }, [dispatch, page, userInfo]);

  const column = [
    {
      title: '社員番号',
      dataIndex: 'employee_code',
      key: 'employee_code',
      width: '10%',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
    },
    {
      title: 'フリガナ',
      dataIndex: 'kana',
      key: 'kana',
      width: '10%',
    },
    {
      title: '所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
    },
    {
      title: '役職',
      dataIndex: 'position_code',
      key: 'position_code',
      width: '10%',
    },
    {
      title: '年齢',
      dataIndex: 'birthday',
      key: 'birthday',
      width: '10%',
      render: (text: string) => text && `${dayjs().diff(text, 'years', false)}歳`,
    },
    {
      title: '入社年月日',
      dataIndex: 'hire_date',
      key: 'hire_date',
      width: '10%',
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM/DD')}`,
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      width: '10%',
      render: (text: string) => dayjs(text).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: '権限',
      dataIndex: 'role_code',
      key: 'role_code',
      width: '10%',
    },
    {
      title: '編集',
      dataIndex: 'i_id',
      width: '5%',
      render: (text: string) => (
        <FormOutlined
          className="icon"
          onClick={() =>
            navigate(
              generatePath(routes.EditEmployee.path, {
                entity: 'receiving',
                id: text,
              })
            )
          }
        />
      ),
    },
    {
      title: '削除',
      dataIndex: 'operation',
      width: '5%',
      render: (_: string, item: Types.EmployeeType) => (
        <DeleteOutlined
          className="icon"
          onClick={() => {
            setVisiblePopupConfirmDelete(true);
            setItemSelected(item);
          }}
        />
      ),
    },
  ];

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv') {
      try {
        if (!selectedRow || selectedRow.length === 0) {
          return;
        }
        const csvData = selectedRow.map((employee) => ({
          社員番号: employee.employee_code,
          ユーザ名: employee.name,
          'ユーザ名（カナ）': employee.kana,
          メールアドレス: employee.email,
          パスワード: employee.password,
          所属ID: employee.affiliation_id,
          役職コード: employee.role_code,
          誕生日: employee.birthday,
          入社日: employee.hire_date,
          権限コード: employee.authority_code,
        }));
        const csvContent =
          '\uFEFF' +
          '社員番号,ユーザ名,ユーザ名（カナ）,メールアドレス,パスワード,所属ID,役職コード,誕生日,入社日,権限コード\n' +
          csvData.map((employee) => Object.values(employee).join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', '従業員.csv');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('error when export csv:', error);
      }
    } else {
      if (!selectedRow.length) return;

      if (selectedRow.length === 1) {
        const userObject: Types.EmployeeExportPDF = {
          employee_code: selectedRow[0].employee_code || '',
          name: selectedRow[0].name || '',
          kana: selectedRow[0].kana || '',
          login_id: selectedRow[0].login_id || '',
          birthday: selectedRow[0].birthday || '',
          hire_date: selectedRow[0].hire_date || '',
          main_position: {
            affiliation_name: '',
            position_name: '',
            main_role: '',
          },
        };

        const resultAction = await dispatch(
          getSubPosition({
            conditions: [
              { id: 'company_id', search_value: [selectedRow[0].company_id] },
              { id: 'login_id', search_value: [selectedRow[0].login_id], exact_match: true },
            ],
            sort_fields: [{ id: 'sort_order', order: 'asc' }],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
            include_links: true,
          })
        );

        if (getSubPosition.fulfilled.match(resultAction)) {
          const position = resultAction.payload.items.map((item) => ({
            affiliation_name: item.lookup_items?.affiliation_id?.name || '',
            position_name: item.lookup_items?.positions_code?.name || '',
            main_role: item.main_role || '',
          }));
          const main_position = position.find((pos) => pos.main_role === 'main');
          Object.assign(userObject, { main_position });
        }

        const blob = await pdf(<PDFUserDocument employee={userObject} />).toBlob();
        saveAs(blob, `${selectedRow[0].employee_code}_${selectedRow[0].name}.pdf`);
      } else {
        const blob = await pdf(
          <PDFUsersDocument employeeSelected={selectedRow} total={total} />
        ).toBlob();
        saveAs(blob, 'users_master_table.pdf');
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getSelectEmployee({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'employee_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectPosition({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectAffiliation({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  }, [dispatch, userInfo]);

  useEffect(fetchEmployee, [fetchEmployee]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title="ユーザーマスタ" />
      <UserMasterStyled collapsedMenu={collapsedMenu} isEmptyData={!dataEmployee.length}>
        <p className="text-note">ユーザーマスタの作成・管理を行う画面です。</p>
        <div className="line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item name="employee_code" label="社員番号" className="item">
                <SelectField
                  name="employee_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectEmployeeCode?.map((val, index) => (
                    <Option value={val} key={index}>
                      {val}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="name" label="氏名" className="item">
                <SelectField
                  name="name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectEmployeeName?.map((val, index) => (
                    <Option value={val} key={index}>
                      {val}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="department_name" label="所属" className="item">
                <SelectField
                  name="department_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectAffiliation?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="position_code" label="役職" className="item">
                <SelectField
                  name="position_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectPosition?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                  所属
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
            <div className="wrap-button">
              <Button
                className="btn btn-active"
                icon={<CloudUploadOutlined className="icon" />}
                onClick={() => setVisiblePopupUploadCSV(true)}
              >
                インポート
              </Button>

              <Button
                className="btn btn-active"
                icon={<PlusOutlined className="icon" />}
                onClick={() =>
                  navigate(generatePath(routes.CreateEmployee.path, { entity: 'receiving' }))
                }
              >
                新規登録
              </Button>
            </div>
            <Table
              rowKey="index"
              columns={column}
              className="table"
              dataSource={dataEmployee.map((item, index) => ({ ...item, index }))}
              rowSelection={{
                onChange: (_, selectedRows: Types.EmployeeType[]) => setSelectedRow(selectedRows),
              }}
              pagination={{
                pageSize: PER_PAGE,
                position: ['topCenter'],
                total,
                current: page,
                onChange: setPage,
                showSizeChanger: false,
                showTotal: () => (
                  <span className="text-count">
                    {page * PER_PAGE > total ? total : page * PER_PAGE} 件表示 / {total} 名
                  </span>
                ),
              }}
            />
          </Form>
        </FormikProvider>
        <div className="wrap-bottom">
          <div className="text-label">
            選択したユーザを処理：
            <Button className="btn btn-active" onClick={handleButtonExport}>
              エクスポート
            </Button>
          </div>
        </div>
        <UploadCSV
          onSubmit={() => {}}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={handleSubmitDelete}
          setVisible={setVisiblePopupConfirmDelete}
        />
      </UserMasterStyled>
    </>
  );
};

export default UserMaster;
