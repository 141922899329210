import yup from '../yup';

export const CreateAuthorityMasterSchema = yup.object().shape({
  name: yup.string().required().max(120),
});

export const CreateEditJobTitleSchema = yup.object().shape({
  name: yup.string().required('※役職名称が未入力です').max(120),
});

export const administratorMasterSchema = yup.object().shape({
  name: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '法人名100以内で入力してください。'),
  name_furigana: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '法人名 ﾌﾘｶﾞﾅ100以内で入力してください。'),
  postal_code: yup
    .string()
    .required('※正しく入力してください')
    .min(7, '郵便番号7以内で入力してください。')
    .max(7, '郵便番号7以内で入力してください。'),
  prefecture: yup
    .string()
    .required('※正しく入力してください')
    .max(4, '都道府県4以内で入力してください。'),
  plot_number: yup.string().required('※正しく入力してください'),
  address: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '市区町村100以内で入力してください。'),
  building_name: yup.string().required('※正しく入力してください').max(100),
  admin_name: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '管理者氏名100以内で入力してください。'),
  admin_name_furigana: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '管理者氏名 ﾌﾘｶﾞﾅ100以内で入力してください。'),
  admin_department: yup.string().required('※正しく入力してください'),
  admin_position: yup
    .string()
    .required('※正しく入力してください')
    .max(100, '役職100以内で入力してください。'),
  admin_phone: yup
    .string()
    .required('※正しく入力してください')
    .min(10, '管理者電話番号11以内で入力してください。')
    .max(11, '管理者電話番号11以内で入力してください。'),
  admin_email: yup.string().required('※メールアドレスを正しく入力してください（半角英数字のみ）'),
  email_address_confirmation: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('admin_email'), null], '電子メールの確認は電子メールと同じではありません')
    .required('※メールアドレスを正しく入力してください（半角英数字のみ）'),
});
