import styled from 'styled-components';

export default styled.section`
  height: 100%;
  margin: 40px 30px;
  display: flex;
  justify-content: space-between;
  .right-side {
    border: 1px solid #eeeeee;
    background: #ffffff;
    height: 80vh;
    width: 49%;
    border-radius: 4px;
    @media (max-width: 1440px) {
      width: 586px;
    }
    .ant-table-body > table > tbody .row-1,
    .row-2,
    .row-3 {
      height: 64px;
    }
  }
  .left-side {
    border: 1px solid #eeeeee;
    background: #ffffff;
    height: 80vh;
    width: 49%;
    border-radius: 4px;
    @media (max-width: 1440px) {
      width: 586px;
    }
    .ant-table-body {
      .ant-table-cell:nth-of-type(1) {
        font-size: 11px;
        color: #999999;
      }
      .ant-table-cell:nth-of-type(2) {
        font-size: 13px;
      }
      .ant-table-cell:nth-of-type(3) {
        font-size: 12px;
      }
      .ant-table-cell:nth-of-type(3) {
        font-size: 13px;
      }
    }
    .table {
      thead {
        .ant-table-cell {
          padding: 12px 12px;
        }
      }
    }
  }
  .wrap-header-left {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
    background: #ffffff;
    .title {
      font-size: 16px;
      display: grid;
      align-content: center;
    }
    .wrap-select {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 41%;
      .item-select {
        height: 32px;
        width: 120px;
      }
      .ant-select-selector {
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
      span:nth-of-type(1) {
        font-size: 12px;
      }
    }
  }
  .wrap-header-right {
    padding: 19px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:nth-of-type(1) {
      height: 34px;
      line-height: 17px;
    }
    .text {
      font-size: 12px;
    }
    .subtitle {
      font-size: 16px;
    }
    .btn {
      border: 1px solid #00a3a5;
      color: #00a3a5;
      border-radius: 5px;
      font-weight: 700;
      width: 120px;
      font-size: 12px;
    }
  }
  .table {
    .ant-table-cell {
      padding: 10.5px 12px;
    }
  }
  .right-side {
    .wrap-header-right {
      background: #ffffff;
    }
    .ant-table-header {
      height: 44px;
      background: #ebebeb;
    }
    .title-ranking {
      display: flex;
      font-size: 12px;
      font-weight: 700;
    }
    .title-ranking::after {
      content: '';
      border-right: 1px solid #dddddd;
      height: 22px;
      display: block;
      margin-left: 13px;
      margin-bottom: -2px;
    }
    .goverment {
      background: #f5fdfd !important;
      z-index: 9999;
    }
    .ant-table-row .ant-table-cell {
      border-bottom: none !important;
    }
    table {
      padding: 0 3px;
      background: #ebebeb;
      .ant-table-row {
        background: #ffffff;
      }
      .ant-table-cell {
        padding: 1.8px 12px;
      }
      thead {
        font-size: 12px;
        .ant-table-cell:nth-of-type(2) {
          padding: 0 12px;
        }
      }
    }
    .icon-ranking {
      font-size: 20px;
    }
    .ranking::before {
      position: absolute;
      color: #ffffff;
      top: 37%;
      left: 42%;
      font-size: 14px;
    }
    .ranking-1::before {
      content: '1';
    }
    .ranking-2::before {
      content: '2';
    }
    .ranking-3::before {
      content: '3';
    }
    .curriculum-name {
      font-size: 13px;
    }
    .curriculum {
      font-weight: 700;
      font-size: 13px;
    }
    .curriculum-top {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }
    .curriculum-bottom {
      font-size: 11px;
      font-weight: 400;
    }
    .number-user,
    .number-company {
      font-size: 18px;
      color: #2a2a2a;
    }
    .number {
      font-size: 16px;
    }
    .ant-table-row .ant-table-cell {
      border-bottom: 4px solid #dddddd !important;
      &:last-child {
        border-left: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &:first-child {
        border-right: none;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        border-left: none;
        border-right: none;
      }
    }
  }
  // .ant-table-thead
  //   > tr
  //   > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  //   background-color: transparent !important;
  // }
  .ant-table-container table > thead {
    font-size: 12px;
    height: 44px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .icon-status {
    margin: 0 5px 3px 0;
  }
`;
