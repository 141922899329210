import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Types from 'types';
import { services, userService } from 'services';
import {
  USERS,
  CURRICULUMS,
  SKILL_CHECK,
  SELECT_ROLE,
  SELECT_POSITION,
  SELECT_SKILL_CHECK,
  EMPLOYEE_USER_EXPORT,
  USER_ASSIGN_SKILL_CHECK,
  AFFILIATION_ASSIGN_ROLE,
  SELECT_SKILL_CHECK_CREATOR,
  SELECT_REQUIRED_CURRICULUM,
  SELECT_SKILL_CHECK_CATEGORY,
  SELECT_SKILL_CHECK_USE_STATUS,
  SELECT_SKILL_CHECK_START_PERIOD,
  USER_ASSIGN_REQUIRED_CURRICULUM,
  SELECT_SKILL_CHECK_GROUPING_CODE,
  SELECT_REQUIRED_CURRICULUM_CREATOR,
  EMPLOYEE_USER,
  AFFILIATION_HIERARCHY,
} from 'configs';

export const getSelectEmployee = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DataEmployeeRes>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(EMPLOYEE_USER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataEmployee = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.employeeFilter(req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSelectPosition = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_POSITION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAffiliation = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectAffiliation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(AFFILIATION_HIERARCHY.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectRole = createAsyncThunk<
  Types.ReportsItemResponseType<Types.RoleSelectType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteEmployee = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/thunk/deleteEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USERS.id, req);

    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataEmployeeExport = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataEmployeeExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(EMPLOYEE_USER_EXPORT.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createEmployee = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.User.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_ROLE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addUser = createAsyncThunk<
  Types.AddUserResponse,
  Types.AddUserRequest,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/addUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.addUser(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSubPosition = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationResType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSubPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateEmployee = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.User.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAffiliationRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.User.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAffiliationRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheck = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculumBatch = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataCurriculumBatch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculumCode = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectCurriculumCode', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCreator = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_REQUIRED_CURRICULUM_CREATOR.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckStartPeriod = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckStartPeriod', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_SKILL_CHECK_START_PERIOD.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckCategory = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckCategory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_SKILL_CHECK_CATEGORY.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckGroupCode = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckGroupCode', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_SKILL_CHECK_GROUPING_CODE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckStatus = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_SKILL_CHECK_USE_STATUS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckCreator = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_SKILL_CHECK_CREATOR.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkUserAssignCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteLinkUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_ASSIGN_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignSkillCheck = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkUserAssignSkillCheck = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteLinkUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUserAssignSkillCheck = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataEmployeeDetail = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataEmployeeDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAffiliationPosition = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateAffiliationPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_ROLE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateAffiliationPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(AFFILIATION_ASSIGN_ROLE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
