import React, { useEffect } from 'react';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { generatePath, Link } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { Button } from 'antd';

import { isFirstLogin, signIn, updateLoginTime } from 'containers/Auth/thunk';
import { Input, InputPassword, Checkbox } from 'components';
import AuthLayout from 'containers/Auth/Layout';
import { loginSchema } from 'libs/validations';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { LoginFormik } from 'types';
import { Centering } from 'assets';
import { updateDisableLoadingReducer } from 'containers/Auth/slice';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const formik = useFormik<LoginFormik>({
    initialValues: {
      email: '',
      password: '',
      save: false,
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      dispatch(updateDisableLoadingReducer(true));
      const resultAction = await dispatch(
        signIn({
          email: values.email,
          password: values.password,
          exclusive_w_id: '655983b2942ff9ef0f4a0081',
        })
      );
      if (signIn.fulfilled.match(resultAction)) {
        if (values.save) {
          localStorage.setItem('email', values.email);
          localStorage.setItem('remember_me', 'saved');
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('remember_me');
        }
        const resultActionIsFirstLogin = await dispatch(
          isFirstLogin({
            conditions: [
              {
                id: 'email',
                search_value: [values.email],
                exact_match: true,
              },
            ],
            include_lookups: true,
            page: 1,
            per_page: 0,
          })
        );
        if (isFirstLogin.fulfilled.match(resultActionIsFirstLogin)) {
          if (resultActionIsFirstLogin.payload.totalItems === 0) {
            setErrorLoginFail();
            return;
          }
          await dispatch(
            updateLoginTime({
              id: resultActionIsFirstLogin.payload.items[0].i_id!,
              data: {
                item: {
                  last_login_at: dayjs().toISOString(),
                },
                is_force_update: true,
              },
            })
          );
        } else {
          setErrorLoginFail();
        }
      } else {
        setErrorLoginFail();
      }
    },
  });

  const setErrorLoginFail = () =>
    formik.setErrors({
      email: '',
      password: 'メールアドレスまたはパスワードが正しくありません',
    });

  useEffect(() => {
    formik.setFieldValue('email', localStorage.getItem('email') || '');
    formik.setFieldValue('save', localStorage.getItem('remember_me') === 'saved');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <div>
        <SectionStyled>
          <img src={Centering} alt="logo" className="logo" />
          <FormikProvider value={formik}>
            <Form className="form">
              <Form.Item name="email">
                <Input
                  name="email"
                  type="email"
                  placeholder="メールアドレス"
                  className="input"
                  prefix={<MailOutlined className="icon" />}
                />
                <Button
                  onClick={() => formik.setFieldValue('email', '')}
                  style={{
                    opacity: 0,
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 9999,
                  }}
                >
                  click
                </Button>
              </Form.Item>
              <Form.Item name="password">
                <InputPassword
                  className="input"
                  name="password"
                  placeholder="パスワード"
                  prefix={<LockOutlined className="icon" />}
                />
              </Form.Item>
              <Form.Item name="save">
                <Checkbox name="save" className="checkbox">
                  ログイン情報を保存する
                </Checkbox>
              </Form.Item>
              <SubmitButton className="btn-submit">ログイン</SubmitButton>
            </Form>
          </FormikProvider>
          <div className="wrap-bottom">
            <p className="register">
              {messages['L-01-5.1']}
              <Link
                className="text-link"
                to={generatePath(routes.ResetPassword.path, { entity: 'receiving' })}
              >
                パスワードの再発行はこちら
              </Link>
            </p>
          </div>
        </SectionStyled>
      </div>
    </AuthLayout>
  );
};

export default Login;
