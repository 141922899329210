import React from 'react';
import { FormikProps, FormikProvider } from 'formik';
import { Form, SubmitButton, Radio } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { settingSelector } from 'containers/AppSettings/selectors';
import { CreateAuthorityMasterFormik } from 'types';
import AuthorityMasterFromStyled from './styles';
import { TextArea } from 'components';

interface Prop {
  formik: FormikProps<CreateAuthorityMasterFormik>;
}

const AuthorityMasterFrom: React.FC<Prop> = ({ formik }) => {
  const navigate = useNavigate();

  const { collapsedMenu } = useSelector(settingSelector);

  const { messages } = useIntl();

  const handleBack = () => {
    navigate(-1);
  };

  const { isSubmitting } = formik;

  return (
    <AuthorityMasterFromStyled collapsedMenu={collapsedMenu}>
      <FormikProvider value={formik}>
        <Form colon={false} className="form-setting">
          <div className="block">
            <Form.Item name="name">
              <label className="text-label">
                権限名称<span className="require">*</span>
              </label>
              <TextArea
                name="name"
                className="input-textarea-group"
                showCount
                maxLength={120}
                placeholder="最大120文字"
              />
            </Form.Item>
          </div>

          <div className="block">
            <label className="text-label">Home</label>
            <div className="waper-setting">
              <Form.Item name="admin_dashboard_permission">
                <div className="setting-item">
                  <div className="setting-item_left">ダッシュボード</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="admin_dashboard_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="admin_dashboard_permission" className="radio-custom" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="admin_dashboard_permission" className="radio-custom" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="admin_dashboard_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>

          <div className="block">
            <label className="text-label">ユーザー管理</label>
            <div className="waper-setting">
              <Form.Item name="inter_users_master_permission">
                <div className="setting-item">
                  <div className="setting-item_left item-left">社内ユーザーマスタ</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="inter_users_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="inter_users_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="inter_users_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="inter_users_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="roles_master_permission">
                <div className="setting-item">
                  <div className="setting-item_left item-left">権限マスタ</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="roles_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="roles_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="roles_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="roles_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
              </Form.Item>
              <Form.Item name="departments_master_permission">
                <div className="setting-item">
                  <div className="setting-item_left">所属マスタ</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="departments_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="departments_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="departments_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="departments_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
                <div className="setting-item">
                  <div className="setting-item_left">役職マスタ</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="position_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="position_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="position_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="position_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
                <div className="setting-item">
                  <div className="setting-item_left">管理者マスタ</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="admin_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="admin_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="admin_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="admin_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>

          <div className="block">
            <label className="text-label label">OFFICIALカリキュラム公開管理</label>
            <div className="waper-setting">
              <label className="text-label">{messages['M-02-8']}</label>
              <Form.Item name="official_curricullum_master_permission">
                <div className="setting-item">
                  <div className="setting-item_left">OFFICIALカリキュラム公開管理</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="official_curricullum_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="official_curricullum_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="official_curricullum_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="official_curricullum_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="block">
            <label className="text-label label">カリキュラム管理</label>
            <div className="waper-setting">
              <label className="text-label">{messages['M-02-8']}</label>
              <Form.Item name="curricullum_master_permission">
                <div className="setting-item">
                  <div className="setting-item_left">カリキュラムマスタ</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="curricullum_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="curricullum_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="curricullum_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="curricullum_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
                <div className="setting-item">
                  <div className="setting-item_left">設問マスタ</div>
                  <div className="setting-item_right">
                    <Radio.Group
                      name="question_master_permission"
                      className="wrap-radio"
                      defaultValue="1"
                    >
                      <div className="item-radio">
                        <Radio name="question_master_permission" value="2">
                          編集権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="question_master_permission" value="1">
                          閲覧権限
                        </Radio>
                      </div>
                      <div className="item-radio">
                        <Radio name="question_master_permission" value="0">
                          非表示
                        </Radio>
                      </div>
                    </Radio.Group>
                  </div>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className={`wrap-submit ${!formik.errors.name ? 'wrap-message' : ''}`}>
            <p className="error-message">{formik.errors.name ? '※権限名称が未入力です' : ' '}</p>
            <div className="wrap-button">
              <SubmitButton className="btn btn_submit" loading={isSubmitting}>
                更新
              </SubmitButton>
              <button className="btn btn_close" onClick={handleBack}>
                戻る
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </AuthorityMasterFromStyled>
  );
};

export default AuthorityMasterFrom;
