import { createAsyncThunk } from '@reduxjs/toolkit';

import { AFFILIATION_HIERARCHY, AFFILIATION_LEVEL, DEPARTMENTS } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getBelongingMaster = createAsyncThunk<
  Types.ReportsItemResponseType<Types.AffiliationHierarchy.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BelongingMaster/thunk/getBelongingMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(AFFILIATION_HIERARCHY.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchBelongingMaster = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BelongingMaster/thunk/searchBelongingMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(DEPARTMENTS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateBelongingMaster = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('BelongingMaster/thunk/updateBelongingMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createBelongingMaster = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('BelongingMaster/thunk/createBelongingMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteBelongingMaster = createAsyncThunk<
  string,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BelongingMaster/thunk/deleteBelongingMaster', async (req, { rejectWithValue }) => {
  try {
    await services.delete(DEPARTMENTS.id, req);

    return req.id;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getBelongingMasterCSV = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('BelongingMaster/thunk/getAnalysisGroupCSV', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.search(DEPARTMENTS.id, {
      page: 1,
      per_page: 0,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
