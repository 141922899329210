import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Select, Table } from 'antd';
import { CSVLink } from 'react-csv';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { HEADER_JOB_TITLE_MASTER_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateEditJobTitleSchema } from 'libs/validations';
import { Header, SelectField, TextArea } from 'components';
import { resetListRankOrder, resetSearch } from './slice';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { jobTitleMasterSelector } from './selectors';
import { loadingRef } from 'components/Loading';
import AuthorityMasterStyled from './styles';
import { useAppDispatch } from 'hooks';
import { POSITIONS } from 'configs';
import { services } from 'services';
import * as Types from 'types';
import {
  createItemJobTitleMaster,
  deleteItemJobTitleMaster,
  getJobTitleMaster,
  getJobTitleMasterCSV,
  getRankOrders,
  updateItemJobTitleMaster,
} from './thunk';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import FileExportInvoicePDF from './FileExportPDF';

const { Option } = Select;

const PER_PAGE = 10;

const JobTitleMaster: React.FC = () => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [dataExport, setDataExport] = useState<Types.DataItemJobTitleMaster[]>([]);
  const [completedModalTitle, setCompletedModalTitle] = useState<string>('');
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [itemDeleted, setItemDeleted] = useState<Types.TypeRecord>();
  const [idItem, setIdItem] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const { loading, listJobTitleMaster, total, listRankOrder } = useSelector(jobTitleMasterSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const exportDataRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const { messages } = useIntl();

  const dispatch = useAppDispatch();

  const formikSearch = useFormik<Types.SearchJobTitleMasterFormik>({
    initialValues: {
      job_title_search: '',
    },
    onSubmit: async ({ job_title_search }) => {
      if (!job_title_search) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する権限を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        await dispatch(
          getJobTitleMaster({
            page: 1,
            per_page: PER_PAGE,
            conditions: [
              {
                id: 'position_code',
                search_value: [job_title_search],
                exact_match: true,
              },
            ],
            sort_field_id: 'rank_order',
            sort_order: 'asc',
            omit_total_items: false,
          })
        );
      }
    },
    onReset: () => {
      dispatch(resetSearch());
      dispatch(
        getJobTitleMaster({
          page: 1,
          per_page: PER_PAGE,
          sort_field_id: 'rank_order',
          sort_order: 'asc',
        })
      );
    },
  });

  const isEditing = (record: Types.TypeRecord) => record.i_id === idItem;

  const handleCancel = () => setIdItem('');

  const handleEditItem = (record: Types.TypeRecord) => {
    const { i_id } = record;
    setIdItem(i_id);
  };

  const columns: any = [
    {
      key: 'position_code',
      title: 'コード',
      dataIndex: 'position_code',
      width: '15%',
      align: 'center',
      sorter: (a: Types.TypeRecord, b: Types.TypeRecord) => Number(a.code) - Number(b.code),
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
    },
    {
      title: messages['M-07-14'],
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: any, record: Types.TypeRecord) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              更新
            </SubmitButton>
            <button type="button" className="btn btn_close" onClick={handleCancel}>
              キャンセル
            </button>
          </div>
        ) : (
          <FormOutlined className="icon" onClick={() => handleEditItem(record)} />
        );
      },
    },
    {
      title: messages['M-07-15'],
      width: '7%',
      dataIndex: 'operation',
      align: 'left',
      render: (_: any, record: Types.TypeRecord) =>
        idItem !== record.i_id ? (
          <DeleteOutlined
            className="icon"
            onClick={() => {
              setItemDeleted(record);
              setOpenModalConfirmDeleteItem(true);
            }}
          />
        ) : null,
    },
  ];

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => {
    const item = listJobTitleMaster.find((j) => j.position_code === restProps['data-row-key']);
    return (
      <Formik<Types.CreateEditJobTitleFormik>
        initialValues={{
          name: item?.name || '',
          rank_order: item?.rank_order || 0,
        }}
        validationSchema={CreateEditJobTitleSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const { data } = await services.search(POSITIONS.id, {
              page: 1,
              per_page: 0,
              conditions: [
                {
                  id: 'rank_order',
                  search_value: listRankOrder.filter((o) => Number(o) >= Number(values.rank_order)),
                  exact_match: true,
                },
              ],
              sort_field_id: 'rank_order',
              sort_order: 'asc',
            });
            const resultAction = await dispatch(
              updateItemJobTitleMaster({
                id: idItem,
                data: {
                  item: {
                    ...values,
                    company_id: userInfo?.company_id,
                    updatedat: new Date(),
                    updatedby: userInfo?.login_id,
                  },
                  is_force_update: true,
                },
              })
            );
            if (updateItemJobTitleMaster.fulfilled.match(resultAction)) {
              if (
                data.totalItems > 0 &&
                data.items.find((i) => i.rank_order === values.rank_order && i.i_id !== idItem)
              ) {
                await Promise.all(
                  data.items
                    .filter((i) => i.i_id !== idItem)
                    .map((i) =>
                      dispatch(
                        updateItemJobTitleMaster({
                          id: i.i_id,
                          data: {
                            item: {
                              rank_order: Number(i.rank_order) + 1,
                              updatedat: new Date(),
                              updatedby: userInfo?.login_id,
                            },
                            is_force_update: true,
                          },
                        })
                      )
                    )
                );
                await dispatch(getRankOrders());
              }
              setCompletedModalTitle('更新が完了しました');
              setShowCompleteModal(true);
            } else {
              setActionModalState({
                subTitle: '役職の更新に失敗しました',
                description: (
                  <p className="text-content">
                    役職の更新に失敗しました。
                    <br />
                    もう一度情報を入力し、再度お試しください。
                  </p>
                ),
              });
              setShowActionErrorModal(true);
            }
          } finally {
            setIdItem('');
            resetForm();
          }
        }}
      >
        <tr {...restProps}>{children}</tr>
      </Formik>
    );
  };

  const EditableCell: React.FC<Types.TypeCell> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <Form>
          {editing ? (
            <Form.Item
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              <TextArea showCount maxLength={120} name="name" />
            </Form.Item>
          ) : (
            children
          )}
        </Form>
      </td>
    );
  };

  const mergedColumns = columns.map((col: Types.TypeCell) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Types.TypeRecord) => ({
        record,
        inputType: 'textArea',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleChangePage = useCallback(
    (p: number) => {
      setPage(p);
      dispatch(
        getJobTitleMaster({
          page: p,
          per_page: PER_PAGE,
          sort_field_id: 'rank_order',
          sort_order: 'asc',
          omit_total_items: false,
        })
      );
    },
    [dispatch]
  );

  const handleSearch = (value: string) => {
    dispatch(
      getJobTitleMaster({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'name',
            search_value: [value],
          },
        ],
      })
    );
  };

  const handleExportCSV = async (value: string) => {
    try {
      if (value === 'csv') {
        const resultAction = await dispatch(getJobTitleMasterCSV());
        if (getJobTitleMasterCSV.fulfilled.match(resultAction)) {
          setDataExport(resultAction.payload.items);
          exportDataRef.current?.link.click();
        }
      } else {
        const blob = await pdf(<FileExportInvoicePDF data={listJobTitleMaster} />).toBlob();
        saveAs(blob, '役職マスタ.pdf');
      }
    } catch (error) {
      console.log(error);
    }
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    if (!userInfo) return;

    dispatch(
      getJobTitleMaster({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: PER_PAGE,
        sort_fields: [{ id: 'position_code', order: 'asc' }],
        omit_total_items: false,
      })
    );
    return () => {
      dispatch(resetListRankOrder());
    };
  }, [dispatch]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <AuthorityMasterStyled collapsedMenu={collapsedMenu}>
      <Header title="役職マスタ" className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            役職マスタの作成・管理を行う画面です。
            <br />
            作成した役職マスタはユーザーに設定します。
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="job_title_search"
                label={<span className="label"> 役職検索</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  className="select-input"
                  placeholder="選択してください"
                  onSearch={handleSearch}
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="job_title_search"
                >
                  {listJobTitleMaster.map(({ name, position_code }, index) => (
                    <Option key={index} value={position_code}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">リセット</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>
          {total > 0 ? (
            <div className="text-count">
              {page * PER_PAGE > total ? total : page * PER_PAGE} 件表示 / {total} 件中
            </div>
          ) : null}
          <div className="btn-div">
            <button
              className="btn btn-active btn-download"
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </button>
            <CSVLink
              ref={exportDataRef}
              style={{ display: 'none' }}
              filename="役職マスタ.csv"
              data={dataExport}
              headers={HEADER_JOB_TITLE_MASTER_CSV}
            />
          </div>

          <Table
            dataSource={listJobTitleMaster}
            columns={mergedColumns}
            loading={loading}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            className={listJobTitleMaster.length > 0 ? 'table' : 'table_mr'}
            pagination={{
              showSizeChanger: false,
              total: total,
              current: page,
              pageSize: 10,
              onChange: handleChangePage,
              position: ['bottomCenter', 'topCenter'],
            }}
            rowKey="position_code"
          />
        </div>
        <div className="wrap-create">
          <p className="title">役職新規登録</p>
          <Formik<Types.CreateEditJobTitleFormik>
            initialValues={{
              name: '',
              rank_order: listRankOrder[listRankOrder.length - 1],
            }}
            enableReinitialize
            validationSchema={CreateEditJobTitleSchema}
            onSubmit={async (values, { resetForm }) => {
              const resultAction = await dispatch(
                createItemJobTitleMaster({
                  item: {
                    company_id: userInfo?.company_id,
                    createdat: new Date(),
                    createdby: userInfo?.login_id,
                    ...values,
                  },
                  is_force_update: true,
                  return_item_result: true,
                })
              );
              if (createItemJobTitleMaster.fulfilled.match(resultAction)) {
                dispatch(
                  getJobTitleMaster({
                    page: page,
                    per_page: PER_PAGE,
                    sort_field_id: 'rank_order',
                    sort_order: 'asc',
                    omit_total_items: false,
                  })
                );
                const { data } = await services.search(POSITIONS.id, {
                  page: 1,
                  per_page: 0,
                  conditions: [
                    {
                      id: 'rank_order',
                      search_value: listRankOrder.filter(
                        (o) => Number(o) >= Number(values.rank_order)
                      ),
                      exact_match: true,
                    },
                  ],
                  sort_field_id: 'rank_order',
                  sort_order: 'asc',
                });
                if (
                  data.totalItems > 0 &&
                  data.items.find(
                    (i) =>
                      i.rank_order === values.rank_order && i.i_id !== resultAction.payload.item_id
                  )
                ) {
                  await Promise.all(
                    data.items
                      .filter((i) => i.i_id !== resultAction.payload.item_id)
                      .map((i) =>
                        dispatch(
                          updateItemJobTitleMaster({
                            id: i.i_id,
                            data: {
                              item: {
                                rank_order: Number(i.rank_order) + 1,
                                updatedat: new Date(),
                                updatedby: userInfo?.login_id,
                              },
                              is_force_update: true,
                            },
                          })
                        )
                      )
                  );
                }
                setCompletedModalTitle('登録が完了しました');
                setShowCompleteModal(true);
              } else {
                setActionModalState({
                  subTitle: '新規役職の登録に失敗しました',
                  description: (
                    <p className="text-content">
                      新規役職の登録に失敗しました。
                      <br />
                      もう一度情報を入力し、再度お試しください。
                    </p>
                  ),
                });
                setShowActionErrorModal(true);
              }
              dispatch(getRankOrders());
              resetForm();
            }}
          >
            <Form colon={false} layout="vertical" className="form">
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    役職名称
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="name"
                  showCount
                  maxLength={120}
                  placeholder="最大120文字"
                  className="text-area"
                />
              </Form.Item>
              <SubmitButton className="btn_submit">
                <PlusOutlined className="size-icon" />
                新規登録
              </SubmitButton>
            </Form>
          </Formik>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
        subTitle="役職マスタをエクスポートします。"
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title={completedModalTitle}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        visible={openModalConfirmDeleteItem}
        onSubmit={async () => {
          setActionModalState({
            subTitle: '削除に失敗しました',
            description: (
              <p className="text-content">マスタの削除に失敗しました。 再度お試しください。</p>
            ),
          });
          if (!itemDeleted) return;
          const resultAction = await dispatch(deleteItemJobTitleMaster({ id: itemDeleted.i_id }));
          if (deleteItemJobTitleMaster.fulfilled.match(resultAction)) {
            dispatch(getRankOrders());
            setCompletedModalTitle('削除が完了しました');
            setShowCompleteModal(true);
          } else {
            setShowActionErrorModal(true);
          }
          setItemDeleted(undefined);
        }}
        onCancel={() => setItemDeleted(undefined)}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </AuthorityMasterStyled>
  );
};

export default JobTitleMaster;
