import React from 'react';
import { ColumnsType } from 'antd/es/table';
import { filter, uniqBy } from 'lodash';
import { Table } from 'antd';

import { TableStyle } from './styles';

type Props = {
  companyUserData: any;
  loading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const TableScreen: React.FC<Props> = ({ companyUserData, loading, setPage }) => {
  const columns: ColumnsType<any> = [
    {
      title: 'ID',
      dataIndex: 'curriculum_code',
      key: 'curriculum_code',
      className: 'code',
      width: 79,
      ellipsis: true,
    },
    {
      title: 'OFFICIALカリキュラム名',
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      className: 'name',
      width: 514,
      ellipsis: true,
    },
    {
      title: (
        <span className="title">
          企業数<small className="text-small">（上段）</small>/ ユーザー数
          <small className="text-small">（下段）</small>
        </span>
      ),
      dataIndex: 'a',
      key: 'c',
      align: 'center',
      width: 588,
      className: 'number-company',
      children:
        companyUserData[0]?.date &&
        Object.keys(companyUserData[0]?.date).map((key) => ({
          title: key,
          dataIndex: ['date'],
          className: 'date',
          render: (_, { date, curriculum_code }) => (
            <>
              <span>
                {
                  filter(
                    date[key],
                    (e) => e.createdat && e.official_curriculum_code === curriculum_code
                  ).length
                }
              </span>
              <br />
              <span>
                {
                  filter(
                    date[key],
                    (e) => e.implementation_date && e.official_curriculum_code === curriculum_code
                  ).length
                }
              </span>
            </>
          ),
        })),
    },
  ];

  return (
    <TableStyle>
      <Table
        rowKey="id"
        className="table"
        loading={loading}
        columns={columns}
        pagination={{
          pageSize: 100,
          total: companyUserData.length,
          position: ['bottomCenter'],
          onChange: setPage,
        }}
        dataSource={uniqBy(companyUserData, 'curriculum_code')}
        bordered
      />
    </TableStyle>
  );
};

export default TableScreen;
