import React, { useEffect, useRef, useState } from 'react';
import {
  UploadChangeParam,
  UploadFile,
  UploadFile as UploadFileAntd,
} from 'antd/lib/upload/interface';
import { Image as ImageAntd, Upload } from 'antd';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { UploadFileFieldType } from 'types/services/curriculum';
import { FileType } from 'constant/enum.constant';
import { SectionStyled } from './styles';
import { Modal } from 'components';
import { NoImage, NoImage2 } from 'assets';
import { slice } from 'lodash';

interface Props {
  visible: {
    show: boolean;
    data: Array<UploadFileAntd<File>>;
    type: UploadFileFieldType;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      data: Array<UploadFileAntd<File>>;
      type: UploadFileFieldType;
    }>
  >;
  onSubmit: (data: Array<UploadFileAntd<File>>, fieldType: UploadFileFieldType) => void;
}

const UploadImageVideo: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [files, setFiles] = useState<Array<UploadFileAntd<File>>>([]);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const vidRef = useRef<HTMLVideoElement>(null);

  const handleClose = () => {
    setVisible({ ...visible, show: false });
    vidRef.current?.pause();
  };

  const handleDelete = () => {
    setFiles((prevState) => prevState.filter((_item, i) => i !== index));
    setIndex((prevState) => (prevState > 0 ? prevState - 1 : 0));
  };

  const handleSubmit = () => {
    handleClose();
    onSubmit(files, visible.type);
  };

  const onChange = async (info: UploadChangeParam<UploadFile<File>>) => {
    const fileNumber = !files[index] && info.fileList.length > index + 1 ? 3 - index : 1;
    const lastThreeFiles =
      info.fileList.length > fileNumber ? slice(info.fileList, -fileNumber) : info.fileList;
    const fileList: Array<UploadFile<File>> = [...files];

    for (const element of lastThreeFiles) {
      const file = element;
      const isJpgOrPngOrMp4 =
        file.type === FileType.JPEG || file.type === FileType.PNG || file.type === FileType.MP4;

      const isAccept = await new Promise<boolean>((resolve) => {
        if (!file.originFileObj) return resolve(false);
        const src = URL.createObjectURL(new Blob([file.originFileObj]));

        if (file.originFileObj.type === FileType.PNG || file.originFileObj.type === FileType.JPEG) {
          const image = new Image();
          image.src = src;
          image.onload = function () {
            URL.revokeObjectURL(src);
            resolve(file.originFileObj!.size < 10485760);
          };
        } else {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(src);
            resolve(file.originFileObj!.size < 104857600);
          };
          video.src = src;
          video.load();
        }
      });

      if (!isJpgOrPngOrMp4 || !isAccept) {
        alert(`※画像はjpegまたはpngである必要があり、最大10MBまで可能です。
    ビデオはmp4である必要があり、最大100MBにすることができます。`);
        fileList.push(file);
      } else {
        !files[index] && fileList.length < 3 ? fileList.push(file) : (fileList[index] = file);
      }
    }
    setFiles(fileList);
    return fileList;
  };

  useEffect(() => {
    if (visible.show && visible.type !== 'problems2_attach') {
      setFiles(visible.data);
    }
    return () => {
      setFiles([]);
      setIsShowImage(false);
      setIndex(0);
    };
  }, [visible]);

  return (
    <Modal
      title="画像・動画アップロード"
      width={720}
      open={visible.show && visible.type !== 'problems2_attach'}
      onCancel={handleClose}
      okButton={{
        text: '登録',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="form-upload">
          {files && files.length > 1 ? (
            <>
              <LeftOutlined
                className="icon-prev"
                onClick={() => {
                  setIndex((prevState) => (prevState === 0 ? files.length - 1 : prevState - 1));
                  vidRef.current?.pause();
                }}
              />
              <RightOutlined
                className="icon-next"
                onClick={() => {
                  setIndex((prevState) => (prevState === files.length - 1 ? 0 : prevState + 1));
                  vidRef.current?.pause();
                }}
              />
            </>
          ) : null}
          <div className="file-upload">
            {files.length > 0 && files[index] ? (
              <div className="info-file">
                <p className="name-image">{files[index].name.split(',')[index]}</p>
                {files[index].type === FileType.MP4 ? (
                  <video ref={vidRef} width="100%" height={338} controls>
                    <source
                      src={URL.createObjectURL(new Blob([files[index].originFileObj as BlobPart]))}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <ImageAntd
                    className="image-file"
                    src={URL.createObjectURL(new Blob([files[index].originFileObj as BlobPart]))}
                    alt={files[index].name}
                    preview={{
                      visible: isShowImage,
                      src: URL.createObjectURL(new Blob([files[index].originFileObj as BlobPart])),
                      onVisibleChange: (value) => {
                        setIsShowImage(value);
                      },
                      mask: (
                        <ZoomInOutlined
                          className="zoom-image"
                          onClick={() => setIsShowImage(!isShowImage)}
                        />
                      ),
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                <CloudUploadOutlined className="icon" />
                <p className="ant-upload-text">
                  アップロードするファイルをここにドロップ
                  <br />
                  または
                </p>
              </>
            )}
            <div className="flex">
              <Upload
                name="file"
                accept=".jpeg, .png, .mp4"
                multiple
                beforeUpload={() => false}
                fileList={files}
                onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                  onChange(info);
                }}
              >
                <button type="button" className="btn-upload">
                  ファイルを選択
                </button>
              </Upload>
              {files.length > 0 ? (
                <button className="btn-delete" type="button" onClick={handleDelete}>
                  <DeleteOutlined className="icon-delete-outlined" />
                  <span className="text-delete-outlined">ファイルを削除</span>
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex-image-small">
          {[0, 1, 2].map((idx) => {
            const item = files[idx];
            if (!item) {
              return (
                <div
                  key={idx}
                  className={`image ${idx === index ? 'image_active' : ''}`}
                  onClick={() => setIndex(idx)}
                >
                  <img src={NoImage2} alt="no image" className="image-small" />
                </div>
              );
            }
            return (
              <div
                key={idx}
                className={`image ${idx === index ? 'image_active' : ''}`}
                onClick={() => setIndex(idx)}
              >
                {item.type === FileType.MP4 ? (
                  <div className="no-image">
                    <img src={NoImage} className="icon" alt="NoImage" />
                    <span className="text-no-image">No Image</span>
                  </div>
                ) : (
                  <img
                    src={URL.createObjectURL(new Blob([item.originFileObj as BlobPart]))}
                    alt={item.name}
                    className="image-small"
                  />
                )}
              </div>
            );
          })}
        </div>
        <p className="text-content">
          ※ファイルはまとめて３つまでアップロード可能です。
          <br />
          ※画像は16:4でjpegまたはpng必須で、10MBまでになります。
          <br />
          ※動画は16:4でmp4必須で、100MBまでになります。
          <br />
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default UploadImageVideo;
