import React, { useEffect, useRef, useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Image as ImageAntd, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { includes } from 'lodash';
import ReactPlayer from 'react-player';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { UploadFileFieldType } from 'types/services/curriculum';
import { FileType } from 'constant/enum.constant';
import { SectionStyled } from './styles';
import { Modal } from 'components';
import { NoImage2 } from 'assets';

interface Props {
  visible: {
    show: boolean;
    data: Array<UploadFile<File>>;
    type: UploadFileFieldType;
  };
  setVisible: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      data: Array<UploadFile<File>>;
      type: UploadFileFieldType;
    }>
  >;
  onSubmit: (data: Array<UploadFile<File> | undefined>, fieldType: UploadFileFieldType) => void;
}

const UploadImageVideoQuestion: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [files, setFiles] = useState<Array<UploadFile<File>>>([]);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const vidRef = useRef<HTMLVideoElement>(null);

  const handleClose = () => {
    setVisible({ ...visible, show: false });
    vidRef.current?.pause();
  };

  const handleDelete = () => {
    setFiles((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    handleClose();
    onSubmit(files, visible.type);
  };

  const beforeUpload = async (_: RcFile, FileList: RcFile[]) => {
    for (let i = 0; i < FileList.length; i++) {
      const file = FileList[i];

      const isJpgOrPngOrMp4 =
        file.type === FileType.PNG || file.type === FileType.JPEG || file.type === FileType.MP4;

      const isAccept = await new Promise<boolean>((resolve) => {
        const src = URL.createObjectURL(new Blob([file]));
        if (file.type === FileType.PNG || file.type === FileType.JPEG) {
          const image = new Image();
          image.src = src;
          image.onload = function () {
            URL.revokeObjectURL(src);
            resolve(file.size < 10485760);
          };
        } else {
          const video = document.createElement('video');
          video.onloadedmetadata = () => {
            URL.revokeObjectURL(src);
            resolve(file.size < 104857600);
          };
          video.src = src;
          video.load();
        }
      });

      if (!isJpgOrPngOrMp4 || !isAccept) {
        alert(`※画像はjpegまたはpngである必要があり、最大10MBまで可能です。
ビデオはmp4である必要があり、最大100MBにすることができます。`);
      }

      return isJpgOrPngOrMp4 && isAccept ? false : Upload.LIST_IGNORE;
    }
  };

  useEffect(() => {
    if (visible.show && visible.type === 'problems2_attach') {
      setFiles(visible.data);
    }
    return () => {
      setFiles([]);
      setIsShowImage(false);
      setIndex(0);
    };
  }, [visible]);

  return (
    <Modal
      title="画像・動画アップロード"
      width={720}
      visible={visible.show}
      onCancel={handleClose}
      okButton={{
        text: '登録',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="form-upload">
          <>
            <LeftOutlined
              className="icon-prev"
              onClick={() => {
                setIndex((prevState) => (prevState === 0 ? files.length - 1 : prevState - 1));
                vidRef.current?.pause();
              }}
            />
            <RightOutlined
              className="icon-next"
              onClick={() => {
                setIndex((prevState) => (prevState === files.length - 1 ? 0 : prevState + 1));
                vidRef.current?.pause();
              }}
            />
          </>
          <div className="file-upload">
            <span className="label-question">
              選択肢{index === 0 ? 'A' : index === 1 ? 'B' : 'C'}
            </span>
            {files[index] ? (
              <div className="info-file">
                <p className="name-image">{files[index]?.name.split(',')[index]}</p>
                {files[index]?.type === FileType.MP4 ? (
                  <video ref={vidRef} width="100%" height={338} controls>
                    <source
                      src={URL.createObjectURL(files[index]?.originFileObj as Blob)}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <ImageAntd
                    className="image-file"
                    src={URL.createObjectURL(files[index]?.originFileObj as Blob)}
                    alt={files[index]?.name}
                    preview={{
                      visible: isShowImage,
                      src: URL.createObjectURL(files[index]?.originFileObj as Blob),
                      onVisibleChange: (value) => {
                        setIsShowImage(value);
                      },
                      mask: (
                        <ZoomInOutlined
                          className="zoom-image"
                          onClick={() => setIsShowImage(!isShowImage)}
                        />
                      ),
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                <CloudUploadOutlined className="icon" />
                <p className="ant-upload-text">
                  アップロードするファイルをここにドロップ
                  <br />
                  または
                </p>
              </>
            )}
            <div className="flex">
              <Upload
                name="file"
                accept=".jpeg, .png, .mp4"
                multiple
                maxCount={visible.type?.includes('option') ? 1 : 3}
                beforeUpload={beforeUpload}
                fileList={files}
                onChange={(info: UploadChangeParam<UploadFile<File>>) => {
                  setFiles(info.fileList);
                }}
              >
                <button type="button" className="btn-upload">
                  ファイルを選択
                </button>
              </Upload>
              {files[index] && (
                <button className="btn-delete" type="button" onClick={handleDelete}>
                  <DeleteOutlined className="icon-delete-outlined" />
                  <span className="text-delete-outlined">ファイルを削除</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex-image-small">
          <div>
            <div
              className={`image ${index === 0 ? 'image_active' : ''}`}
              onClick={() => setIndex(0)}
            >
              {files[0]?.type === FileType.MP4 ||
              files[0]?.originFileObj?.type === FileType.MP4 ||
              includes(files[0] as unknown as string, 'mp4') ? (
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  url={
                    files[0]?.originFileObj
                      ? URL.createObjectURL(new Blob([files[0]?.originFileObj as Blob]))
                      : files[0]
                      ? (files[0] as unknown as string)
                      : NoImage2
                  }
                />
              ) : (
                <img
                  src={
                    files[0]?.originFileObj
                      ? URL.createObjectURL(new Blob([files[0]?.originFileObj as Blob]))
                      : files[0]
                      ? (files[0] as unknown as string)
                      : NoImage2
                  }
                  className="image-question"
                  alt={files[0]?.name || ''}
                />
              )}
            </div>
            <p className="label">
              {visible.type === 'problems2_attach' ? '選択肢A' : '画像・動画1'}
            </p>
          </div>
          <div>
            <div
              className={`image ${index === 1 ? 'image_active' : ''}`}
              onClick={() => setIndex(1)}
            >
              {files[1]?.type === FileType.MP4 ||
              files[1]?.originFileObj?.type === FileType.MP4 ||
              includes(files[1] as unknown as string, 'mp4') ? (
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  url={
                    files[1]?.originFileObj
                      ? URL.createObjectURL(new Blob([files[1]?.originFileObj as Blob]))
                      : files[1]
                      ? (files[1] as unknown as string)
                      : NoImage2
                  }
                />
              ) : (
                <img
                  src={
                    files[1]?.originFileObj
                      ? URL.createObjectURL(new Blob([files[1]?.originFileObj as Blob]))
                      : files[1]
                      ? (files[1] as unknown as string)
                      : NoImage2
                  }
                  className="image-question"
                  alt={files[1]?.name || ''}
                />
              )}
            </div>
            <p className="label">
              {' '}
              {visible.type === 'problems2_attach' ? '選択肢B' : '画像・動画２'}
            </p>
          </div>
          <div>
            <div
              className={`image ${index === 2 ? 'image_active' : ''}`}
              onClick={() => setIndex(2)}
            >
              {files[2]?.type === FileType.MP4 ||
              files[2]?.originFileObj?.type === FileType.MP4 ||
              includes(files[2] as unknown as string, 'mp4') ? (
                <ReactPlayer
                  width={'100%'}
                  height={'100%'}
                  url={
                    files[2]?.originFileObj
                      ? URL.createObjectURL(new Blob([files[2]?.originFileObj as Blob]))
                      : files[2]
                      ? (files[2] as unknown as string)
                      : NoImage2
                  }
                />
              ) : (
                <img
                  src={
                    files[2]?.originFileObj
                      ? URL.createObjectURL(new Blob([files[2]?.originFileObj as Blob]))
                      : files[2]
                      ? (files[2] as unknown as string)
                      : NoImage2
                  }
                  className="image-question"
                  alt={files[2]?.name || ''}
                />
              )}
            </div>
            <p className="label">
              {visible.type === 'problems2_attach' ? '選択肢C' : '画像・動画3'}
            </p>
          </div>
        </div>
        <p className="text-content">
          ※ファイルはまとめて３つまでアップロード可能です。
          <br />
          ※画像は16:4でjpegまたはpng必須で、10MBまでになります。
          <br />
          ※動画は16:4でmp4必須で、100MBまでになります。
          <br />
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default UploadImageVideoQuestion;
