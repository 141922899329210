export const getIndexFromYupErrorPath = (path: string) => {
  let matches = (path || '').match(/\[(.*?)\]/);
  if (matches) {
    const value = Number(matches[1].replace(/\"/g, ''));
    return isNaN(value) ? 0 : value;
  }
  return 0;
};

export function convertToFull(str: string) {
  return str.replace(/[!-~]/g, (fullwidthChar) =>
    String.fromCharCode(fullwidthChar.charCodeAt(0) + 0xfee0)
  );
}
