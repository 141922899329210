import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  USERS,
  POSITIONS,
  DEPARTMENTS,
  SELECT_USERS,
  USER_ASSIGN_REQUIRED_CURRICULUM,
} from 'configs';

export const getDataSearchUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.UserMenuRightType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getDataSearchUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ItemSelectMenuRightType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPositionUser = createAsyncThunk<
  Types.GetItemResponseType<Types.ItemSelectMenuRightType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getPositionUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDepartmentUser = createAsyncThunk<
  Types.GetItemResponseType<Types.ItemSelectMenuRightType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getDepartmentUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(DEPARTMENTS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/createUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_REQUIRED_CURRICULUM.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
