import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

type Props = {
  collapsedMenu: boolean;
};

export default styled.section<Props>`
  margin-bottom: 120px;
  @media (max-width: 1440px) {
    margin-bottom: 175px;
    .form-setting {
      .block {
        margin-top: 0 !important;
        .input-textarea-group {
          height: 66px;
        }
        .text-label,
        .label {
          color: #777 !important;
          margin: 20px 0 12px 0 !important;
        }
        .waper-setting {
          .text-label {
            display: none !important;
          }
          .ant-form-item:last-child {
            margin-bottom: 0 !important;
          }
          .ant-form-item {
            margin-bottom: 8px !important;
          }
          .setting-item {
            .setting-item_left {
              width: 236px;
            }
            .setting-item_right {
              width: 916px;
              .wrap-radio {
                justify-content: flex-start;
                .item-radio {
                  width: 305px;
                  height: 48px;
                  border-right: 1px solid #e2e2e2;
                }
                .item-radio:last-child {
                  border-right: none;
                }
              }
            }
          }
          .setting-item:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
      .wrap-message {
        flex-direction: unset !important;
      }
      .wrap-submit {
        box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.06) !important;
        .wrap-button {
          .btn {
            width: 180px !important;
            &_submit {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .form-setting {
    .block {
      margin-top: 12px;
      .ant-form-item {
        margin-bottom: 0;
      }
      .input-textarea-group {
        font-size: 14px;
        color: #2a2a2a;
        ::placeholder {
          font-size: 14px;
          color: #cccccc;
        }
      }
      .text-label {
        font-size: 13px;
        color: #222222;
        display: block;
        margin: 8px 0;
        .require {
          color: #f0330a;
          font-size: 20px;
        }
      }
      .waper-setting {
        padding: 12px;
        background-color: #ffffff;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        .text-label {
          font-size: 12px;
          color: #777777;
          display: block;
          margin: 5px 0;
        }
        .ant-row + .ant-row {
          margin-top: 8px;
        }
        .setting-item {
          background-color: #ebebeb;
          height: 56px;
          padding: 4px;
          display: flex;
          margin-bottom: 8px;
          &_left {
            width: 21%;
            padding: 12px;
            .checkbox {
              .ant-checkbox-inner {
                border: 1px solid #d9d9d9;
              }
            }
          }
          &_right {
            width: 79%;
            display: flex;
            align-items: center;
            .text-content {
              font-size: 13px;
              color: #777777;
            }
            .wrap-radio {
              display: flex;
              justify-content: space-between;
            }
            .ant-radio-checked ~ span {
              color: #424242;
              font-size: 16px;
              font-weight: 700 !important;
            }
            .item-radio {
              width: 33.23%;
              background: #ffffff;
              padding: 11px 20px;
            }
            .ant-radio-group {
              width: 100%;
              .ant-radio {
                margin-right: -20px;
                width: 16px;
              }
            }
            .ant-radio-wrapper {
              width: 100%;
              span:nth-child(2) {
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                font-family: Noto Sans Javanese;
              }
            }
          }
        }
        .active {
          background-color: #00a3a5;
          .checkbox {
            color: #ffffff;
            font-weight: 700;
            .ant-checkbox-checked::after {
              border: 1px solid rgba(255, 255, 255, 0.25);
            }
            .ant-checkbox-inner {
              background-color: #00a3a5;
              border: 1px solid rgba(255, 255, 255, 0.25);
            }
          }
        }
        .ant-form-item-with-help .ant-form-item-explain {
          display: none;
        }
      }
    }
    .wrap-submit {
      height: 95px;
      width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
      transition: width 0.3s;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
      .error-message {
        font-weight: 400;
        font-size: 13px;
        color: #f0330a;
        height: 24px;
        margin: 0;
      }
      .wrap-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .btn {
          border-radius: 5px;
          border: none;
          width: 16%;
          height: 40px;
          cursor: pointer;
          &_submit {
            margin-right: 12px;
            color: #ffffff;
            background-color: #f6ac00;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          }
          &_close {
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            color: #777777;
          }
        }
      }
    }
  }
`;
