import { createAsyncThunk } from '@reduxjs/toolkit';

import { services, userService } from 'services';
import { UpdateItemRequestType } from 'types';
import { COMPANIES, config, USERS } from 'configs';
import * as Types from 'types';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { convertFileResponse } from 'libs/utils/question';
import { sharedFileInMinIO } from 'services/minioService';
import { DEFAULT_COMPANY_NAME } from 'constant';

export const signIn = createAsyncThunk<
  Types.SignInRes,
  Types.SignInReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signIn', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signIn(req);
    localStorage.setItem('sk_access_token', data.token);
    return { ...data, signInUrl: req.email };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const signUp = createAsyncThunk<
  Types.SignUpRes,
  Types.SignUpReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signUp', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signUp(req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
export const signUpUser = createAsyncThunk<
  Types.SignUpUserResponse,
  Types.SignUpUserRequest,
  Types.ThunkAPI<Types.requestError>
>('auth/signUpUser', async (req, { rejectWithValue }) => {
  try {
    const { data, status } = await userService.signUpUser(req);
    if (status === 200) {
      const dataInviteUser: any = {
        item: req.item,
        as_params: {
          users: [
            {
              email: req.item.name,
            },
          ],
          email_templates_id: config.TEMPLATES_ID,
          domain: 'dev-rsweb.hexabase.com',
          invitation_path: 'confirm_email',
        },
        is_force_update: true,
      };
      await userService.inviteUser(dataInviteUser, data.item_id);
    }

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk<
  Types.ResetPasswordRes,
  Types.ResetPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/resetPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.resetPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const isFirstLogin = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/isFirstLogin', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    if (data.items.length) {
      const { data: companyItems } = await services.search(COMPANIES.id, {
        conditions: [
          { id: 'company_id', search_value: [data.items[0].company_id], exact_match: true },
        ],
        include_lookups: true,
        page: 1,
        per_page: 0,
      });
      data.items[0].isFistLogin =
        !companyItems.items.length ||
        (companyItems.items.length === 1 && companyItems.items[0].name === DEFAULT_COMPANY_NAME);
      if (!data.items[0].item_links) {
        data.items[0].item_links = {
          links: [
            {
              d_id: COMPANIES.id,
              items: [
                {
                  i_id: companyItems.items.length
                    ? companyItems.items.find((obj) => obj.id === data.items[0].company_id)?.i_id
                    : undefined,
                },
              ],
            },
          ],
        };
      }
      data.items[0].defaultTimeLimitIndex = companyItems.items.length
        ? companyItems.items.find((obj) => obj.id === data.items[0].company_id)?.time_limit
        : '1';
    }

    if (data.items[0]?.icon_fileID) {
      const fileName = extractFileName(data.items[0]?.icon_fileID);
      const nodeFileUrl = await sharedFileInMinIO(data.items[0]?.icon_fileID);
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

      data.items[0].avatar = convertFileResponse({
        file: fileFromUrl,
        fileID: data.items[0].icon_fileID,
        fileName: fileName,
      });
    }
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setNewPassword = createAsyncThunk<
  undefined,
  Types.SetNewPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/setNewPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.setNewPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getConfirmRegistration = createAsyncThunk<
  Types.GetConfirmRegistrationRes,
  Types.GetConfirmIdReq,
  Types.ThunkAPI<Types.requestError>
>('auth/getConfirmRegistration', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.confirmRegistration(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateLoginTime = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.User.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('auth/updateLoginTime', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserDetail = createAsyncThunk<
  Types.GetItemDetailResponseType,
  Types.GetItemDetailRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/getUserDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.detail(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateUser = createAsyncThunk<
  Types.UpdateItemResponseType,
  UpdateItemRequestType<Types.User.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
