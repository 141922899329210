import React, { useEffect, useState } from 'react';
import type { SubMenuType } from 'rc-menu/lib/interface';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ReadOutlined,
  GlobalOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { settingSelector } from 'containers/AppSettings/selectors';
import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';
import { setCollapsed } from 'containers/AppSettings/slice';
import Loading, { loadingRef } from 'components/Loading';
import { LogoNav, LogoNavSmall } from 'assets';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';

const { Sider } = Layout;

const SideBar: React.FC = ({ children }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { collapsedMenu } = useSelector(settingSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const onOpenChange = (keys: string[]) => {
    const currentOpenKey = openKeys[0];
    const isChildOfCurrent = currentOpenKey && keys.some((key) => key.startsWith(currentOpenKey));
    if (isChildOfCurrent) {
      setOpenKeys(keys);
    } else {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const toggle = () => dispatch(setCollapsed(!collapsedMenu));
  const items: ItemType[] = [
    {
      key: routes.Dashboard.path,
      icon: <HomeOutlined />,
      label: (
        <span className="title-home">
          HOME{!collapsedMenu && <i className="ant-menu-submenu-arrow"></i>}
        </span>
      ),
      onClick: () => navigate(routes.Dashboard.path),
    },
    {
      key: routes.PublicManagement.path,
      icon: <GlobalOutlined />,
      label: <span>公開管理{!collapsedMenu && <i className="ant-menu-submenu-arrow"></i>}</span>,
      onClick: () => navigate(routes.PublicManagement.path),
    },
    {
      key: 'sub2',
      icon: <ReadOutlined />,
      label: 'カリキュラム管理',
      children: [
        {
          label: '-   　カリキュラムマスタ',
          key: routes.Curriculum.path,
          onClick: () => navigate(routes.Curriculum.path),
        },
        {
          label: '-   　 設問マスタ',
          key: routes.QuestionMaster.path,
          onClick: () => navigate(routes.QuestionMaster.path),
        },
      ],
    },
    {
      key: 'menu3',
      icon: <UserOutlined />,
      label: 'ユーザー管理',
      children: [
        {
          label: '-   　ユーザーマスタ',
          key: routes.Employee.path,
          onClick: () => navigate(routes.Employee.path),
        },
        {
          label: '-   　権限マスタ',
          key: routes.AuthorityMaster.path,
          onClick: () => navigate(routes.AuthorityMaster.path),
        },
        {
          label: '-   　所属マスタ',
          key: routes.Belonging.path,
          onClick: () => navigate(routes.Belonging.path),
        },
        {
          label: '-   　役職マスタ',
          key: routes.JobTitleMaster.path,
          onClick: () => navigate(routes.JobTitleMaster.path),
        },
        {
          label: '-   　管理者マスタ',
          key: routes.AdministratorMaster.path,
          onClick: () => navigate(routes.AdministratorMaster.path),
        },
      ],
    },
  ];

  useEffect(() => {
    const openKey = items.find((item) =>
      (item as SubMenuType).children?.some(
        (c) => (c as SubMenuType).key === `/${window.location.pathname.split('/')[1]}`
      )
    )?.key as string;
    setOpenKeys(openKey ? [openKey] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedMenu]);

  return (
    <Wrapper>
      <Sider
        width={MENU_WIDTH}
        className="sider"
        trigger={null}
        collapsedWidth={MENU_COLLAPSED_WIDTH}
        collapsible={true}
        collapsed={collapsedMenu}
      >
        <div className="site-layout-background">
          {collapsedMenu ? (
            <MenuUnfoldOutlined className="trigger" onClick={toggle} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={toggle} />
          )}
        </div>
        <div className="wrap-logo">
          <img
            src={collapsedMenu ? LogoNavSmall : LogoNav}
            className={collapsedMenu ? 'image-logo' : 'image-logo-large'}
            onClick={() => navigate(routes.Dashboard.path)}
            alt="skill-familiar"
          />
        </div>
        <Menu
          className="menu"
          mode="inline"
          openKeys={openKeys}
          selectedKeys={[`/${window.location.pathname.split('/')[1]}`]}
          onOpenChange={onOpenChange}
          items={items}
        />
      </Sider>
      <Layout>
        <Loading ref={loadingRef} />
        {children}
      </Layout>
    </Wrapper>
  );
};

export default SideBar;
