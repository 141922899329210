import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_LEVEL,
  AFFILIATION_ASSIGN_ROLE,
  AFFILIATION_LEVEL,
  COMPANIES,
  GMO,
  PLANS,
  POSITIONS,
  ROLES,
  USERS,
} from 'configs';

export const getPlans = createAsyncThunk<
  Types.GetItemResponseType,
  undefined,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/getPlan', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.search(PLANS.id, {
      per_page: 0,
      page: 1,
    });

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const confirmCreditCardThunk = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<{ name: string }>,
  Types.ThunkAPI<Types.ConfirmCreditCardError>
>('informationRegister/confirmCreditCard', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(GMO.name, req);

    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const registerUserThunk = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.User.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/RegisterUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createCompanies = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/createCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createRoles = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/createRoles', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/createAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createItemPositionMaster = createAsyncThunk<
  Types.CreateItemResponseType<Types.Positions.ResponseType>,
  Types.CreateItemRequestType<Types.Positions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/createItemPositionMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_ROLE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationAssignLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createAffiliationAssignLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
