import React, { useState } from 'react';

import CurriculumMasterTable from './Table';
import CurriculumTreeview from './Treeview';

const Curriculum: React.FC = () => {
  const [openCurriculumMaster, setOpenCurriculumMaster] = useState<boolean>(false);

  return openCurriculumMaster ? (
    <CurriculumMasterTable setOpenCurriculumMaster={setOpenCurriculumMaster} />
  ) : (
    <CurriculumTreeview setOpenCurriculumMaster={setOpenCurriculumMaster} />
  );
};

export default Curriculum;
