import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined, FileTextOutlined, FormOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Select, Table, Tooltip } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';

import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumSelector } from '../selectors';
import { Header, SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import { getCurriculumMaster } from '../thunk';
import { useAppDispatch } from 'hooks';
import EditModal from './Edit';
import * as Types from 'types';
import Wrapper from './styles';
import {
  getCurriculumLevelOption,
  getCurriculumQuestionOption,
} from 'containers/Curriculum/Search/thunk';
import { v4 as uuidv4 } from 'uuid';

const PER_PAGE = 10;

const { Option } = Select;

type Props = {
  setOpenCurriculumMaster: React.Dispatch<React.SetStateAction<boolean>>;
};

export type VisiblePopupEditType = {
  visible: boolean;
  index: number;
};

const CurriculumMaster: React.FC<Props> = ({ setOpenCurriculumMaster }) => {
  const [selectedRows, setSelectedRows] = useState<Types.CurriculumMasterType[]>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [visible, setVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [visiblePopupEdit, setVisiblePopupEdit] = useState<VisiblePopupEditType>({
    visible: false,
    index: -1,
  });

  const { loading, curricullumMaster, total } = useSelector(curriculumSelector);
  const { level1Option, level2Option, level3Option, level4Option } = useSelector(
    searchQuestionCurriculumSelector
  );

  console.log(curricullumMaster);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();
  const getCurriculumNames = (curriculumMaster: Types.CurriculumMasterType[]): string[] => {
    const names: string[] = [];
    curriculumMaster.forEach((item) => {
      names.push(item.curriculum_name);
    });
    return names.filter((item, index, self) => self.indexOf(item) === index);
  };
  const getQuestionName = (curriculumMaster: Types.CurriculumMasterType[]): string[] => {
    const names: string[] = [];
    curriculumMaster.forEach((item) => {
      names.push(item.question_name);
    });
    return names.filter((item, index, self) => self.indexOf(item) === index);
  };
  const getChildNames = (option: Types.CurriculumOptionType[]): string[] => {
    const names: string[] = [];
    option.forEach((item) => {
      names.push(item?.name);
    });
    return names.filter((item, index, self) => self.indexOf(item) === index);
  };

  const curriculumNames: string[] = getCurriculumNames(curricullumMaster);
  const questionName: string[] = getQuestionName(curricullumMaster);
  const level1Names: string[] = getChildNames(level1Option);
  const level2Names: string[] = getChildNames(level2Option);
  const level3Names: string[] = getChildNames(level3Option);
  const level4Names: string[] = getChildNames(level4Option);
  const columns: ColumnsType<Types.CurriculumMasterType> = [
    {
      title: () => <p className="title-table">ステータス</p>,
      dataIndex: 'publish',
      key: 'publish',
      align: 'center',
      render: (record: number, item) => {
        const status = getStatus(record);
        return <Tooltip title={status.text}>{status.icon}</Tooltip>;
      },
      width: '8%',
    },
    {
      title: () => (
        <>
          <p className="title-table">カリキュラムコード</p>
          <p>カリキュラム名</p>
        </>
      ),
      dataIndex: 'operation',
      width: '17%',
      ellipsis: true,
      render: (_text: string, item: Types.CurriculumMasterType) => (
        <>
          <p className="text-code">{item.curriculum_code}</p>
          <p className="text-title">{item.curriculum_name}</p>
        </>
      ),
    },
    {
      title: '説明',
      dataIndex: 'curriculum_description',
      key: 'curriculum_description',
      width: '5%',
      align: 'center',
      render: (text: string, _: Types.CurriculumMasterType, index: number) => (
        <Tooltip
          trigger={['click']}
          title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
          color="#ffffff"
          onVisibleChange={(v) =>
            setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
          }
        >
          <FileTextOutlined
            className="icon"
            style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
          />
        </Tooltip>
      ),
    },
    {
      title: () =>
        !visible && (
          <>
            <p className="title-table">第１階層コード</p>
            <p>第１階層名</p>
          </>
        ),
      dataIndex: 'operation',
      width: '13%',
      render: (_text: string, { level1_code, level1_name }: Types.CurriculumMasterType) =>
        !visible && (
          <>
            <p className="text-code">{level1_code}</p>
            <p className="text-title">{level1_name}</p>
          </>
        ),
    },
    {
      title: () =>
        !visible && (
          <>
            <p className="title-table">第２階層コード</p>
            <p>第２階層名</p>
          </>
        ),
      dataIndex: 'operation',
      width: '13%',
      render: (_text: string, { level2_name, level2_code }: Types.CurriculumMasterType) =>
        !visible && (
          <>
            <p className="text-code">{level2_code}</p>
            <p className="text-title">{level2_name}</p>
          </>
        ),
    },
    {
      title: () =>
        !visible && (
          <>
            <p className="title-table">第３階層コード</p>
            <p>第３階層名</p>
          </>
        ),
      dataIndex: 'operation',
      width: '13%',
      render: (_text: string, { level3_code, level3_name }: Types.CurriculumMasterType) =>
        !visible && (
          <>
            <p className="text-code">{level3_code}</p>
            <p className="text-title">{level3_name}</p>
          </>
        ),
    },

    {
      title: () =>
        !visible && (
          <>
            <p className="title-table">第４階層コード</p>
            <p>第４階層名</p>
          </>
        ),
      dataIndex: 'operation',
      width: '13%',
      render: (_text: string, { level4_code, level4_name }: Types.CurriculumMasterType) =>
        !visible && (
          <>
            <p className="text-code">{level4_code}</p>
            <p className="text-title">{level4_name}</p>
          </>
        ),
    },
    {
      title: () =>
        !visible && (
          <>
            <p className="title-table">設問コード</p>
            <p>設問名</p>
          </>
        ),
      dataIndex: 'question_name',
      width: '10%',
      ellipsis: true,
      render: (_text: string, { question_code, question_name }: Types.CurriculumMasterType) =>
        !visible && (
          <>
            <p className="text-code">{question_code}</p>
            <p className="text-title">{question_name}</p>
          </>
        ),
    },
    {
      title: !visible && '編集',
      dataIndex: 'operation',
      width: '7%',
      align: 'center',
      render: (_: string, item: Types.CurriculumMasterType, index: number) =>
        !visible && (
          <FormOutlined
            className="icon"
            onClick={() =>
              setVisiblePopupEdit({
                visible: true,
                index,
              })
            }
            style={{ color: visiblePopupEdit.index === index ? '#00a3a5' : '#c4c4c4' }}
          />
        ),
    },
  ];

  const fetchDataCurriculumHierarchy = useCallback(() => {
    if (userInfo) {
      setSelectedRows([]);
      dispatch(
        getCurriculumMaster({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: page,
          per_page: PER_PAGE,
        })
      );
    }
  }, [dispatch, page, userInfo]);

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      console.log('csv');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRows.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const formik = useFormik<Types.CurriculumMasterSearchFormik>({
    initialValues: {
      public_status: '',
      curriculum_name: '',
      level1_name: '',
      level2_name: '',
      level3_name: '',
      level4_name: '',
      question_name: '',
    },
    onSubmit: (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      dispatch(
        getCurriculumMaster({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            ...conditions,
          ],
          page: page,
          per_page: PER_PAGE,
        })
      );
    },
  });

  useEffect(fetchDataCurriculumHierarchy, [fetchDataCurriculumHierarchy]);

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getCurriculumQuestionOption({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 1,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 2,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 3,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 4,
            company_id: userInfo.company_id,
          })
        ),
      ]);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  const dataSource = useMemo(
    () => curricullumMaster.map((obj) => ({ ...obj, rowKey: uuidv4() })),
    [curricullumMaster]
  );

  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title="カリキュラムマスタ 階層リスト" />
      <div className="container">
        <p className="text-note">
          カリキュラムマスタの階層リストです。階層の確認と編集が可能です。
          <br />
          編集は編集マークの行に設定されている各階層を編集できます。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="public_status"
                className="item"
                label={<span className="text-label">公開ステータス</span>}
              >
                <SelectField
                  name="public_status"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="0">編集中</Option>
                  <Option value="1">未公開</Option>
                  <Option value="2">公開中</Option>
                  <Option value="3">公開停止中</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="curriculum_name"
                className="item"
                label={<span className="text-label">カリキュラム名</span>}
              >
                <SelectField
                  name="curriculum_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumNames.map((value, index) => (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level1_name"
                className="item"
                label={<span className="text-label">第１階層名</span>}
              >
                <SelectField
                  name="level1_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {level1Names.map((value, index) =>
                    !value ? (
                      <Option key={index} value={''}>
                        （空白）
                      </Option>
                    ) : (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level2_name"
                className="item"
                label={<span className="text-label">第２階層名</span>}
              >
                <SelectField
                  name="level2_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {level2Names.map((value, index) =>
                    !value ? (
                      <Option key={index} value={''}>
                        （空白）
                      </Option>
                    ) : (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level3_name"
                className="item"
                label={<span className="text-label">第3階層名</span>}
              >
                <SelectField
                  name="level3_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {level3Names.map((value, index) =>
                    !value ? (
                      <Option key={index} value={''}>
                        （空白）
                      </Option>
                    ) : (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level4_name"
                className="item"
                label={<span className="text-label">第4階層名</span>}
              >
                <SelectField
                  name="level4_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {level4Names.map((value, index) =>
                    !value ? (
                      <Option key={index} value={''}>
                        （空白）
                      </Option>
                    ) : (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="question_name"
                className="item"
                label={<span className="text-label">設問名</span>}
              >
                <SelectField
                  name="question_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {questionName.map((value, index) =>
                    !value ? (
                      <Option key={index} value={''}>
                        （空白）
                      </Option>
                    ) : (
                      <Option key={index} value={value}>
                        {value}
                      </Option>
                    )
                  )}
                </SelectField>
              </Form.Item>
              <div className="wrap-submit-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <Button className="button-collapse">
          <Checkbox name="status" checked={visible} onClick={() => setVisible(!visible)} />
          <span className="text">階層を折りたたむ</span>
        </Button>
        <Table
          rowKey="rowKey"
          className="table"
          dataSource={dataSource}
          columns={columns}
          rowSelection={{
            onChange: (_, selected: Types.CurriculumMasterType[]) => setSelectedRows(selected),
          }}
          pagination={{
            pageSize: PER_PAGE,
            total,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <div className="text-count">
                {page * PER_PAGE > total
                  ? 1 +
                    (page - 1) * PER_PAGE +
                    ' ~ ' +
                    (10 - (page * PER_PAGE - total) + (page - 1) * PER_PAGE)
                  : 1 + (page - 1) * PER_PAGE + ' ~ ' + page * PER_PAGE}
                <span className="text-static"> 件表示</span> / {total}
                <span className="text-static"> 件中</span>
              </div>
            ),
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択した階層リストを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={() => setOpenCurriculumMaster(false)}>
              <span>カリキュラムツリー</span>
              <DownOutlined className="icon" />
            </Button>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
          subTitle="役職マスタをエクスポートします。"
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <EditModal
          page={page}
          per_page={PER_PAGE}
          visible={visiblePopupEdit}
          setVisible={setVisiblePopupEdit}
        />
      </div>
    </Wrapper>
  );
};

export default CurriculumMaster;

const getStatus = (record: number) => {
  switch (record) {
    case 2:
      return {
        icon: <img className="status" src={TickComplete} alt="" />,
        text: '公開中',
      };
    case 1:
      return {
        icon: <img className="status" src={UnPublished} alt="" />,
        text: '未公開',
      };
    case 0:
      return {
        icon: <img className="status" src={WhiteEditing} alt="" />,
        text: '編集中',
      };
    default:
      return {
        icon: <img className="status" src={StopPublic} alt="" />,
        text: '公開停止中',
      };
  }
};
