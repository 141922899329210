import { createAsyncThunk } from '@reduxjs/toolkit';

import { userService } from 'services';
import * as Types from 'types';

export const changePassword = createAsyncThunk<Types.ChangePasswordRes, Types.ChangePasswordReq>(
  'users/thunk/changePassword',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await userService.changePassword(req);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
