import { Document, Page, View, Text, Font } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

interface IItem {
  label: string;
  checked: number;
}

interface IDataPage {
  title: string;
  items: IItem[];
}

const textItems = [
  { text: '編集権限', value: 2 },
  { text: '閲覧権限', value: 1 },
  { text: '非表示', value: 0 },
];

const pageSize = 'A4';
const orientation = 'landscape';

const PDFDocument = ({ role }: { role: Types.AuthorityMasterType }) => {
  const dataFirstPage: IDataPage[] = [
    {
      title: 'Home',
      items: [
        {
          label: 'ダッシュボード',
          checked: role.admin_dashboard_permission || -1,
        },
      ],
    },
    {
      title: 'ユーザー管理',
      items: [
        {
          label: '社内ユーザーマスタ',
          checked: role.inter_users_master_permission || -1,
        },
        {
          label: '権限マスタ',
          checked: role.roles_master_permission || -1,
        },
        {
          label: '所属マスタ',
          checked: role.departments_master_permission || -1,
        },
        {
          label: '役職マスタ',
          checked: role.position_master_permission || -1,
        },
        {
          label: '管理者マスタ',
          checked: role.admin_master_permission || -1,
        },
      ],
    },
    {
      title: 'OFFICIALカリキュラム公開管理',
      items: [
        {
          label: 'OFFICIALカリキュラム公開管理',
          checked: role.official_curricullum_master_permission || -1,
        },
      ],
    },
    {
      title: 'カリキュラム管理',
      items: [
        {
          label: 'カリキュラムマスタ',
          checked: role.curricullum_master_permission || -1,
        },
        {
          label: '設問マスタ',
          checked: role.question_master_permission || -1,
        },
      ],
    },
  ];

  const renderItems = (items: IItem[]) => {
    return items.map((item, index) => (
      <View style={styles.item} key={index}>
        <View style={styles.wrapLabel}>
          <Text style={styles.label}>{item.label}</Text>
        </View>
        <View style={styles.wrapRadio}>
          {textItems.map((textItem, textIndex) => (
            <View style={styles.radioItem} key={textIndex}>
              <View
                style={[
                  styles.radioButton,
                  { borderColor: Number(item.checked) === textItem.value ? '#1890ff' : '#ebebeb' },
                ]}
              >
                {Number(item.checked) === textItem.value ? (
                  <View style={styles.radioSelected} />
                ) : null}
              </View>
              <Text style={styles.textItem}>{textItem.text}</Text>
              <View />
            </View>
          ))}
        </View>
      </View>
    ));
  };

  const renderDataPage = (dataPages: IDataPage[]) => {
    return dataPages.map((data, index) => (
      <View key={index}>
        <Text style={styles.title}>{data.title}</Text>
        <View style={styles.wrapItem}>{renderItems(data.items)}</View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size={pageSize} orientation={orientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>権限名称</Text>
        </View>

        <View style={styles.subscription}>
          <Text style={styles.textSubscription}>権限マスタの新規登録を行う画面です。</Text>
          <Text style={styles.textSubscription}>
            権限名称を入力し、作成する権限で使用可能な機能にチェックを入れ、登録ボタンをクリックしてください。
          </Text>
        </View>
        <View style={styles.lineBorder} />

        <View style={styles.body}>{renderDataPage(dataFirstPage)}</View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
