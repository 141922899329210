import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Modal } from 'components';
import Styled from './styles';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => Promise<void> | void;
}

const ExecuteDelete: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const handleToggleModal = () => {
    setVisible(false);
  };
  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    await setVisible(false);
  };

  return (
    <Modal
      centered
      visible={visible}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
    >
      <Styled>
        <ExclamationCircleOutlined
          style={{
            fontSize: 28,
            color: '#bbbbbb',
          }}
        />
        <span className="text">削除を実行します。</span>
      </Styled>
    </Modal>
  );
};

export default ExecuteDelete;
