export const HEADER_CURRICULUM_CSV = [
  { label: 'カリキュラムコード', key: 'curriculum_code' },
  { label: 'カリキュラム名', key: 'curriculum_name' },
  { label: 'カリキュラム説明', key: 'curriculum_description' },
  { label: '第１階層名', key: 'level1_name' },
  { label: '第１階層コード', key: 'level1_code' },
  { label: '第２階層名', key: 'level2_name' },
  { label: '第2階層コード', key: 'level2_code' },
  { label: '第３階層名', key: 'level3_name' },
  { label: '第3階層コード', key: 'level3_code' },
  { label: '第４階層名', key: 'level4_name' },
  { label: '第4階層コード', key: 'level4_code' },
  { label: '設問名', key: 'question_name' },
  { label: '設問コード', key: 'question_code' },
  { label: '設問内容', key: 'question_description' },
  { label: '設問添付ファイルID', key: 'question_attach' },
  { label: '設問添付ファイルID2', key: 'question2_attach' },
  { label: '設問添付ファイルID3', key: 'question3_attach' },
  { label: '選択肢A', key: 'problems1' },
  { label: '選択肢B', key: 'problems2' },
  { label: '選択肢C', key: 'problems3' },
  { label: '選択肢A添付ファイルID', key: 'problems1_attach' },
  { label: '選択肢B添付ファイルID', key: 'problems2_attach' },
  { label: '選択肢C添付ファイルID', key: 'problems3_attach' },
  { label: '解答', key: 'answer' },
  { label: '解説', key: 'comment' },
  { label: '解説添付ファイルID1', key: 'problems1_attach_fileID' },
  { label: '解説添付ファイルID2', key: 'problems2_attach_fileID' },
  { label: '解説添付ファイルID3', key: 'problems3_attach_fileID' },
  { label: 'ファイルID', key: 'fileID' },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア', key: 'score' },
];

export const HEADER_JOB_TITLE_MASTER_CSV = [
  { label: '会社ID', key: 'company_id' },
  { label: '役職コード', key: 'code' },
  { label: '役職名', key: 'name' },
  { label: '序列順序', key: 'rank_order' },
  { label: '登録日', key: 'createdat' },
  { label: '登録者', key: 'createdby' },
  { label: '更新日', key: 'updatedat' },
  { label: '更新者', key: 'updatedby' },
  { label: '削除日', key: 'deletedat' },
];

export const HEADER_AUTHORITY_MASTER_CSV = [
  { label: '権限コード', key: 'code' },
  { label: '会社ID', key: 'company_id' },
  { label: '権限名', key: 'name' },
  { label: '管理者ダッシュボード権限', key: 'admin_dashboard_permission' },
  { label: '部署マスタ権限', key: 'departments_master_permission' },
  { label: 'ユーザーマスタ権限', key: 'users_master_permission' },
  { label: '管理者マスタ権限', key: 'admin_master_permission' },
  { label: 'カリキュラムマスタ権限', key: 'curricullum_master_permission' },
  { label: '分析グループマスタ権限', key: 'groups_master_permission' },
  { label: 'スキルチェックマスタ権限', key: 'skill_check_master_permission' },
  { label: 'バンドルマスタ権限', key: 'bundle_master_permission' },
  { label: 'レポート閲覧権限', key: 'report_view_permission' },
  { label: '権限マスタ権限', key: 'roles_master_permission' },
  { label: '役職マスタ権限', key: 'positions_master_permission' },
  { label: '支払いマスタ権限', key: 'payment_master_permission' },
  { label: 'タイマーマスタ権限', key: 'timer_master_permission' },
  { label: '設問マスタ権限', key: 'question_master_permission' },
  { label: 'スキルチェックツリー権限', key: 'skill_check_tree_permission' },
  { label: '面接ユーザーマスタ権限', key: 'inter_users_master_permission' },
  { label: '更新者', key: 'updatedby' },
  { label: '登録者', key: 'createdby' },
  { label: '登録日', key: 'createdat' },
  { label: '更新日', key: 'updatedat' },
  { label: '削除日', key: 'deletedat' },
];

export const HEADER_EMPLOYEE_CSV = [
  { label: '社員番号', key: 'employee_code' },
  { label: 'ユーザ名', key: 'name' },
  { label: 'ユーザ名（カナ）', key: 'kana' },
  { label: 'メールアドレス', key: 'email' },
  { label: 'パスワード', key: 'password' },
  { label: '所属ID', key: 'department_name' },
  { label: '役職コード', key: 'position_code' },
  { label: '誕生日', key: 'birthday' },
  { label: '入社日', key: 'hire_date' },
  { label: '権限コード', key: 'role_code' },
];

export const HEADER_INTERVIEW_CSV = [
  {
    label: '管理番号',
    key: 'manage_code',
  },
  {
    label: '氏名',
    key: 'name',
  },
  {
    label: 'フリガナ',
    key: 'kana',
  },
  {
    label: '募集所属',
    key: 'department_name',
  },
  {
    label: '募集役職',
    key: 'position_code',
  },
  {
    label: '年齢',
    key: 'birthday',
  },
  {
    label: '職歴',
    key: 'work_history',
  },
  {
    label: '採用予定年月',
    key: 'hire_date',
  },
  {
    label: 'メールアドレス',
    key: 'email',
  },
  {
    label: 'スキルチェック',
    key: 'implementation_date',
  },
];

export const HEADER_QUESTION_MASTER_CSV = [
  { label: '設問名称', key: 'name' },
  { label: '設問ID', key: 'i_id' },
  { label: '設問内容', key: 'description' },
  { label: '設問画像・動画ID①', key: 'attach_fileID1' },
  { label: '設問画像・動画ID②', key: 'attach_fileID2' },
  { label: '設問画像・動画ID③', key: 'attach_fileID3' },
  { label: '選択肢A', key: 'problems1' },
  { label: '選択肢B', key: 'problems2' },
  { label: '選択肢C', key: 'problems3' },
  { label: '選択肢A画像・動画ID', key: 'problems1_attach_fileID' },
  { label: '選択肢B画像・動画ID', key: 'problems2_attach_fileID' },
  { label: '選択肢C画像・動画ID', key: 'problems3_attach_fileID' },
  { label: '解答', key: 'answer' },
  { label: '解説', key: 'comment' },
  { label: '解説画像・動画ID①', key: 'comment_attach_fileID1' },
  { label: '解説画像・動画ID②', key: 'comment_attach_fileID2' },
  { label: '解説画像・動画ID③', key: 'comment_attach_fileID3' },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア（設問難易度）', key: 'score' },
  { label: '会社ID', key: 'company_id' },
  { label: '登録者', key: 'createdby' },
  { label: '登録日', key: 'createdat' },
  { label: '作成ユーザ名', key: 'creator' },
  { label: 'provider_id', key: 'provider_id' },
  { label: 'provider_question_code', key: 'provider_question_code' },
  { label: '設問画像・動画名①', key: 'attach_file_name1' },
  { label: '設問画像・動画名②', key: 'attach_file_name2' },
  { label: '設問画像・動画名③', key: 'attach_file_name3' },
  { label: '選択肢A画像・動画名', key: 'problems1_attach_file_name' },
  { label: '選択肢B画像・動画名', key: 'problems2_attach_file_name' },
  { label: '選択肢C画像・動画名', key: 'problems3_attach_file_name' },
  { label: '解説画像・動画名①', key: 'comment_attach_file_name1' },
  { label: '解説画像・動画名②', key: 'comment_attach_file_name2' },
  { label: '解説画像・動画名③', key: 'comment_attach_file_name3' },
];

export const HEADER_QUESTION_MASTER_REQUIRED_CSV = [
  '設問名称',
  '設問ID',
  '設問内容',
  '設問画像・動画ID①',
  '設問画像・動画ID②',
  '設問画像・動画ID③',
  '選択肢A',
  '選択肢B',
  '選択肢C',
  '選択肢A画像・動画ID',
  '選択肢B画像・動画ID',
  '選択肢C画像・動画ID',
  '解答',
  '解説',
  '解説画像・動画ID①',
  '解説画像・動画ID②',
  '解説画像・動画ID③',
  '制限時間',
  'スコア（設問難易度）',
];

export const HEADER_ADMIN_RELEASE_NOTE_MANAGEMENT = [
  { label: 'i_id', key: 'i_id' },
  { label: 'provider_id', key: 'provider_id' },
  { label: 'official_curriculum_code', key: 'official_curriculum_code' },
  { label: 'history_id', key: 'history_id' },
  { label: 'publish_start_date', key: 'publish_start_date' },
  { label: 'publish_type', key: 'publish_type' },
  { label: 'release_note_id', key: 'release_note_id' },
  { label: 'updatedat', key: 'updatedat' },
  { label: 'release_note_title', key: 'release_note_title' },
  { label: 'release_version', key: 'release_version' },
  { label: 'release_note_version', key: 'release_note_version' },
  { label: 'release_note_publish_status', key: 'release_note_publish_status' },
  { label: 'createdby', key: 'createdby' },
  { label: 'official_curriculum_name', key: 'official_curriculum_name' },
];
