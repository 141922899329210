import styled from 'styled-components';

export default styled.section`
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f9f8f8;
  .background {
    position: absolute;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    max-width: 100vw;
  }
  .canvas {
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
    position: relative;
    margin: auto;
    width: 560px;
    min-height: 590px;
    background: #ffffff;
    padding: 0px 5px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    z-index: 9;
    .line-top {
      width: 100%;
      height: 4px;
      background: #f6ac00;
      border-radius: 2px;
      margin-bottom: 30px;
    }
  }
`;
