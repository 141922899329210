import React from 'react';

import { Wrapper } from './styles';

type Props = {
  currentStep: number;
};

const HeaderInformationRegister: React.FC<Props> = () => {
  return (
    <Wrapper>
      <div className="header-information-register">
        <div className="wrap-step-info">
          <span className="step-name">お客様情報入力</span>
        </div>
        <div className="sub-title">
          <p>
            企業情報・管理者の登録画面です。
            <br />
            入力完了後に確認画面へボタンをクリックしてください。
          </p>
        </div>
      </div>
    </Wrapper>
  );
};

export default HeaderInformationRegister;
