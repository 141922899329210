import { createAsyncThunk } from '@reduxjs/toolkit';

import { POSITIONS, SELECT_POSITION } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getJobTitleMaster = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMaster/thunk/getJobTitleMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_POSITION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRankOrders = createAsyncThunk<
  Types.GetItemResponseType,
  undefined,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMaster/thunk/getRankOrders', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.search(POSITIONS.id, {
      page: 1,
      per_page: 0,
      sort_field_id: 'rank_order',
      sort_order: 'desc',
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateItemJobTitleMaster = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Positions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMaster/thunk/updateItemJobTitleMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createItemJobTitleMaster = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Positions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMaster/thunk/createItemJobTitleMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemJobTitleMaster = createAsyncThunk<
  string,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMaster/thunk/deleteJobTitleMaster', async (req, { rejectWithValue }) => {
  try {
    await services.delete(POSITIONS.id, req);

    return req.id;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getJobTitleMasterCSV = createAsyncThunk<
  Types.GetItemResponseType,
  undefined,
  Types.ThunkAPI<Types.requestError>
>('JobTitleMaster/thunk/getJobTitleMasterCSV', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.search(POSITIONS.id, {
      page: 1,
      per_page: 0,
      sort_field_id: 'rank_order',
      sort_order: 'asc',
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
