import styled from 'styled-components';

export default styled('div')`
  text-align: center;
  margin-top: 12px;
  img {
    height: 28px;
  }
  .text {
    font-size: 18px;
    margin-left: 12px;
  }
`;
