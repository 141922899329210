import React, { useEffect, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { Form } from 'formik-antd';
import { Button } from 'antd';

import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import { getReleaseNoteSection } from '../../thunk';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Input, Modal, TextArea } from 'components';
import { ReleaseNoteStyled } from './styles';
import { routes } from 'navigations/routes';
import UpdateStatus from '../UpdateStatus';
import { useAppDispatch } from 'hooks';
import Published from '../Published';
import * as Types from 'types';

interface Props {
  selected?: Types.ManageReleaseRes;
  publish: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReleaseNote: React.FC<Props> = ({ visible, setVisible, publish, selected }) => {
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);

  const { loading, releaseNoteDetail } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: releaseNoteDetail ?? {
      title: '',
      description: '',
      version: 1.0,
      curriculum_code: '',
      release_note_id: '',
      sections: [
        {
          section_name: '',
          text: '',
        },
      ],
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (userInfo && visible) {
      dispatch(
        getReleaseNoteSection({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'release_note_id',
              search_value: [selected?.release_note_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, visible, userInfo]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title={<span className="title">リリースノート</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1400}
      visible={visible}
      onCancel={handleToggleModal}
    >
      <ReleaseNoteStyled isEmptyData={!releaseNoteDetail?.sections.length}>
        <div className="information">
          <div>
            <span className="content">{selected?.curriculum_name}</span>
            <span className="id">（ID：{selected?.curriculum_code})</span>
          </div>
          <div className="version">
            <span className="label">バージョン：</span>
            <span>1.001（2022/01/10 10:00　初回公開）</span>
          </div>
        </div>
        <FormikProvider value={formik}>
          <Form>
            <div className="while-editing">
              <div>
                <img
                  src={
                    publish === 0 || !publish
                      ? WhiteEditing
                      : publish === 2
                      ? TickComplete
                      : publish === 1
                      ? UnPublished
                      : StopPublic
                  }
                  alt=""
                />
                <span className="content">{formik.values.title}</span>
              </div>
              <Button className=" btn-outline">
                <CloudDownloadOutlined
                  style={{
                    fontSize: 18,
                  }}
                />
                <span> エクスポート</span>
              </Button>
            </div>
            <div className="wrap-editor">
              <div className="left-side">
                <div className="title-editor">
                  <div className="no-editor">{indexSection + 1}</div>
                  <Form.Item name="section_name" className="item input-title">
                    <Input
                      disabled
                      value={formik.values.sections[indexSection].section_name}
                      name={`sections.${indexSection}.section_name`}
                      placeholder="セクション名を入力..."
                    />
                  </Form.Item>
                </div>
                <JoditEditor
                  config={defaultConfig}
                  value={formik.values.sections[indexSection]?.text}
                />
                <div className="wrap-bottom-editor">
                  <div className="wrap-button-editor">
                    <Button
                      className="btn button-prev"
                      disabled={indexSection === 0}
                      onClick={() => setIndexSection((prevState) => prevState - 1)}
                    >
                      <CaretLeftOutlined />
                      <span>前のセクション</span>
                    </Button>
                    <Button
                      className="btn button-next"
                      disabled={indexSection === formik.values.sections.length - 1}
                      onClick={() => setIndexSection(indexSection + 1)}
                    >
                      <span>次のセクション</span>
                      <CaretRightOutlined />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <Form.Item name="section_name" label="概要：" className="text-area">
                  <TextArea
                    disabled
                    value={formik.values.description}
                    name="section_name"
                    rows={5}
                    placeholder="最大480文字"
                  />
                </Form.Item>
                <span className="title-right">セクション</span>
                <div className="dashed-line"></div>
                <div className="wrap-radio">
                  {formik.values.sections.map(({ section_name }, index) => (
                    <div
                      key={index}
                      onClick={() => setIndexSection(index)}
                      className={`section ${index === indexSection && 'section-checked'}`}
                    >
                      <span className="no-number">{index + 1}</span>
                      <span className="section-name">{section_name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-submit">
                <div className="wrap-button">
                  <Button className="btn btn_close" onClick={() => setVisible(false)}>
                    閉じる
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          <Completed
            title="リリースノートを保存しました。"
            visible={visibleComplete}
            setVisible={setVisibleComplete}
            onSubmit={() => navigate(routes.PublicManagement.path)}
          />
          <UpdateStatus
            publish={publish}
            status="unPublished"
            title="リリースノートを公開します"
            from={UnPublished}
            to={TickComplete}
            description={
              <span>
                リリースノートを公開すると、
                <br /> SKILL FAMILIARで一般のユーザー様が閲覧できる状態になります。
              </span>
            }
            visible={visibleUpdate}
            setVisible={setVisibleUpdate}
            onSubmit={() => setVisiblePublished(true)}
          />
          <Published
            visible={visiblePublished}
            setVisible={setVisiblePublished}
            onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
          />
        </FormikProvider>
      </ReleaseNoteStyled>
    </Modal>
  );
};

export default ReleaseNote;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
];

const defaultConfig = {
  license: '%LICENSE_KEY%',
  placeholder: '本文を入力...',
  readonly: true,
  enableDragAndDropFileToEditor: true,
  buttons,
  buttonsMD: buttons,
  buttonsSM: buttons,
  buttonsXS: buttons,
  uploader: {
    url: 'https://xdsoft.net/jodit/finder/files/',
    baseUrl: 'sdadafaf',
    insertImageAsBase64URI: true,
    withCredentials: false,
    format: 'json',
    data: {
      dir: '',
    },
    filesVariableName: function (t: any) {
      return 'files[' + t + ']';
    },

    process: function (resp: any) {
      //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
      let files = [];
      files.unshift(resp.data);
      return { files: resp.data, error: resp.msg, msg: resp.msg };
    },

    isSuccess: () => {
      return true;
    },
    getMessage: function (e: any) {
      return void 0 !== e.data.messages && Array.isArray(e.data.messages)
        ? e.data.messages.join('')
        : '';
    },
    pathVariableName: 'path',
  },
  filebrowser: {
    ajax: {
      url: 'url',
    },
  },
};
