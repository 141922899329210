import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';

import AuthorityMasterFrom from 'containers/AuthorityMasterFrom';
import { CreateAuthorityMasterSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import AuthorityMasterRegisterStyled from './styles';
import { CreateAuthorityMasterFormik } from 'types';
import { createItemAuthority } from '../thunk';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';

const AuthorityMasterRegister: React.FC = () => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik<CreateAuthorityMasterFormik>({
    initialValues: {
      name: '',
      admin_dashboard_permission: 1,
      inter_users_master_permission: 1,
      roles_master_permission: 1,
      departments_master_permission: 1,
      position_master_permission: 1,
      admin_master_permission: 1,
      official_curricullum_master_permission: 1,
      curricullum_master_permission: 1,
      question_master_permission: 1,
    },
    validationSchema: CreateAuthorityMasterSchema,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        createItemAuthority({
          item: {
            ...values,
            company_id: userInfo?.company_id,
          },
          is_force_update: true,
        })
      );
      if (createItemAuthority.fulfilled.match(resultAction)) {
        setShowCompleteModal(true);
      } else {
        setShowActionErrorModal(true);
      }
    },
  });

  return (
    <AuthorityMasterRegisterStyled>
      <Header title="権限マスタ 新規登録" className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            権限マスタの新規登録を行う画面です。
            <br />
            権限名称を入力し、機能ごとに権限範囲を設定してください。
          </p>
        </div>
        <AuthorityMasterFrom formik={formik} />
      </div>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="登録が完了しました"
        onSubmit={() =>
          navigate(generatePath(routes.AuthorityMaster.path, { entity: 'receiving' }))
        }
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="新規権限の登録に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </AuthorityMasterRegisterStyled>
  );
};

export default AuthorityMasterRegister;
