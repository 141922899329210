import styled from 'styled-components';

export default styled.section`
  .header .title-page {
    font-weight: normal;
  }
  .icon-cloud {
    font-size: 18px;
  }
  @media (max-width: 1440px) {
    .container {
      padding: 30px 30px 73px 30px !important;
      .border {
        margin: 30px 0 20px 0 !important;
      }
      .form-search {
        .ant-select-selector {
          border-radius: 3px;
        }
        .wrap-btn {
          margin-left: 20px !important;
          .btn-search {
            width: 100px;
          }
        }
      }
      .btn-div {
        .btn {
          width: 160px;
        }
        .btn-active {
          .size-icon {
            font-size: 18px;
          }
        }
      }
      .table {
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          padding: 10px;
          height: 44px;
        }
      }
    }
  }
  .container {
    padding: 30px;
    .description .content {
      font-size: 12px;
      color: #424242;
    }
    .border {
      width: 40px;
      margin: 30px 0;
      border-top: 1px solid #dddddd;
    }
    .form-search {
      margin: 20px 0;
      display: flex;
      align-items: center;
      .form-input {
        .label {
          font-family: 'Lato';
          font-size: 12px;
          color: #424242;
        }
        .select-input {
          width: 400px;
          font-family: 'Lato';
        }
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-left: 12px;
      }
      .wrap-btn {
        margin-left: 30px;
        margin-top: 5px;
        .btn-search {
          background-color: #ffffff;
          border: 1px solid #00a3a5;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-size: 14px;
          color: #00a3a5;
          padding-right: 25px;
          cursor: pointer;
          .icon-search {
            font-size: 18px;
            margin-right: 5px;
          }
        }
        .label-reset {
          cursor: pointer;
          margin-right: 12px;
          border: none;
          background: none;
          .label-text {
            font-size: 12px;
            text-decoration: underline;
            color: #00a3a5;
          }
        }
      }
    }
    .text-count {
      position: absolute;
      margin-top: 22px;
      font-family: 'Lato';
      font-size: 14px;
      color: #424242;
      z-index: 9;
    }
    .btn-div {
      display: flex;
      position: absolute;
      right: 26px;
      justify-content: space-between;
      margin-top: 10px;
      z-index: 9;
      .btn {
        padding: 0 20px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        margin-right: 8px;
      }
      .btn-active {
        display: inline-flex;
        align-items: center;
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        font-weight: bold;
        border-radius: 5px;
        color: #ffffff;
        .size-icon {
          margin-right: 12px;
        }
      }
    }
    .table {
      &_mr {
        margin-top: 85px;
      }
      .ant-table-tbody .ant-table-cell.column {
        font-size: 14px;
        color: #424242;
      }
      .ant-table-pagination.ant-pagination {
        justify-content: center;
      }
      .ant-table-thead .ant-table-cell {
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        ::before {
          display: none;
        }
      }
      .icon {
        font-size: 20px;
        color: #c4c4c4;
      }
    }
  }
`;
