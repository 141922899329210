import React, { useEffect, useMemo, useRef, useState } from 'react';

import { UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { Button, Col, Row, Select, Tooltip } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { includes, isEqual, minBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Form, Radio } from 'formik-antd';
import ReactPlayer from 'react-player';
import {
  QuestionCircleOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SelectOutlined,
} from '@ant-design/icons';

import { questionMasterSelector } from 'pages/CurriculumManagement/QuestionMaster/selectors';
import { handleGetFileQuestionDetail, handleSubmit } from 'libs/utils/question';
import { Input, Modal, SelectField, SpinLoading, TextArea } from 'components';
import { ErrorModelToOpen, UploadFileToServerModel } from 'types/components';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import UploadFileToServer from 'components/Modal/UploadFilesToServer';
import { createEditQuestionSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { FileType } from 'constant/enum.constant';
import { UploadImageVideoQuestion } from '../';
import { getDataQuestion } from '../../thunk';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import PopupComment from '../Comment';
import { NoImage2 } from 'assets';
import * as Types from 'types';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { convertAttachFileId, exportPDF } from 'libs/utils/export';
import QuestionPDF from 'pages/CurriculumManagement/QuestionMaster/Modal/CreateQuestion/QuesttionPdf';
import { HEADER_QUESTION_MASTER_CSV } from 'constant/header.export.constant';
import saveAs from 'file-saver';

interface Props {
  page: number;
  setVisibleSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openModalCreateEditQuestion: {
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
  };
  setOpenModalCreateEditQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
    }>
  >;
  fetchData?: () => void | Promise<void>;
}

const { Option } = Select;

const initShowActionErrorModal = {
  errorVisible: false,
  title: '',
  subTitle: '',
  description: '',
};

const CreateEditQuestionMaster: React.FC<Props> = ({
  page,
  setOpenModalCreateEditQuestion,
  openModalCreateEditQuestion: { type, visible, question_id },
  setVisibleSuccess,
  fetchData,
}) => {
  const [uploading, setUploading] = useState<UploadFileToServerModel>({ show: false, files: [] });
  const [responseQuestionDetail, setResponseQuestionDetail] =
    useState<Types.Question.ResponseType>();
  const [questionDetail, setQuestionDetail] = useState<Types.CreateEditQuestionFormik>();
  const [showPopupComment, setShowPopupComment] = useState<boolean>(false);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [isSettingTime, setIsSettingTime] = useState<boolean>(false);
  const [filesInServer, setFilesInServer] = useState<string[]>([]);
  const [loading] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] =
    useState<ErrorModelToOpen>(initShowActionErrorModal);
  const [showPopupUploadMedia, setShowPopupUploadMedia] = useState<{
    show: boolean;
    data: Array<UploadFileAntd<File>>;
    dataName: Array<string>;
    dataProblem: Array<{
      content: string;
      indexProblem: number;
      file: UploadFileAntd<File>;
      fileID: string;
    }>;

    type: Types.UploadFileFieldType;
    fileAmount?: number;
    fistIndex?: number;
  }>({
    show: false,
    data: [],
    dataName: [],
    dataProblem: [],
    type: 'attach',
    fileAmount: 0,
  });

  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);

  const { timeLimit, defaultTimeLimit } = useSelector(questionMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const ref = useRef(null);

  const dispatch = useAppDispatch();

  const handleOnSetVisible = (isVisible: boolean) => {
    setShowActionErrorModal({ subTitle: '', title: '', description: '', errorVisible: isVisible });
  };

  const handleOnChangeVisibleModel = (visibleUploadFileModel: boolean) => {
    setUploading((prevState: UploadFileToServerModel) => ({
      ...prevState,
      show: visibleUploadFileModel,
    }));
  };

  const handleSubmitUploadFiles = (
    data: Array<UploadFileAntd<File> | undefined>,
    fieldType: Types.UploadFileFieldType
  ) => {
    if (fieldType === 'problems') {
      data[0] && formik.setFieldValue('problems1_attach', [data[0]]);
      data[1] && formik.setFieldValue('problems2_attach', [data[1]]);
      data[2] && formik.setFieldValue('problems3_attach', [data[2]]);
    }
    formik.setFieldValue(fieldType, data);
  };

  const handleCancel = () => {
    setOpenModalCreateEditQuestion({ visible: false, type });
    formik.resetForm();
  };

  const handleDeleteAllAttach = () => {
    if (!formik.values.attach_fileID) return;
    const arrUidImage = formik.values.attach_fileID.map((item) => item);

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const handleDeleteAllProblem = () => {
    if (!formik.values.problems_attach_fileID) return;
    const arrUidImage = formik.values.problems_attach_fileID.map((item) => item);

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const handleDeleteAllComment = () => {
    if (!formik.values?.comments_attach_fileID) return;
    const arrUidImage = formik.values.comments_attach_fileID.map((item) => item);

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: '',
      time_limit:
        type === 'create' && timeLimit
          ? defaultTimeLimit
            ? timeLimit[defaultTimeLimit]?.option_id
            : timeLimit[0]?.option_id
          : '',
      comment: '',
      score: 1,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attach_fileID: [],
      attach_fileName: [],
      comments_attach_fileID: [],
      comments_attach_fileName: [],
      problems_attach_fileID: [],
      problems_attach_fileName: [],
    },
    enableReinitialize: true,
    validationSchema: createEditQuestionSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (isEqual(values, questionDetail)) return;
      handleSubmit({
        dispatch,
        fileIdsToDelete,
        filesInServer,
        handleCancel,
        setFileIdsToDelete,
        setFilesInServer,
        setShowActionErrorModal,
        setUploading,
        type,
        userInfo,
        values,
        i_id: question_id,
        handleFetchData: async () => {
          dispatch(startLoading());
          await dispatch(
            getDataQuestion({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: page ?? 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
              omit_total_items: false,
            })
          );
          fetchData && (await fetchData());
          dispatch(stopLoading());
          setVisibleSuccess(true);
        },
      });
    },
  });

  const handleShowModalUploadFile = (fieldType: Types.UploadFileFieldType) => {
    const data =
      fieldType === 'problems'
        ? [
            formik.values.problems1_attach[0],
            formik.values.problems2_attach[0],
            formik.values.problems3_attach[0],
          ].filter((file) => file ?? false)
        : formik.values[fieldType];

    const dataName =
      fieldType === 'attach'
        ? formik.values.attach_fileName
        : fieldType === 'comment_attach'
        ? formik.values.comments_attach_fileName
        : fieldType === 'problems'
        ? formik.values.problems_attach_fileName
        : [];

    const dataProblem = [
      {
        content: 'problems1_attach',
        indexProblem: 0,
        file: formik.values.problems1_attach[0],
        fileID:
          formik.values.problems_attach_fileID?.length && formik.values.problems_attach_fileID[0]
            ? formik.values.problems_attach_fileID[0]
            : '',
      },
      {
        content: 'problems2_attach',
        indexProblem: 1,
        file: formik.values.problems2_attach[0],
        fileID:
          formik.values.problems_attach_fileID?.length && formik.values.problems_attach_fileID[1]
            ? formik.values.problems_attach_fileID[1]
            : '',
      },
      {
        content: 'problems3_attach',
        indexProblem: 2,
        file: formik.values.problems3_attach[0],
        fileID:
          formik.values.problems_attach_fileID?.length && formik.values.problems_attach_fileID[2]
            ? formik.values.problems_attach_fileID[2]
            : '',
      },
    ].filter((file) => file.file !== undefined);
    const minIndexItem = minBy(dataProblem, 'indexProblem')?.indexProblem || 0;

    setShowPopupUploadMedia({
      show: true,
      data: data,
      dataName: dataName || [''],
      type: fieldType,
      fileAmount: 3,
      dataProblem: dataProblem,
      fistIndex: minIndexItem,
    });
  };

  useEffect(() => {
    if (!visible || !question_id) return;

    handleGetFileQuestionDetail({
      dispatch,
      handleCancel,
      setFilesInServer,
      i_id: question_id,
      formik,
      setQuestionDetail,
      setResponseQuestionDetail,
    });

    return () => {
      formik.setValues({
        name: '',
        description: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: '',
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
        attach_fileID: [],
        comments_attach_fileID: [],
        problems_attach_fileID: [],
      });
      setFilesInServer([]);
      setFileIdsToDelete([]);
      setUploading({ show: false, files: [] });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type, visible]);

  useEffect(() => {
    if (type === 'create' && timeLimit) {
      const time_limit = defaultTimeLimit
        ? timeLimit[defaultTimeLimit]?.option_id
        : timeLimit[0]?.option_id;
      formik.setFieldValue('time_limit', time_limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTimeLimit, timeLimit, type]);

  const imageAttach = useMemo(() => {
    const defaultImageCount = 3;
    const attachCount = formik.values.attach.length;

    const images = [];

    for (let i = 0; i < defaultImageCount; i++) {
      if (i < attachCount) {
        const attach = formik.values.attach[i];
        images.push(
          <div className="image" key={i}>
            {attach?.type === FileType.MP4 || includes(attach as unknown as string, 'mp4') ? (
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={
                  attach?.originFileObj
                    ? URL.createObjectURL(new Blob([attach?.originFileObj as Blob]))
                    : (attach as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                src={
                  attach?.originFileObj
                    ? URL.createObjectURL(new Blob([attach?.originFileObj as Blob]))
                    : attach
                    ? (attach as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={attach?.name}
              />
            )}
          </div>
        );
      } else {
        images.push(
          <div className="image" key={i}>
            <img src={NoImage2} className="image-question" alt="No Image" />
          </div>
        );
      }
    }

    return images;
  }, [formik.values.attach]);

  const imageComment = useMemo(() => {
    const defaultImageCount = 3;
    const commentImageCount = formik.values.comment_attach.length;

    const images = [];

    for (let i = 0; i < defaultImageCount; i++) {
      if (i < commentImageCount) {
        const comment_attach = formik.values.comment_attach[i];
        images.push(
          <div className="image" key={i}>
            {comment_attach?.type === FileType.MP4 ||
            includes(comment_attach as unknown as string, 'mp4') ? (
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={
                  comment_attach?.originFileObj
                    ? URL.createObjectURL(new Blob([comment_attach?.originFileObj as Blob]))
                    : (comment_attach as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                src={
                  comment_attach?.originFileObj
                    ? URL.createObjectURL(new Blob([comment_attach?.originFileObj as Blob]))
                    : comment_attach
                    ? (comment_attach as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={comment_attach?.name}
              />
            )}
          </div>
        );
      } else {
        images.push(
          <div className="image" key={i}>
            <img src={NoImage2} className="image-question" alt="No Image" />
          </div>
        );
      }
    }

    return images;
  }, [formik.values.comment_attach]);

  const renderProblem = (formikValues: Types.CreateEditQuestionFormik, problemKey: number) => {
    const key = `problems${problemKey}_attach` as keyof Types.CreateEditQuestionFormik;

    const problemArray = formikValues[key] as UploadFileAntd<File>[] | undefined;
    const problem = problemArray ? problemArray[0] : undefined;

    const labels = ['A', 'B', 'C'];
    const label = labels[problemKey - 1];

    const isVideo = problem?.type === FileType.MP4 || includes(problem as unknown as string, 'mp4');

    return (
      <>
        <div className="image" key={problemKey}>
          {isVideo ? (
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              url={
                problem?.originFileObj
                  ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                  : (problem as unknown as string)
              }
              controls
              config={{
                file: {
                  attributes: {
                    disablePictureInPicture: true,
                    controlsList: 'nodownload noplaybackrate',
                  },
                },
              }}
            />
          ) : (
            <img
              src={
                problem?.originFileObj
                  ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                  : problem
                  ? (problem as unknown as string)
                  : NoImage2
              }
              className="image-question"
              alt={problem?.name}
            />
          )}
        </div>
        <p className="label">{`選択肢${label}`}</p>
      </>
    );
  };

  const handleExport = (value: string) => {
    if (value === 'pdf') {
      exportPDF(ref, '設問マスタ', 'p', true);
    } else {
      exportCsv();
    }

    setVisibleConfirm(false);
  };

  const exportCsv = () => {
    const { values } = formik;
    const itemCsv = {
      name: values.name,
      i_id: question_id,
      description: values.description,
      attach_fileID1: convertAttachFileId(
        responseQuestionDetail?.attach2,
        values.attach_fileID?.[0]
      ),
      attach_fileID2: convertAttachFileId(
        responseQuestionDetail?.attach2,
        values.attach_fileID?.[1]
      ),
      attach_fileID3: convertAttachFileId(
        responseQuestionDetail?.attach3,
        values.attach_fileID?.[2]
      ),
      problems1: values.problems1,
      problems2: values.problems2,
      problems3: values.problems3,
      problems1_attach_fileID: convertAttachFileId(
        responseQuestionDetail?.problems1_attach,
        values.problems_attach_fileID?.[0]
      ),
      problems2_attach_fileID: convertAttachFileId(
        responseQuestionDetail?.problems2_attach,
        values.problems_attach_fileID?.[1]
      ),
      problems3_attach_fileID: convertAttachFileId(
        responseQuestionDetail?.problems3_attach,
        values.problems_attach_fileID?.[2]
      ),
      answer: values.answer,
      comment: values.comment,
      comment_attach_fileID1: convertAttachFileId(
        responseQuestionDetail?.comment_attach1,
        values.comments_attach_fileID?.[0]
      ),
      comment_attach_fileID2: convertAttachFileId(
        responseQuestionDetail?.comment_attach2,
        values.comments_attach_fileID?.[1]
      ),
      comment_attach_fileID3: convertAttachFileId(
        responseQuestionDetail?.comment_attach1,
        values.comments_attach_fileID?.[2]
      ),
      time_limit: values.time_limit,
      score: values.score,
      attach_file_name1: `${responseQuestionDetail?.code}_attach1`,
      attach_file_name2: `${responseQuestionDetail?.code}_attach2`,
      attach_file_name3: `${responseQuestionDetail?.code}_attach3`,
      problems1_attach_file_name: `${responseQuestionDetail?.code}_problems1_attach`,
      problems2_attach_file_name: `${responseQuestionDetail?.code}_problems2_attach`,
      problems3_attach_file_name: `${responseQuestionDetail?.code}_problems3_attach`,
      comment_attach_file_name1: `${responseQuestionDetail?.code}_comment_attach1`,
      comment_attach_file_name2: `${responseQuestionDetail?.code}_comment_attach2`,
      comment_attach_file_name3: `${responseQuestionDetail?.code}_comment_attach3`,
    };

    const csvString = [
      HEADER_QUESTION_MASTER_CSV.map(({ label }) => label),
      ...[itemCsv].map((item: any) => Object.values(item)),
    ]
      .map((e) => e.join(','))
      .join('\n');
    const bom = '\uFEFF';
    const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
    saveAs(file, '設問マスタ.csv');
  };

  const pdfComponent = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          position: 'absolute',
          right: '9999px',
        }}
      >
        <QuestionPDF
          data={formik.values}
          imageAttach={imageAttach}
          imageComment={imageComment}
          renderProblem={renderProblem}
          timeLimit={timeLimit}
        />
      </div>
    );
  }, [formik.values, imageAttach, imageComment, renderProblem, type, timeLimit]);

  const CustomHeader = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <p
        style={{
          margin: 0,
        }}
      >
        設問編集
      </p>
      <Button
        style={{
          width: '160px',
          height: '40px',
          borderRadius: '5px',
          background: '#f6ac00',
          color: '#ffffff',
          right: 13,
          position: 'absolute',
          fontWeight: '700',
        }}
        onClick={() => setVisibleConfirm(true)}
      >
        エクスポート
      </Button>
    </div>
  );

  return (
    <Modal
      title={type === 'create' ? '設問新規作成' : <CustomHeader />}
      width={860}
      className="btn"
      open={visible}
      okButton={{
        text: type === 'create' ? '登録' : '更新',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.isValid || !formik.dirty,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setOpenModalCreateEditQuestion({ visible: false, type }),
      }}
      onCancel={() => setOpenModalCreateEditQuestion({ visible: false, type })}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {pdfComponent}
        <p className="sub-title">
          {type === 'create'
            ? '設問の新規作成画面です。入力後に登録ボタンをクリックしてください。'
            : '設問の内容確認と編集用の画面です。編集した場合は更新ボタンをクリックしてください。'}
        </p>
        <SpinLoading size="large" loading={loading}>
          <FormikProvider value={formik}>
            <Form colon={false} layout="vertical" className="form">
              <p className="label-note">
                <span className="number">1</span>設問内容
              </p>
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    設問名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  name="name"
                  className="input"
                  type="text"
                  showCount
                  maxLength={30}
                  placeholder="全角：最大30文字"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={
                  <span className="text-label">
                    設問内容
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="description"
                  className="input-textarea"
                  showCount
                  maxLength={480}
                  placeholder="全角：最大100文字"
                />
              </Form.Item>
              <Form.Item name="attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">設問画像・動画 </p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('attach')}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {imageAttach}
                </div>
                <div
                  className={`text-clear${formik.values.attach ? '' : '__disabled'}`}
                  onClick={() => {
                    formik.setFieldValue('attach', []);
                    handleDeleteAllAttach();
                  }}
                >
                  クリア
                </div>
              </div>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">2</span>解答選択肢
              </p>
              <Form.Item
                name="problems1"
                label={
                  <span className="text-label">
                    選択肢A
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems1"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems2"
                label={
                  <span className="text-label">
                    選択肢B
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems2"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems3"
                label={
                  <span className="text-label">
                    選択肢C
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems3"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item name="problems" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">回答選択肢画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('problems')}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {[1, 2, 3].map((problemKey) => (
                    <div key={problemKey}>{renderProblem(formik.values, problemKey)}</div>
                  ))}
                </div>
                <div
                  className={`text-clear${
                    formik.values.problems1_attach ||
                    formik.values.problems2_attach ||
                    formik.values.problems3_attach
                      ? ''
                      : '__disabled'
                  }`}
                  onClick={() => {
                    formik.setFieldValue('problems1_attach', []);
                    formik.setFieldValue('problems2_attach', []);
                    formik.setFieldValue('problems3_attach', []);
                    handleDeleteAllProblem();
                  }}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="answer"
                label={
                  <span className="text-label">
                    解答
                    <span className="require">*</span>
                  </span>
                }
                className="form-input form-input-radio"
              >
                <Radio.Group name="answer" className="radio_group">
                  <Radio name="answer" value="A" className="radio-text">
                    選択肢A
                  </Radio>
                  <Radio name="answer" value="B" className="radio-text">
                    選択肢B
                  </Radio>
                  <Radio name="answer" value="C" className="radio-text">
                    選択肢C
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">3</span>解説・その他設定
              </p>
              <Form.Item
                name="comment"
                label={
                  <div className="wrap-label-form-input">
                    <span className="text-label">
                      解説
                      <span className="require">*</span>
                    </span>
                    <span className="text-enlarge" onClick={() => setShowPopupComment(true)}>
                      <SelectOutlined className="icon" />
                      入力欄を拡大
                    </span>
                  </div>
                }
                className="form-input"
              >
                <TextArea
                  name="comment"
                  className="input-textarea"
                  showCount
                  maxLength={1050}
                  placeholder="全角：最大100文字"
                />
              </Form.Item>
              <Form.Item name="comment_attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">設問画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('comment_attach')}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {imageComment}
                </div>
                <div
                  className={`text-clear ${formik.values.comment_attach ? '' : '__disabled'}`}
                  onClick={() => {
                    formik.setFieldValue('comment_attach', []);
                    handleDeleteAllComment();
                  }}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="time_limit"
                label={
                  <span className="text-label">
                    制限時間
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <SelectField name="time_limit" className="time_limit">
                  {timeLimit.map((item, index) => (
                    <Option key={index} value={item.option_id}>
                      {item.value}
                    </Option>
                  ))}
                </SelectField>
                <Tooltip
                  trigger={['click']}
                  overlayClassName="tooltip-setup-time-limit"
                  placement="topLeft"
                  title={
                    <>
                      {isSettingTime ? (
                        <div className="text-content">
                          <CheckCircleOutlined className="icon" />
                          設定しました
                        </div>
                      ) : (
                        <>
                          <div className="text-content">
                            現在設定されている制限時間を設問新規作 <br />
                            成時の制限時間初期値として設定します。
                          </div>
                          <div className="box-center">
                            <Button className="btn-set-time" onClick={() => setIsSettingTime(true)}>
                              設定する
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  }
                >
                  <ClockCircleOutlined className="watch-icon" />
                  <span className="time-setting">制限時間初期値設定</span>
                </Tooltip>
              </Form.Item>
              <Row>
                <Col span={24}>
                  <div className="form-input-radio">
                    <span className="text-label">
                      スコア設定（設問難易度の設定）
                      <span className="require">*</span>
                      <Tooltip
                        trigger={['click']}
                        overlayClassName="question-tooltip"
                        title={
                          <>
                            <h6
                              style={{
                                fontFamily: 'Noto Sans Javanese',
                                fontWeight: '400',
                                fontSize: 16,
                                color: '#2A2A2A',
                              }}
                            >
                              スコア設定（設問難易度の設定）について
                            </h6>
                            <p>
                              スコアは設問の難易度を設定する項目です。
                              <br />
                              設定の際は「１・２・３」の３段階からスコアを選択します。
                              <br />
                              <br />
                              スコアは設問の難易度を表すと共に、獲得スコアとして得点も表します。
                              <br />
                              スキルチェック実施の際に、正解した設問に設定されているスコアを獲得できます。
                              <br />
                              <br />
                              設定したスコアの情報や獲得したスコアの結果はレポートから確認することができます。
                              <br />
                              <br />
                              獲得したスコアから、スキルチェックの結果が難易度の高い設問を多く正解できているか、難易度の低い設問を多く
                              <br />
                              間違えているので、基礎ができていないか、ケアレスミスで間違えている等の分析ができる仕組みです。
                              <br />
                              <br />
                              正解した数や割合だけでなく、質をはかる指標です。
                              <br />
                              <br />
                              スキルチェック実施後は、ぜひスコアを活用してみてください。
                            </p>
                          </>
                        }
                      >
                        <QuestionCircleOutlined className="question-mark-icon" />
                      </Tooltip>
                    </span>
                    <Radio.Group name="score" className="radio_group">
                      <Radio name="score" value={1} className="radio-text">
                        1
                      </Radio>
                      <Radio name="score" value={2} className="radio-text">
                        2
                      </Radio>
                      <Radio name="score" value={3} className="radio-text">
                        3
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </SpinLoading>
        {/* <UploadImageVideo
          visible={showPopupUploadMedia}
          setVisible={setShowPopupUploadMedia}
          onSubmit={handleSubmitUploadFiles}
        /> */}
        <UploadImageVideoQuestion
          visible={showPopupUploadMedia}
          setVisible={setShowPopupUploadMedia}
          onSubmit={handleSubmitUploadFiles}
        />
        <ActionErrorModal
          visible={showActionErrorModal.errorVisible}
          description={showActionErrorModal.description}
          subTitle={showActionErrorModal.subTitle}
          title={showActionErrorModal.title}
          setVisible={handleOnSetVisible}
        />
        <PopupComment
          visible={showPopupComment}
          setVisible={setShowPopupComment}
          commentValues={formik.values.comment}
          onSubmit={(values) => {
            setShowPopupComment(false);
            formik.setFieldValue('comment', values);
          }}
        />
        <UploadFileToServer
          uploadingFiles={uploading.files}
          visible={uploading.show}
          setVisible={(isVisible: boolean) => handleOnChangeVisibleModel(isVisible)}
          title={'アップロード中'}
        />
        <PopupConfirmExportFile
          visible={visibleConfirm}
          setVisible={setVisibleConfirm}
          onSubmit={handleExport}
        />
      </SectionStyled>
    </Modal>
  );
};

export default CreateEditQuestionMaster;
