import React, { useEffect, useState } from 'react';
import { Form, Select, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import { some } from 'lodash';

import {
  CaretLeftOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { getStatus } from 'pages/Dashboard/OfficialCurriculum';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Modal, SelectField } from 'components';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import Styled from './styles';
import {
  getArchiveList,
  removeCurriculum,
  updateCurriculum,
  getOfficialPublish,
} from '../../thunk';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;
const PER_PAGE = 10;

const ArchiveList: React.FC<Props> = ({ visible, setVisible }) => {
  const [visibleDeleteComplete, setVisibleDeleteComplete] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Types.ArchiveRes[]>([]);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const { archiveList, loading } = useSelector(publicManagerSelector);
  const { userInfo } = useSelector(authSelector);

  const formik = useFormik<Types.ArchiveListSearchFormik>({
    initialValues: {
      curriculum_code: '',
      curriculum_name: '',
      publish: '',
      createdby: '',
    },
    onSubmit: () => {},
  });
  const dispatch = useAppDispatch();

  const handleToggleModal = () => {
    setVisible(false);
  };
  const handleDelete = async () => {
    if (!selectedRows.length) return;
    const actionResult = await Promise.all(
      selectedRows.map((val) =>
        dispatch(
          removeCurriculum({
            id: val.item_ref.curriculum_code?.i_id,
          })
        )
      )
    );
    if (some(actionResult, (item) => removeCurriculum.fulfilled.match(item))) setCompleted(true);

    setVisibleDelete(false);
  };

  const fetchArchiveList = () => {
    if (!userInfo) return;

    dispatch(
      getArchiveList({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'archive_flg',
            search_value: ['1'],
          },
        ],
        include_item_ref: true,
        page: 1,
        per_page: 0,
        omit_total_items: false,
      })
    );
  };

  const fetchOfficialPublish = () => {
    if (!userInfo) return;

    dispatch(
      getOfficialPublish({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'archive_flg',
            search_value: ['0'],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
      })
    );
  };
  const handleUpdateCurriculum = async () => {
    if (!selectedRows.length) return;
    const actionResult = await Promise.all(
      selectedRows.map((val) =>
        dispatch(
          updateCurriculum({
            id: val.item_ref.curriculum_code?.i_id,
            data: {
              item: {
                archive_flg: 0,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        )
      )
    );
    if (some(actionResult, (item) => updateCurriculum.fulfilled.match(item))) setCompleted(true);
  };

  const columns: ColumnsType<Types.ArchiveRes> = [
    {
      title: 'ID',
      dataIndex: 'curriculum_code',
      key: 'curriculum_code',
      className: 'curriculum_code',
    },
    {
      title: 'OFFICIALカリキュラム名',
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      width: '27%',
    },
    {
      title: '公開ステータス',
      dataIndex: 'publish',
      key: 'publish',
      align: 'center',
      render: (record: number) => getStatus(record),
    },
    {
      title: '前回公開日',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      align: 'center',
    },
    {
      title: '公開管理に戻す',
      dataIndex: '',
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_: string, record) => (
        <Button
          className="button-return"
          onClick={() => {
            setVisibleUpdate(true);
            setSelectedRows([record]);
          }}
        >
          <CaretLeftOutlined
            style={{
              color: '#ffffff',
            }}
          />
          <span>戻す</span>
        </Button>
      ),
    },
    {
      title: '削除',
      dataIndex: '',
      key: 'delete',
      width: '5%',
      align: 'center',
      render: () => (
        <DeleteOutlined
          style={{
            fontSize: 20,
            color: '#c4c4c4',
          }}
          onClick={() => setVisibleDelete(true)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (visible) {
      fetchArchiveList();
    }
  }, [visible, dispatch, userInfo]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title="アーカイブリスト"
      visible={visible}
      width={1500}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #EAEAEA',
      }}
      bodyStyle={{
        padding: 0,
      }}
    >
      <Styled isEmptyData={!archiveList.length}>
        <div className="container">
          <p className="description">アーカイブしたOFFICIALカリキュラムの管理を行う画面です。</p>
          <div className="line" />
          <FormikProvider value={formik}>
            <Form layout="vertical">
              <div className="form-search">
                <Form.Item name="official_id" className="item" label="OFFICIALカリキュラムID">
                  <SelectField name="official_id" placeholder="指定なし">
                    {archiveList.map(
                      ({ curriculum_code }) =>
                        curriculum_code && (
                          <Option key={curriculum_code} value={curriculum_code}>
                            {curriculum_code}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="official_curriculum_name"
                  className="item"
                  label="OFFICIALカリキュラム名"
                >
                  <SelectField name="official_curriculum_name" placeholder="指定なし">
                    {archiveList.map(
                      ({ curriculum_name }, index) =>
                        curriculum_name && (
                          <Option key={index} value={curriculum_name}>
                            {curriculum_name}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item name="public_status" className="item" label="公開ステータス">
                  <SelectField name="public_status" placeholder="指定なし">
                    {archiveList.map(
                      ({ publish }, index) =>
                        publish && (
                          <Option key={index} value={publish}>
                            {getStatus(publish)}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item name="createdby" className="item" label="作成者">
                  <SelectField name="createdby" placeholder="指定なし">
                    {archiveList.map(
                      ({ createdby }, index) =>
                        createdby && (
                          <Option key={index} value={createdby}>
                            {createdby}
                          </Option>
                        )
                    )}
                  </SelectField>
                </Form.Item>
                <div className="button-search">
                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span className="label-reset" onClick={() => formik.resetForm()}>
                    リセット
                  </span>
                </div>
              </div>
            </Form>
            <div className="wrap-button">
              <Button className="btn btn-active" icon={<CloudUploadOutlined className="icon" />}>
                インポート
              </Button>
            </div>
            <Table
              className="table"
              loading={loading}
              dataSource={archiveList}
              columns={columns}
              pagination={{
                pageSize: PER_PAGE,
                total: archiveList.length,
                onChange: setPage,
                showTotal: (total) => (
                  <span>
                    {page * PER_PAGE > total ? total : page * PER_PAGE}
                    件表示 / {total} 件
                  </span>
                ),
                position: ['topCenter'],
              }}
              scroll={{
                y: '35vh',
              }}
              rowSelection={{
                onChange: (_, values: Types.ArchiveRes[]) => setSelectedRows(values),
              }}
            />
          </FormikProvider>
        </div>
        <div className="wrap-bottom">
          <div className="text-label">
            選択したOFFICIALカリキュラムを処理：
            <div className="wrap-bottom-button">
              <Button className="btn btn-active" onClick={() => setVisibleUpdate(true)}>
                公開管理に戻す
              </Button>
              <Button className="btn btn-outline" onClick={() => setVisibleDelete(true)}>
                削除
              </Button>
            </div>
          </div>
          <Button className="btn-cancle" onClick={() => handleToggleModal()}>
            閉じる
          </Button>
        </div>
        <ConfirmDeleteModal
          title="OFFICIALカリキュラム削除"
          subTitle="選択したOFFICIALカリキュラムを削除します"
          description="※削除を実行すると、元に戻せませんのでご注意ください。"
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          onCancel={() => handleDelete()}
          onSubmit={() => setVisibleDeleteComplete(true)}
        />
        <ConfirmDeleteModal
          title="公開管理に戻す"
          subTitle="選択したOFFICIALカリキュラムを公開管理に戻します。"
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          onCancel={() => setVisibleUpdate(false)}
          onSubmit={() => {
            handleUpdateCurriculum();
            setVisibleUpdate(false);
          }}
        />
        <Completed
          title="公開管理に戻しました。"
          visible={completed}
          setVisible={setCompleted}
          onSubmit={() => {
            setCompleted(false);
            fetchArchiveList();
            fetchOfficialPublish();
          }}
        />
        <DeleteCompleted visible={visibleDeleteComplete} setVisible={setVisibleDeleteComplete} />
      </Styled>
    </Modal>
  );
};

export default ArchiveList;
