import {
  ClipPath,
  Defs,
  Document,
  Font,
  G,
  Page,
  Path,
  Rect,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface PDFQuestionMasterTableProps {
  dataQuestion: Types.Question.ResponseType[];
  statusTime?: boolean;
  timeLimit?: string;
  curriculum_name?: string;
  required_curriculum?: string;
  createdby?: string;
  question_name?: string;
  score?: string;
  total?: number;
}

const PDFQuestionMasterTable = (props: PDFQuestionMasterTableProps) => {
  const {
    dataQuestion,
    statusTime,
    timeLimit,
    createdby,
    curriculum_name,
    question_name,
    score,
    required_curriculum,
    total,
  } = props;
  const columns: { title: string; width: string | number; key: string }[] = [
    {
      title: 'No.',
      width: 50,
      key: 'index',
    },
    {
      title: '設問コード',
      width: 120,
      key: 'question_code',
    },
    {
      title: '設問名',
      width: 180,
      key: 'question_name',
    },
    {
      title: '設問内容抜粋',
      width: 180,
      key: 'question_description',
    },
    {
      title: '製作者',
      width: 180,
      key: 'createdby',
    },
    {
      title: 'スコア',
      width: 50,
      key: 'score',
    },
  ];

  const DownIcon = () => (
    <Svg style={styles.selectIcon} width="8" height="4" viewBox="0 0 8 4">
      <Path
        d="M7.0317 0H6.36586C6.32058 0 6.27797 0.0195313 6.25134 0.0515625L3.72915 3.11094L1.20696 0.0515625C1.18033 0.0195313 1.13772 0 1.09244 0H0.426604C0.368898 0 0.335162 0.0578126 0.368898 0.0992188L3.49921 3.89687C3.61285 4.03437 3.84545 4.03437 3.9582 3.89687L7.08851 0.0992188C7.12314 0.0578126 7.0894 0 7.0317 0Z"
        fill="#999999"
      />
    </Svg>
  );

  const QAIcon = () => (
    <Svg style={styles.statusTimeIcon} width="16" height="16" viewBox="0 0 16 16">
      <Path
        d="M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0ZM8 14.6429C4.33214 14.6429 1.35714 11.6679 1.35714 8C1.35714 4.33214 4.33214 1.35714 8 1.35714C11.6679 1.35714 14.6429 4.33214 14.6429 8C14.6429 11.6679 11.6679 14.6429 8 14.6429Z"
        fill="#08A3A5"
      />
      <Path
        d="M9.99449 4.51194C9.45878 4.0423 8.75164 3.78516 8.00165 3.78516C7.25166 3.78516 6.54452 4.04408 6.00881 4.51194C5.45167 4.99944 5.14453 5.6548 5.14453 6.35658V6.4923C5.14453 6.57087 5.20882 6.63516 5.28739 6.63516H6.14452C6.22309 6.63516 6.28738 6.57087 6.28738 6.4923V6.35658C6.28738 5.56908 7.05701 4.92801 8.00165 4.92801C8.94628 4.92801 9.71592 5.56908 9.71592 6.35658C9.71592 6.91194 9.32307 7.42087 8.71414 7.6548C8.33557 7.79944 8.01415 8.05301 7.78379 8.38516C7.54987 8.72444 7.42844 9.13158 7.42844 9.54408V9.92801C7.42844 10.0066 7.49272 10.0709 7.5713 10.0709H8.42843C8.507 10.0709 8.57129 10.0066 8.57129 9.92801V9.52266C8.57221 9.3493 8.62535 9.18025 8.72378 9.03754C8.82221 8.89484 8.96135 8.7851 9.12307 8.72266C10.1766 8.3173 10.857 7.38873 10.857 6.35658C10.8588 5.6548 10.5516 4.99944 9.99449 4.51194ZM7.28737 11.928C7.28737 12.1175 7.36262 12.2991 7.49658 12.4331C7.63053 12.567 7.81221 12.6423 8.00165 12.6423C8.19109 12.6423 8.37277 12.567 8.50672 12.4331C8.64067 12.2991 8.71593 12.1175 8.71593 11.928C8.71593 11.7386 8.64067 11.5569 8.50672 11.4229C8.37277 11.289 8.19109 11.2137 8.00165 11.2137C7.81221 11.2137 7.63053 11.289 7.49658 11.4229C7.36262 11.5569 7.28737 11.7386 7.28737 11.928Z"
        fill="#08A3A5"
      />
    </Svg>
  );

  const CheckedIcon = () => (
    <Svg style={styles.statusTimeIcon} width="28" height="28" viewBox="0 0 28 28">
      <G clip-path="url(#clip0_10_23146)">
        <Path
          d="M19.8441 9.03125H18.3785C18.0598 9.03125 17.7566 9.18437 17.5691 9.44687L12.6566 16.2594L10.4316 13.1719C10.2441 12.9125 9.94415 12.7563 9.62227 12.7563H8.15665C7.95352 12.7563 7.83477 12.9875 7.95352 13.1531L11.8473 18.5531C11.9393 18.6815 12.0605 18.7861 12.201 18.8583C12.3415 18.9305 12.4971 18.9681 12.6551 18.9681C12.813 18.9681 12.9687 18.9305 13.1092 18.8583C13.2497 18.7861 13.3709 18.6815 13.4629 18.5531L20.0441 9.42813C20.166 9.2625 20.0473 9.03125 19.8441 9.03125Z"
          fill="#08A3A5"
        />
        <Path
          d="M14 0C6.26875 0 0 6.26875 0 14C0 21.7313 6.26875 28 14 28C21.7313 28 28 21.7313 28 14C28 6.26875 21.7313 0 14 0ZM14 25.625C7.58125 25.625 2.375 20.4188 2.375 14C2.375 7.58125 7.58125 2.375 14 2.375C20.4188 2.375 25.625 7.58125 25.625 14C25.625 20.4188 20.4188 25.625 14 25.625Z"
          fill="#08A3A5"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_10_23146">
          <Rect width="28" height="28" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>設問マスタ</Text>
        </View>

        <View style={styles.subscription}>
          <View>
            <Text style={styles.textSubscription}>
              設問の作成・編集、作成した設問の一覧を確認できます。
            </Text>
            <Text style={styles.textSubscription}>
              設問に設定するデフォルトの制限時間も設定可能です。
            </Text>
          </View>
          {!statusTime ? (
            <View style={styles.statusTime}>
              <Text style={styles.statusTimeText}>制限時間の初期値</Text>
              <QAIcon />
              <Text style={styles.statusTimeText}>:</Text>
              <View style={styles.selectBox}>
                <Text style={styles.selectText}>{timeLimit || ''}</Text>
                <DownIcon />
              </View>
              <View style={styles.button}>
                <Text>設定</Text>
              </View>
            </View>
          ) : (
            <View style={styles.statusTime}>
              <CheckedIcon />
              <Text style={styles.statusTimeText}>設定した時間を初期値として登録しました</Text>
            </View>
          )}
        </View>
        <View style={styles.divider}></View>
        <View style={styles.searchPanel}>
          <View style={styles.searchItem}>
            <Text style={styles.label}>カリキュラム名</Text>
            <View style={styles.selectBox}>
              <Text style={styles.selectText}>{curriculum_name || ''}</Text>
              <DownIcon />
            </View>
          </View>
          <View style={styles.searchItem}>
            <Text style={styles.label}>カリキュラム種類</Text>
            <View style={styles.selectBox}>
              <Text style={styles.selectText}>{required_curriculum || ''}</Text>
              <DownIcon />
            </View>
          </View>
          <View style={styles.searchItem}>
            <Text style={styles.label}>制作者</Text>
            <View style={styles.selectBox}>
              <Text style={styles.selectText}>{createdby || ''}</Text>
              <DownIcon />
            </View>
          </View>
          <View style={styles.searchItem}>
            <Text style={styles.label}>設問名</Text>
            <View style={styles.selectBox}>
              <Text style={styles.selectText}>{question_name || ''}</Text>
              <DownIcon />
            </View>
          </View>
          <View style={styles.searchItem}>
            <Text style={styles.label}>スコア（設問難易度）</Text>
            <View style={styles.selectBox}>
              <Text style={styles.selectText}>{score || ''}</Text>
              <DownIcon />
            </View>
          </View>
        </View>
        <View style={styles.body}>
          <View style={styles.textCountGeneral}>
            <Text style={styles.textCountNumber}>1 - {dataQuestion.length}</Text>
            <Text style={styles.textCountNumber}>/</Text>
            <Text style={styles.textCountNumber}>{total || dataQuestion.length}</Text>
            <Text style={styles.textCountDescription}>件</Text>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              {columns.map((column, index) => (
                <View key={index} style={[styles.wrapCell, { width: column.width }]}>
                  <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
                </View>
              ))}
            </View>

            {dataQuestion.map((row, rowIndex) => (
              <View
                key={rowIndex}
                style={[
                  styles.tableRow,
                  {
                    borderBottom:
                      rowIndex !== dataQuestion.length - 1 ? 'none' : '1px solid #e5e5e5',
                  },
                ]}
              >
                <View style={[styles.wrapCell, { width: columns[0].width }]}>
                  <Text style={styles.tableCell}>{rowIndex + 1}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[1].width }]}>
                  <Text style={styles.tableCell}>{row.code}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[2].width }]}>
                  <Text style={styles.tableCell}>{row.name}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[3].width }]}>
                  <Text style={styles.tableCell}>{row.description}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[4].width }]}>
                  <Text style={styles.tableCell}>{row.createdby}</Text>
                </View>
                <View style={[styles.wrapCell, { width: columns[5].width }]}>
                  <Text style={styles.tableCell}>{row.score}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFQuestionMasterTable;
