import { createSlice } from '@reduxjs/toolkit';

import { deleteBelongingMaster, getBelongingMaster, searchBelongingMaster } from './thunk';
import { AffiliationHierarchy } from 'types';

export type InitialState = {
  loading: boolean;
  items: AffiliationHierarchy.ResponseType[];
  searchResult: AffiliationHierarchy.ResponseType[];
  total: number;
};

const initialState: InitialState = {
  items: [],
  searchResult: [],
  loading: false,
  total: 0,
};

export const analysisGroupSlice = createSlice({
  name: 'BelongingMaster-slice',
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.searchResult = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getBelongingMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBelongingMaster.fulfilled, (state, action) => {
      state.items = action.payload.report_results;
      state.total = action.payload.totalItems;
      state.loading = false;
    });
    builder.addCase(getBelongingMaster.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchBelongingMaster.fulfilled, (state, action) => {
      state.searchResult = action.payload.items;
    });
    builder.addCase(deleteBelongingMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBelongingMaster.fulfilled, (state, action) => {
      state.items = state.items.filter((item) => item.i_id !== action.payload);
      state.loading = false;
    });
    builder.addCase(deleteBelongingMaster.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetSearch } = analysisGroupSlice.actions;

export default analysisGroupSlice.reducer;
