import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { useIntl } from 'react-intl';
import saveAs from 'file-saver';
import { omit } from 'lodash';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import PDFRolesMasterTableDocument from './PDFRolesMasterTableDocument';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { authorityMasterSelector } from './selectors';
import { Header, SelectField } from 'components';
import AuthorityMasterStyled from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { resetSearch } from './slice';
import * as Types from 'types';
import {
  deleteItemAuthority,
  getDataAuthority,
  getDataAuthorityCSV,
  searchDataAuthority,
} from './thunk';

const { Option } = Select;

const PER_PAGE = 20;

const AuthorityMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showWarningDeleteModal, setShowWarningDeleteModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const { loading, listAuthority, total, searchResult } = useSelector(authorityMasterSelector);
  const { userInfo } = useSelector(authSelector);

  const { messages } = useIntl();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const searchFormik = useFormik<Types.SearchAuthorityMasterFormik>({
    initialValues: {
      authority: '',
    },
    onSubmit: async ({ authority }) => {
      if (!authority || !userInfo) {
        setShowActionErrorModal(true);
      } else {
        await dispatch(
          getDataAuthority({
            page: 1,
            per_page: PER_PAGE,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
                exact_match: true,
              },
              {
                id: 'code',
                search_value: [authority],
              },
            ],
          })
        );
      }
    },
    onReset: () => {
      dispatch(resetSearch());
      dispatch(
        getDataAuthority({
          page: 1,
          per_page: PER_PAGE,
        })
      );
    },
  });

  const columns: ColumnsType<Types.AuthorityMasterType> = [
    {
      title: 'コード',
      dataIndex: 'code',
      key: 'code',
      className: 'column',
      width: '10%',
      align: 'center',
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      className: 'column',
      width: '74%',
    },
    {
      title: '編集',
      dataIndex: '',
      key: '',
      className: 'column',
      render: (item) => (
        <FormOutlined
          className="icon"
          onClick={() =>
            navigate(
              generatePath(routes.EditAuthorityMaster.path, { entity: 'receiving', id: item.code }),
              {
                state: item,
              }
            )
          }
        />
      ),
    },
    {
      title: '削除',
      className: 'column',
      render: (item) => <DeleteOutlined className="icon" onClick={() => handleDeleteItem(item)} />,
    },
  ];

  const handleDeleteItem = (item: Types.AuthorityMasterType) => {
    setDeleteItemId(item.i_id);
    setOpenModalConfirmDeleteItem(true);
  };

  const handleAddItem = () => {
    navigate(generatePath(routes.CreateAuthorityMaster.path, { entity: 'receiving' }));
  };

  const handleSearch = (value: string) => {
    dispatch(
      searchDataAuthority({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'company_id',
            search_value: [value],
          },
        ],
      })
    );
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'csv' && userInfo) {
      const resultAction = await dispatch(
        getDataAuthorityCSV({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getDataAuthorityCSV.fulfilled.match(resultAction)) {
        const listCsv: Array<
          Partial<
            Omit<
              Types.AuthorityMasterType,
              'i_id' | 'unread' | 'a_id' | 'd_id' | 'status_id' | 'p_id' | 'rev_no'
            >
          >
        > = !!resultAction.payload.items.length
          ? resultAction.payload.items.map(
              (
                item: Omit<
                  Types.AuthorityMasterType,
                  'i_id' | 'unread' | 'a_id' | 'd_id' | 'status_id' | 'p_id' | 'rev_no'
                >
              ) => omit(item, ['i_id', 'unread', 'a_id', 'd_id', 'status_id', 'p_id', 'rev_no'])
            )
          : [];

        const csvString = [
          Object.keys(listCsv[0]),
          ...listCsv.map((item: any) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '分析グループマスタ.csv');
      }
    } else {
      const blob = await pdf(<PDFRolesMasterTableDocument roles={listAuthority} />).toBlob();
      saveAs(blob, 'roles_master_table.pdf');
      setShowConfirmExportFileModal(false);
    }
  };

  useEffect(() => {
    if (!userInfo) return;

    Promise.all([
      dispatch(
        getDataAuthority({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: page,
          per_page: PER_PAGE,
          sort_fields: [{ id: 'code', order: 'asc' }],
        })
      ),
      dispatch(
        searchDataAuthority({
          conditions: [
            {
              id: 'compay_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, [dispatch, page]);

  return (
    <AuthorityMasterStyled>
      <Header title="権限マスタ" className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            権限マスタの作成・管理を行う画面です。 権限マスタはSKILL
            FAMILIAR上で社内ユーザーが使用できる機能の範囲を設定します。
            <br />
            作成した権限マスタを社内ユーザーに設定することで、機能ごとの権限が社内ユーザーに設定されます。
          </p>
          <div className="border" />
          <FormikProvider value={searchFormik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '10%',
              }}
              colon={false}
              className="form-search"
            >
              <Form.Item
                name="authority"
                label={<span className="label"> 権限</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  onSearch={handleSearch}
                  className="select-input"
                  placeholder="選択してください"
                  name="authority"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {searchResult.map((item, index) => (
                    <Option key={index} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => searchFormik.resetForm()}>
                  リセット
                </span>
              </div>
            </Form>
          </FormikProvider>
          {total > 0 ? (
            <div className="text-count">
              {page * PER_PAGE > total ? total : page * PER_PAGE} {messages['M-01-8']} / {total}{' '}
              {messages['M-01-9']}
            </div>
          ) : null}
          <div className="btn-div">
            <Button
              className="btn btn-active"
              disabled={!listAuthority.length}
              onClick={() => setShowConfirmExportFileModal(true)}
              icon={<CloudDownloadOutlined className="size-icon" />}
            >
              エクスポート
            </Button>
            <Button
              className="btn btn-active"
              onClick={handleAddItem}
              icon={<PlusOutlined className="size-icon" />}
            >
              新規登録
            </Button>
          </div>
          <Table
            rowKey="code"
            columns={columns}
            dataSource={listAuthority}
            loading={loading}
            className={listAuthority.length > 0 ? 'table' : 'table_mr'}
            pagination={{
              pageSize: PER_PAGE,
              showSizeChanger: false,
              total: total,
              current: page,
              onChange: setPage,
              position: ['bottomCenter', 'topCenter'],
            }}
          />
        </div>
      </div>
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="検索するマスタが未選択です"
        description={
          <p className="text-content">
            検索する権限を選択後、
            <br />
            「検索」をクリックしてください。
          </p>
        }
      />
      <ActionErrorModal
        visible={showWarningDeleteModal}
        setVisible={setShowWarningDeleteModal}
        subTitle="削除に失敗しました"
        description="マスタの削除に失敗しました。 再度お試しください。"
      />
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
        subTitle="役職マスタをエクスポートします。"
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="削除が完了しました"
      />
      <ConfirmDeleteModal
        visible={openModalConfirmDeleteItem}
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        onSubmit={async () => {
          const resultAction = await dispatch(deleteItemAuthority({ id: deleteItemId }));
          if (deleteItemAuthority.fulfilled.match(resultAction)) {
            dispatch(
              getDataAuthority({
                page: page,
                per_page: 20,
              })
            );
            setShowCompleteModal(true);
          } else {
            setShowWarningDeleteModal(true);
          }
        }}
        onCancel={() => setDeleteItemId('')}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </AuthorityMasterStyled>
  );
};

export default AuthorityMaster;
