import React, { useState } from 'react';
import { Popover } from 'antd';

import { IconLocked, TickComplete, IconRequired } from 'assets';
import TooltipUserSetting from '../Tooltip';
import { Wrapper } from './styles';

const NodeRenderer: React.FC<{
  isPublish: number;
  curricullum_name: string;
  curriculumSelected: boolean;
  curricullum_description: string;
  handleSwitch: (value: boolean) => void;
}> = ({
  isPublish,
  handleSwitch,
  curricullum_name,
  curriculumSelected,
  curricullum_description,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Wrapper>
      <Popover
        overlayStyle={{
          width: 220,
        }}
        placement="bottomLeft"
        content={
          <TooltipUserSetting
            isPublish={isPublish}
            curriculumSelected={curriculumSelected}
            curricullum_description={curricullum_description}
            handleSwitch={(value) => {
              handleSwitch(value);
              setVisible(false);
            }}
          />
        }
        trigger="click"
        onVisibleChange={setVisible}
        visible={visible}
      >
        <div className="row">
          <div className="box_icon">
            {isPublish ? (
              <img src={TickComplete} className="icon" alt="publish-icon" />
            ) : (
              <img src={IconLocked} className="icon" alt="edit-icon" />
            )}
            <img src={IconRequired} className="icon" alt="required-icon" />
          </div>
          <span className="rowTitle">{curricullum_name || '（空白）'}</span>
        </div>
      </Popover>
    </Wrapper>
  );
};

export default NodeRenderer;
