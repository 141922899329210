import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Locale } from 'constant/enum.constant';

export interface InitialState {
  locale: keyof typeof Locale;
  collapsedMenu: boolean;
  hiddenSidebar: boolean;
  loading: boolean;
}

const initialState: InitialState = {
  locale: 'ja',
  collapsedMenu: false,
  hiddenSidebar: false,
  loading: false,
};

export const SettingSlice = createSlice({
  name: 'setting-container',
  initialState,
  reducers: {
    setDefaultLocale(state, action: PayloadAction<keyof typeof Locale>) {
      state.locale = action.payload;
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.collapsedMenu = action.payload;
    },
    toggleHiddenSidebar(state, action: PayloadAction<boolean>) {
      state.hiddenSidebar = action.payload;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
  },
});

export const { setDefaultLocale, setCollapsed, toggleHiddenSidebar, startLoading, stopLoading } =
  SettingSlice.actions;
export default SettingSlice.reducer;
