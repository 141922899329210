import yup from '../yup';
import { REGEX_HALF_WIDTH } from 'constant';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('メールアドレスは必須フィールドです。')
    .max(300, 'メールアドレスを300文字以内で入力してください。')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
      'メールアドレスは@を含む半角英数字で入力してください。'
    )
    .test('valid-email', 'メールアドレスは@を含む半角英数字で入力してください。', (value) => {
      if (value === undefined) return false;
      return value.includes('@');
    }),
  password: yup
    .string()
    .required('パスワードは必須フィールドです。')
    .max(30, 'パスワードは30文字以内で入力してください。')
    .matches(
      /^(?=.*[0-9]?)(?=.*[^\uFF21-\uFF3A\uFF41-\uFF5A]?)[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/,
      'パスワードは半角英数字で入力してください。'
    ),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required('メールアドレスは必須フィールドです。')
    .max(300, 'メールアドレスを300文字以内で入力してください。')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
      'メールアドレスは@を含む半角英数字で入力してください。'
    )
    .test('valid-email', 'メールアドレスは@を含む半角英数字で入力してください。', (value) => {
      if (value === undefined) return false;
      return value.includes('@');
    }),
});

export const registerSchema = yup.object().shape({
  email: yup.string().required().email().max(300),
});

export const registerFormSchema = yup.object().shape({
  password: yup.string().required().max(30),
  rePassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません')
    .max(300),
});

export const changeEmailSchema = (userEmail: string) =>
  yup.object().shape({
    email: yup
      .string()
      .required('新しい電子メール アドレスは必須フィールドです。')
      .test(
        'valid-email',
        '新しいメールアドレスは半角英数字、記号で入力してください。',
        (value) => {
          if (value === undefined) return false;
          return value.includes('@');
        }
      )
      .notOneOf([userEmail], '有効なメールアドレスを入力してください')
      .max(300),
    confirm_email: yup
      .string()
      .required('新しいメール アドレス (確認) は必須フィールドです。')
      .oneOf([yup.ref('email'), null], 'メールアドレスが一致しません')
      .notOneOf([userEmail], '有効なメールアドレスを入力してください')
      .test(
        'valid-email',
        '新しいメールアドレス（確認）は半角英数字、記号で入力してください。',
        (value) => {
          if (value === undefined) return false;
          return value.includes('@');
        }
      )
      .max(300),
  });

export const changePasswordSchema = yup.object().shape({
  current_password: yup
    .string()
    .required('現在のパスワードは必須入力項目です。')
    .max(30)
    .matches(REGEX_HALF_WIDTH, {
      message: '現在のパスワードは半角英数字、記号で入力してください。',
    }),
  new_password: yup
    .string()
    .required('新しいパスワードは必須入力項目です。')
    .max(30)
    .matches(REGEX_HALF_WIDTH, {
      message: '新しいパスワードは半角英数字、記号で入力してください。',
    }),
  confirm_password: yup
    .string()
    .required('新しいパスワード（確認）は必須入力項目です。')
    .oneOf([yup.ref('new_password'), null], 'パスワードが一致しません')
    .max(30)
    .matches(REGEX_HALF_WIDTH, {
      message: '新しいパスワード（確認）は半角英数字、記号で入力してください。',
    }),
});
