import { createAsyncThunk } from '@reduxjs/toolkit';

import { curriculumService, services } from 'services';
import * as Types from 'types';
import {
  CURRICULUMS,
  CURRICULUM_EXPORT,
  CURRICULUM_HIERARCHY,
  REQUIRED_CURRICULUM_USERS,
  USER_ASSIGN_REQUIRED_CURRICULUM,
  QUESTION_SEARCH,
  ATTACH,
} from 'configs';

export const createCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(CURRICULUM_HIERARCHY.id, {
      ...req,
      include_item_ref: true,
      sort_fields: [
        { id: 'curriculum_sort_order', order: 'asc' },
        { id: 'level1_sort_order', order: 'asc' },
        { id: 'level2_sort_order', order: 'asc' },
        { id: 'level3_sort_order', order: 'asc' },
        { id: 'level4_sort_order', order: 'asc' },
        { id: 'question_sort_order', order: 'asc' },
      ],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const setRequiredCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/setRequiredCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const setPublishCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/setPublishCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(CURRICULUMS.id, req);

    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLevelCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.createLevel(req.level, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLevelCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.updateLevel(req.level, {
      id: req.id,
      data: {
        ...req.data,
        is_force_update: true,
        return_item_result: true,
      },
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevelCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.deleteLevel(req.level, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserSetting = createAsyncThunk<
  Types.ReportsItemResponseType<Types.UserCurriculumResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataUserSetting', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(REQUIRED_CURRICULUM_USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkUserAssignCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLinkUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_REQUIRED_CURRICULUM.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const curriculumExportDataCSV = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumExport.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/curriculumExportDataCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(CURRICULUM_EXPORT.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumMaster = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getCurriculumMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(CURRICULUM_HIERARCHY.id, {
      ...req,
      include_item_ref: true,
      omit_total_items: false,
      sort_fields: [
        { id: 'curriculum_sort_order', order: 'asc' },
        { id: 'level1_sort_order', order: 'asc' },
        { id: 'level2_sort_order', order: 'asc' },
        { id: 'level3_sort_order', order: 'asc' },
        { id: 'level4_sort_order', order: 'asc' },
        { id: 'question_sort_order', order: 'asc' },
      ],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSearchType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(QUESTION_SEARCH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createImages', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createImages = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Curriculum/thunk/createImages', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  any,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
