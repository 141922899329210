import { pick, omit } from 'lodash';

import * as Pages from 'pages';

export const routes = {
  Login: {
    path: '/login',
    element: <Pages.LoginPage />,
  },
  Dashboard: {
    index: true,
    element: <Pages.DashboardPage />,
    path: '/',
  },
  Employee: {
    path: '/employee',
    element: <Pages.UserMasterPage />,
  },
  CreateEmployee: {
    path: '/employee/create',
    element: <Pages.CreateEmployeePage />,
  },
  EditEmployee: {
    path: '/employee/edit/:id',
    element: <Pages.EditEmployeePage />,
  },
  AuthorityMaster: {
    path: '/authority-mater',
    element: <Pages.AuthorityMasterPage />,
  },
  EditAuthorityMaster: {
    path: '/authority-master/edit/:id',
    element: <Pages.EditAuthorityMasterPage />,
  },
  CreateAuthorityMaster: {
    path: '/authority-master-register',
    element: <Pages.CreateAuthorityMasterPage />,
  },
  Belonging: {
    path: '/belonging',
    element: <Pages.BelongingMasterPage />,
  },
  JobTitleMaster: {
    path: '/job-title-master',
    element: <Pages.JobTitleMaster />,
  },
  AdministratorMaster: {
    path: '/administrator-master',
    element: <Pages.AdministratorMasterPage />,
  },
  PublicManagement: {
    path: '/public-management',
    element: <Pages.PublicManagementPage />,
  },
  Curriculum: {
    path: '/curriculum',
    element: <Pages.CurriculumPage />,
  },
  QuestionMaster: {
    path: '/question-master',
    element: <Pages.QuestionMasterPage />,
  },
  ChangeEmailComplete: {
    path: '/changed-email',
    element: <Pages.ChangeEmailComplete />,
  },
  ResetPassword: {
    path: '/pwd_reset',
    element: <Pages.ResetPassword />,
  },
  ResetPasswordForm: {
    path: '/pwd_reset/:confirmation_id',
    element: <Pages.ResetPasswordForm />,
  },
  RegisterForm: {
    path: '/confirm_email/:confirmation_id',
    title: '',
    element: <Pages.RegisterFormPage />,
  },
};

export type Routes = typeof routes;

export type RouteKeys = keyof Routes;

const publicKeys: RouteKeys[] = [
  'Login',
  'ChangeEmailComplete',
  'ResetPassword',
  'ResetPasswordForm',
  'RegisterForm',
];

export const publicRoutes = pick<Routes, RouteKeys>(routes, publicKeys);

export const privateRoutes = omit<Routes, RouteKeys>(routes, publicKeys);
