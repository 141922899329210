import React, { useEffect, useState } from 'react';
import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { Form, Select, SubmitButton } from 'formik-antd';
import { RangeValue } from 'rc-picker/lib/interface';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { groupBy, omit } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';

import { getDataCompaniesCurriculumn, getDataNumberUserCurriculum } from '../thunk';
import { authSelector } from 'containers/Auth/selectors';
import { dashboardSelector } from '../selectors';
import { loadingRef } from 'components/Loading';
import DatePicker from 'components/DatePicker';
import CompanyUserStyled from './styles';
import { SelectField } from 'components';
import ColumnChart from './ColumnChart';
import { useAppDispatch } from 'hooks';
import TableScreen from './Table';
import * as Types from 'types';

const { Option } = Select;
const { RangePicker } = DatePicker;

const OptionSelect = [
  {
    key: 'カリキュラム利用数',
    value: 'curriculum_usage',
  },
  {
    key: 'カリキュラムアクティブ利用数',
    value: 'curriculum_active_usage',
  },
  {
    key: 'カリキュラム新規利用数',
    value: 'number_new_curriculum_users',
  },
  {
    key: 'カリキュラム解除数',
    value: 'number_unlocked_curriculum',
  },
];

const CompanyUser: React.FC = () => {
  const [indicatorSelected, setIndicatorSelected] = useState<string>('');
  const [segmented, setSegmented] = useState<number>(0);
  const [date, setDate] = useState<RangeValue<Dayjs>>();
  const [page, setPage] = useState<number>(1);

  const { loading, companyUserCurriculumData, numberUserCurriculumData } =
    useSelector(dashboardSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  const dataTable: Array<any> = [];
  const mergeArray = [
    ...companyUserCurriculumData,
    ...numberUserCurriculumData.map((e) => ({
      ...omit(e, ['curriculum_code']),
      official_curriculum_code: e.curriculum_code,
    })),
  ];

  mergeArray.forEach((e) => {
    dataTable.push({
      curriculum_name: e.curriculum_name,
      curriculum_code: e.official_curriculum_code,
      date: groupBy(mergeArray, (val: any) => val.createdat || val.implementation_date),
    });
  });

  const formik = useFormik<Types.CompanyUserSearchFormik>({
    initialValues: {
      indicator_selection: '',
      aggregation_method: '',
      report_type: '',
      start_date: '',
      end_date: '',
      date: '',
    },
    onSubmit: async (values) => {
      if (userInfo) {
        await Promise.all([
          dispatch(
            getDataCompaniesCurriculumn({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'official_curriculum_code',
                  search_value: ['000001560'],
                },
                {
                  id: 'createdat',
                  search_value: [values.start_date, null],
                },
                {
                  id: 'createdat',
                  search_value: [null, values.end_date],
                },
              ],
              type: indicatorSelected,
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataNumberUserCurriculum({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'curriculum_code',
                  search_value: ['000001560'],
                },
                {
                  id: 'implementation_date',
                  search_value: [values.start_date, null],
                },
                {
                  id: 'implementation_date',
                  search_value: [null, values.end_date],
                },
              ],
              type: indicatorSelected,
              page: 1,
              per_page: 0,
            })
          ),
        ]);
      }
    },
    onReset: (values, formikHelpers) => {
      formikHelpers.setFieldValue('start_date', null);
      formik.setFieldValue('date', '');
      setDate([null, null]);
    },
  });

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getDataCompaniesCurriculumn({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              // {
              //   id: 'official_curriculum_code',
              //   search_value: ['000001560'],
              // },
            ],
            type: indicatorSelected,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataNumberUserCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              // {
              //   id: 'curriculum_code',
              //   search_value: ['000001560'],
              // },
            ],
            type: indicatorSelected,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  }, [dispatch, userInfo, indicatorSelected]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <CompanyUserStyled>
      <span className="title">集計条件</span>
      <FormikProvider value={formik}>
        <Form layout="vertical">
          <div className="aggregation-condition">
            <div className="form-search">
              <Form.Item name="indicator_selection" className="item" label="指標選択">
                <SelectField
                  name="indicator_selection"
                  value={indicatorSelected}
                  onChange={(e) => setIndicatorSelected(e)}
                >
                  {OptionSelect.map(({ key, value }) => (
                    <Option key={key} value={value}>
                      {key}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="aggregation_method" className="item" label="集計方法選択">
                <SelectField name="aggregation_method">
                  <Option key={1} value={1}>
                    単月推移
                  </Option>
                  <Option key={2} value={2}>
                    累計推移
                  </Option>
                </SelectField>
              </Form.Item>
              <div className="wrap-segmented">
                <span className="label">レポートタイプ選択</span>
                <div className="segmented">
                  <div
                    className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                    onClick={() => setSegmented(0)}
                  >
                    <TableOutlined className="icon" />
                    <span>表</span>
                  </div>
                  <div
                    className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                    onClick={() => setSegmented(1)}
                  >
                    <BarChartOutlined className="icon" />
                    <span>グラフ</span>
                  </div>
                </div>
              </div>
              <Form.Item name="report_type" className="item" label="レポートタイプ選択">
                <SelectField name="report_type">
                  <Option key={1} value={1}>
                    1
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item name="date" className="item" label="カリキュラム指定">
                <RangePicker
                  name="date"
                  picker="month"
                  format={['YYYY / MM']}
                  allowEmpty={[true, true]}
                  onChange={(dates) => {
                    if (dates) {
                      formik.setFieldValue(
                        'start_date',
                        dayjs(dates[0]).format('YYYY-MM-01T[00]:[00]:[00][Z]')
                      );
                      formik.setFieldValue(
                        'end_date',
                        dayjs(dates[1]).format('YYYY-MM-31T[23]:[59]:[59][Z]')
                      );
                      setDate(dates);
                    } else {
                      formik.setFieldValue('start_date', '');
                      formik.setFieldValue('end_date', '');
                    }
                  }}
                  value={date}
                />
              </Form.Item>
              <div className="wrap-btn">
                <span className="reset-btn" onClick={() => formik.resetForm()}>
                  リセット
                </span>
                <SubmitButton className="btn-submit">表示</SubmitButton>
              </div>
            </div>
          </div>
          {segmented === 0 ? (
            <TableScreen
              companyUserData={dataTable}
              loading={loading}
              page={page}
              setPage={setPage}
            />
          ) : (
            <ColumnChart
              selected={indicatorSelected}
              start_date={formik.values.start_date}
              end_date={formik.values.end_date}
            />
          )}
        </Form>
      </FormikProvider>
    </CompanyUserStyled>
  );
};

export default CompanyUser;
