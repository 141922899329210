import { ConfigType, DataStoreType } from 'types/config';

export const config: ConfigType = {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  TEMPLATES_ID: process.env.REACT_APP_TEMPLATE_ID ?? '',
  CONFIRM_TEMPLATES_ID: process.env.REACT_APP_CONFIRM_TEMPLATES_ID ?? '',
  APP_ID: process.env.REACT_APP_ID ?? '',
  WORKSPACE: 'SKILL FAMILIAR PARTNER',
  USER_G_ID: '62fbb2c1dfb82aa2a49815b1',
};

export const USERS: DataStoreType = {
  id: '6571eccc8c988396fd7215d0',
  name: 'users',
};

export const CURRICULUMS: DataStoreType = {
  id: '6571ed028c988396fd7217bd',
  name: 'curricullums',
};

export const LEVEL_1: DataStoreType = {
  id: '6571ecfe8c988396fd721798',
  name: 'level1s',
};

export const LEVEL_2: DataStoreType = {
  id: '6571ecfa8c988396fd721773',
  name: 'level2s',
};

export const LEVEL_3: DataStoreType = {
  id: '6571ecf68c988396fd72174e',
  name: 'level3s',
};

export const LEVEL_4: DataStoreType = {
  id: '6571ecf28c988396fd721729',
  name: 'level4s',
};

export const QUESTIONS: DataStoreType = {
  id: '6571ec8b8c988396fd721394',
  name: 'questions',
};
export const ATTACH: DataStoreType = {
  id: '6571ec978c988396fd721425',
  name: 'attach',
};

export const QUESTION_ASSIGN_LEVEL: DataStoreType = {
  id: '6571ecef8c988396fd721706',
  name: 'question_assign_level',
};

export const DEPARTMENTS: DataStoreType = {
  id: '6571eceb8c988396fd7216e8',
  name: 'departments',
};

export const POSITIONS: DataStoreType = {
  id: '6571ece78c988396fd7216c9',
  name: 'positions',
};

export const USER_ASSIGN_REQUIRED_CURRICULUM: DataStoreType = {
  id: '6571ecc28c988396fd72157e',
  name: 'user_assign_required_curriculum',
};

export const ROLES: DataStoreType = {
  id: '6571ecdf8c988396fd72168a',
  name: 'roles',
};

export const PLANS: DataStoreType = {
  id: '655986c7baeaf8d6328c981a',
  name: 'plans',
};

export const AFFILIATION_LEVEL: DataStoreType = {
  id: '6571ec828c988396fd721339',
  name: 'affiliation_level',
};

export const AFFILIATION_ASSIGN_ROLE: DataStoreType = {
  id: '6571ec788c988396fd7212cc',
  name: 'affiliation_assign_role',
};

export const AFFILIATION_ASSIGN_LEVEL: DataStoreType = {
  id: '6571ec878c988396fd721360',
  name: 'affiliation_assign_level',
};

export const CURRICULUM_HIERARCHY: DataStoreType = {
  id: '6571ed0e8c988396fd721c21',
  name: 'curriculum_hierarchy_list',
};

export const UNRELATED_QUESTIONS: DataStoreType = {
  id: '6571ed0e8c988396fd721c23',
  name: 'unrelated_questions',
};

export const CURRICULUM_EXPORT: DataStoreType = {
  id: '6571ed0e8c988396fd721c25',
  name: 'curriculum_export',
};

export const REQUIRED_CURRICULUM_USERS: DataStoreType = {
  id: '6571ed0e8c988396fd721c27',
  name: 'required_curriculum_users',
};

export const QUESTION_SEARCH: DataStoreType = {
  id: '6571ed0e8c988396fd721c29',
  name: 'question_search',
};

export const AFFILIATION_HIERARCHY: DataStoreType = {
  id: '6571ed0e8c988396fd721c2b',
  name: 'affiliation_hierarchy',
};

export const EMPLOYEE_USER: DataStoreType = {
  id: '6571ed0e8c988396fd721c31',
  name: 'employee_user',
};

export const SELECT_USERS: DataStoreType = {
  id: '6571ed0e8c988396fd721c4b',
  name: 'select_users',
};

export const SELECT_QUESTION_CREATOR: DataStoreType = {
  id: '6571ed0e8c988396fd721c4f',
  name: 'select_question_creator',
};

export const SELECT_CURRICULUMS: DataStoreType = {
  id: '6571ed0e8c988396fd721c51',
  name: 'select_curriculums',
};

export const SELECT_LEVEL1S: DataStoreType = {
  id: '6571ed0e8c988396fd721c55',
  name: 'select_level1s',
};

export const SELECT_LEVEL2S: DataStoreType = {
  id: '6571ed0e8c988396fd721c57',
  name: 'select_level2s',
};

export const SELECT_LEVEL3S: DataStoreType = {
  id: '6571ed0e8c988396fd721c59',
  name: 'select_level3s',
};

export const SELECT_LEVEL4S: DataStoreType = {
  id: '6571ed0e8c988396fd721c5b',
  name: 'select_level4s',
};

export const SELECT_DEPARTMENT: DataStoreType = {
  id: '6571ed0e8c988396fd721c5d',
  name: 'select_department',
};

export const SELECT_POSITION: DataStoreType = {
  id: '6571ed0e8c988396fd721c5f',
  name: 'select_position',
};

export const SELECT_ROLE: DataStoreType = {
  id: '6571ed0e8c988396fd721c61',
  name: 'select_role',
};

export const GMO: DataStoreType = {
  id: '6571ec9c8c988396fd72143b',
  name: 'gmo',
};

export const COMPANIES: DataStoreType = {
  id: '6571ecd48c988396fd72161c',
  name: 'companies',
};

export const EMPLOYEE_USER_EXPORT: DataStoreType = {
  id: '6571ed0e8c988396fd721c31',
  name: 'employee_user_export',
};

export const REQUIRED_CURRICULUM_SKILL_CHECK: DataStoreType = {
  id: '6571ed0e8c988396fd721c39',
  name: 'required_curriculum_skill_check',
};

export const SELECT_EMPLOYEE_USER: DataStoreType = {
  id: '6571ed0e8c988396fd721c73',
  name: 'select_employee_user',
};

export const SELECT_REQUIRED_CURRICULUM: DataStoreType = {
  id: '6571ed0e8c988396fd721c63',
  name: 'select_required_curriculum',
};

export const SELECT_REQUIRED_CURRICULUM_CREATOR: DataStoreType = {
  id: '6571ed0e8c988396fd721c65',
  name: 'select_required_curriculum_creator',
};

export const SELECT_SKILL_CHECK: DataStoreType = {
  id: '6571ed0e8c988396fd721c67',
  name: 'select_skill_check',
};

export const SELECT_SKILL_CHECK_START_PERIOD: DataStoreType = {
  id: '6571ed0e8c988396fd721c69',
  name: 'select_skill_check_start_period',
};

export const SELECT_SKILL_CHECK_CATEGORY: DataStoreType = {
  id: '6571ed0e8c988396fd721c6b',
  name: 'select_skill_check_category',
};

export const SELECT_SKILL_CHECK_GROUPING_CODE: DataStoreType = {
  id: '6571ed0e8c988396fd721c6d',
  name: 'select_skill_check_grouping_code',
};

export const SELECT_SKILL_CHECK_USE_STATUS: DataStoreType = {
  id: '6571ed0e8c988396fd721c6f',
  name: 'select_skill_check_use_status',
};

export const SELECT_SKILL_CHECK_CREATOR: DataStoreType = {
  id: '6571ed0e8c988396fd721c71',
  name: 'select_skill_check_creator',
};

export const SKILL_CHECK: DataStoreType = {
  id: '6571ecb78c988396fd721523',
  name: 'skill_check',
};

export const USER_ASSIGN_SKILL_CHECK: DataStoreType = {
  id: '6571ecbe8c988396fd72155b',
  name: 'user_assign_skill_check',
};

export const EMPLOYEE_USER_DETAIL: DataStoreType = {
  id: '6571ed0e8c988396fd721c35',
  name: 'employee_user_detail',
};

export const GROUPS: DataStoreType = {
  id: '6571ecb08c988396fd7214e9',
  name: 'groups',
};

export const INTERVIEW_USER: DataStoreType = {
  id: '6571ed0e8c988396fd721c33',
  name: 'interview_user',
};

export const INTERVIEW_USER_EXPORT: DataStoreType = {
  id: '6571ed0e8c988396fd721c2f',
  name: 'interview_user_export',
};

export const SKILL_CHECK_HIERARCHY: DataStoreType = {
  id: '6571ed0e8c988396fd721c3d',
  name: 'skill_check_hierarchy',
};

export const SELECT_TYPES: DataStoreType = {
  id: '6571ec6b8c988396fd721273',
  name: 'select_types',
};

export const SKILL_CHECK_ASSIGN_QUESTION: DataStoreType = {
  id: '6571ec718c988396fd7212a4',
  name: 'skill_check_assign_question',
};

export const INTERVIEW_USER_DETAIL: DataStoreType = {
  id: '6571ed0e8c988396fd721c35',
  name: 'interview_user_detail',
};

export const SKILL_CHECK_USERS: DataStoreType = {
  id: '6571ed0e8c988396fd721c3f',
  name: 'skill_check_users',
};

export const INTERVIEW_SKILL_CHECK: DataStoreType = {
  id: '6571ed0e8c988396fd721c3b',
  name: 'interview_skill_check',
};

export const SELECT_INTERVIEW_USER: DataStoreType = {
  id: '6571ed0e8c988396fd721c75',
  name: 'select_interview_user',
};

export const SELECT_HIRE_DATE: DataStoreType = {
  id: '6571ed0e8c988396fd721c77',
  name: 'select_hire_date',
};

export const SELECT_CURRICULUM_CREATOR: DataStoreType = {
  id: '6571ed0e8c988396fd721c53',
  name: 'select_curriculum_creator',
};

export const SELECT_QUESTION: DataStoreType = {
  id: '6571ed0e8c988396fd721c4d',
  name: 'select_question',
};

export const SKILL_CHECK_IMPLE: DataStoreType = {
  id: '62e538a9337d660b0277bb6e',
  name: 'skill_check_imple',
};

export const SKILL_CHECK_TRANS: DataStoreType = {
  id: '6571ecab8c988396fd7214be',
  name: 'skill_check_trans',
};

export const SKILL_CHECK_QUESTION: DataStoreType = {
  id: '6571ed0e8c988396fd721c41',
  name: 'skill_check_question',
};

export const OFFICAL_CURRICULUM_PUBLISHING_SETTINGS: DataStoreType = {
  id: '6571ed0e8c988396fd721c7d',
  name: 'Official_Curriculum_Publishing_Settings',
};

export const ADMIN_DETAILS: DataStoreType = {
  id: '6571ed0e8c988396fd721c79',
  name: 'admin_details',
};

export const RELEASE_NOTE_MANAGEMENT: DataStoreType = {
  id: '6571ed0e8c988396fd721c7f',
  name: 'release_note_management',
};

export const CURRICULUM_PUBLISHED_HISTORY: DataStoreType = {
  id: '6571ec4c8c988396fd721134',
  name: 'curriculum_published_history',
};

export const RELEASE_NOTE_SECTION: DataStoreType = {
  id: '6571ec518c988396fd72115d',
  name: 'release_note_section',
};

export const RELEASE_NOTE: DataStoreType = {
  id: '6571ec5c8c988396fd7211d8',
  name: 'release_note',
};

export const RELEASE_NOTE_DETAIL: DataStoreType = {
  id: '6571ed0e8c988396fd721c7b',
  name: 'release_note_detail',
};

export const COMPANIES_USING_CURRICULUM = {
  id: '6571ed0e8c988396fd721c83',
  name: 'companies_using_curriculum',
};

export const NUMBER_USERS_OF_CURRICULUM = {
  id: '6571ed0e8c988396fd721c85',
  name: 'number_users_of_curriculum',
};

export const NUMBER_NEW_USERS_OF_CURRICULUM = {
  id: '6571ed0e8c988396fd721c89',
  name: 'number_new_users_of_curriculum',
};

export const COMPANIES_ACTIVE_USING_CURRICULUM = {
  id: '6571ed0e8c988396fd721c8d',
  name: 'companies_active_using_curriculum',
};

export const NUMBER_ACTIVE_USERS_OF_CURRICULUM = {
  id: '6571ed0e8c988396fd721c8b',
  name: 'number_active_users_of_curriculum',
};

export const COMPANIES_DELETE_USING_CURRICULUM = {
  id: '6571ed0e8c988396fd721c8f',
  name: 'companies_delete_using_curriculum',
};

export const NUMBER_DELETE_USERS_OF_CURRICULUM = {
  id: '6571ed0e8c988396fd721c91',
  name: 'number_delete_users_of_curriculum',
};

export const OFFICIAL_CURRICULUM_INFOMATION = {
  id: '6571ed0e8c988396fd721c93',
  name: 'official_curriculum_infomation',
};

export const OFFICIAL_CURRICULUM_NUM_OF_COMPANIES_RANKING = {
  id: '6571ed0e8c988396fd721c97',
  name: 'official_curriculum_num_of_companies_ranking',
};

export const OFFICIAL_CURRICULUM_NUM_OF_USER_RANKING = {
  id: '6571ed0e8c988396fd721c95',
  name: 'official_curriculum_num_of_user_ranking',
};
