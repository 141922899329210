import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  createFileAttach,
  deleteFileAttach,
  getCompany,
  getDataAllQuestion,
  getDataQuestion,
  getOptionLimit,
  getSelectCurriculum,
  getSelectCurriculumCreator,
  getSelectQuestion,
  getSelectTypes,
} from './thunk';

export type InitialState = {
  total: number;
  dataQuestion: Array<Types.QuestionSearchType>;
  curriculumNameSelect: Array<Types.CurriculumOptionType>;
  curriculumTypeSelect: Array<Types.CreateEditSkillCheckSelectType>;
  curriculumCreatorSelect: Array<Types.CurriculumCreatorSelect>;
  questionSelect: Array<Types.QuestionSelect>;
  scoreSelect: Array<Types.CreateEditSkillCheckSelectType>;
  dataAllQuestion: Array<Types.Question.ResponseType>;

  loading: boolean;
  timeLimit: Types.TimeLimitType[];
  defaultTimeLimit: number;
};

const initialState: InitialState = {
  total: 0,
  dataQuestion: [],
  loading: false,
  curriculumCreatorSelect: [],
  curriculumNameSelect: [],
  curriculumTypeSelect: [],
  questionSelect: [],
  dataAllQuestion: [],
  scoreSelect: [],
  timeLimit: [],
  defaultTimeLimit: 0,
};

export const questionMasterSlice = createSlice({
  name: 'questionMaster-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(getDataQuestion.pending, startLoading)
      .addCase(getSelectCurriculum.pending, startLoading)
      .addCase(getSelectCurriculumCreator.pending, startLoading)
      .addCase(getSelectQuestion.pending, startLoading)
      .addCase(getSelectTypes.pending, startLoading)
      .addCase(getDataAllQuestion.pending, startLoading)
      .addCase(createFileAttach.pending, startLoading)
      .addCase(deleteFileAttach.pending, startLoading)
      .addCase(getCompany.pending, startLoading);

    builder.addCase(getDataQuestion.fulfilled, (state, action) => {
      state.dataQuestion = action.payload.report_results;
      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getDataAllQuestion.fulfilled, (state, action) => {
      state.dataAllQuestion = action.payload.items;
      stopLoading(state);
    });
    builder.addCase(getSelectCurriculum.fulfilled, (state, action) => {
      state.curriculumNameSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectCurriculumCreator.fulfilled, (state, action) => {
      state.curriculumCreatorSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectQuestion.fulfilled, (state, action) => {
      state.questionSelect = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      action.payload.items.forEach((item) => {
        if (item.type === 'required_curriculum') {
          state.curriculumTypeSelect.push(item);
        } else if (item.type === 'score') {
          state.scoreSelect.push(item);
        }
      });
      stopLoading(state);
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.defaultTimeLimit = Number(action.payload.items[0].time_limit) || 0;
    });

    builder.addCase(getOptionLimit.fulfilled, (state, action) => {
      state.timeLimit =
        action.payload.fields['6571ec8b8c988396fd72139f']?.options
          ?.filter((o: Types.TimeLimitType) => o.enabled)
          .sort((a: Types.TimeLimitType, b: Types.TimeLimitType) => a.sort_id - b.sort_id) || [];
      stopLoading(state);
    });
    builder
      .addCase(createFileAttach.fulfilled, stopLoading)
      .addCase(deleteFileAttach.fulfilled, stopLoading);

    builder
      .addCase(getDataQuestion.rejected, stopLoading)
      .addCase(getSelectCurriculum.rejected, stopLoading)
      .addCase(getSelectCurriculumCreator.rejected, stopLoading)
      .addCase(getSelectQuestion.rejected, stopLoading)
      .addCase(getSelectTypes.rejected, stopLoading)
      .addCase(getDataAllQuestion.rejected, stopLoading)
      .addCase(createFileAttach.rejected, stopLoading)
      .addCase(deleteFileAttach.rejected, stopLoading)
      .addCase(getCompany.rejected, stopLoading);
  },
});

// export const {} = questionMasterSlice.actions;

export default questionMasterSlice.reducer;
