import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';

import UnrelatedQuestionSearch from './UnrelatedQuestionSearch';
import { searchQuestionCurriculumSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import { loadingRef } from 'components/Loading';
import QuestionSearch from './QuestionSearch';
import { useAppDispatch } from 'hooks';
import StyledSearch from './styles';
import { resetData } from './slice';
import {
  getQuestionSearch,
  getCurriculumLevelOption,
  getCreatorQuestionOption,
  getCurriculumQuestionOption,
} from './thunk';

const { TabPane } = Tabs;

type Props = {
  pageYOffset: number;
  setOpenModalCreateQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
      onSubmit?: () => void;
    }>
  >;
};

const SearchCurriculum: React.FC<Props> = ({ pageYOffset, setOpenModalCreateQuestion }) => {
  const [tabKey, setTabKey] = useState<string>('1');
  const [selectedQuestion, setSelectedQuestion] = useState<
    Array<{
      i_id: string;
      name: string;
      code: string;
    }>
  >([]);
  const [showQuestionSearch, setShowQuestionSearch] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const { loading } = useSelector(searchQuestionCurriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const handleSelectTabKey = (activeKey: string) => {
    if (selectedQuestion.length) {
      setSelectedQuestion([]);
    }
    setShowQuestionSearch(activeKey === '1');
    setTabKey(activeKey);
  };

  useEffect(() => {
    if (userInfo?.company_id) {
      Promise.all([
        dispatch(getCreatorQuestionOption()),
        dispatch(
          getCurriculumQuestionOption({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 1,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 2,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 3,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 4,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getQuestionSearch({
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        ),
      ]);
    }
    return () => {
      setTabKey('1');
      setSelectedQuestion([]);
      dispatch(resetData());
    };
  }, [dispatch, userInfo?.company_id]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <StyledSearch tabKey={tabKey} pageYOffset={pageYOffset}>
      <Tabs type="card" onChange={handleSelectTabKey}>
        <TabPane tab="条件検索" key="1" className="card-tab-pane">
          {showQuestionSearch && (
            <QuestionSearch
              setOpenModalCreateQuestion={setOpenModalCreateQuestion}
              setSelectedQuestion={setSelectedQuestion}
              selectedQuestion={selectedQuestion}
            />
          )}
        </TabPane>
        <TabPane tab="未設定設問一覧" key="2" className="card-tab-pane">
          {!showQuestionSearch && (
            <UnrelatedQuestionSearch
              setOpenModalCreateQuestion={setOpenModalCreateQuestion}
              setSelectedQuestion={setSelectedQuestion}
              selectedQuestion={selectedQuestion}
            />
          )}
        </TabPane>
      </Tabs>
    </StyledSearch>
  );
};

export default SearchCurriculum;
