import styled from 'styled-components';

type Props = {
  isEmptyData: boolean;
};
export default styled('div')<Props>`
  @media (max-width: 1440px) {
    .form-search {
      justify-content: flex-start !important;
      .item {
        width: 234px !important;
        margin-right: 12px !important;
        .ant-form-item-label > label {
          font-size: 12px;
        }
      }
      .btn-search {
        margin-left: 28px !important;
        width: 100px;
      }
    }
    .wrap-button {
      margin: 38px 41px 0 41px !important;
      .btn {
        width: 160px;
      }
    }
    .table {
      margin-top: 30px !important;
      .ant-table-thead {
        .ant-table-cell {
          height: 44px;
          padding: 5px;
          font-size: 12px;
          .ant-checkbox {
            margin-top: 12px;
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          height: 44px;
          padding: 5px;
        }
        .curriculum_code {
          font-size: 11px !important;
          color: #999 !important;
        }
        .button-return {
          width: 60px !important;
          padding: 2px 0 !important;
          font-size: 12px;
          span {
            margin: 4px;
          }
        }
      }
    }
    .wrap-bottom {
      .btn {
        width: 180px !important;
      }
      .btn-cancle {
        width: 140px !important;
      }
    }
  }
  .container {
    padding: 0 40px;
  }
  .icon-status {
    margin-right: 8px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .description {
    text-align: center;
    padding-top: 30px;
  }
  .line {
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    margin-top: 32px;
  }
  .form-search {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .item {
      width: 19.5%;
      margin: 0;
    }
    .button-search {
      display: flex;
      align-items: center;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-left: 12px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      margin-left: 88px;
    }
    .icon-search {
      font-size: 18px;
      margin-right: 12px;
    }
    .ant-select-selector {
      border: 1px solid #cccccc;
      border-radius: 3px;
    }
  }
  .wrap-button {
    position: absolute;
    right: 0;
    margin: 12px 41px;
    z-index: 9;
    .btn {
      padding: 0 30px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      margin-left: 10px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      .icon {
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }
  .table {
    margin-top: ${(props) => (props.isEmptyData ? 64 : 0)}px;
    padding-bottom: 60px;
    .ant-table .ant-table-container::before {
      content: 'ALL';
      margin-left: 7px;
      margin-top: 4px;
      font-size: 11px;
    }
    .wrap-status {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      height: 20px;
      border-radius: 2px;
    }
    .status {
      margin-left: 8px;
    }
    .button-return {
      background: #08a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      padding: 4px 13px;
      border-radius: 6px;
      span {
        color: #ffffff;
      }
    }
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      width: 0;
    }
  }
  .wrap-bottom {
    display: flex;
    justify-content: space-between;
    height: 95px;
    width: 100%;
    transition: width 0.3s;
    background: #ffffff;
    bottom: 0;
    right: 0;
    align-items: center;
    padding: 0 42px;
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.06);
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .wrap-bottom-button {
      display: flex;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
    .btn-outline {
      background-color: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #f6ac00;
      margin-left: 8px;
    }
    .btn-cancle {
      padding: 20px 50px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border: 1px solid #d9d9d9;
      display: inline-flex;
      align-items: center;
      border-radius: 5px;
      color: #777777;
    }
  }
`;
