import { createAsyncThunk } from '@reduxjs/toolkit';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { services } from 'services';
import * as Types from 'types';
import {
  ATTACH,
  COMPANIES,
  QUESTIONS,
  QUESTION_SEARCH,
  SELECT_CURRICULUMS,
  SELECT_CURRICULUM_CREATOR,
  SELECT_QUESTION,
  SELECT_TYPES,
} from 'configs';

export const getDataQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSearchType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(QUESTION_SEARCH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumOptionType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_CURRICULUMS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.CreateEditSkillCheckSelectType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(SELECT_TYPES.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculumCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumCreatorSelect>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectCurriculumCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_CURRICULUM_CREATOR.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSelect>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_QUESTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createImages', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileQuestion = createAsyncThunk<
  any,
  Types.DeleteFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFileQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.AttachFileType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getAttachQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAttachQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteAttachQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Question.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getQuestionDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getCompany', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search(COMPANIES.id, req);

    dispatch(stopLoading());
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOptionLimit = createAsyncThunk<
  Types.GetDatastoreFieldsResponseType,
  undefined,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getOptionLimit', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.fields({ dataStoreId: QUESTIONS.name });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateTimeLimitCompany = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/updateTimeLimitCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Question.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/editQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAllQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Question.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataAllQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
