import React, { useState } from 'react';
import { Input } from 'antd';

import Modal from 'components/Modal';
import { ArrowRight } from 'assets';
import * as Types from 'types';
import Styled from './styles';

interface Props {
  status: string;
  title: string;
  from: string;
  to: string | React.ReactNode;
  selected: Array<Types.OfficialPublicRes>;
  noteText?: React.ReactNode;
  description: React.ReactNode;
  visible: boolean;
  onSubmit?: (arg: string) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateCurriculum: React.FC<Props> = ({
  status,
  visible,
  title,
  setVisible,
  onSubmit,
  description,
  noteText,
  from,
  to,
  selected,
}) => {
  const [version, setVersion] = useState<string>('');
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    await handleToggleModal();
    onSubmit && (await onSubmit(version));
  };
  return (
    <Modal
      visible={visible}
      width={740}
      okButton={{
        text: '公開する',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
      onCancel={handleToggleModal}
      title={title}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <Styled>
        <div className="wrap-image">
          <div className="left-side">
            <span
              className={`name-status ${
                selected[0]?.publish === 0
                  ? 'violet'
                  : selected[0]?.publish === 2
                  ? 'green'
                  : selected[0]?.publish === 1
                  ? 'blue'
                  : ''
              }`}
            >
              {selected[0]?.publish === 0
                ? '編集中'
                : selected[0]?.publish === 2
                ? '公開中'
                : selected[0]?.publish === 3
                ? '公開停止中'
                : selected[0]?.publish === 1
                ? '未公開'
                : ''}
            </span>
            <img className="icon-update" src={from} alt="" />
          </div>
          <img className="arrow-right" src={ArrowRight} alt="" />
          <div className="right-side">
            {typeof to === 'string' ? <img className="icon-update large" src={to} alt="" /> : to}
            <span
              className={`name-status large-text ${
                status === 'publish'
                  ? 'green'
                  : status === 'editing'
                  ? 'violet'
                  : status === 'publishWarning'
                  ? 'grey'
                  : ''
              }`}
            >
              {status === 'editing'
                ? '編集中'
                : status === 'publish'
                ? '公開中'
                : status === 'publishWarning'
                ? '公開停止中'
                : status === 'unPublished'
                ? '未公開'
                : ''}
            </span>
          </div>
        </div>
        <p className="description">{description}</p>
        {noteText && <p className="note">{noteText}</p>}
        <div className="wrap-input">
          <div
            style={{
              width: '65%',
            }}
          >
            <div className="wrap-label">
              <label className="label">
                アップデート公開予定情報 <span className="required"> *</span>
              </label>
              <span className="version-label">
                前回のバージョン：
                <span className="version">{selected && selected[0]?.version}</span>
              </span>
            </div>
            <Input
              className="input"
              name="version"
              placeholder="例）1.0001"
              onChange={(e) => setVersion(e.target.value)}
            />
          </div>
        </div>
      </Styled>
    </Modal>
  );
};

export default CreateCurriculum;
