import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from '@ant-design/plots';

import { getDataCompaniesCurriculumn, getDataNumberUserCurriculum } from '../../thunk';
import { authSelector } from 'containers/Auth/selectors';
import { dashboardSelector } from '../../selectors';
import { loadingRef } from 'components/Loading';
import ColumnChartStyled from './styles';

type Props = {
  selected: string;
  start_date: string;
  end_date: string;
};

const ColumnChart: React.FC<Props> = ({ start_date, end_date, selected }) => {
  const { loading, companyUserCurriculumChartData, numberUserCurriculumChartData } =
    useSelector(dashboardSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getDataCompaniesCurriculumn({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'official_curriculum_code',
                search_value: ['000001560'],
              },
              {
                id: 'createdat',
                search_value: [start_date, null],
              },
              {
                id: 'createdat',
                search_value: [null, end_date],
              },
            ],
            type: selected,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataNumberUserCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'implementation_date',
                search_value: [start_date, null],
              },
              {
                id: 'implementation_date',
                search_value: [null, end_date],
              },
            ],
            type: selected,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  }, [dispatch, userInfo, start_date, end_date, selected]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <ColumnChartStyled>
      <div className="column-chart">
        <div className="title">企業情報</div>
        <Column
          xField="date"
          yField="value"
          data={companyUserCurriculumChartData}
          width={300}
          height={500}
          style={{
            width: '100%',
          }}
          yAxis={{
            grid: {
              line: {
                style: {
                  lineDash: [1, 3],
                },
              },
            },
            tickCount: 10,
          }}
          xAxis={{
            tickLine: null,
          }}
          columnStyle={{
            fill: '#519EE3',
          }}
          maxColumnWidth={30}
        />
        <div className="bottom-text">
          カリキュラム利用企業数推移【累計】
          <br />
          ALL
        </div>
      </div>
      <div className="column-chart">
        <span className="title">ユーザー情報</span>
        <Column
          xField="date"
          yField="value"
          data={numberUserCurriculumChartData}
          width={300}
          height={500}
          style={{
            width: '100%',
          }}
          columnStyle={{
            fill: '#F59887',
          }}
          yAxis={{
            grid: {
              line: {
                style: {
                  lineDash: [1, 3],
                },
              },
            },
            tickCount: 10,
            tickLine: null,
          }}
          xAxis={{
            tickLine: null,
          }}
          maxColumnWidth={30}
        />
        <div className="bottom-text">
          カリキュラム利用ユーザー数推移【累計】
          <br />
          ALL
        </div>
      </div>
    </ColumnChartStyled>
  );
};

export default ColumnChart;
