import { createAsyncThunk } from '@reduxjs/toolkit';

import { services, curriculumService } from 'services';
import * as Types from 'types';
import {
  QUESTION_SEARCH,
  SELECT_CURRICULUMS,
  SELECT_QUESTION_CREATOR,
  UNRELATED_QUESTIONS,
} from 'configs';

export const getQuestionSearch = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSearchType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getQuestionSearch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(QUESTION_SEARCH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUnrelatedQuestions = createAsyncThunk<
  Types.ReportsItemResponseType<Types.UnrelatedQuestionsType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getUnrelatedQuestions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(UNRELATED_QUESTIONS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCreatorQuestionOption = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CreatorOptionType>,
  undefined,
  Types.requestError
>('Curriculum/Search/getCreatorQuestionOption', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_QUESTION_CREATOR.name, {
      page: 1,
      per_page: 0,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumQuestionOption = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumOptionType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getCurriculumQuestionOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(SELECT_CURRICULUMS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumLevelOption = createAsyncThunk<
  { level: number; data: Types.ReportsItemResponseType<Types.CurriculumOptionType> },
  { level: number; company_id: string },
  Types.requestError
>('Curriculum/Search/getCurriculumLevelOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.findAllCurriculumLevelOption(req.level, {
      page: 1,
      per_page: 0,
      conditions: [
        {
          id: 'company_id',
          search_value: [req.company_id],
        },
      ],
    });

    return { level: req.level, data };
  } catch (error) {
    return rejectWithValue(error);
  }
});
