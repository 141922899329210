import axios from 'axios';
import { config } from 'configs';
import { mockAdapter } from './mock-adapter';

export const instance = axios.create({
  baseURL: config.API_URL,
});

const injectStore = (store: any) => {
  instance.interceptors.request.use(
    (defaultConfig) => {
      const accessToken = localStorage.getItem('sk_access_token');
      defaultConfig.headers = {
        ...defaultConfig.headers,
        Authorization: `Bearer ${accessToken}`,
      };
      return defaultConfig;
    },
    (error) => {
      delete axios.defaults.headers.common['Authorization'];
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch();
      }
      return Promise.reject(error);
    }
  );
};
export default injectStore;
export const instanceMock = axios.create({
  baseURL: '',
});
mockAdapter(instanceMock);
