import React, { useCallback, useEffect, useState } from 'react';
import { Form, Select, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { Key } from 'antd/lib/table/interface';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import { uniqBy } from 'lodash';
import dayjs from 'dayjs';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  ContainerOutlined,
  FormOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { HEADER_EMPLOYEE_CSV } from 'constant/header.export.constant';
import { settingSelector } from 'containers/AppSettings/selectors';
import ArchiveCompleted from 'components/Modal/ArchiveCompleted';
import ManageRelease from './Modal/ManageRelease/ManageRelease';
import ConfirmArchive from 'components/Modal/ConfirmArchive';
import { getStatus } from '../Dashboard/OfficialCurriculum';
import { authSelector } from 'containers/Auth/selectors';
import CreateCurriculum from './Modal/CreateCurriculum';
import { useAppDispatch, usePermission } from 'hooks';
import { publicManagerSelector } from './selectors';
import UploadCSV from 'components/Modal/UploadCSV';
import { Header, SelectField } from 'components';
import UpdateStatus from './Modal/UpdateStatus';
import { loadingRef } from 'components/Loading';
import ArchiveList from './Modal/ArchiveList';
import Info from 'components/Modal/Info';
import Changed from './Modal/Changed';
import PublicStyled from './styles';
import * as Types from 'types';
import {
  createCurriculumPublishedHistory,
  getOfficialPublish,
  updateCurriculum,
  updateCurriculumPublishedHistory,
} from './thunk';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import ActionErrorModal from 'components/Modal/ActionError';
import { pdf } from '@react-pdf/renderer';
import PDFCurriculumDocument from 'pages/CurriculumManagement/Curriculum/PDFCurriculumDocument';

const { Option } = Select;

const PER_PAGE = 10;

const PublicManagement = () => {
  const [archiveItem, setArchiveItem] = useState<Types.OfficialPublicRes>();
  const [status, setStatus] = useState<'publish' | 'editing' | 'publishWarning'>('editing');
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Array<Types.OfficialPublicRes>>([]);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleChanged, setVisibleChanged] = useState<boolean>(false);
  const [visibleArchive, setVisibleArchive] = useState<boolean>(false);
  const [visibleRelease, setVisibleRelease] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [visibleList, setVisibleList] = useState<boolean>(false);
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const { loading, officialPublishData, total } = useSelector(publicManagerSelector);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { permissionNumber } = usePermission();

  const dispatch = useAppDispatch();
  const formik = useFormik<Types.OfficialPublishSearchFormik>({
    initialValues: {
      publish: '',
      curriculum_name: '',
      curriculum_code: '',
      createdby: '',
    },
    onSubmit: (values) => {
      if (userInfo) {
        const conditions: Types.ConditionsType[] = [];
        Object.keys(values).forEach((key) => {
          if (values[key as keyof typeof values]) {
            conditions.push({
              id: key,
              search_value: [values[key as keyof typeof values]],
            });
          }
          dispatch(
            getOfficialPublish({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'archive_flg',
                  search_value: ['0'],
                },
                ...conditions,
              ],
              page: 1,
              per_page: 0,
              include_item_ref: true,
            })
          );
        });
      }
    },
    onReset: () => {
      if (userInfo) {
        dispatch(
          getOfficialPublish({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'archive_flg',
                search_value: ['0'],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
      }
    },
  });

  const handleCreateCurriculumPublished = async (version: string) => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          createCurriculumPublishedHistory({
            item: {
              company_id: userInfo.company_id,
              curriculum_code: selectedRow[0]?.curriculum_code,
              publish_start_date: selectedRow[0].publish_start_date,
              version,
            },
            realtime_auto_link: true,
          })
        ),
        dispatch(
          updateCurriculum({
            id: selectedRow[0]?.i_id,
            data: {
              item: {
                company_id: userInfo.company_id,
                publish: 2,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (updateCurriculum.fulfilled.match(resultAction[1])) {
        await dispatch(
          getOfficialPublish({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'archive_flg',
                search_value: ['0'],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
          })
        );
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const handleUpdateCurriculumPublishedHistory = async () => {
    if (userInfo) {
      const resultAction = await Promise.all([
        dispatch(
          updateCurriculumPublishedHistory({
            id: selectedRow[0]?.item_ref.history_id.i_id,
            data: {
              item: {
                company_id: userInfo.company_id,
                curriculum_code: selectedRow[0]?.curriculum_code,
                publish_end_date: selectedRow[0]?.publish_end_date,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
        dispatch(
          updateCurriculum({
            id: selectedRow[0]?.i_id,
            data: {
              item: {
                company_id: userInfo.company_id,
                publish: 3,
              },
              is_force_update: true,
              realtime_auto_link: true,
            },
          })
        ),
      ]);
      if (updateCurriculum.fulfilled.match(resultAction[1])) {
        await dispatch(
          getOfficialPublish({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'archive_flg',
                search_value: ['0'],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
          })
        );
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };

  const handleUpdateUnpublish = async () => {
    if (userInfo) {
      const resultAction = await dispatch(
        updateCurriculum({
          id: selectedRow[0]?.i_id,
          data: {
            item: {
              company_id: userInfo.company_id,
              publish: 0,
            },
            is_force_update: true,
            realtime_auto_link: true,
          },
        })
      );
      if (updateCurriculum.fulfilled.match(resultAction)) {
        await dispatch(
          getOfficialPublish({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'archive_flg',
                search_value: ['0'],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
          })
        );
        await setSelectedRow([]);
        setVisibleChanged(true);
      }
    }
  };
  const handleButtonExport = () => {
    setVisiblePopupConfirmExportFile(true);
    // if (!selectedRow.length) {
    //   setVisiblePopupError(true);
    // } else {
    //   setVisiblePopupConfirmExportFile(true);
    // }
  };

  const column: ColumnsType<Types.OfficialPublicRes> = [
    {
      title: 'ID',
      dataIndex: 'curriculum_code',
      key: 'curriculum_code',
      width: '5%',
      className: 'curriculum_code',
      align: 'left',
    },
    {
      title: 'OFFICIALカリキュラム名',
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      align: 'left',
    },
    {
      title: '公開ステータス',
      dataIndex: 'publish',
      key: 'publish',
      width: '10%',
      align: 'left',
      render: (record: number) => getStatus(record),
    },
    {
      title: '前回公開日時',
      dataIndex: 'publish_start_date',
      key: 'publish_start_date',
      width: '13%',
      render: (record) => dayjs(record).locale('ja').format('YYYY/MM/DD (dddd)  HH:mm'),
    },
    {
      title: '最終更新日時',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: '13%',
      render: (record) => dayjs(record).locale('ja').format('YYYY/MM/DD (dddd)  HH:mm'),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      width: '10%',
    },
    {
      title: '閲覧・編集',
      dataIndex: '',
      key: '',
      width: '8%',
      render: () => <FormOutlined className="icon" onClick={() => setVisibleEdit(true)} />,
    },
    {
      title: 'アーカイブ',
      dataIndex: 'publish',
      width: '8%',
      render: (record, item) =>
        record === 3 && (
          <ContainerOutlined
            onClick={() => {
              setArchiveItem(item);
              setVisibleArchive(true);
            }}
            className="icon"
          />
        ),
    },
  ];
  const handleUpdateArchiveCurriculumMaster = async () => {
    if (!archiveItem) return;

    await dispatch(
      updateCurriculum({
        id: archiveItem.i_id,
        data: {
          item: {
            archive_flg: 1,
          },
          is_force_update: true,
          realtime_auto_link: true,
        },
      })
    );
    fetchOfficialPublish();
  };
  const fetchOfficialPublish = useCallback(() => {
    if (userInfo) {
      dispatch(
        getOfficialPublish({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'archive_flg',
              search_value: ['0'],
            },
          ],
          page: page,
          per_page: 0,
          omit_total_items: false,
          include_item_ref: true,
        })
      );
    }
  }, [userInfo, page, dispatch]);
  const rowSelection = {
    selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
    onChange: (_key: Key[], row: Array<Types.OfficialPublicRes>) => setSelectedRow(row),
  };

  const handleExportCSV = async (value: string) => {
    if (value === 'pdf') {
      if (!selectedRow || selectedRow.length === 0) {
        const blob = await pdf(
          <PDFCurriculumDocument listCurriculum={officialPublishData} total={total} />
        ).toBlob();
        saveAs(blob, 'curriculum_table.pdf');
      } else {
        const blob = await pdf(
          <PDFCurriculumDocument listCurriculum={selectedRow} total={total} />
        ).toBlob();
        saveAs(blob, 'curriculum_table.pdf');
      }
    } else {
      try {
        if (!selectedRow || selectedRow.length === 0) {
          return;
        }
        const csvData = selectedRow.map((curriculum) => ({
          ID: curriculum.company_id,
          OFFICIALカリキュラム: curriculum.curriculum_name,
          公開ステータス: curriculum.publish,
          前回公開日時: curriculum.publish_end_date,
          最終更新日時: curriculum.updatedat,
          作成者: curriculum.author,
        }));
        const csvContent =
          '\uFEFF' +
          'ID,OFFICIALカリキュラム,公開ステータス,前回公開日時,最終更新日時,作成者\n' +
          csvData.map((curriculum) => Object.values(curriculum).join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'カリキュラム.csv');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('error when export csv:', error);
      }
    }
    setVisiblePopupConfirmExportFile(false);
  };

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getOfficialPublish({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'archive_flg',
                search_value: ['0'],
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        ),
      ]);
    }
  }, [dispatch, userInfo]);

  useEffect(fetchOfficialPublish, [fetchOfficialPublish]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title="OFFICIALカリキュラム　公開管理" />
      <PublicStyled collapsedMenu={collapsedMenu} isEmptyData={!officialPublishData.length}>
        <p className="text-note">作成したOFFICIALカリキュラムの公開管理を行う画面です。</p>
        <div className="line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item name="publish" label="公開ステータス" className="item">
                <SelectField name="publish">
                  {uniqBy(officialPublishData, 'publish').map(({ publish }) => (
                    <Option value={`${publish}`} key={publish}>
                      {publish === 0
                        ? '編集中'
                        : publish === 1
                        ? '未公開'
                        : publish === 2
                        ? '公開中'
                        : '公開停止中'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="curriculum_code" label="ID" className="item">
                <SelectField name="curriculum_code">
                  {officialPublishData.map(({ curriculum_code }) => (
                    <Option value={curriculum_code} key={curriculum_code}>
                      {curriculum_code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="curriculum_name" label="OFFICIALカリキュラム名" className="item">
                <SelectField name="curriculum_name">
                  {officialPublishData.map(({ curriculum_name }) => (
                    <Option value={curriculum_name} key={curriculum_name}>
                      {curriculum_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item name="createdby" label="作成者" className="item">
                <SelectField name="createdby" allowClear>
                  {officialPublishData.map(
                    ({ createdby }) =>
                      createdby && (
                        <Option value={createdby} key={createdby}>
                          {createdby}
                        </Option>
                      )
                  )}
                </SelectField>
              </Form.Item>

              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
            <div className="wrap-button">
              <Button
                className="btn btn-active"
                onClick={handleButtonExport}
                icon={<CloudDownloadOutlined className="icon" />}
              >
                エクスポート
              </Button>

              <Button className="btn btn-outline" onClick={() => setVisibleList(true)}>
                アーカイブリスト
              </Button>
            </div>
            <Table
              rowKey="i_id"
              columns={column}
              className="table"
              dataSource={officialPublishData}
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              pagination={{
                pageSize: 20,
                position: ['topCenter'],
                total: total,
                showSizeChanger: false,
                onChange: setPage,
                showTotal: () => (
                  <span className="text-count">
                    {page * PER_PAGE > officialPublishData.length
                      ? officialPublishData.length
                      : page * PER_PAGE}
                    件表示 /{officialPublishData.length} 名
                  </span>
                ),
              }}
            />
          </Form>
        </FormikProvider>
        <div className="wrap-bottom">
          <div className="text-label">
            選択したOFFICIALカリキュラムを処理：
            <Button
              className="btn btn-active"
              onClick={() => {
                selectedRow.length > 0 && setVisible(true);
                setStatus('publish');
              }}
            >
              公開する
            </Button>
            <Button
              className="btn btn-active"
              onClick={() => {
                selectedRow.length > 0 && setVisibleUpdate(true);
                setStatus('editing');
              }}
            >
              編集中にする
            </Button>
            <Button
              className="btn btn-active"
              onClick={() => {
                selectedRow.length > 0 && setVisibleUpdate(true);
                setStatus('publishWarning');
              }}
            >
              公開停止にする
            </Button>
            <Button
              className="btn btn-outline"
              onClick={() => selectedRow.length > 0 && setVisibleRelease(true)}
            >
              リリースノートを管理
            </Button>
          </div>
        </div>
        <UploadCSV
          onSubmit={() => {}}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="カリキュラムが選択されていません"
          description={
            <>
              対象カリキュラムを選択してください
              <br />
              そしてさらに試みる。
            </>
          }
        />
        <ManageRelease
          permissionNumber={permissionNumber}
          visible={visibleRelease}
          selectedRows={selectedRow}
          setVisible={setVisibleRelease}
        />
        <CreateCurriculum
          title="OFFICIALカリキュラムを公開します"
          from={
            selectedRow[0]?.publish === 0
              ? WhiteEditing
              : selectedRow[0]?.publish === 2
              ? TickComplete
              : selectedRow[0]?.publish === 1
              ? UnPublished
              : StopPublic
          }
          to={
            status === 'publish'
              ? TickComplete
              : status === 'editing'
              ? WhiteEditing
              : status === 'publishWarning'
              ? StopPublic
              : ''
          }
          status={status}
          description={getDescription(status)}
          visible={visible}
          setVisible={setVisible}
          selected={selectedRow}
          onSubmit={handleCreateCurriculumPublished}
        />
        <UpdateStatus
          status={status}
          publish={selectedRow[0]?.publish}
          title="公開中のOFFICIALカリキュラムを公開停止します"
          from={
            selectedRow[0]?.publish === 0
              ? WhiteEditing
              : selectedRow[0]?.publish === 2
              ? TickComplete
              : selectedRow[0]?.publish === 1
              ? UnPublished
              : StopPublic
          }
          to={
            status === 'publish'
              ? TickComplete
              : status === 'editing'
              ? WhiteEditing
              : status === 'publishWarning'
              ? StopPublic
              : ''
          }
          description={getDescription(status)}
          visible={visibleUpdate}
          setVisible={setVisibleUpdate}
          noteText={getNoteText(status)}
          onSubmit={
            status === 'editing' ? handleUpdateUnpublish : handleUpdateCurriculumPublishedHistory
          }
        />
        <ConfirmArchive
          setVisibleComplete={setVisibleComplete}
          visible={visibleArchive}
          setVisible={setVisibleArchive}
          onSubmit={handleUpdateArchiveCurriculumMaster}
        />
        <ArchiveCompleted visible={visibleComplete} setVisible={setVisibleComplete} />
        <ArchiveList visible={visibleList} setVisible={setVisibleList} />
        <Info
          visible={visibleEdit}
          setVisible={setVisibleEdit}
          title="OFFICAILカリキュラム閲覧・編集"
          content="OFFICIALカリキュラム閲覧・編集のためにカリキュラムツリーに移動します。"
        />
        <Changed visible={visibleChanged} setVisible={setVisibleChanged} status={status} />
      </PublicStyled>
    </>
  );
};

export default PublicManagement;

export const getNoteText = (status: string) => {
  switch (status) {
    case 'publish':
      return (
        <span>
          ※編集中は利用者に影響はありません。
          <br />
          再度公開する際にはリリースノートの公開と利用者側でアップデートが必要になります。
        </span>
      );
    case 'publishWarning':
      return (
        <span>
          ※すでに利用中の一般ユーザー様はそのまま利用継続します。
          <br />
          利用中の一般ユーザー様に利用を停止して頂く場合は、リリースノートにて通知をお願いいたします。
        </span>
      );
    default:
      return '';
  }
};

const getDescription = (status: string) => {
  switch (status) {
    case 'editing':
      return (
        <span>
          OFFICIALカリキュラムを公開すると、SKILL FAMILIARで <br />
          一般のユーザー様が利用できる状態になります。
        </span>
      );
    case 'publish':
      return (
        <span>
          OFFICIALカリキュラムを編集にすると、
          <br />
          カリキュラムマスタから内容の修正が可能になります。。
        </span>
      );
    case 'publishWarning':
      return (
        <span>
          OFFICIALカリキュラムを公開停止にすると、
          <br /> 一般ユーザー様の新規の利用は不可になります。
        </span>
      );
    default:
      return '';
  }
};
