import styled from 'styled-components';

export const SectionStyled = styled.div`
  .messageTitle {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 15px;
    justify-content: center;
    .anticon {
      font-size: 20px;
      margin-right: 5px;
      color: #bbbbbb;
    }
  }
  .container {
    margin-top: 16px;
    margin-bottom: 8px;
    //width: 640px;
    background-color: white;
    padding: 8px;
    .message {
      font-size: 16px;
      text-align: center;
      color: #2a2a2a;
    }
  }
  .label {
    color: #999999;
    font-size: 11px;
  }
  .text-center {
    text-align: center;
  }
`;
