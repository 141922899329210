import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdministratorMasterFormik, AdminstratorRes } from 'types';
import { getAdminDetails } from './thunk';

export interface InitialState {
  loading: boolean;
  err?: string;
  information: AdministratorMasterFormik;
  admin_detail: Array<AdminstratorRes>;
}

const initialState: InitialState = {
  loading: false,
  information: {
    plan_name: '',
    number_of_users: 0,
    name: '',
    name_furigana: '',
    postal_code: '',
    prefecture: '',
    address: '',
    plot_number: '',
    building_name: '',
    admin_name: '',
    admin_name_furigana: '',
    admin_email_confirm: '',
    admin_department: '',
    admin_position: '',
    admin_phone: '',
    admin_email: '',
  },
  admin_detail: [],
};

export const AdministratorMasterSlice = createSlice({
  name: 'AdministratorMaster-container',
  initialState,
  reducers: {
    setInformationRegister(state, action: PayloadAction<AdministratorMasterFormik>) {
      state.information = action.payload;
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };
    // builder.addCase(getPlans.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(getPlans.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.plans = orderBy(action.payload.items, ['sort_order'], ['asc']);
    // });
    // builder.addCase(getPlans.rejected, (state) => {
    //   state.loading = false;
    //   state.plans = [];
    // });
    builder.addCase(getAdminDetails.pending, startLoading);

    builder.addCase(getAdminDetails.fulfilled, (state, action) => {
      state.admin_detail = action.payload.report_results;
      stopLoading(state);
    });

    builder.addCase(getAdminDetails.rejected, stopLoading);
  },
});

export const { setInformationRegister } = AdministratorMasterSlice.actions;
export default AdministratorMasterSlice.reducer;
