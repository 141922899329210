import styled from 'styled-components';

export const SectionStyled = styled.div`
  margin: 8px 16px;
  @media (max-width: 1440px) {
    margin: 8px 40px;
    .sub-title {
      margin: 20px 0 40px 0;
    }
    .form {
      .input {
        height: 40px;
      }
      .wrap-show-image {
        padding: 12px 20px;
        .flex-image .label {
          margin-bottom: 0 !important;
        }
      }
      .mr-30 {
        height: 40px;
      }
      .label-note {
        margin-bottom: 30px;
      }
      .form-input {
        .btn_primary {
          width: 160px !important;
        }
        .input-textarea {
          height: 90px !important;
        }
        .time_limit .ant-select-selector {
          height: 40px !important;
        }
        .ant-select-selector > span {
          margin-top: 2px;
        }
        .wrap-flex {
          margin-bottom: -16px;
        }
      }
      .form-input-radio .radio_group {
        .radio-text {
          margin-right: 0 !important;
          width: 246px !important;
        }
      }
    }
  }
  .sub-title {
    font-size: 13px;
    color: #424242;
    text-align: center;
  }
  .label-note {
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    .number {
      width: 19px;
      height: 19px;
      background-color: #424242;
      font-family: 'Lato';
      font-size: 11px;
      color: #dddddd;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .form {
    .text-label {
      font-size: 13px;
      display: block;
      color: #424242;
      margin: 0;
      .require {
        color: #f0330a;
      }
    }
    .form-input-radio {
      .ant-form-item-control-input-content {
        display: flex !important;
      }
      .radio_group {
        width: 100%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        .radio-text {
          font-size: 14px;
          color: #2a2a2a;
          width: 33%;
          display: flex;
          height: 40px;
          justify-content: space-around;
          align-items: center;
        }
        .radio-text.question {
          width: 50%;
        }
        .radio-text + .radio-text {
          border-left: 1px solid #e2e2e2;
        }
      }
    }
    .form-input {
      .ant-select-selection-item {
        text-align: left;
      }
      label,
      .wrap-label-form-input {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .text-enlarge {
          font-size: 12px;
          text-decoration: underline;
          color: #00989a;
          cursor: pointer;
          .icon {
            color: #00989a;
            margin-right: 5px;
            font-size: 15px;
          }
        }
      }
      .time_limit {
        width: 30%;
      }
      .btn_primary {
        background: #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        border: none;
        width: 140px;
        height: 40px;
        color: #ffffff;
        cursor: pointer;
        margin: 0 10px;
        .icon {
          margin-right: 5px;
        }
      }
      .text-note {
        font-size: 12px;
        color: #999999;
        margin-bottom: 0;
      }
      .text-clear {
        font-size: 12px;
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        cursor: pointer;
        background: none;
        border: none;
      }
      .active {
        color: #00989a;
      }

      .wrap-flex {
        display: flex;
        align-items: center;
      }
      .input,
      .input-textarea {
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        color: #424242;
        ::placeholder {
          color: #cccccc;
          font-size: 14px;
        }
      }
      .ant-input-show-count-suffix {
        position: absolute;
        top: 46px;
        width: 55px;
        right: -11px;
        text-align: end;
      }
      .input-textarea {
        height: 100px;
      }
      .input-textarea-small {
        height: 60px;
      }
    }
  }
  .wrap-show-image {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 24px 0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
    padding: 10px 20px;
    border-radius: 3px;
    .flex-image {
      display: flex;
      align-items: center;
    }
    .image {
      background-color: #efefef;
      border: 1px solid #eeeeee;
      width: 128px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 6px;
      cursor: pointer;
    }
    .text-clear {
      font-size: 12px;
      text-decoration-line: underline;
      cursor: pointer;
      color: #00a3a5;
      &__disabled {
        text-decoration-line: underline;
        pointer-events: none;
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .mr-30 {
    height: 30px;
  }
  .question-mark-icon,
  .watch-icon {
    font-size: 16px;
    color: #08a3a5;
    cursor: pointer;
    margin: 0 10px;
  }
  .time-setting {
    color: #08a3a5;
    cursor: pointer;
    text-decoration: underline;
  }
  .label {
    font-family: 'Lato';
    font-size: 12px;
    color: #777777;
    text-align: center;
  }
`;
