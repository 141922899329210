import yup from '../yup';

export const createEmployeeSchema = yup.object().shape({
  employee_code: yup.string().required(),
  name: yup.string().required(),
  name_furigana: yup.string().required(),
  email: yup.string().email().required(),
  email_confirm: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('email'), null], 'Email confirmation is not the same as email'),
  password: yup.string().required().max(30).min(4),
  password_confirm: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません')
    .max(30)
    .min(4),
  main_position: yup
    .object()
    .shape({
      affiliation_id: yup.string(),
      positions_code: yup.string(),
    })
    .required(),
});

export const updateUserSchema = yup.object().shape({
  employee_code: yup.string().required(),
  name: yup.string().required(),
  name_furigana: yup.string().required(),
  dob: yup.string().required(),
  doj: yup.string(),
  role_code: yup.string(),
  main_position: yup
    .object()
    .shape({
      affiliation_id: yup.string().required(),
      positions_code: yup.string().required(),
    })
    .required(),
});
