import styled from 'styled-components';

type Props = {};

export default styled.section<Props>`
  @media (max-width: 1440px) {
    .btn-upload {
      width: 160px;
    }
  }
  .tabs .ant-tabs-nav {
    padding-left: 32px !important;
  }
  position: relative;
  background: #f9f8f8;
  min-height: 100vh;
  overflow: auto;
  .tabs {
    width: 100%;
    height: 60px;
    background: #ffffff;
    .icon {
      font-size: 20px;
    }
    .btn-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 20px;
      top: 0.65rem !important;
      background: #f6ac00;
      width: 160px;
      height: 40px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      border: none;
      color: #ffffff;
      font-size: 13px;
    }
    .ant-tabs-nav {
      padding-left: 33px;
      .ant-tabs-tab-active div {
        color: #08a3a5;
        font-weight: 700;
      }
      .ant-tabs-ink-bar {
        border-bottom: 2px solid #08a3a5;
      }
    }
    .ant-tabs-nav-wrap {
      height: 60px;
    }
  }
`;
