import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Progress } from 'antd';

import { FileUploadStatus } from 'constant/enum.constant';
import { SectionStyled } from './styles';
import { FileToUpload } from 'types';
import Modal from '..';

interface Props {
  visible: boolean;
  onSubmit?: () => void;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  uploadingFiles: FileToUpload[];
  setVisible: (visible: boolean) => void;
}

const UploadFileToServer: React.FC<Props> = ({
  visible,
  title,
  subTitle,
  uploadingFiles,
  setVisible,
  onSubmit,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit();
    handleToggleModal();
  };
  const isSuccess = () => {
    if (uploadingFiles.length === 0) {
      return 'icon';
    }
    if (uploadingFiles.every((file: FileToUpload) => file.percentage === 100)) {
      return 'icon-success';
    }
  };

  return (
    <Modal
      title={title && <h3 className="title">{title}</h3>}
      visible={visible}
      width={416}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      onCancel={handleToggleModal}
    >
      <SectionStyled>
        <p className="sub-title">
          <CheckCircleOutlined className={isSuccess()} />
          {subTitle}
        </p>
        <div className="text-content">
          {
            <div>
              {uploadingFiles.map((file: FileToUpload) => {
                return (
                  <div key={file.uid}>
                    <span>{file.name}: </span>
                    <Progress
                      percent={file.percentage}
                      status={file.status === FileUploadStatus.exception ? 'exception' : undefined}
                    />
                  </div>
                );
              })}
            </div>
          }
        </div>
      </SectionStyled>
    </Modal>
  );
};
export default UploadFileToServer;
