export const LIST_TITLE_HEADER_INFORMATION_REGISTER = [
  'AJ-Step-1',
  'AJ-Step-2',
  'AJ-Step-3',
  'AJ-Step-4',
  'AJ-Step-5',
];

export const IMAGE_CREDIT_CARD = [
  {
    name: 'VISA',
    image: '',
  },
  {
    name: 'マスターカード',
    image: '',
  },
  {
    name: 'AMEX',
    image: '',
  },
  {
    name: 'JCB',
    image: '',
  },
];

export const CREDIT_CARD_MONTH = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const LIST_LABEL = ['カリキュラム', '第１階層', '第２階層', '第３階層', '第４階層', '設問'];

export const LIST_TAB_BAR = [' 通常モード', '移動＆コピーモード'];

export const MENU_WIDTH = 196;

export const MENU_COLLAPSED_WIDTH = 60;

export const REGEX_HALF_WIDTH = /^[a-zA-Z0-9!"#$%&'()*+,-.\\:/;<=>?@[\]^_`{|}~]+$/;

export const DEFAULT_COMPANY_NAME = '仮登録';

export const HELP_CENTER_URL = 'https://rstandard.zendesk.com/';
