import styled from 'styled-components';

export default styled.section`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    width: 150px;
    margin-bottom: 34px;
  }
  .logo-completed {
    font-size: 66px;
    color: #08a3a5;
    margin-bottom: 38px;
  }
  .description {
    font-size: 13px;
    color: #424242;
    margin-bottom: 42px;
  }
  .redirect {
    background: #f6ac00;
    height: 48px;
  }
  .title {
    margin-bottom: 32px;
    font-size: 15px;
    color: #424242;
  }
`;
