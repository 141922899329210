import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import zipcodeJa from 'zipcode-ja';

import { informationRegisterSelector } from 'containers/InformationRegister/selectors';
import { resetInformation, setInformationRegister } from 'containers/InformationRegister/slice';
import { authSelector } from 'containers/Auth/selectors';
import { secondStepSchema } from 'libs/validations';
import { RegisterInformationFormik } from 'types';
import { useAppDispatch } from 'hooks';
import PopupProvision from './Modal';
import { Wrapper } from './styles';
import { Input } from 'components';
import CompletedModal from 'components/Modal/Completed';
import {
  createAffiliationAssignLevel,
  createAffiliationLevel,
  createAffiliationRole,
  createCompanies,
  createItemPositionMaster,
  createRoles,
  registerUserThunk,
} from 'containers/InformationRegister/thunk';

type Props = {
  nextStep: () => void;
};

const SecondStep: React.FC<Props> = (props) => {
  const { nextStep } = props;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);

  const { information } = useSelector(informationRegisterSelector);
  const { userInfo, signedInEmail } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<RegisterInformationFormik>({
    initialValues: { ...information, admin_email: userInfo?.email || '' },
    validationSchema: secondStepSchema,
    onSubmit: async (values) => {
      await dispatch(setInformationRegister(values));
      const data = {
        ...values,
        admin_phone: values.admin_phone.toString(),
        postal_code: values.postal_code.toString(),
      };
      const resultActionRegisterInformation = await dispatch(
        createCompanies({
          item: {
            ...data,
            createdat: new Date(),
            createdby: signedInEmail,
          },
          return_item_result: true,
          return_display_id: true,
        })
      );
      if (createCompanies.fulfilled.match(resultActionRegisterInformation)) {
        const { item } = resultActionRegisterInformation.payload;
        //TODO: check field name
        const resultActionCreateRole = await dispatch(
          createRoles({
            item: {
              name: 'admin',
              admin_dashboard_permission: 2,
              skill_check_implementation_permission: 2,
              training_report_permission: 1,
              skill_check_report_permission: 1,
              my_chart_permission: 1,
              manual_permission: 2,
              knowledge_permission: 2,
              official_curriculum_permission: 2,
              curricullum_master_permission: 2,
              question_master_permission: 2,
              skill_check_master_permission: 2,
              inter_users_master_permission: 2,
              groups_master_permission: 2,
              users_master_permission: 2,
              roles_master_permission: 2,
              departments_master_permission: 2,
              positions_master_permission: 2,
              admin_master_permission: 2,
              payment_master_permission: 2,
              report_view_permission: 1,
              bundle_master_permission: 1,
              company_id: item.id,
              createdby: signedInEmail,
              createdat: new Date(),
            },
            is_force_update: true,
          })
        );
        const resultActions1 = await Promise.all([
          dispatch(
            createAffiliationLevel({
              item: {
                name: information.admin_department,
                sort_order: 1,
                level: 1,
                createdby: signedInEmail,
                company_id: item.id,
                createdat: new Date(),
              },
              return_display_id: true,
              return_item_result: true,
            })
          ),
          dispatch(
            createItemPositionMaster({
              item: {
                name: information.admin_position,
                company_id: item.id,
                rank_order: 1,
                createdby: signedInEmail,
                createdat: new Date(),
              },
              return_display_id: true,
              return_item_result: true,
            })
          ),
          dispatch(
            registerUserThunk({
              item: {
                company_id: item.id,
                login_id: signedInEmail,
                name: information.admin_name,
                name_furigana: information.admin_name_furigana,
                email: signedInEmail,
                user_type: 'admin',
                admin_phone: information.admin_phone,
                role_code: createRoles.fulfilled.match(resultActionCreateRole)
                  ? resultActionCreateRole.payload.item_id
                  : undefined,
                createdat: new Date(),
                createdby: signedInEmail,
              },
            })
          ),
        ]);

        if (registerUserThunk.fulfilled.match(resultActions1[2])) {
          let affiliation_id;
          let positions_id;
          let affiliation_code;
          if (
            createAffiliationLevel.fulfilled.match(resultActions1[0]) &&
            resultActions1[0].payload.item
          ) {
            affiliation_id = resultActions1[0].payload.item_id;
            affiliation_code = resultActions1[0].payload.item.affiliation_id;
          }
          if (
            createItemPositionMaster.fulfilled.match(resultActions1[1]) &&
            resultActions1[1].payload.item
          ) {
            positions_id = resultActions1[1].payload.item_id;
          }
          await Promise.all([
            dispatch(
              createAffiliationRole({
                item: {
                  company_id: item.id,
                  affiliation_id: affiliation_id,
                  login_id: signedInEmail,
                  positions_code: positions_id,
                  sort_order: 1,
                  main_role: 'main',
                  createdby: signedInEmail,
                  createdat: new Date(),
                },
              })
            ),
            dispatch(
              createAffiliationAssignLevel({
                item: {
                  company_id: item.id,
                  affiliation_id: affiliation_code,
                  login_id: signedInEmail,
                  sort_order: 1,
                  createdby: signedInEmail,
                  createdat: new Date(),
                },
              })
            ),
          ]);
          dispatch(resetInformation());
        }
        setVisibleCompleted(true);
      }
    },
  });

  const convertZipCodeToAddress = () => {
    if (formik.values.postal_code) {
      const address = zipcodeJa[formik.values.postal_code as unknown as number]?.address;
      formik.setFieldValue('prefecture', address ? address[0] : '');
      formik.setFieldValue('address', address ? `${address[1]} ${address[2]}` : '');
    }
  };

  return (
    <Wrapper>
      <FormikProvider value={formik}>
        <Form
          layout="vertical"
          labelCol={{
            flex: '22%',
          }}
          colon={false}
        >
          <div className="form">
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  法人名
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="name" className="input" type="text" placeholder="全角：最大100文字" />
            </Form.Item>
            <Form.Item
              name="name_furigana"
              label={
                <span className="text-label">
                  法人名 （フリガナ）
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="name_furigana"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="postal_code"
              label={
                <span className="text-label">
                  郵便番号
                  <span className="require">*</span>
                </span>
              }
              className={`form-input wrap-input-zip-code ${
                formik.touched.postal_code && formik.errors.postal_code && 'error-input-zip-code'
              }`}
            >
              <Input
                name="postal_code"
                className="input"
                type="number"
                placeholder="数字：７文字（ハイフンなし）"
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || formik.values.postal_code!.toString().length > 6) {
                    e.preventDefault();
                    return;
                  }
                }}
              />
              <button type="button" className="btn-check-zipCode" onClick={convertZipCodeToAddress}>
                郵便番号から住所検索
              </button>
            </Form.Item>
            <Form.Item
              name="prefecture"
              label={
                <span className="text-label">
                  都道府県
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="prefecture"
                type="text"
                placeholder="全角：最大100文字"
                className="input"
              />
            </Form.Item>
            <Form.Item
              name="address"
              label={
                <span className="text-label">
                  市区町村
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="address" className="input" type="text" placeholder="全角：最大100文字" />
            </Form.Item>
            <Form.Item
              name="plot_number"
              label={
                <span className="text-label">
                  番地 <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="plot_number"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="building_name"
              label={<span className="text-label">建物名・部屋番号</span>}
              className="form-input"
            >
              <Input
                name="building_name"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_name"
              label={
                <span className="text-label">
                  管理者氏名
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_name"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={
                <span className="text-label">
                  管理者氏名 （フリガナ）
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_name_furigana"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_department"
              label={
                <span className="text-label">
                  所属
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_department"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_position"
              label={
                <span className="text-label">
                  役職
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_position"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_phone"
              label={
                <span className="text-label">
                  管理者電話番号
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_phone"
                className="input"
                type="tel"
                placeholder="数字：最大11文字（ハイフン無し）"
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || formik.values.admin_phone!.toString().length > 10) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="admin_email"
              label={<span className="text-label">管理者メールアドレス</span>}
              className="form-input"
            >
              <Input readOnly name="admin_email" className="input__disabled input" type="text" />
            </Form.Item>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">確認画面へ</SubmitButton>
          </div>
        </Form>
      </FormikProvider>
      <CompletedModal
        title="登録が完了しました"
        visible={visibleCompleted}
        setVisible={setVisibleCompleted}
        onSubmit={nextStep}
      />
      {isShow ? (
        <PopupProvision
          onSubmit={() => {
            setIsShow(false);
          }}
          onClose={() => setIsShow(false)}
        />
      ) : null}
    </Wrapper>
  );
};

export default SecondStep;
