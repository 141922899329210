import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { memoizedConvertFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { CurriculumStatus, CurriculumType } from 'constant/enum.constant';
import * as Types from 'types';
import {
  getCurriculum,
  createCurriculum,
  deleteCurriculum,
  updateCurriculum,
  getDataUserSetting,
  getCurriculumMaster,
  setPublishCurriculum,
  setRequiredCurriculum,
  createLevelCurriculum,
  deleteLevelCurriculum,
  updateLevelCurriculum,
  curriculumExportDataCSV,
  deleteLinkUserAssignCurriculum,
} from './thunk';

export type InitialState = {
  total: number;
  loading: boolean;
  curricullum: Array<Types.TreeItem<Types.CurriculumItemType>>;
  curricullumMaster: Array<Types.CurriculumMasterType>;
  data_user_setting: Array<Types.UserTreeviewType>;
  data_curricullum_export: Array<Types.CurriculumExport.ResponseType>;
  filter_conditions: {
    type?: keyof typeof CurriculumType;
    status?: keyof typeof CurriculumStatus;
    name?: string;
    conditions: Array<Types.ConditionsType>;
  };
};

const initialState: InitialState = {
  total: 0,
  loading: false,
  curricullum: [],
  curricullumMaster: [],
  data_user_setting: [],
  data_curricullum_export: [],
  filter_conditions: {
    conditions: [],
  },
};

export const curriculumSlice = createSlice({
  name: 'curricullum-page',
  initialState,
  reducers: {
    setFilterConditions(
      state,
      action: PayloadAction<{
        type?: keyof typeof CurriculumType;
        status?: keyof typeof CurriculumStatus;
        name?: string;
      }>
    ) {
      if (action.payload.type) {
        state.filter_conditions.type = action.payload.type;
        const findIndex = state.filter_conditions.conditions.findIndex(
          (value) => value.id === 'required_curricullum'
        );
        switch (action.payload.type) {
          case 'curriculum':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['0'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'required_curricullum',
                search_value: ['0'],
                exact_match: true,
              });
            }
            break;
          case 'required':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['1'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'required_curricullum',
                search_value: ['1'],
                exact_match: true,
              });
            }
            break;
          default:
            if (findIndex >= 0) {
              state.filter_conditions.conditions.splice(findIndex, 1);
            }
            break;
        }
      }
      if (action.payload.status) {
        state.filter_conditions.status = action.payload.status;
        const findIndex = state.filter_conditions.conditions.findIndex(
          (value) => value.id === 'publish'
        );
        switch (action.payload.status) {
          case 'publish':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['1'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'publish',
                search_value: ['1'],
                exact_match: true,
              });
            }
            break;
          case 'edit':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['0'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'publish',
                search_value: ['0'],
                exact_match: true,
              });
            }
            break;
          default:
            if (findIndex >= 0) {
              state.filter_conditions.conditions.splice(findIndex, 1);
            }
            break;
        }
      }
      if (action.payload.name) {
        state.filter_conditions.name = action.payload.name;
        const data = [...state.curricullum];
        state.curricullum = data.sort((x, y) =>
          x.i_id === action.payload.name ? -1 : y.i_id === action.payload.name ? 1 : 0
        );
        const new_data_user_setting = [...state.data_user_setting];
        state.data_user_setting = new_data_user_setting.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      }
    },
    clearFilterConditions(state) {
      state.filter_conditions = {
        conditions: [],
        name: undefined,
        status: undefined,
        type: undefined,
      };
    },
  },
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };

    builder
      .addCase(createCurriculum.pending, startLoading)
      .addCase(setRequiredCurriculum.pending, startLoading)
      .addCase(setPublishCurriculum.pending, startLoading)
      .addCase(updateCurriculum.pending, startLoading)
      .addCase(deleteCurriculum.pending, startLoading)
      .addCase(getCurriculum.pending, startLoading)
      .addCase(createLevelCurriculum.pending, startLoading)
      .addCase(deleteLevelCurriculum.pending, startLoading)
      .addCase(updateLevelCurriculum.pending, startLoading)
      .addCase(getDataUserSetting.pending, startLoading)
      .addCase(deleteLinkUserAssignCurriculum.pending, startLoading)
      .addCase(getCurriculumMaster.pending, startLoading);

    builder.addCase(createCurriculum.fulfilled, stopLoading);
    builder.addCase(setRequiredCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum[curriculumIndex] = {
        ...state.curricullum[curriculumIndex],
        required_curriculum: action.payload.item.required_curriculum,
      };
      stopLoading(state);
    });
    builder.addCase(setPublishCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum[curriculumIndex] = {
        ...state.curricullum[curriculumIndex],
        publish: action.payload.item.publish,
      };
      stopLoading(state);
    });
    builder.addCase(updateCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum[curriculumIndex] = {
        ...state.curricullum[curriculumIndex],
        name: action.payload.item.name,
        description: action.payload.item.description,
      };
      stopLoading(state);
    });
    builder.addCase(deleteCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum.splice(curriculumIndex, 1);
      stopLoading(state);
    });
    builder.addCase(getCurriculum.fulfilled, (state, action) => {
      const newCurriculum: Array<Types.TreeItem<Types.CurriculumItemType>> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newCurriculum.findIndex((c) => c.i_id === item.i_id);
        if (itemIndex >= 0) {
          newCurriculum[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: newCurriculum[itemIndex],
            item: {
              ...item,
              curriculum_code: item.official_curriculum_code || item.curriculum_code,
            },
          });
        } else {
          newCurriculum.push({
            i_id: item.i_id,
            code: item.official_curriculum_code || item.curriculum_code,
            name: item.curriculum_name,
            description: item.description,
            sort_order: item.curriculum_sort_order,
            publish: item.publish,
            required_curriculum: item.required_curriculum,
            columnIndex: 0,
            fileID: item.fileID,
            children: item.item_ref?.level1_code?.i_id
              ? [
                  {
                    i_id: item.item_ref.level1_code.i_id,
                    name: item.level1_name,
                    code: item.level1_code,
                    sort_order: item.level1_sort_order,
                    publish: item.publish,
                    required_curriculum: item.required_curriculum,
                    columnIndex: 1,
                    children: item.item_ref.level2_code?.i_id
                      ? [
                          {
                            i_id: item.item_ref.level2_code.i_id,
                            name: item.level2_name,
                            code: item.level2_code,
                            sort_order: item.level2_sort_order,
                            publish: item.publish,
                            required_curriculum: item.required_curriculum,
                            columnIndex: 2,
                            children: item.item_ref.level3_code?.i_id
                              ? [
                                  {
                                    i_id: item.item_ref.level3_code.i_id,
                                    name: item.level3_name,
                                    code: item.level3_code,
                                    sort_order: item.level3_sort_order,
                                    publish: item.publish,
                                    required_curriculum: item.required_curriculum,
                                    columnIndex: 3,
                                    children: item.item_ref.level4_code?.i_id
                                      ? [
                                          {
                                            i_id: item.item_ref.level4_code.i_id,
                                            name: item.level4_name,
                                            code: item.level4_code,
                                            sort_order: item.level4_sort_order,
                                            publish: item.publish,
                                            required_curriculum: item.required_curriculum,
                                            columnIndex: 4,
                                            children: item.item_ref.question_code
                                              ? [
                                                  {
                                                    i_id: item.item_ref.question_code.i_id,
                                                    name: item.question_name,
                                                    code: item.question_code,
                                                    sort_order: item.question_sort_order,
                                                    question_assign_level_i_id:
                                                      item.item_ref.question_sort_order.i_id,
                                                    publish: item.publish,
                                                    required_curriculum: item.required_curriculum,
                                                    columnIndex: 5,
                                                  },
                                                ]
                                              : [],
                                          },
                                        ]
                                      : [],
                                  },
                                ]
                              : [],
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          });
        }
      });
      if (state.filter_conditions.name) {
        state.curricullum = newCurriculum.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      } else {
        state.curricullum = newCurriculum;
      }
      stopLoading(state);
    });
    builder.addCase(getDataUserSetting.fulfilled, (state, action) => {
      const newCurriculum: Array<Types.UserTreeviewType> = [];
      action.payload.report_results.forEach((item) => {
        const curriculumIndex = newCurriculum.findIndex((curr) => curr.i_id === item.i_id);
        if (curriculumIndex >= 0) {
          const departmentIndex = newCurriculum[curriculumIndex].children?.findIndex(
            (department) => department.department_name === item.department_name
          );
          if (departmentIndex !== undefined && departmentIndex >= 0) {
            if (
              !newCurriculum[curriculumIndex].children![departmentIndex].children?.some(
                (user) => user.user_id === item.item_ref?.user_name.i_id
              )
            ) {
              newCurriculum[curriculumIndex].children![departmentIndex].children?.push({
                i_id: item.item_ref?.department_name.i_id,
                user_name: item.user_name,
                user_id: item.item_ref?.user_name.i_id,
              });
            }
          } else {
            if (item.department_name) {
              newCurriculum[curriculumIndex].children?.push({
                department_name: item.department_name,
                children: [
                  {
                    i_id: item.item_ref?.department_name.i_id,
                    user_name: item.user_name,
                    user_id: item.item_ref?.user_name.i_id,
                  },
                ],
              });
            }
          }
        } else {
          newCurriculum.push({
            curricullum_name: item.curricullum_name,
            curricullum_sort_order: item.curricullum_sort_order,
            curricullum_description: item.curricullum_description,
            publish: item.publish,
            curricullum_code: item.curricullum_code,
            company_id: item.company_id,
            i_id: item.i_id,
            children: item.department_name
              ? [
                  {
                    department_name: item.department_name,
                    children: [
                      {
                        i_id: item.item_ref?.department_name.i_id,
                        user_name: item.user_name,
                        user_id: item.item_ref?.user_name.i_id,
                      },
                    ],
                  },
                ]
              : [],
          });
        }
      });
      if (state.filter_conditions.name) {
        state.data_user_setting = newCurriculum.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      } else {
        state.data_user_setting = newCurriculum;
      }
      stopLoading(state);
    });
    builder.addCase(getCurriculumMaster.fulfilled, (state, action) => {
      state.curricullumMaster = action.payload.report_results;
      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(curriculumExportDataCSV.fulfilled, (state, action) => {
      state.data_curricullum_export = action.payload.report_results;
      const allCode = uniq(state.data_curricullum_export.map((value) => value.curriculum_code));
      state.data_curricullum_export = state.data_curricullum_export.map((value) => {
        const indexCode = allCode.findIndex((code) => value.curriculum_code === code);
        return { flag: indexCode + 1, ...value };
      });
    });
    builder
      .addCase(createLevelCurriculum.fulfilled, stopLoading)
      .addCase(deleteLevelCurriculum.fulfilled, stopLoading)
      .addCase(deleteLinkUserAssignCurriculum.fulfilled, stopLoading)
      .addCase(updateLevelCurriculum.fulfilled, stopLoading);
    builder
      .addCase(createCurriculum.rejected, stopLoading)
      .addCase(setRequiredCurriculum.rejected, stopLoading)
      .addCase(setPublishCurriculum.rejected, stopLoading)
      .addCase(updateCurriculum.rejected, stopLoading)
      .addCase(deleteCurriculum.rejected, stopLoading)
      .addCase(getCurriculum.rejected, stopLoading)
      .addCase(createLevelCurriculum.rejected, stopLoading)
      .addCase(deleteLevelCurriculum.rejected, stopLoading)
      .addCase(updateLevelCurriculum.rejected, stopLoading)
      .addCase(getDataUserSetting.rejected, stopLoading)
      .addCase(getCurriculumMaster.rejected, stopLoading)
      .addCase(deleteLinkUserAssignCurriculum.rejected, stopLoading)
      .addCase(curriculumExportDataCSV.rejected, stopLoading);
  },
});
export const { setFilterConditions, clearFilterConditions } = curriculumSlice.actions;

export default curriculumSlice.reducer;
