import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import zipcodeJa from 'zipcode-ja';

import { settingSelector } from 'containers/AppSettings/selectors';
import { administratorMasterSchema } from 'libs/validations';
import { administratorMasterSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import ConfirmAdministratorMaster from './Confirm';
import { AdministratorMasterFormik } from 'types';
import { setInformationRegister } from './slice';
import { Header, Input } from 'components';
import { getAdminDetails } from './thunk';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import * as Types from 'types';

const AdministratorMaster: React.FC = () => {
  const [nextScreen, setNextScreen] = useState<boolean>(false);
  const [numberOfUsers, setNumberOfUsers] = useState<number>(0);
  const dispatch = useAppDispatch();

  const { information } = useSelector(administratorMasterSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const formik = useFormik<AdministratorMasterFormik>({
    initialValues: information,
    validationSchema: administratorMasterSchema,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(setInformationRegister(values));
      toggleScreen();
      setSubmitting(false);
    },
  });

  useEffect(() => {
    (async () => {
      const resultAction = await dispatch(
        getAdminDetails({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          omit_total_items: false,
          include_item_ref: true,
        })
      );
      if (getAdminDetails.fulfilled.match(resultAction)) {
        const values: Types.AdminstratorRes = resultAction?.payload?.report_results[0];
        formik.setValues({
          ...formik.values,
          name: values?.name,
          address: values?.address,
          admin_department: values?.admin_department,
          admin_email: values?.admin_email,
          admin_name: values?.admin_name,
          admin_phone: values?.admin_phone,
          admin_position: values?.admin_position,
          building_name: values?.building_name,
          name_furigana: values?.admin_name,
          postal_code: values?.postal_code,
          admin_name_furigana: values?.name_furigana,
          plot_number: values?.plot_number,
          prefecture: values?.prefecture,
        });
        setNumberOfUsers(resultAction.payload.totalItems);
      }
    })();
  }, [dispatch, userInfo]);

  const convertZipCodeToAddress = () => {
    if (formik.values.postal_code) {
      const address = zipcodeJa[formik.values.postal_code as number]?.address;
      formik.setFieldValue('prefecture', address ? address[0] : '');
      formik.setFieldValue('address', address ? address[1] : '');
    }
  };

  const toggleScreen = () => setNextScreen((prevState) => !prevState);

  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title="管理者マスタ" />
      {nextScreen ? (
        <ConfirmAdministratorMaster goBack={toggleScreen} />
      ) : (
        <div className="container">
          <div className="description">
            <p>
              管理者情報の確認・編集を行う画面です。
              <br />
              編集完了後に確認画面へボタンをクリックしてください。。
            </p>
          </div>
          <FormikProvider value={formik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '22%',
              }}
              colon={false}
            >
              <div className="form">
                <Form.Item
                  name="numberOfUsers"
                  label={<span className="text-label">利用ユーザー数</span>}
                  className="form-input wrap-number-users"
                >
                  <Input
                    name="numberOfUsers"
                    className="input__disabled number-users"
                    type="text"
                    value={numberOfUsers}
                    disabled
                  />
                  <span className="suffix">人</span>
                </Form.Item>
                <Form.Item
                  name="name"
                  label={
                    <span className="text-label">
                      法人名 <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input name="name" type="text" className="input" />
                </Form.Item>
                <Form.Item
                  name="name_furigana"
                  label={
                    <span className="text-label">
                      法人名 （フリガナ）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="name_furigana"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="postal_code"
                  label={
                    <span className="text-label">
                      郵便番号
                      <span className="require">*</span>
                    </span>
                  }
                  className={`form-input wrap-input-zip-code ${
                    formik.touched.postal_code && formik.errors.postal_code
                      ? 'error-input-zip-code'
                      : ''
                  }`}
                >
                  <Input
                    name="postal_code"
                    className="input"
                    type="text"
                    placeholder="数字：７文字（ハイフンなし）"
                    onKeyPress={(e) => {
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.postal_code!.toString().length > 6
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="btn-check-zipCode"
                    onClick={convertZipCodeToAddress}
                  >
                    郵便番号から住所検索
                  </button>
                </Form.Item>
                <Form.Item
                  name="prefecture"
                  label={
                    <span className="text-label">
                      都道府県
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="prefecture"
                    type="text"
                    placeholder="全角：最大100文字"
                    className="input"
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label={
                    <span className="text-label">
                      市区町村
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="address"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="plot_number"
                  label={
                    <span className="text-label">
                      番地
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="plot_number"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="building_name"
                  label={<span className="text-label">建物名・部屋番号</span>}
                  className="form-input"
                >
                  <Input
                    name="building_name"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name"
                  label={
                    <span className="text-label">
                      管理者氏名
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="admin_name"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name_furigana"
                  label={
                    <span className="text-label">
                      管理者氏名 （フリガナ）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="admin_name_furigana"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_department"
                  label={
                    <span className="text-label">
                      所属
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="admin_department"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_position"
                  label={
                    <span className="text-label">
                      役職
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="admin_position"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_phone"
                  label={
                    <span className="text-label">
                      管理者電話番号
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="admin_phone"
                    className="input"
                    type="text"
                    placeholder="数字：最大11文字（ハイフン無し）"
                    onKeyPress={(e) => {
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.admin_phone!.toString().length > 10
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_email"
                  label={
                    <span className="text-label">
                      管理者メールアドレス
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input name="admin_email" className="input" type="text" />
                </Form.Item>

                <Form.Item
                  name="email_address_confirmation"
                  label={
                    <span className="text-label">
                      管理者メールアドレス（確認）
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="email_address_confirmation"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
              </div>
              <div className="wrap-button">
                <SubmitButton className="btn-submit">確認画面へ</SubmitButton>
              </div>
            </Form>
          </FormikProvider>
        </div>
      )}
    </Wrapper>
  );
};

export default AdministratorMaster;
