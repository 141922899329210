import { createSlice } from '@reduxjs/toolkit';
import { union } from 'lodash';

import { getDataAuthority, searchDataAuthority } from './thunk';
import { AuthorityMasterType } from 'types/services/setting';

export type InitialState = {
  loading: boolean;
  listAuthority: AuthorityMasterType[];
  searchResult: AuthorityMasterType[];
  total: number;
  itemEdit?: AuthorityMasterType;
};

const initialState: InitialState = {
  listAuthority: [],
  searchResult: [],
  loading: false,
  total: 0,
};

export const authorityMasterSlice = createSlice({
  name: 'authorityMaster-slice',
  initialState,
  reducers: {
    itemEditAuthorityMaster(state, action) {
      state.itemEdit = action.payload;
    },
    resetSearch(state) {
      state.searchResult = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataAuthority.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDataAuthority.fulfilled, (state, action) => {
      state.loading = false;
      state.listAuthority = union(action.payload.items, ['code']);
      state.total = action.payload.totalItems;
    });
    builder.addCase(getDataAuthority.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(searchDataAuthority.fulfilled, (state, action) => {
      state.searchResult = union(action.payload.report_results, ['role_code']);
    });
  },
});

export const { itemEditAuthorityMaster, resetSearch } = authorityMasterSlice.actions;

export default authorityMasterSlice.reducer;
