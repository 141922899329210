import { Switch } from 'antd';

import { IconLocked, TickComplete, IconRequired } from 'assets';
import { Button, Wrapper } from './styles';

const TooltipUserSetting: React.FC<{
  isPublish: number;
  curriculumSelected: boolean;
  curricullum_description: string;
  handleSwitch: (value: boolean) => void;
}> = ({ handleSwitch, curriculumSelected, curricullum_description, isPublish }) => {
  return (
    <Wrapper>
      <div className="rowWrapper bgGrey">
        <div className="rowContentHead">
          <div className="spanHead">
            {isPublish ? (
              <>
                <img src={TickComplete} className="icon" alt="publish-icon" />
                <span className="spanText">公開中</span>
              </>
            ) : (
              <>
                <img src={IconLocked} className="icon" alt="edit-icon" />
                <span className="spanText">編集中</span>
              </>
            )}
          </div>
          <div className="spanHead">
            <img src={IconRequired} className="icon" alt="required-icon" />
            <span className="spanText">必修カリキュラム</span>
          </div>
        </div>
      </div>
      <div className="rowWrapper">
        <p className="text-content">{curricullum_description}</p>
        {!isPublish && (
          <div className="rowContents">
            <Button>+ ユーザー追加</Button>
          </div>
        )}
      </div>
      {!isPublish && (
        <div className="rowWrapper blOrange">
          <div className="rowContent">
            <span className="text-content">ユーザー追加先に設定 </span>
            <Switch checked={curriculumSelected} size="small" onChange={handleSwitch} />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default TooltipUserSetting;
