import { Document, Page, View, Text, Font } from '@react-pdf/renderer';
import { map } from 'lodash';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';
import { useMemo } from 'react';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface TableRow {
  index: number;
  code: string;
  name: string;
}

const PDFRolesMasterTableDocument = ({ roles }: { roles: Types.AuthorityMasterType[] }) => {
  const columns: { title: string; width: string | number }[] = [
    {
      title: '序列順位',
      width: 90,
    },
    {
      title: 'コード',
      width: 180,
    },
    {
      title: '名称',
      width: 546,
    },
  ];

  const rows = useMemo(() => {
    const roleList: TableRow[] = map(roles, (role, index) => ({
      index: index + 1,
      code: role.code,
      name: role.name,
    }));

    return roleList;
  }, [roles]);

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>役職マスタ</Text>
        </View>

        <View style={styles.subscription}>
          <Text style={styles.textSubscription}>役職マスタの作成・管理を行う画面です。</Text>
          <Text style={styles.textSubscription}>
            役職マスタは社内ユーザーの役職として社内ユーザーマスタに設定します
          </Text>
        </View>
        <View style={styles.body}>
          <View style={styles.textCountGeneral}>
            <Text style={styles.textCountNumber}>{rows.length}</Text>
            <Text style={styles.textCountDescription}>件表示</Text>
            <Text style={styles.textCountNumber}>/</Text>
            <Text style={styles.textCountNumber}>{rows.length}</Text>
            <Text style={styles.textCountDescription}>件中</Text>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              {columns.map((column, index) => (
                <View key={index} style={[styles.wrapCell, { width: column.width }]}>
                  <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
                </View>
              ))}
            </View>

            {rows.map((row, rowIndex) => (
              <View
                key={rowIndex}
                style={[
                  styles.tableRow,
                  { borderBottom: rowIndex !== rows.length - 1 ? 'none' : '1px solid #e5e5e5' },
                ]}
              >
                {Object.keys(row).map((key, colIndex) => (
                  <View
                    key={colIndex}
                    style={[styles.wrapCell, { width: columns[colIndex].width }]}
                  >
                    <Text style={styles.tableCell}>{row[key as keyof TableRow]}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFRolesMasterTableDocument;
