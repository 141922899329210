import React, { useMemo } from 'react';
import { Col, Form, Input, Radio, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { SectionStyled } from '../UploadImageVideoQuestion/styles';
import * as Types from 'types';
import { FileType } from 'constant/enum.constant';
import { includes } from 'lodash';
import ReactPlayer from 'react-player';
import { NoImage2 } from 'assets';
import { UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';

interface Props {
  imageAttach?: React.JSX.Element[];
  renderProblem?: (
    formikValues: Types.CreateEditQuestionFormik,
    problemKey: number
  ) => React.JSX.Element;
  data: Types.CreateEditQuestionFormik;
  imageComment?: React.JSX.Element[];
  timeLimit: Types.TimeLimitType[];
}

const QuestionPDF: React.FC<Props> = ({
  imageAttach,
  renderProblem,
  data,
  imageComment,
  timeLimit,
}) => {
  const defaultImageAttach = useMemo(() => {
    const defaultImageCount = 3;
    const attachCount = data.attach.length;

    const images = [];

    for (let i = 0; i < defaultImageCount; i++) {
      if (i < attachCount) {
        const attach = data.attach[i];
        images.push(
          <div className="image" key={i}>
            {attach?.type === FileType.MP4 || includes(attach as unknown as string, 'mp4') ? (
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={
                  attach?.originFileObj
                    ? URL.createObjectURL(new Blob([attach?.originFileObj as Blob]))
                    : (attach as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                src={
                  attach?.originFileObj
                    ? URL.createObjectURL(new Blob([attach?.originFileObj as Blob]))
                    : attach
                    ? (attach as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={attach?.name}
              />
            )}
          </div>
        );
      } else {
        images.push(
          <div className="image" key={i}>
            <img src={NoImage2} className="image-question" alt="No Image" />
          </div>
        );
      }
    }

    return images;
  }, [data.attach]);

  const defaultImageComment = useMemo(() => {
    const defaultImageCount = 3;
    const commentImageCount = data.comment_attach.length;

    const images = [];

    for (let i = 0; i < defaultImageCount; i++) {
      if (i < commentImageCount) {
        const comment_attach = data.comment_attach[i];
        images.push(
          <div className="image" key={i}>
            {comment_attach?.type === FileType.MP4 ||
            includes(comment_attach as unknown as string, 'mp4') ? (
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={
                  comment_attach?.originFileObj
                    ? URL.createObjectURL(new Blob([comment_attach?.originFileObj as Blob]))
                    : (comment_attach as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                src={
                  comment_attach?.originFileObj
                    ? URL.createObjectURL(new Blob([comment_attach?.originFileObj as Blob]))
                    : comment_attach
                    ? (comment_attach as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={comment_attach?.name}
              />
            )}
          </div>
        );
      } else {
        images.push(
          <div className="image" key={i}>
            <img src={NoImage2} className="image-question" alt="No Image" />
          </div>
        );
      }
    }

    return images;
  }, [data.comment_attach]);

  const defaultRenderProblem = (
    formikValues: Types.CreateEditQuestionFormik,
    problemKey: number
  ) => {
    const key = `problems${problemKey}_attach` as keyof Types.CreateEditQuestionFormik;

    const problemArray = formikValues[key] as UploadFileAntd<File>[] | undefined;
    const problem = problemArray ? problemArray[0] : undefined;

    const labels = ['A', 'B', 'C'];
    const label = labels[problemKey - 1];

    const isVideo = problem?.type === FileType.MP4 || includes(problem as unknown as string, 'mp4');

    return (
      <>
        <div className="image" key={problemKey}>
          {isVideo ? (
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              url={
                problem?.originFileObj
                  ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                  : (problem as unknown as string)
              }
              controls
              config={{
                file: {
                  attributes: {
                    disablePictureInPicture: true,
                    controlsList: 'nodownload noplaybackrate',
                  },
                },
              }}
            />
          ) : (
            <img
              src={
                problem?.originFileObj
                  ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                  : problem
                  ? (problem as unknown as string)
                  : NoImage2
              }
              className="image-question"
              alt={problem?.name}
            />
          )}
        </div>
        <p className="label">{`選択肢${label}`}</p>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #e5e5e5',
          padding: '10px 20px',
        }}
        className="header-pdf"
      >
        <span
          style={{
            fontSize: 18,
            color: '#424242',
          }}
        >
          設問マスタ
        </span>
      </div>
      <SectionStyled style={{ padding: '16px 32px' }}>
        <div className="wrapper">
          <Form colon={false} layout="vertical" className="form">
            <p className="label-note">
              <span className="number">1</span>設問内容
            </p>
            <Form.Item
              label={
                <span className="text-label">
                  設問名
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                className="input"
                type="text"
                showCount
                maxLength={30}
                placeholder="全角：最大30文字"
                value={data.name}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-label">
                  設問内容
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea"
                showCount
                maxLength={480}
                placeholder="全角：最大480文字"
                value={data.description}
              />
            </Form.Item>
            <Form.Item className="form-input" label="設問画像・動画">
              <div className="wrap-show-image" style={{ margin: 0 }}>
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {imageAttach || defaultImageAttach}
                </div>
              </div>
            </Form.Item>

            <div className="mr-30" />
            <p className="label-note">
              <span className="number">2</span>解答選択肢
            </p>
            <Form.Item
              label={
                <span className="text-label">
                  選択肢A
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea-small"
                showCount
                maxLength={60}
                placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                value={data.problems1}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-label">
                  選択肢B
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea-small"
                showCount
                maxLength={60}
                placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                value={data.problems2}
              />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-label">
                  選択肢C
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea-small"
                showCount
                maxLength={60}
                placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                value={data.problems3}
              />
            </Form.Item>
            <Form.Item name="problems" className="form-input" label="解答選択肢画像・動画">
              <div className="wrap-show-image" style={{ margin: 0 }}>
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {[1, 2, 3].map((problemKey) => (
                    <div key={problemKey}>
                      {renderProblem
                        ? renderProblem(data, problemKey)
                        : defaultRenderProblem(data, problemKey)}
                    </div>
                  ))}
                </div>
              </div>
            </Form.Item>

            <Form.Item
              label={
                <span className="text-label">
                  解答
                  <span className="require">*</span>
                </span>
              }
              className="form-input form-input-radio"
            >
              <Radio.Group className="radio_group" value={data.answer}>
                <Radio value="A" className="radio-text">
                  選択肢A
                </Radio>
                <Radio value="B" className="radio-text">
                  選択肢B
                </Radio>
                <Radio value="C" className="radio-text">
                  選択肢C
                </Radio>
              </Radio.Group>
            </Form.Item>
            <div className="mr-30" />
            <p className="label-note">
              <span className="number">3</span>解説・その他設定
            </p>
            <Form.Item
              label={
                <div className="wrap-label-form-input">
                  <span className="text-label">
                    解説
                    <span className="require">*</span>
                  </span>
                </div>
              }
              className="form-input"
            >
              <TextArea
                className="input-textarea"
                showCount
                maxLength={1050}
                placeholder="全角：最大1050文字"
                value={data.comment}
              />
            </Form.Item>
            <Form.Item name="comment_attach" className="form-input" label="解説画像・動画">
              <div className="wrap-show-image" style={{ margin: 0 }}>
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {imageComment || defaultImageComment}
                </div>
              </div>
            </Form.Item>

            <Form.Item
              label={
                <span className="text-label">
                  制限時間
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Select
                className="time_limit"
                value={(timeLimit || []).find((e) => e.option_id === data.time_limit)?.value || ''}
              ></Select>
            </Form.Item>
            <Row>
              <Col span={24}>
                <div className="form-input-radio">
                  <Form.Item
                    label={
                      <span className="text-label">
                        スコア設定（設問難易度の設定）
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Radio.Group className="radio_group" value={data.score}>
                      <Radio value={1} className="radio-text">
                        1
                      </Radio>
                      <Radio value={2} className="radio-text">
                        2
                      </Radio>
                      <Radio value={3} className="radio-text">
                        3
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </SectionStyled>
    </>
  );
};

export default QuestionPDF;
