import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
  },

  header: {
    padding: 11.7,
    paddingBottom: 12,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.5,
    color: '#424242',
  },

  body: {
    paddingHorizontal: 11.7,
    paddingVertical: 5.6,
    flexDirection: 'column',
    gap: 10,
  },

  subscription: {
    paddingVertical: 17.54,
    paddingHorizontal: 11.7,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },

  statusTime: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#EBEBEB',
    borderRadius: 2,
    border: 1,
    borderColor: '#E5E5E5',
    padding: 8,
  },

  statusTimeText: {
    fontSize: 7,
    color: '#424242',
    paddingHorizontal: 2,
  },

  statusTimeIcon: {
    width: 12,
    height: 12,
  },

  selectBox: {
    textAlign: 'center',
    borderRadius: 3,
    border: 1,
    borderColor: '#CCCCCC',
    paddingVertical: 5,
    paddingLeft: 8,
    paddingRight: 16,
    backgroundColor: '#FFFFFF',
    minHeight: 24,
    maxHeight: 24,
    position: 'relative',
    maxWidth: 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  selectText: {
    fontSize: 7,
    color: '#424242',
    textAlign: 'center',
    maxWidth: 130,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  selectIcon: {
    width: 6.75,
    height: 4,
    position: 'absolute',
    zIndex: 10,
    top: 8,
    right: 4,
    objectFit: 'cover',
  },

  button: {
    borderRadius: 5,
    border: 1,
    borderColor: '#00A3A5',
    backgroundColor: '#FFFFFF',
    padding: 5,
    color: '#00A3A5',
    fontSize: 7,
    marginLeft: 5,
  },

  divider: {
    width: 40,
    height: 1,
    marginLeft: 11.7,
    marginBottom: 11.7,
    backgroundColor: '#DDDDDD',
  },

  searchPanel: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 11.7,
    marginBottom: 11.7,
  },

  label: {
    fontSize: 7,
    color: '#424242',
    paddingVertical: 2,
  },

  searchItem: {
    minWidth: 130,
    marginRight: 8,
  },

  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  lineBorder: {
    width: 24,
    height: 1,
    backgroundColor: '#dddddd',
    marginLeft: 11.7,
  },

  textCountGeneral: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'flex-end',
  },

  textCountNumber: {
    fontSize: 8.186,
    color: '#424242',
  },

  textCountDescription: {
    fontSize: 6.432,
    color: '#424242',
  },

  table: {
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 25.728,
    border: '1px solid #e5e5e5',
  },

  wrapCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 6.35,
    maxLines: 1,
  },

  tableCell: {
    textAlign: 'left',
    fontSize: 7.6,
    fontColor: '#424242',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  headerCell: {
    color: '#2a2a2a',
    fontSize: 7.017,
    fontWeight: 'bold',
  },
});
