import styled from 'styled-components';

type Props = {
  dataCurriculumBatch: boolean;
};

export default styled.div<Props>`
  padding: 30px 30px 0 30px;
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #424242;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
  }
  .form-search {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .item {
      width: 27.5%;
      margin-right: 8px;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-right: 12px;
      display: block;
      width: 60px;
      margin-left: 10px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .text-count {
    position: absolute;
    margin-top: 22px;
    font-family: 'Lato';
    font-size: 14px;
    color: #424242;
    z-index: 9;
    .text-static {
      font-size: 11px;
      padding-left: 3px;
    }
  }
  .table {
    position: relative;
    margin-bottom: 80px;
    margin-top: ${(props) => (props.dataCurriculumBatch ? 0 : 65)}px;
    .flex {
      display: flex;
      align-items: center;
      .text-content {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ant-table-thead {
      border-radius: 2px 2px 0px 0px;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        padding: 11px 16px;
        &:nth-child(3) {
          text-align: left;
        }
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: 'Lato';
        font-size: 13px;
        color: #424242;
        word-wrap: break-word;
        padding: 10px 16px;
        &:nth-child(3) {
          text-align: left;
        }
        .icon {
          color: #c4c4c4;
          font-size: 16px;
          cursor: pointer;
          margin-right: 5px;
        }
      }
      .ant-table-row-selected .ant-table-cell {
        font-weight: 700;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
    }
    .ant-pagination-item-active,
    .ant-pagination-item:hover {
      border-color: #00a3a5;
      a {
        color: #00a3a5;
      }
    }
  }
`;
