import styled from 'styled-components';

export default styled.section`
  @media (max-width: 1440px) {
    .form-search {
      height: 58px;
    }
    .aggregation-condition {
      height: 91px;
    }
    .item:nth-child(1),
    .item:nth-child(4) {
      width: 260px !important;
    }

    .item:nth-child(2) {
      width: 110px !important;
    }

    .item:nth-child(5) {
      width: 248px !important;
    }
    .segmented-item {
      width: 90px !important;
      padding: 5px 8px !important;
    }
    .ant-table-tbody .ant-table-cell {
      padding: 0 5px !important;
    }
  }
  margin: 12px;
  margin-top: 0;
  .title {
    color: #999999;
    font-size: 11px;
    margin-left: 4px;
  }
  .aggregation-condition {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    background: #ffffff;
    padding: 14px 18px 20px 18px;

    .form-search {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .ant-picker-range {
        height: 40px;
        width: 100%;
      }
      .wrap-btn {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .reset-btn {
          color: #00a3a5;
          font-size: 12px;
          text-decoration: underline;
          padding-bottom: 2px;
          cursor: pointer;
        }
      }
      .btn-submit {
        background: #08a3a5;
        height: 40px;
        color: #ffffff;
        border-color: #08a3a5;
        font-size: 13px;
        width: 80px;
        border-radius: 5px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      }
    }
    .item {
      width: 24%;
      margin: 0;
      @media (min-width: 1400px) and (max-width: 1780px) {
        width: 22%;
      }
      label {
        color: #777777;
        font-size: 12px;
      }
      .ant-select-selector {
        height: 40px;
        border-radius: 3px;
      }
      &:nth-child(2) {
        width: 12%;
      }
    }
    .wrap-segmented {
      .label {
        color: #777777;
        font-size: 12px;
      }
    }
    .segmented {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3px;
      .segmented-item {
        display: inline-flex;
        align-items: center;
        padding: 5px 10px;
        background: #ffffff;
        border: 2px solid #cccccc;
        cursor: pointer;
        color: #424242;
        font-weight: 400;
        font-size: 13px;
        height: 40px;
        .icon {
          margin-right: 10px;
          color: #c4c4c4;
        }
        &:nth-child(1) {
          border-radius: 3px 0 0 3px;
          border-right-color: #08a3a5;
        }
        &:nth-child(2) {
          border-radius: 0 3px 3px 0;
          border-left-width: 0;
        }
      }
      .segmented-item-selected {
        border-color: #08a3a5;
        color: #08a3a5;
        font-weight: bold;
        .icon {
          color: #08a3a5;
        }
      }
    }
  }
  .table {
    padding-bottom: 95px;
    margin: 40px 19px 0 19px;
    .number-company {
      background: #777777 !important;
      color: #ffffff !important;
    }
    .render-column {
      text-align: right;
    }
  }
`;
