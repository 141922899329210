import React, { useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { useFormik } from 'formik';
import saveAs from 'file-saver';
import { Button } from 'antd';

import { CloudDownloadOutlined } from '@ant-design/icons';

import { CreateAuthorityMasterFormik, AuthorityMasterType } from 'types';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import AuthorityMasterFrom from 'containers/AuthorityMasterFrom';
import { CreateAuthorityMasterSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { updateItemAuthorityByID } from '../thunk';
import EditAuthorityMasterStyled from './styles';
import { routes } from 'navigations/routes';
import PDFDocument from '../PDFDocument';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';

const EditAuthorityMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const { userInfo } = useSelector(authSelector);
  const state = useLocation().state as AuthorityMasterType;

  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const formikEdit = useFormik<CreateAuthorityMasterFormik>({
    initialValues: {
      name: state.name,
      admin_dashboard_permission: state.admin_dashboard_permission,
      inter_users_master_permission: state.inter_users_master_permission,
      roles_master_permission: state.roles_master_permission,
      departments_master_permission: state.departments_master_permission,
      position_master_permission: state.position_master_permission,
      admin_master_permission: state.admin_master_permission,
      official_curricullum_master_permission: state.official_curricullum_master_permission,
      curricullum_master_permission: state.curricullum_master_permission,
      question_master_permission: state.question_master_permission,
    },
    validationSchema: CreateAuthorityMasterSchema,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        updateItemAuthorityByID({
          id: state.i_id,
          data: {
            item: {
              ...values,
              company_id: userInfo?.company_id,
            },
            is_force_update: true,
            return_item_result: false,
          },
        })
      );
      if (updateItemAuthorityByID.fulfilled.match(resultAction)) {
        setShowCompleteModal(true);
      } else {
        setShowActionErrorModal(true);
      }
      setShowCompleteModal(true);
    },
  });

  const handleExportPDF = async (value: string) => {
    if (value !== 'pdf' || !state) return;

    const blob = await pdf(<PDFDocument role={state} />).toBlob();
    saveAs(blob, `${state.code}_${state.name}.pdf`);
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    (() => {
      if (!id) return;
      // const resultAction = await dispatch(getReportDataByConditions({ id }));
      // if (getReportDataByConditions.rejected.match(resultAction)) {
      //   navigate(generatePath(routes.AuthorityMaster.path, { entity: 'receiving' }));
      // }
    })();
  }, [dispatch, id, navigate]);

  return (
    <EditAuthorityMasterStyled>
      <Header title="権限マスタ 編集" className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            権限マスタの編集を行う画面です。
            <br />
            編集完了後は更新ボタンをクリックしてください。
          </p>
          <div className="btn-div">
            <Button
              className="btn btn-active"
              icon={<CloudDownloadOutlined className="icon-cloud" />}
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              エクスポート
            </Button>
          </div>
        </div>
        <AuthorityMasterFrom formik={formikEdit} />
      </div>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="更新が完了しました"
        onSubmit={() =>
          navigate(generatePath(routes.AuthorityMaster.path, { entity: 'receiving' }))
        }
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="権限の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の更新に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
      <PopupConfirmExportFile
        disableCsv
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportPDF}
      />
    </EditAuthorityMasterStyled>
  );
};

export default EditAuthorityMaster;
