import React, { useEffect, useMemo, useState } from 'react';
import { CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { Button, Radio } from 'antd';

import { createReleaseNote, createReleaseNoteSection, getManageRelease } from '../../thunk';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import DeleteCompleted from 'components/Modal/DeleteCompleted';
import { authSelector } from 'containers/Auth/selectors';
import { publicManagerSelector } from '../../selectors';
import Completed from 'components/Modal/Completed';
import { loadingRef } from 'components/Loading';
import { Input, Modal, TextArea } from 'components';
import ExecuteDelete from '../ExecuteDelete';
import { routes } from 'navigations/routes';
import UpdateStatus from '../UpdateStatus';
import { useAppDispatch } from 'hooks';
import Published from '../Published';
import * as Types from 'types';
import { ManageReleaseStyled } from './styles';

interface Props {
  selected?: Types.ManageReleaseRes;
  publish: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoteReleaseNew: React.FC<Props> = ({ visible, setVisible, publish, selected }) => {
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<boolean>(false);
  const [visiblePublished, setVisiblePublished] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleDeleted, setVisibleDeleted] = useState<boolean>(false);
  const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);
  const [, setShowPopup] = useState(false);

  const { userInfo } = useSelector(authSelector);

  const { releaseNoteDetail, loading } = useSelector(publicManagerSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  // const handlePopupClose = () => {
  //   setShowPopup(false);
  // };

  const defaultConfig = useMemo(
    () => ({
      license: '%LICENSE_KEY%',
      placeholder: '本文を入力...',
      height: 550,
      statusbar: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: 'Insert Video',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  const handleUpdateStatus = async () => {
    if (!userInfo || !selected) return;

    const resultAction = await dispatch(
      createReleaseNote({
        item: {
          company_id: userInfo.company_id,
          release_note_title: formik.values.title,
          curriculum_publish_history_id: selected.history_id,
          curriculum_code: selected.curriculum_code,
          publish_status: 2,
          version: 1,
          description: formik.values.description,
          createdby: userInfo.login_id,
          createdat: new Date(),
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );
    await Promise.all(
      formik.values.sections?.map((value, index) => {
        if (createReleaseNote.fulfilled.match(resultAction)) {
          return dispatch(
            createReleaseNoteSection({
              item: {
                company_id: userInfo.company_id,
                section_name: value.section_name,
                release_note_id: resultAction?.payload.item?.release_note_id,
                text: value.text,
                display_order: index,
                createdby: userInfo.login_id,
                createdat: new Date(),
              },
              realtime_auto_link: true,
            })
          );
        }
      })
    );
    await dispatch(
      getManageRelease({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'curriculum_code',
            search_value: [selected.curriculum_code],
          },
        ],
        page: 1,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
      })
    );
    await setVisiblePublished(true);
  };

  const formik = useFormik<Types.ReleaseNote.ReleaseNoteFormik>({
    initialValues: {
      title: '',
      description: '',
      version: 1.0,
      curriculum_code: '',
      release_note_id: '',
      sections: [
        {
          section_name: '',
          text: '',
        },
      ],
    },
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (userInfo && selected) {
        const resultAction = await dispatch(
          createReleaseNote({
            item: {
              company_id: userInfo.company_id,
              release_note_title: values.title,
              curriculum_publish_history_id: selected.history_id,
              curriculum_code: selected.curriculum_code,
              publish_status: 0,
              description: values.description,
              version: selected.publish_version,
              createdby: userInfo.login_id,
              createdat: new Date(),
            },
            return_item_result: true,
            return_display_id: true,
            realtime_auto_link: true,
          })
        );
        await Promise.all(
          formik.values.sections?.map((value, index) => {
            if (createReleaseNote.fulfilled.match(resultAction)) {
              return dispatch(
                createReleaseNoteSection({
                  item: {
                    company_id: userInfo.company_id,
                    section_name: value.section_name,
                    release_note_id: resultAction.payload?.item?.release_note_id,
                    text: value.text,
                    display_order: index,
                    createdby: userInfo.login_id,
                    createdat: new Date(),
                  },
                  realtime_auto_link: true,
                })
              );
            }
          })
        );
        await dispatch(
          getManageRelease({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
              {
                id: 'curriculum_code',
                search_value: [selected.curriculum_code],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
          })
        );
        setVisible(false);
      }
    },
  });

  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <Modal
      title={<span className="title">リリースノート 新規作成</span>}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      width={1400}
      open={visible}
      onCancel={handleToggleModal}
    >
      <ManageReleaseStyled isEmptyData={!releaseNoteDetail?.sections.length}>
        <div className="information">
          <div>
            <span className="content">ここから始めるプログラミングの基礎</span>
            <span className="id">（ID：K0002)</span>
          </div>
          <div className="version">
            <span className="label">バージョン：</span>
            <span>1.001（2022/01/10 10:00　初回公開）</span>
          </div>
        </div>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="while-editing">
              <img
                src={
                  !publish
                    ? WhiteEditing
                    : publish === 2
                    ? TickComplete
                    : publish === 1
                    ? UnPublished
                    : StopPublic
                }
                alt=""
              />
              <div className="wrap-input">
                <div className="wrap-input-version">
                  <Form.Item className="item" name="title">
                    <Input name="title" placeholder="【タイトル】最大60文字" />
                  </Form.Item>
                </div>
              </div>
            </div>
            <FieldArray
              name="sections"
              render={({ remove, push }) => (
                <div className="wrap-editor">
                  <div className="left-side">
                    <div className="title-editor">
                      <div className="no-editor">{indexSection + 1}</div>
                      <Input
                        name={`sections.${indexSection}.section_name`}
                        className="input"
                        suffix={null}
                        placeholder="セクション名を入力..."
                      />
                    </div>
                    <JoditEditor
                      value={formik.values.sections[indexSection]?.text}
                      config={defaultConfig}
                      onBlur={(newContent) =>
                        formik.setFieldValue(`sections.${indexSection}.text`, newContent)
                      }
                    />

                    <div className="wrap-bottom-editor">
                      <div className="wrap-button-editor">
                        <Button
                          className="btn button-prev"
                          disabled={indexSection === 0}
                          onClick={() => setIndexSection((prevState) => prevState - 1)}
                        >
                          <CaretLeftOutlined />
                          <span>前のセクション</span>
                        </Button>
                        <Button
                          className="btn button-next"
                          disabled={indexSection === formik.values.sections.length - 1}
                          onClick={() => setIndexSection(indexSection + 1)}
                        >
                          <span>次のセクション</span>
                          <CaretRightOutlined />
                        </Button>
                      </div>
                      <div
                        className="delete-editor"
                        onClick={() => {
                          setVisibleConfirmDelete(true);
                        }}
                      >
                        <DeleteOutlined
                          className="icon-delete"
                          style={{
                            color: '#00989A',
                          }}
                        />
                        <span>このセクションを削除</span>
                      </div>
                    </div>
                    <DeleteCompleted
                      onSubmit={() => {
                        setIndexSection((prevState) => (prevState > 0 ? prevState - 1 : 0));
                        remove(indexSection);
                      }}
                      visible={visibleDeleted}
                      setVisible={setVisibleDeleted}
                    />
                  </div>
                  <div className="right-side">
                    <Form.Item name="description" label="概要：" className="text-area">
                      <TextArea name="description" rows={5} placeholder="最大480文字" />
                    </Form.Item>
                    <span className="title-right">セクション</span>
                    <div className="dashed-line" />
                    <div className="wrap-radio">
                      <Radio.Group
                        value={indexSection}
                        onChange={(e) => setIndexSection(e.target.value)}
                      >
                        {formik.values.sections.map((_, index) => (
                          <div
                            className={`input-radio ${
                              formik.errors.sections && formik.errors.sections[index] ? 'error' : ''
                            } ${index === indexSection && 'section-checked'}`}
                            key={index}
                          >
                            <span className="label-radio">{index + 1}</span>
                            <Input
                              placeholder="セクション名を入力..."
                              bordered={false}
                              name={`sections.${index}.section_name`}
                              onClick={() => setIndexSection(index)}
                              suffix={<Radio value={index} />}
                            />
                          </div>
                        ))}
                      </Radio.Group>
                    </div>
                    <div className="wrap-button-add">
                      {formik.values.sections.length < 6 && (
                        <Button
                          className="add-section"
                          onClick={() =>
                            push({
                              section_name: '',
                              text: '',
                            })
                          }
                        >
                          ＋ セクションの追加
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            />
            <div className="wrap-submit">
              <div className="wrap-submit">
                <div className="wrap-button">
                  <SubmitButton className="btn-outline">一時保存</SubmitButton>
                  <Button className="btn btn_submit" onClick={() => setVisibleUpdate(true)}>
                    公開
                  </Button>
                  <Button className="btn btn_close" onClick={() => setVisible(false)}>
                    キャンセル
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          <Completed
            title="リリースノートを保存しました。"
            visible={visibleComplete}
            setVisible={setVisibleComplete}
            onSubmit={() => navigate(routes.PublicManagement.path)}
          />
          <UpdateStatus
            publish={publish}
            status="unPublished"
            title="リリースノートを公開します"
            from={UnPublished}
            to={TickComplete}
            description={
              <span>
                リリースノートを公開すると、
                <br /> SKILL FAMILIARで一般のユーザー様が閲覧できる状態になります。
              </span>
            }
            visible={visibleUpdate}
            setVisible={setVisibleUpdate}
            onSubmit={handleUpdateStatus}
          />
          <Published
            visible={visiblePublished}
            setVisible={setVisiblePublished}
            onSubmit={() => navigate(generatePath(routes.PublicManagement.path))}
            setVisibleEdit={setVisible}
          />
          <ExecuteDelete
            visible={visibleConfirmDelete}
            setVisible={setVisibleConfirmDelete}
            onSubmit={() => setVisibleDeleted(true)}
          />
        </FormikProvider>
      </ManageReleaseStyled>
    </Modal>
  );
};

export default NoteReleaseNew;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
  'preview',
];
