import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { createLevelCurriculum, getCurriculum } from '../../thunk';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumSelector } from '../../selectors';
import { Input, Modal } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  node: Types.TreeItem<Types.CurriculumItemType>;
  maxSortOrder?: number;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleTooltip?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateLevelCurriculum: React.FC<Props> = ({
  node,
  maxSortOrder,
  visible,
  setVisible,
  setVisibleTooltip,
}) => {
  const dispatch = useAppDispatch();

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const formik = useFormik<Types.AddLowerHierarchyFormik>({
    initialValues: {
      curriculumName: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (!node || node.columnIndex === undefined) return;
      const resultAction = await dispatch(
        createLevelCurriculum({
          level: node.columnIndex + 1,
          item: {
            company_id: userInfo?.company_id,
            name: values.curriculumName,
            sort_order: maxSortOrder,
            ...(node.columnIndex === 0
              ? { curricullum_code: node.node?.code }
              : { [`level${node.columnIndex}_code`]: node.node?.code }),
          },
          return_item_result: true,
        })
      );
      if (createLevelCurriculum.fulfilled.match(resultAction)) {
        await dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
      }
      resetForm();
      setVisible(false);
      setVisibleTooltip && setVisibleTooltip(false);
    },
  });

  return (
    <Modal
      title={`第${node.columnIndex! + 1}階層 新規作成`}
      width={720}
      open={visible}
      okButton={{
        text: '新規作成',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.dirty,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="sub-title">
            第{node.columnIndex! + 1}階層の新規作成が可能です。
            <br />第{node.columnIndex! + 1}階層名称を入力し、新規作成ボタンをクリックしてください。
          </p>
          <FormikProvider value={formik}>
            <Form layout="vertical" colon={false} className="form">
              <Form.Item
                name="curriculumName"
                label={
                  <span className="text-label">
                    第{node.columnIndex! + 1}階層名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  name="curriculumName"
                  className="input"
                  type="text"
                  placeholder="全角：最大30文字"
                  showCount
                  maxLength={30}
                />
              </Form.Item>
            </Form>
          </FormikProvider>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default CreateLevelCurriculum;
