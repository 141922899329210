import styled from 'styled-components';

export default styled.section`
  @media (max-width: 1440px) {
    canvas {
      height: 400px !important;
    }
    .column-chart {
      height: 651px;
    }
  }
  gap: 12px;
  margin: 40px 18px;
  display: flex;
  justify-content: space-between;
  .column-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    width: 50%;
    //height: 65vh;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    padding: 26px 17px;
    .title {
      color: #424242;
      font-size: 18px;
      font-weight: 400;
      font-family: Noto Sans Javanese;
      text-align: center;
      margin-bottom: 58px;
    }
    .bottom-text {
      text-align: center;
      color: #519ee3;
      font-size: 15px;
      font-weight: 700;
      font-family: Noto Sans Javanese;
      margin-bottom: 20px;
      margin-top: 35px;
      &:nth-of-type(2) {
        color: #fd7f68 !important;
      }
    }
  }
`;
