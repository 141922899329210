import { AuthorityMasterType, Roles } from 'types';

type RouterWithScreenPermissionsType = {
  [key in keyof Omit<
    Roles.ResponseType,
    | 'i_id'
    | 'company_id'
    | 'code'
    | 'name'
    | 'deletedat'
    | 'updatedat'
    | 'updatedby'
    | 'createdat'
    | 'createdby'
    | 'payment_master_permission'
    | 'timer_master_permission'
    | 'bundle_master_permission'
  >]: string[];
};

type RouterPermissionsType = {
  [key in keyof Omit<
    AuthorityMasterType,
    | 'i_id'
    | 'title'
    | 'company_id'
    | 'code'
    | 'name'
    | 'deletedat'
    | 'updatedat'
    | 'updatedby'
    | 'createdat'
    | 'createdby'
    | 'payment_master_permission'
    | 'timer_master_permission'
    | 'bundle_master_permission'
  >]: string[];
};

export const ROUTER_WITH_SCREEN_PERMISSIONS: RouterWithScreenPermissionsType = {
  admin_dashboard_permission: [],
  skill_check_implementation_permission: [],
  training_report_permission: [],
  skill_check_report_permission: [],
  my_chart_permission: [],
  manual_permission: [],
  knowledge_permission: [],
  official_curriculum_permission: [],
  curricullum_master_permission: [],
  question_master_permission: [],
  skill_check_master_permission: [],
  inter_users_master_permission: [],
  groups_master_permission: [],
  users_master_permission: [],
  roles_master_permission: [],
  departments_master_permission: [],
  positions_master_permission: [],
  admin_master_permission: [],
  report_view_permission: [],
  skill_check_tree_permission: [],
  official_curricullum_master_permission: ['/public-management'],
};

export const ROUTER_WITH_PERMISSIONS: RouterPermissionsType = {
  admin_dashboard_permission: [],
  curricullum_master_permission: [],
  question_master_permission: [],
  inter_users_master_permission: [],
  users_master_permission: [],
  roles_master_permission: [],
  departments_master_permission: [],
  admin_master_permission: [],
  position_master_permission: [],
  official_curricullum_master_permission: ['/public-management'],
};
