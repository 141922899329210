import React, { useEffect, useState } from 'react';
import { FormOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import { CreateEditCurriculum, EditLevelCurriculum } from '../../Modal';
import PopupCreateEditQuestion from 'containers/CreateEditQuestion';
import { curriculumSelector } from '../../selectors';
import { ErrorBoundary, Modal } from 'components';
import { VisiblePopupEditType } from '..';
import { SectionStyled } from './styles';
import { UploadFile } from 'antd/lib/upload/interface';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { sharedFileInMinIO } from 'services/minioService';
import { convertFileResponse } from 'libs/utils/question';
import { useAppDispatch } from 'hooks';

interface Props {
  page?: number;
  per_page?: number;
  visible: VisiblePopupEditType;
  setVisible: React.Dispatch<React.SetStateAction<VisiblePopupEditType>>;
}

const EditModal: React.FC<Props> = ({
  page,
  per_page,
  visible: { visible, index },
  setVisible,
}) => {
  const [isOpenModalEditCurriculum, setOpenModalEditCurriculum] = useState<boolean>(false);
  const [isOpenModalEditLevel, setOpenModalEditLevel] = useState<boolean>(false);
  const [isOpenModalEditQuestion, setOpenModalEditQuestion] = useState<{
    visible: boolean;
    type: 'create' | 'edit';
  }>({
    visible: false,
    type: 'edit',
  });
  const [itemEdit, setItemEdit] = useState<{
    id: string;
    name?: string;
    level?: number;
    description?: string;
    fileID?: string;
  }>();
  const [files, setFiles] = useState<UploadFile<File>>();

  const { curricullumMaster } = useSelector(curriculumSelector);
  const dispatch = useAppDispatch();

  const handleGetFileCurriculum = async () => {
    if (!itemEdit || !itemEdit.fileID) return;
    const fileQuestion: UploadFile<File> = { uid: '', name: '' };
    dispatch(startLoading());
    const fileName = extractFileName(itemEdit.fileID);
    const nodeFileUrl = await sharedFileInMinIO(itemEdit.fileID);
    const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
    Object.assign(
      fileQuestion,
      convertFileResponse({
        file: fileFromUrl,
        fileID: itemEdit.fileID,
        fileName: fileName,
      })
    );
    setFiles(fileQuestion);
    dispatch(stopLoading());
  };

  useEffect(() => {
    handleGetFileCurriculum();
  }, [itemEdit]);

  return (
    <Modal
      title={<h3 className="title">カリキュラムマスタ階層編集</h3>}
      open={visible}
      width={860}
      onCancel={() =>
        setVisible({
          visible: false,
          index: -1,
        })
      }
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () =>
          setVisible({
            visible: false,
            index: -1,
          }),
      }}
    >
      <SectionStyled>
        <p className="sub-title">編集したい階層の編集ボタンをボタンをクリックしてください。</p>
        {curricullumMaster[index]?.curriculum_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              カリキュラム名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index].curriculum_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    onClick={() => {
                      setItemEdit({
                        id: curricullumMaster[index].item_ref?.curriculum_code.i_id!,
                        name: curricullumMaster[index].curriculum_name,
                        description: curricullumMaster[index].curriculum_description,
                        fileID: curricullumMaster[index].fileID,
                      });
                      setOpenModalEditCurriculum(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level1_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第１階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level1_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    onClick={() => {
                      setItemEdit({
                        id: curricullumMaster[index].item_ref?.level1_code.i_id!,
                        level: 1,
                        name: curricullumMaster[index].level1_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level2_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第２階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level2_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    onClick={() => {
                      setItemEdit({
                        id: curricullumMaster[index].item_ref?.level2_code.i_id!,
                        level: 2,
                        name: curricullumMaster[index].level2_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level3_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第３階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level3_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    onClick={() => {
                      setItemEdit({
                        id: curricullumMaster[index].item_ref?.level3_code.i_id!,
                        level: 3,
                        name: curricullumMaster[index].level3_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level4_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第４階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level4_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    onClick={() => {
                      setItemEdit({
                        id: curricullumMaster[index].item_ref?.level4_code.i_id!,
                        level: 4,
                        name: curricullumMaster[index].level4_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.question_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              設問名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.question_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    onClick={() => {
                      setItemEdit({
                        id: curricullumMaster[index].item_ref?.question_code.i_id!,
                      });
                      setOpenModalEditQuestion({ visible: true, type: 'edit' });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </SectionStyled>
      <CreateEditCurriculum
        page={page}
        per_page={per_page}
        type="edit"
        textSubmit="更新"
        id={itemEdit?.id}
        title="カリキュラム編集"
        name={itemEdit?.name}
        visible={isOpenModalEditCurriculum}
        description={itemEdit?.description}
        setVisible={setOpenModalEditCurriculum}
        subTitle="カリキュラム名・説明の編集が可能です。編集後に更新ボタンをクリックしてください。"
        fileCurriculum={files}
        data={{ fileID: itemEdit?.fileID }}
      />
      <EditLevelCurriculum
        node={{ node: { i_id: itemEdit?.id, name: itemEdit?.name }, columnIndex: itemEdit?.level }}
        page={page}
        per_page={per_page}
        visible={isOpenModalEditLevel}
        setVisible={setOpenModalEditLevel}
      />
      <ErrorBoundary>
        <PopupCreateEditQuestion
          page={page}
          per_page={per_page}
          question_id={itemEdit?.id}
          openModalCreateEditQuestion={isOpenModalEditQuestion}
          setOpenModalCreateEditQuestion={setOpenModalEditQuestion}
        />
      </ErrorBoundary>
    </Modal>
  );
};

export default EditModal;
