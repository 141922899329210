import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  COMPANIES_ACTIVE_USING_CURRICULUM,
  COMPANIES_DELETE_USING_CURRICULUM,
  COMPANIES_USING_CURRICULUM,
  NUMBER_ACTIVE_USERS_OF_CURRICULUM,
  NUMBER_DELETE_USERS_OF_CURRICULUM,
  NUMBER_NEW_USERS_OF_CURRICULUM,
  NUMBER_USERS_OF_CURRICULUM,
  OFFICIAL_CURRICULUM_INFOMATION,
  OFFICIAL_CURRICULUM_NUM_OF_COMPANIES_RANKING,
  OFFICIAL_CURRICULUM_NUM_OF_USER_RANKING,
} from 'configs';

export const getDataCompaniesCurriculumn = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.HomeCompanyUserSearchReq,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getDataChartCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(
      req.type === 'curriculum_active_usage'
        ? COMPANIES_ACTIVE_USING_CURRICULUM.id
        : req.type === 'number_unlocked_curriculum'
        ? COMPANIES_DELETE_USING_CURRICULUM.id
        : COMPANIES_USING_CURRICULUM.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataNumberUserCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.HomeCompanyUserSearchReq,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getDataNumberUserCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(
      req.type === 'curriculum_usage' || !req.type
        ? NUMBER_USERS_OF_CURRICULUM.id
        : req.type === 'curriculum_active_usage'
        ? NUMBER_ACTIVE_USERS_OF_CURRICULUM.id
        : req.type === 'number_new_curriculum_users'
        ? NUMBER_NEW_USERS_OF_CURRICULUM.id
        : NUMBER_DELETE_USERS_OF_CURRICULUM.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getDataOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_INFOMATION.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialCompanyRanking = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getDataOfficialCompanyRanking', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_NUM_OF_COMPANIES_RANKING.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataOfficialUserRanking = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getDataOfficialUserRanking', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(OFFICIAL_CURRICULUM_NUM_OF_USER_RANKING.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
