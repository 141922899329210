import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { LockOutlined } from '@ant-design/icons';
import { Form } from 'formik-antd';

import { ModalContent } from 'components/Header/styles';
import { changePasswordSchema } from 'libs/validations';
import { InputPassword, Modal } from 'components';
import { changePassword } from './thunk';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import Styled from './styles';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisiblelComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangePassword: React.FC<Props> = ({ visible, setVisible, setVisiblelComplete }) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<Types.ChangePasswordFormik>({
    initialValues: {
      current_password: '',
      new_password: '',
      confirm_password: '',
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (values, { setErrors }) => {
      const resultAction = await dispatch(
        changePassword({
          old_password: values.current_password,
          new_password: values.new_password,
          confirm_password: values.confirm_password,
        })
      );
      if (changePassword.fulfilled.match(resultAction) && !resultAction.payload.error) {
        setVisiblelComplete(true);
        setVisible(false);
      } else {
        setErrors({ current_password: '現在のパスワードが正しくありません' });
      }
    },
  });
  const handleToggleChangeEmail = () => {
    setVisible(false);
    formik.resetForm();
  };

  return (
    <Modal
      title="パスワード変更"
      open={visible}
      width={720}
      onCancel={handleToggleChangeEmail}
      okButton={{
        text: '送信する',
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => handleToggleChangeEmail(),
      }}
    >
      <ModalContent>
        <Styled>
          <FormikProvider value={formik}>
            <p className="guide">
              パスワードを変更します。
              <br />
              変更すると、自動でログアウトされます。
            </p>
            <Form layout="vertical">
              <Form.Item
                name="current_password"
                label={
                  <div>
                    <span className="label">現在のパスワード</span>
                    <span className="require">*</span>
                  </div>
                }
              >
                <InputPassword
                  name="current_password"
                  showCount
                  maxLength={30}
                  prefix={
                    <LockOutlined
                      style={{
                        color: '#bbbbbb',
                        fontSize: 20,
                      }}
                    />
                  }
                  placeholder="現在のパスワード"
                />
              </Form.Item>
              <Form.Item
                name="new_password"
                label={
                  <div>
                    <span className="label">新しいパスワード</span>
                    <span className="require">*</span>
                  </div>
                }
              >
                <InputPassword
                  showCount
                  maxLength={30}
                  name="new_password"
                  prefix={
                    <LockOutlined
                      style={{
                        color: '#bbbbbb',
                        fontSize: 20,
                      }}
                    />
                  }
                  placeholder="新しいパスワード"
                />
              </Form.Item>
              <Form.Item
                name="confirm_password"
                label={
                  <div>
                    <span className="label">新しいパスワード（確認）</span>
                    <span className="require">*</span>
                  </div>
                }
              >
                <InputPassword
                  showCount
                  maxLength={30}
                  name="confirm_password"
                  prefix={
                    <LockOutlined
                      style={{
                        color: '#bbbbbb',
                        fontSize: 20,
                      }}
                    />
                  }
                  placeholder="確認のため新しいパスワードを再度入力"
                />
              </Form.Item>
            </Form>
          </FormikProvider>
        </Styled>
      </ModalContent>
    </Modal>
  );
};

export default ChangePassword;
