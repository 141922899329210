import styled from 'styled-components';

export const SectionStyled = styled.div`
  font-size: 13px;
  color: #424242;
  padding-top: 20px;
  text-align: center;
  .sub-title {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #424242;
    margin-bottom: 50px;
  }
  .form {
    border-top: 1px solid #eaeaea;
    padding: 10px;
    .flex {
      display: flex;
      justify-content: space-between;
      .label-search {
        font-weight: 700;
        font-size: 11px;
        color: #999999;
        .icon {
          color: #c4c4c4;
          font-size: 18px;
          margin-right: 10px;
        }
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-right: 12px;
        display: block;
        width: 60px;
        margin-left: 10px;
      }
    }
  }
  .form-search {
    display: flex;
    justify-content: space-between;
    height: 80px;
    .item {
      width: 32.5%;
      .text-label {
        display: block;
        margin-bottom: 40px;
        font-family: 'Lato';
        color: #424242;
        font-size: 12px;
      }
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        flex: unset;
      }
      .text-input {
        padding: 4px 10px;
      }
    }
  }
  .text-count {
    position: absolute;
    margin-top: 28px;
    font-family: 'Lato';
    left: 22px;
    font-size: 14px;
    color: #424242;
    z-index: 9;
  }
  .table {
    .ant-pagination-item-active {
      border-color: #00a3a5;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0px 0px;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: 'Lato';
        font-size: 13px;
        color: #424242;
        word-wrap: break-word;
      }
      .ant-table-row-selected .ant-table-cell {
        font-weight: 700;
      }
      & > tr.ant-table-row-selected > td {
        background-color: rgba(246, 172, 0, 0.03);
      }
      .icon {
        color: #c4c4c4;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
`;
