import React from 'react';
import { maxBy } from 'lodash';

import NodeContentRenderer from '../NodeRenderer';
import { Wrapper } from './styles';
import * as Types from 'types';

type Props = {
  accept: string;
  tabActive: number;
  isPublish: boolean;
  nodeHeight: number;
  columnIndex: number;
  maxSortOrder?: number;
  treeViewIndex: number;
  column: Types.TreeItem<Types.CurriculumItemType>[];
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setItemMoveCopySelected: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
  onDrop: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropQuestion: (
    currentItem: Types.DropQuestionType,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
};

const ColumnRenderer: React.FC<Props> = ({
  column,
  accept,
  onDrop,
  isPublish,
  tabActive,
  nodeHeight,
  columnIndex,
  maxSortOrder,
  treeViewIndex,
  onDropQuestion,
  itemMoveCopySelected,
  setItemMoveCopySelected,
}) => {
  return (
    <div
      className={`column ${
        itemMoveCopySelected?.node.columnIndex! - 1 === columnIndex &&
        itemMoveCopySelected?.treeViewIndex === treeViewIndex
          ? 'column-active'
          : ''
      }`}
    >
      {Array.from({
        length: maxBy(column, 'lineIndex')?.lineIndex || 0,
      }).map((__, index) => {
        const row = column.find((r) => r.lineIndex! - 1 === index);
        if (!row) {
          return <Wrapper key={index} nodeHeight={nodeHeight} className="rst__node" />;
        }

        return (
          <NodeContentRenderer
            node={row}
            key={index}
            index={index}
            accept={accept}
            onDrop={onDrop}
            isPublish={isPublish}
            tabActive={tabActive}
            nodeHeight={nodeHeight}
            maxSortOrder={maxSortOrder}
            treeViewIndex={treeViewIndex}
            onDropQuestion={onDropQuestion}
            itemMoveCopySelected={itemMoveCopySelected}
            setItemMoveCopySelected={setItemMoveCopySelected}
          />
        );
      })}
    </div>
  );
};

export default ColumnRenderer;
