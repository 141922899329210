import React, { useEffect, useRef, useState } from 'react';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { IconCopy, IconLocked, TickComplete, IconRequired, IconMove } from 'assets';
import TooltipRowContent from '../TooltipRowContent';
import { Wrapper } from './styles';
import * as Types from 'types';
import { TooltipPlacement } from 'antd/es/tooltip';

const NodeRenderer: React.FC<{
  index: number;
  accept: string;
  tabActive: number;
  nodeHeight: number;
  isPublish?: boolean;
  treeViewIndex: number;
  maxSortOrder?: number;
  node: Types.TreeItem<Types.CurriculumItemType>;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setItemMoveCopySelected?: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
  onDrop?: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropQuestion?: (
    currentItem: Types.DropQuestionType,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onCopyCurriculum?: (node: Types.TreeItem<Types.CurriculumItemType>) => void;
}> = ({
  node,
  index,
  accept,
  onDrop,
  isPublish,
  tabActive,
  nodeHeight,
  maxSortOrder,
  treeViewIndex,
  onDropQuestion,
  itemMoveCopySelected,
  setItemMoveCopySelected,
  onCopyCurriculum,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const popRef = useRef<any>(null);

  const [visible, setVisible] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();
  const [place, setPlace] = useState<TooltipPlacement | undefined>('bottomLeft');

  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);

  let lineClass = '';

  if (node.columnIndex === 1) {
    if (node.lineIndex === 1) {
      lineClass = 'rst__lineHalfHorizontalLeftParentNode rst__lineHalfVerticalTopParentNode';
    } else {
      lineClass = 'rst__lineHalfHorizontalLeftVerticalTop rst__lineHalfVerticalTop';
    }
  } else if (node.columnIndex! > 1) {
    if (node.lineIndex === node.parentNode?.lineIndex) {
      lineClass = 'rst__lineHalfHorizontalLeft';
    } else {
      lineClass = 'rst__lineHalfHorizontalLeftVerticalTop rst__lineHalfVerticalTop';
    }
  }

  const [, dropQuestion] = useDrop<Types.DropQuestionType, void, void>({
    accept: 'move-question',
    canDrop: () =>
      !isPublish &&
      tabActive === 0 &&
      node.columnIndex === 4 &&
      nodeLevel4Selected?.i_id === node.node?.i_id,
    drop: (item) => {
      if (item && node.node && onDropQuestion) {
        onDropQuestion(item, node.node);
      }
    },
  });

  const [, drop] = useDrop<
    Types.TreeItem<Types.CurriculumItemType> & { type: string; mode: 'move' | 'copy' },
    void,
    void
  >({
    accept,
    canDrop: (item) => {
      return Boolean(
        item.node &&
          node &&
          onDrop &&
          item.mode &&
          item.node!.columnIndex! - 1 === node.node?.columnIndex
      );
    },
    drop: (item) => {
      if (item.node && node.node && onDrop) {
        onDrop(item.mode, item.node, node.node);
      }
    },
  });

  const [, drag, preview] = useDrag({
    item: {
      node,
      index,
      type: accept,
      width: width,
      mode: itemMoveCopySelected?.type,
      height: 40,
    },
    canDrag:
      itemMoveCopySelected !== undefined
        ? tabActive === 1 && itemMoveCopySelected.node.node!.i_id === node.node?.i_id
        : false,
    begin: () => {
      if (visible) {
        setVisible(false);
      }
    },
  });

  dropQuestion(ref);
  drag(drop(ref));

  const handleSelectModeDrag = (
    currentNode: Types.TreeItem<Types.CurriculumItemType>,
    type: 'move' | 'copy'
  ) => {
    setItemMoveCopySelected &&
      setItemMoveCopySelected({
        type,
        node: currentNode,
        treeViewIndex: treeViewIndex,
      });
    setVisible(false);
  };
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    return () => {
      setWidth(undefined);
    };
  }, [ref]);
  useEffect(() => {
    if (visible) {
      if (popRef.current) {
        const element = popRef.current.getComponent().ref.current?.getElement();
        const browserHeight = window.innerHeight;
        if (element) {
          const elementTop = popRef.current.getRootDomNode().getBoundingClientRect().top;
          if (elementTop + 250 > browserHeight) {
            setPlace('topLeft');
          } else {
            setPlace('bottomLeft');
          }
        }
      }
    }
  }, [visible]);

  const handleVisible = (v: boolean) => {
    setVisible(v);
  };

  return (
    <Wrapper className="rst__node" nodeHeight={nodeHeight} node={node}>
      <div className="rst__nodeContent">
        <div className={'rst__lineBlock ' + lineClass}>
          <div ref={ref} className="rowWrapper">
            <div
              className={
                'row' +
                (itemMoveCopySelected?.node &&
                ((itemMoveCopySelected?.node.columnIndex! < 5 &&
                  itemMoveCopySelected.node.node?.i_id === node.node?.i_id) ||
                  (itemMoveCopySelected?.node.columnIndex! === 5 &&
                    itemMoveCopySelected.node.node?.question_assign_level_i_id ===
                      node.node?.question_assign_level_i_id))
                  ? ' moving'
                  : '') +
                (itemMoveCopySelected?.node &&
                itemMoveCopySelected?.node.columnIndex! - 1 === node.columnIndex &&
                visible
                  ? ' select'
                  : '')
              }
            >
              <Popover
                overlayClassName={
                  tabActive === 1 && node.columnIndex! > 0 ? 'popover-tab-1' : 'popover-tab-custom'
                }
                overlayStyle={{
                  width: 220,
                }}
                placement={place}
                content={TooltipRowContent({
                  node,
                  onDrop,
                  tabActive,
                  setVisible,
                  maxSortOrder,
                  itemMoveCopySelected,
                  handleSelectModeDrag,
                  visible: visible,
                  onCopyCurriculum,
                })}
                trigger="click"
                onVisibleChange={(v) => handleVisible(v)}
                visible={visible}
                ref={popRef}
              >
                <Tooltip
                  placement="bottomLeft"
                  title={<span>{node.node?.description ?? '説明がありません'}</span>}
                >
                  <div
                    className={
                      'rowContents' +
                      (!node.parentNode ? ' rowContentsDragDisabled ' : '') +
                      (nodeLevel4Selected && node.node && nodeLevel4Selected.i_id === node.node.i_id
                        ? ' node-selected'
                        : '')
                    }
                  >
                    {!node.parentNode ? (
                      node.node!.publish ? (
                        <img src={TickComplete} className="icon" alt="publish-icon" />
                      ) : (
                        <img src={IconLocked} className="icon" alt="edit-icon" />
                      )
                    ) : null}
                    {!node.parentNode ? (
                      node.node!.required_curriculum ? (
                        <img src={IconRequired} className="icon" alt="required-icon" />
                      ) : (
                        <div className="icon icon-required" />
                      )
                    ) : null}
                    {itemMoveCopySelected?.node &&
                    ((itemMoveCopySelected?.node.columnIndex! < 5 &&
                      itemMoveCopySelected.node.node?.i_id === node.node?.i_id) ||
                      (itemMoveCopySelected?.node.columnIndex! === 5 &&
                        itemMoveCopySelected.node.node?.question_assign_level_i_id ===
                          node.node?.question_assign_level_i_id)) ? (
                      <img
                        src={itemMoveCopySelected.type === 'copy' ? IconCopy : IconMove}
                        className="icon-dragging"
                        alt="icon"
                      />
                    ) : null}
                    <span className={`rowTitle${node.node!.name ? '' : ' empty'}`}>
                      {node.node!.name || '（空白）'}
                    </span>
                  </div>
                </Tooltip>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NodeRenderer;
