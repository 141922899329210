import { v4 as uuidv4 } from 'uuid';
import { UploadFile } from 'antd';
import { Client, CopyConditions } from 'minio';

const fileReaderStream = require('filereader-stream');

const minioClient = new Client({
  endPoint: process.env.REACT_APP_MINIO_END_POINT ?? '',
  port: 443,
  useSSL: true,
  accessKey: process.env.REACT_APP_MINIO_ACCESS_KEY ?? '',
  secretKey: process.env.REACT_APP_MINIO_SECRET_KEY ?? '',
});

const bucketName = process.env.REACT_APP_MINIO_BUCKET_NAME ?? '';

export const uploadFileToMinIO = (file: UploadFile<File> | File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const uniqueFileName = `${uuidv4()}-${file.name}`;

    const readStream = fileReaderStream(file);

    minioClient.putObject(
      bucketName,
      uniqueFileName,
      readStream,
      file.size || 467744,
      {
        'Content-Type': file.type,
        'X-Amz-Meta-App': 'SPH-REACT-JS',
      },
      (err, objInfo) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(uniqueFileName);
        }
      }
    );
  });
};

export const copyFileMinIO = (fileName: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const originalFileName = fileName.length > 36 ? fileName.substring(37) : fileName;
    const uniqueFileName = `${uuidv4()}-${originalFileName}`;
    const conds = new CopyConditions();
    minioClient.copyObject(
      bucketName,
      uniqueFileName,
      `/${bucketName}/${fileName}`,
      conds,
      (err, objInfo) => {
        if (err) {
          reject(err.message);
        } else {
          resolve(uniqueFileName);
        }
      }
    );
  });
};

export const deletedFileInMinIO = (fileName: string) => {
  minioClient.removeObject(bucketName, fileName, (err) => {
    if (err) {
      return console.log('Unable to remove object', err);
    }
    return console.log('Removed the object');
  });
};

export const sharedFileInMinIO = (fileName: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    minioClient.presignedUrl('GET', bucketName, fileName, 24 * 60 * 60, (err, presignedUrl) => {
      if (err) {
        reject(err.message);
      } else {
        resolve(presignedUrl);
      }
    });
  });
};

export const downloadFileFromMinio = (objectName: string): Promise<File | null> => {
  return new Promise((resolve, reject) => {
    minioClient.getObject(bucketName, objectName, (err, dataStream) => {
      if (err) {
        console.error('Error downloading file:', err);
        reject(err);
        return;
      }

      const chunks: Uint8Array[] = [];
      dataStream.on('data', (chunk) => {
        chunks.push(chunk);
      });

      dataStream.on('end', () => {
        const blob = new Blob(chunks, { type: 'image/png' });
        const file = new File([blob], objectName, { type: blob.type });

        resolve(file);
      });

      dataStream.on('error', (error) => {
        console.error('Error reading data stream:', error);
        reject(error);
      });
    });
  });
};
