import React from 'react';

import { Document, Page, View, Text, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import dayjs from 'dayjs';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const pageSize = 'A4';
const pageOrientation = 'landscape';

const PDFUserDocument = ({ employee }: { employee: Types.EmployeeExportPDF }) => {
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>社内ユーザーマスタ</Text>
        </View>
        <View style={styles.body}>
          <Card title="基本情報">
            <View style={styles.wrapBasicInfo}>
              <View style={styles.formBasicInfo}>
                <Input required textLabel="社員番号" value={employee.employee_code} />
                <Input required textLabel="氏名" value={employee.name} />
                <Input required textLabel="氏名（フリガナ）" value={employee.kana} />
                <Input
                  required
                  textLabel="メールアドレス"
                  value={employee.login_id}
                  styleInput={styles.inputMail}
                />
              </View>

              <View style={styles.formBasicInfo}>
                <View style={styles.wrapDate}>
                  <Input
                    textLabel="生年月日"
                    value={dayjs(employee.birthday).year().toString()}
                    width={70}
                  />
                  <Text style={styles.textDate}>年</Text>

                  <Input
                    value={(dayjs(employee.birthday).month() + 1).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    width={46}
                  />
                  <Text style={styles.textDate}>月</Text>

                  <Input
                    value={dayjs(employee.birthday).date().toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    width={46}
                  />
                  <Text style={styles.textDate}>日</Text>
                </View>

                <View style={styles.wrapDate}>
                  <Input
                    textLabel="入社年月"
                    value={employee.hire_date ? dayjs(employee.hire_date).year().toString() : ''}
                    width={70}
                  />
                  <Text style={styles.textDate}>年</Text>

                  <Input
                    value={
                      employee.hire_date
                        ? (dayjs(employee.hire_date).month() + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })
                        : ''
                    }
                    width={46}
                  />
                  <Text style={styles.textDate}>月</Text>
                </View>

                <Input required textLabel="権限" value="一般社員１" />
              </View>
            </View>
          </Card>

          <Card title="所属・役職情報">
            <Text style={styles.textDescription}>
              所属・役職情報を設定・編集することができます。兼務枠を増やす場合は兼務所属・役職を追加するボタンをクリックしてください。
            </Text>

            <Text style={[styles.textLabel, { fontWeight: 'bold' }]}>メイン所属・役職</Text>
            <View style={styles.wrapPosition}>
              <View style={styles.position}>
                <Input
                  required
                  textLabel="メイン所属"
                  value={employee.main_position?.affiliation_name}
                />
                <Input
                  required
                  textLabel="メイン役職"
                  value={employee.main_position?.position_name}
                />
              </View>
            </View>
          </Card>
        </View>
      </Page>
    </Document>
  );
};

export default PDFUserDocument;

const Card = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <View style={styles.wrapCard}>
    <View style={styles.headerCard}>
      <Text style={styles.textHeaderCard}>{title}</Text>
    </View>
    <View style={styles.wrapContentCard}>{children}</View>
  </View>
);

const Input = ({
  styleInput,
  textLabel,
  required,
  value,
  width,
}: {
  textLabel?: string;
  required?: boolean;
  styleInput?: Style;
  width?: number;
  value: string;
}) => (
  <View style={width ? [styles.wrapInput, { width: width }] : styles.wrapInput}>
    {textLabel && (
      <View style={styles.wrapLabel}>
        <Text style={styles.textLabel}>{textLabel}</Text>
      </View>
    )}

    <View style={styleInput ? [styles.input, styleInput] : styles.input}>
      <Text style={styles.value}>{value}</Text>
    </View>
  </View>
);
