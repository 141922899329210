import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import { USERS } from 'configs';
import * as Types from 'types';

class UserService {
  protected _axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this._axios = axios;
  }

  signIn(data: Types.SignInReq): AxiosPromise<Types.SignInRes> {
    const request: AxiosRequestConfig = {
      url: '/login',
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  signUp(data: Types.SignUpReq): AxiosPromise<Types.SignUpRes> {
    const request: AxiosRequestConfig = {
      url: `/${USERS.name}/registration/confirm`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  addUser(data: Types.AddUserRequest): AxiosPromise<Types.AddUserResponse> {
    const request: AxiosRequestConfig = {
      url: `/${USERS.name}`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  signUpUser(data: Types.SignUpUserRequest): AxiosPromise<Types.SignUpUserResponse> {
    const request: AxiosRequestConfig = {
      url: 'applications/skillfamiliar/datastores/signup/items/new',
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  resetPassword(data: Types.ResetPasswordReq): AxiosPromise<Types.ResetPasswordRes> {
    const request: AxiosRequestConfig = {
      url: `/${USERS.name}/password/forgot`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  setNewPassword(data: Types.SetNewPasswordReq): AxiosPromise {
    const request: AxiosRequestConfig = {
      url: `/${USERS.name}/password/forgot`,
      method: 'PUT',
      data,
    };
    return this._axios(request);
  }

  changePassword(data: Types.ChangePasswordReq): AxiosPromise<Types.ChangePasswordRes> {
    const request: AxiosRequestConfig = {
      url: `/${USERS.name}/password`,
      method: 'PUT',
      data,
    };
    return this._axios(request);
  }

  confirmRegistration(
    params: Types.GetConfirmIdReq
  ): AxiosPromise<Types.GetConfirmRegistrationRes> {
    const request: AxiosRequestConfig = {
      url: `/${USERS.name}/registration/confirm?id=${params.confirmation_id}`,
      method: 'GET',
    };

    return this._axios(request);
  }

  inviteUser(data: Types.InviteUserRequest, item_id: string): AxiosPromise {
    const request: AxiosRequestConfig = {
      url: `applications/skillfamiliar/datastores/signup/items/action/${item_id}/userinvite`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  changeEmail(data: Types.ChangeEmailReq): AxiosPromise<Types.ChangeEmailRes> {
    const request: AxiosRequestConfig = {
      url: `${USERS.name}/email`,
      method: 'POST',
      data,
    };
    return this._axios(request);
  }
}

export default UserService;
