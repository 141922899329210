import { createAsyncThunk } from '@reduxjs/toolkit';

import { userService } from 'services';
import * as Types from 'types';

export const changeEmail = createAsyncThunk<
  Types.ChangeEmailRes,
  Types.ChangeEmailReq,
  Types.ThunkAPI<Types.requestError>
>('users/thunk/changeEmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.changeEmail(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
