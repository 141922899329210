import React, { useCallback, useEffect, useState } from 'react';
import { UploadFile as UploadFileAntd, UploadChangeParam } from 'antd/lib/upload/interface';
import { parse, ParseResult } from 'papaparse';
import { useSelector } from 'react-redux';

import { Upload } from 'antd';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';

import { createQuestion, getOptionLimit } from 'containers/CreateEditQuestion/thunk';
import { HEADER_QUESTION_MASTER_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { getDataQuestion } from '../../thunk';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Dragger } = Upload;

const UploadCSV: React.FC<Props> = ({ visible, setVisible }) => {
  const [isSuccessfully, setIsSuccessfully] = useState<boolean>(false);
  const [visibleError, setVisibleError] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const fetchQuestion = useCallback(
    (conditions?: Types.ConditionsType[]) => {
      if (!userInfo) return;
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              ...(conditions || []),
            ],

            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
          })
        );
        dispatch(stopLoading());
      })();
    },
    [dispatch, userInfo]
  );

  const onClose = () => {
    if (isSuccessfully) {
      setIsSuccessfully(false);
      setFile(undefined);
      fetchQuestion();
    }
    setVisible(false);
  };

  const handleDelete = () => {
    setVisibleError(false);
    setFile(undefined);
  };

  const handleUploadCSV = async () => {
    if (file && file.type === 'text/csv') {
      dispatch(startLoading());
      await new Promise((resolve) => {
        parse(file, {
          complete: async ({ data }: ParseResult<string>) => {
            const resultsDataCSV = data
              .map((item) =>
                Object.fromEntries(HEADER_QUESTION_MASTER_CSV.map(({ key }, i) => [key, item[i]]))
              )
              .splice(1);
            await Promise.all(
              resultsDataCSV.map(async (record) => {
                const resultActionGetOptionLimit = await dispatch(getOptionLimit());
                const resultAction = await dispatch(
                  createQuestion({
                    item: {
                      name: record.name,
                      description: record.description,
                      problems1: record.problems1,
                      problems2: record.problems2,
                      problems3: record.problems3,
                      answer: record.answer,
                      comment: record.comment,
                      time_limit: getOptionLimit.fulfilled.match(resultActionGetOptionLimit)
                        ? resultActionGetOptionLimit.payload.fields[
                            '628cca99a9e2c62a70e43da1'
                          ]?.options
                            ?.filter((o: Types.TimeLimitType) => o.enabled)
                            .find((v: Types.TimeLimitType) => v.value === record.time_limit)
                            ?.option_id || ''
                        : '',
                      creator: userInfo?.name,
                      company_id: userInfo?.company_id,
                      score: Number(record.score),
                      createdat: new Date(),
                      createdby: userInfo?.login_id,
                    },
                    return_item_result: true,
                  })
                );
                if (createQuestion.fulfilled.match(resultAction)) {
                  fetchQuestion();
                }
              })
            );
            resolve(true);
          },
        });
      });
      dispatch(stopLoading());
      setIsSuccessfully(true);
    }
  };
  useEffect(fetchQuestion, [fetchQuestion]);

  return (
    <Modal
      title="カリキュラムマスタ インポート"
      width={720}
      visible={visible}
      okButton={
        file && !isSuccessfully
          ? {
              text: 'インポート',
              onClick: handleUploadCSV,
              loading,
            }
          : undefined
      }
      cancelButton={{
        text: isSuccessfully ? 'OK' : 'キャンセル',
        style: isSuccessfully ? { padding: '9px 50px' } : undefined,
        onClick: onClose,
      }}
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {!isSuccessfully ? (
          <div className="form-upload">
            <div className="form-upload-border">
              <div className="file-upload">
                <div className="flex">
                  <Dragger
                    accept=".csv"
                    beforeUpload={() => false}
                    onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                      setFile(info.file as unknown as File);
                    }}
                  >
                    {file ? (
                      <div className="info-file">
                        <p className="name-file">{file.name} </p>
                      </div>
                    ) : (
                      <>
                        <CloudUploadOutlined className="icon" />
                        <p className="ant-upload-text">
                          インポートするCSVファイルをここにドロップ
                          <br />
                          または
                        </p>
                      </>
                    )}
                    <div className="wrap-button-upload">
                      <button type="button" className="btn-upload">
                        ファイルを選択
                      </button>
                      {file && (
                        <button
                          className="btn-delete"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete();
                          }}
                        >
                          <DeleteOutlined className="icon-delete-outlined" />
                          <span className="text-delete-outlined">ファイルを削除</span>
                        </button>
                      )}
                    </div>
                  </Dragger>
                </div>
              </div>
            </div>
            <p className="text-download-template">
              <FileOutlined className="icon" />
              テンプレートをダウンロード
            </p>
            {visibleError && (
              <div className="error-message">
                <div className="error-message-title">
                  <p className="text-title">
                    <ExclamationCircleOutlined className="icon" />
                    インポートに失敗しました
                  </p>
                  <p className="text-subtitle">
                    エラー項目をご確認のうえ、修正して再度実行してください。
                  </p>
                </div>
                <div className="error-message-content">
                  <ul>
                    <li className="text-content">１行目 カラム数が不正です。 </li>
                    <li className="text-content">２行目 カラム名が不正です。 </li>
                    <li className="text-content">２行目 カリキュラム名称は必須項目です。 </li>
                    <li className="text-content">３行目 制限時間は半角英数で入力してください。</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadCSV;
