import React from 'react';
import { SelectProps } from 'formik-antd';

import SelectStyled from './styles';

const SelectField: React.FC<SelectProps> = ({ children, ...props }) => {
  return (
    <SelectStyled {...props} getPopupContainer={(trigger) => trigger.parentNode}>
      {children}
    </SelectStyled>
  );
};

export default SelectField;
