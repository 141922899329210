import React from 'react';

import Modal from 'components/Modal';
import { ArrowRight } from 'assets';
import Styled from './styles';

interface Props {
  publish: number;
  status: string;
  title: string;
  from: string;
  to: string | React.ReactNode;
  noteText?: React.ReactNode;
  description: React.ReactNode;
  visible: boolean;
  onSubmit?: () => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateStatus: React.FC<Props> = ({
  status,
  visible,
  title,
  setVisible,
  onSubmit,
  description,
  noteText,
  from,
  to,
  publish,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    await handleToggleModal();
    onSubmit && (await onSubmit());
  };

  return (
    <Modal
      visible={visible}
      width={740}
      okButton={{
        text: '公開する',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
      onCancel={handleToggleModal}
      title={title}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <Styled>
        <div className="wrap-image">
          <div className="left-side">
            <span
              className={`name-status ${
                publish === 0 ? 'violet' : publish === 2 ? 'green' : publish === 1 ? 'blue' : ''
              }`}
            >
              {publish === 0
                ? '編集中'
                : publish === 2
                ? '公開中'
                : publish === 3
                ? '公開停止中'
                : publish === 1
                ? '未公開'
                : ''}
            </span>
            <img className="icon-update" src={from} alt="" />
          </div>
          <img className="arrow-right" src={ArrowRight} alt="" />
          <div className="right-side">
            {typeof to === 'string' ? <img className="icon-update large" src={to} alt="" /> : to}
            <span
              className={`name-status large-text ${
                status === 'publish'
                  ? 'green'
                  : status === 'editing'
                  ? 'violet'
                  : status === 'publishWarning'
                  ? 'grey'
                  : ''
              }`}
            >
              {status === 'editing'
                ? '編集中'
                : status === 'publish'
                ? '公開中'
                : status === 'publishWarning'
                ? '公開停止中'
                : status === 'unPublished'
                ? '未公開'
                : ''}
            </span>
          </div>
        </div>
        <p className="description">{description}</p>
        {noteText && <p className="note">{noteText}</p>}
      </Styled>
    </Modal>
  );
};

export default UpdateStatus;
