import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Select, Table } from 'antd';
import { Form } from 'formik-antd';

import { SectionStyled } from './styles';
import { Input, Modal, SelectField } from 'components';

interface Props {
  visible: boolean;
  loading?: boolean;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const columns = [
  {
    title: '必修カリキュラム名',
    dataIndex: 'curriculum_name',
    key: 'curriculum_name',
    width: '50%',
  },
  {
    title: '必修カリキュラムコード',
    dataIndex: 'curriculum_code',
    key: 'curriculum_code',
    width: '30%',
  },
  {
    title: '作成者',
    dataIndex: 'author',
    key: 'author',
    width: '20%',
  },
];

const dataSource = [
  {
    i_id: 1,
    curriculum_name: '管理部カリキュラム',
    curriculum_code: '1000001',
    author: '山田　花子',
  },
  {
    i_id: 2,
    curriculum_name: '管理部カリキュラム',
    curriculum_code: '1000001',
    author: '山田　花子',
  },
  {
    i_id: 3,
    curriculum_name: '管理部カリキュラム',
    curriculum_code: '1000001',
    author: '山田　花子',
  },
  {
    i_id: 4,
    curriculum_name: '管理部カリキュラム',
    curriculum_code: '1000001',
    author: '山田　花子',
  },
];
const CurriculumSelectionModal: React.FC<Props> = ({
  loading,
  onCancel,
  onSubmit,
  visible,
  setVisible,
}) => {
  const handleCloseModal = async () => {
    onCancel && (await onCancel());
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  const formik = useFormik<{ curriculum_name: string; curriculum_code: string; author: string }>({
    initialValues: {
      curriculum_name: '',
      curriculum_code: '',
      author: '',
    },
    onSubmit: (values) => {
      console.log('values', values);
    },
  });

  const { resetForm } = formik;
  return (
    <Modal
      title={<h3 className="title">必修カリキュラム選択</h3>}
      visible={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      okButton={{
        text: '決定',
        onClick: handleSubmitModal,
        loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          設定する必修カリキュラムを絞り込み・選択後、決定ボタンをクリックしてください。
        </p>
        <div className="form">
          <div className="flex">
            <p className="label-search">
              <SearchOutlined className="icon" />
              絞り込み
            </p>
            <p className="label-reset" onClick={() => resetForm()}>
              リセット
            </p>
          </div>
        </div>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '40%',
            }}
            colon={false}
          >
            <div className="form-search">
              <Form.Item
                name="curriculum_name"
                className="item"
                label={<span className="text-label">必修カリキュラム名</span>}
              >
                <Input name="curriculum_name" className="text-input" placeholder="指定なし" />
              </Form.Item>
              <Form.Item
                name="curriculum_code"
                className="item"
                label={<span className="text-label">必修カリキュラムコード</span>}
              >
                <SelectField
                  name="curriculum_code"
                  allowClear
                  placeholder="指定なし"
                  className="select"
                >
                  <Option value="abc">abc</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="author"
                className="item"
                label={<span className="text-label">作成者</span>}
              >
                <SelectField name="author" allowClear placeholder="指定なし" className="select">
                  <Option value="abc">abc</Option>
                </SelectField>
              </Form.Item>
            </div>
          </Form>
        </FormikProvider>
        <span className="text-count">20 件表示 / 120 件</span>
        <Table
          className="table"
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          rowSelection={{
            type: 'radio',
          }}
          pagination={{
            position: ['topRight'],
          }}
          rowKey="i_id"
        />
      </SectionStyled>
    </Modal>
  );
};

export default CurriculumSelectionModal;
