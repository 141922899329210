import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
  },

  header: {
    padding: 11.7,
    paddingBottom: 12,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.5,
    color: '#424242',
  },

  body: {
    paddingHorizontal: 11.7,
    paddingVertical: 5.6,
    flexDirection: 'column',
    gap: 10,
  },

  subscription: {
    paddingVertical: 17.54,
    paddingHorizontal: 11.7,
  },

  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  lineBorder: {
    width: 24,
    height: 1,
    backgroundColor: '#dddddd',
    marginLeft: 11.7,
  },

  textCountGeneral: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'flex-end',
  },

  textCountNumber: {
    fontSize: 8.186,
    color: '#424242',
  },

  textCountDescription: {
    fontSize: 6.432,
    color: '#424242',
  },

  table: {
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
    position: 'relative',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #e5e5e5',
    position: 'relative',
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 25.728,
    border: '1px solid #e5e5e5',
  },

  wrapCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 6.35,
    maxLines: 1,
  },

  tableCell: {
    textAlign: 'left',
    fontSize: 7.6,
    fontColor: '#424242',
  },

  headerCell: {
    color: '#2a2a2a',
    fontSize: 7.017,
    fontWeight: 'bold',
  },

  divider: {
    width: 40,
    height: 1,
    backgroundColor: '#DDDDDD',
    marginBottom: 17.54,
    marginLeft: 11.7,
  },

  targetSection: {
    paddingHorizontal: 11.7,
    paddingBottom: 8,
  },

  targetTitle: {
    color: '#999999',
    fontSize: 7,
  },

  targetPanel: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    marginTop: 2,
  },

  targetPanelSection1: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: 1,
    paddingVertical: 4,
    paddingHorizontal: 6,
    flex: 1,
    borderColor: '#EEEEEE',
  },

  targetPanelTextGroup: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },

  targetPanelTextPrimary: {
    color: '#2A2A2A',
    fontSize: 7,
  },

  targetPanelTextSecondary: {
    color: '#999999',
    fontSize: 6,
  },

  targetPanelText: {
    color: '#D48AE0',
    fontSize: 7,
    paddingHorizontal: 4,
  },

  targetPanelSection2: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: 1,
    borderLeft: 0,
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderColor: '#EEEEEE',
  },

  statusItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  statusIcon: {
    width: 12,
    height: 12,
  },
  statusText: {
    fontSize: 7,
    paddingHorizontal: 4,
  },

  tableDividerHeader: {
    position: 'absolute',
    top: 0,
    bottom: -1,
    left: 229,
    width: 1,
    border: 0.5,
    borderColor: '#555555',
    zIndex: 1000,
  },

  tableDivider: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 230,
    width: 1,
    border: 0.5,
    borderColor: '#555555',
    zIndex: 1000,
  },
});
