import React, { useEffect, useState } from 'react';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { Header, Input, InputPassword, SelectField } from 'components';
import { settingSelector } from 'containers/AppSettings/selectors';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { createEmployeeSchema } from 'libs/validations';
import { CurriculumSelectionModal } from '../Modal';
import { loadingRef } from 'components/Loading';
import { employeeSelector } from '../selector';
import { CREDIT_CARD_MONTH } from 'constant';
import CreateEmployeeStyled from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { config } from 'configs';
import * as Types from 'types';
import {
  addUser,
  createAffiliationRole,
  createEmployee,
  getSelectAffiliation,
  getSelectPosition,
  getSelectRole,
} from '../thunk';

const { Option } = Select;

const CreateEmployee: React.FC = () => {
  const [visibleCurriculumSelection, setVisibleCurriculumSelection] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });
  const [dateJoinedCompany, setDateJoinedCompany] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });

  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { loading, dataSelectRole, dataSelectAffiliation, dataSelectPosition } =
    useSelector(employeeSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleSelectDateJoinedCompany = (type: 'month' | 'year') => (value: string) => {
    if (type === 'month') {
      setDateJoinedCompany((prevState) => ({ ...prevState, month: value }));
      formik.setFieldValue('doj', `${dateJoinedCompany.year}${value}`);
    } else {
      setDateJoinedCompany((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('doj', `${value}${dateJoinedCompany.month}`);
    }
  };

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (value: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: value, day: '' }));
      formik.setFieldValue('dob', `${birthDay.year}${value}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('dob', `${value}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: value }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${value}`);
    }
  };

  const formik = useFormik<Types.CreateEmployeeFormFormik>({
    initialValues: {
      employee_code: '',
      name: '',
      name_furigana: '',
      email: '',
      email_confirm: '',
      password: '',
      password_confirm: '',
      role_code: '',
      dob: '',
      doj: '',
      main_position: {
        affiliation_id: '',
        positions_code: '',
      },
    },
    validationSchema: createEmployeeSchema,
    onSubmit: async (values) => {
      if (confirmStatus) {
        const resultAddUserAction = await dispatch(
          addUser({
            email: values.email,
            username: values.name,
            no_confirm_email: true,
            tmp_password: values.password,
            send_password_to_email: true,
            invitor_id: userInfo?.i_id,
            g_id: config.USER_G_ID,
          })
        );
        if (
          addUser.fulfilled.match(resultAddUserAction) &&
          resultAddUserAction.payload.added &&
          !resultAddUserAction.payload.exists
        ) {
          await Promise.all([
            dispatch(
              createEmployee({
                item: {
                  employee_code: values.employee_code,
                  company_id: userInfo?.company_id!,
                  login_id: values.email,
                  email: values.email,
                  name: values.name,
                  name_furigana: values.name_furigana,
                  user_type: 'member',
                  dob: values.dob || undefined,
                  doj: values.doj || undefined,
                  role_code: values.role_code,
                  password: values.password,
                },
              })
            ),
            dispatch(
              createAffiliationRole({
                item: {
                  company_id: userInfo?.company_id!,
                  affiliation_id: values.main_position.affiliation_id,
                  login_id: values.email,
                  positions_code: values.main_position.positions_code,
                  sort_order: 1,
                  main_role: 'main',
                },
              })
            ),
          ]);
          setVisibleCompleted(true);
        } else {
          setShowActionErrorModal(true);
        }
      } else {
        setConfirmStatus(true);
      }
      // setSubmitting(false);
    },
  });

  useEffect(() => {
    if (userInfo) {
      Promise.all([
        dispatch(
          getSelectPosition({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectAffiliation({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectRole({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <>
      <Header title="社内ユーザー新規登録" />
      <CreateEmployeeStyled collapsedMenu={collapsedMenu}>
        <p className="text-sub-title">ユーザーマスタの新規登録を行う画面です。</p>
        <FormikProvider value={formik}>
          <Form layout="vertical" autoComplete="off">
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="form-left">
                  <Form.Item
                    name="employee_code"
                    label={
                      <span className="text-label">
                        社員番号<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="employee_code"
                      className="text-input"
                      placeholder="半角数字：最大10文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name"
                      className="text-input"
                      placeholder="全角：最大100文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name_furigana"
                    label={
                      <span className="text-label">
                        氏名（フリガナ）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_furigana"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span className="text-label">
                        メールアドレス <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email"
                      type="email"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大300文字"
                      autoComplete="off"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email_confirm"
                    label={
                      <span className="text-label">
                        メールアドレス（確認）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email_confirm"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大300文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="dob"
                    label={
                      <span className="text-label">
                        生年月日 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      className="pull_down input_small"
                      onChange={handleSelectBirthDay('year')}
                      allowClear
                      value={birthDay.year || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('month')}
                      placeholder="--"
                      allowClear
                      value={birthDay.month || undefined}
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('day')}
                      placeholder="--"
                      allowClear
                      value={birthDay.day || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option key={index} value={`${index < 9 ? '0' : ''}${index + 1}`}>
                          {index < 9 ? '0' : ''}
                          {index + 1}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                  <Form.Item
                    name="doj"
                    label={
                      <span className="text-label not-require">
                        入社年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      onChange={handleSelectDateJoinedCompany('year')}
                      className="pull_down input_small"
                      placeholder="----"
                      allowClear
                      value={dateJoinedCompany.year || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 21,
                        },
                        (_, i) => (i + new Date().getFullYear() - 20).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      onChange={handleSelectDateJoinedCompany('month')}
                      className="pull_down input-month-day"
                      placeholder="--"
                      allowClear
                      value={dateJoinedCompany.month || undefined}
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={
                      <span className="text-label">
                        パスワード <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      name="password"
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password_confirm"
                    label={
                      <span className="text-label">
                        パスワード（確認）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      name="password_confirm"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="role_code"
                    className="pull_down"
                    label={
                      <span className="text-label">
                        権限 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="role_code"
                      className="pull_down"
                      placeholder="選択してください"
                      allowClear
                      disabled={confirmStatus}
                    >
                      {dataSelectRole &&
                        dataSelectRole.map(({ i_id, name }) => (
                          <Option value={i_id} key={i_id}>
                            {name}
                          </Option>
                        ))}
                    </SelectField>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">所属・役職情報</div>
              <div className="body">
                <p className="description">所属・役職情報を設定・編集することができます。 </p>
                <span className="label">所属・役職</span>
                <div className="wrap-main-position">
                  <div className="form-row">
                    <div className="item affiliation-item">
                      <Form.Item
                        name="main_position.affiliation_id"
                        label={
                          <span className="text-label">
                            所属 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.affiliation_id"
                          className="pull_down affiliation"
                          placeholder="選択してください"
                          disabled={confirmStatus}
                        >
                          {dataSelectAffiliation &&
                            dataSelectAffiliation.map(({ i_id, name }) => (
                              <Option value={i_id} key={i_id}>
                                {name}
                              </Option>
                            ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                    <div className="item affiliation-item">
                      <Form.Item
                        name="main_position.positions_code"
                        label={
                          <span className="text-label">
                            役職 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.positions_code"
                          className="pull_down affiliation"
                          placeholder="選択してください"
                          disabled={confirmStatus}
                        >
                          {dataSelectPosition &&
                            dataSelectPosition.map(({ i_id, name }) => (
                              <Option value={i_id} key={i_id}>
                                {name}
                              </Option>
                            ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton className="btn btn_submit">
                  {confirmStatus ? '登録' : '確認画面へ'}
                </SubmitButton>
                <button
                  className="btn btn_close"
                  type="button"
                  onClick={() =>
                    confirmStatus ? setConfirmStatus(false) : navigate(routes.Employee.path)
                  }
                >
                  キャンセル
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <CurriculumSelectionModal
          visible={visibleCurriculumSelection}
          setVisible={setVisibleCurriculumSelection}
        />
        <CompletedModal
          title="登録が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() => navigate(generatePath(routes.Employee.path, { entity: 'receiving' }))}
        />
        <ActionErrorModal
          visible={showActionErrorModal}
          setVisible={setShowActionErrorModal}
          onSubmit={() => setConfirmStatus(false)}
          subTitle="Email exists"
          description="Email exists"
        />
      </CreateEmployeeStyled>
    </>
  );
};

export default CreateEmployee;
