import { createAsyncThunk } from '@reduxjs/toolkit';

import { ADMIN_DETAILS, COMPANIES, ROLES } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getDataAuthority = createAsyncThunk<
  Types.GetItemResponseType,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('authorityMaster/thunk/getDataAuthority', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AdministratorMasterFormik>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAdminDetails = createAsyncThunk<
  Types.ReportsItemResponseType,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getAdminDetails', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(ADMIN_DETAILS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
