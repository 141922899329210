import { createSlice } from '@reduxjs/toolkit';

import { DataItemJobTitleMaster } from 'types/services/setting';
import {
  deleteItemJobTitleMaster,
  getJobTitleMaster,
  getRankOrders,
  updateItemJobTitleMaster,
} from './thunk';

export type InitialState = {
  loading: boolean;
  listJobTitleMaster: DataItemJobTitleMaster[];
  searchResult: DataItemJobTitleMaster[];
  listRankOrder: number[];
  total: number;
};

const initialState: InitialState = {
  listJobTitleMaster: [],
  searchResult: [],
  listRankOrder: [],
  loading: false,
  total: 0,
};

export const jobTitleMasterSlice = createSlice({
  name: 'JobTitleMaster-slice',
  initialState,
  reducers: {
    resetListRankOrder: (state) => {
      state.listRankOrder = [];
    },
    resetSearch: (state) => {
      state.searchResult = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getJobTitleMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobTitleMaster.fulfilled, (state, action) => {
      state.listJobTitleMaster = action.payload.report_results;
      state.total = action.payload.totalItems;
      state.loading = false;
    });
    builder.addCase(getJobTitleMaster.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getRankOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRankOrders.fulfilled, (state, action) => {
      state.listRankOrder = Array.from(
        {
          length: Number(action.payload.items[0].rank_order) + 1,
        },
        (_, i) => i + 1
      );
      state.loading = false;
    });
    builder.addCase(getRankOrders.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteItemJobTitleMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteItemJobTitleMaster.fulfilled, (state, action) => {
      state.listJobTitleMaster = state.listJobTitleMaster.filter(
        (item) => item.i_id !== action.payload
      );
      state.loading = false;
    });
    builder.addCase(deleteItemJobTitleMaster.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateItemJobTitleMaster.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateItemJobTitleMaster.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetListRankOrder, resetSearch } = jobTitleMasterSlice.actions;

export default jobTitleMasterSlice.reducer;
