import { createAsyncThunk } from '@reduxjs/toolkit';

import { ATTACH, USERS } from 'configs';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { convertFileResponse } from 'libs/utils/question';
import { services } from 'services';
import { sharedFileInMinIO } from 'services/minioService';
import * as Types from 'types';

export const updateUserInfo = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.User.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/updateUserInfo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/updatFileToS3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createImage = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/createImage', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttachByConditions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/deleteFileAttachByConditions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserInfo = createAsyncThunk<
  Types.GetItemResponseType<Types.User.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getUserInfo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search(USERS.id, req);

    if (data.items[0]?.icon_fileID) {
      const fileName = extractFileName(data.items[0]?.icon_fileID);
      const nodeFileUrl = await sharedFileInMinIO(data.items[0]?.icon_fileID);
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

      data.items[0].avatar = convertFileResponse({
        file: fileFromUrl,
        fileID: data.items[0].icon_fileID,
        fileName: fileName,
      });
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
