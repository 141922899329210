import React, { useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Popover, Select, Input } from 'antd';
import { unionBy } from 'lodash';
import {
  DeleteOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { createLinkQuestionAssignLevel, deleteQuestion } from 'containers/CreateEditQuestion/thunk';
import { memoizedGetFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { curriculumSelector } from 'pages/CurriculumManagement/Curriculum/selectors';
import { getCurriculum } from 'pages/CurriculumManagement/Curriculum/thunk';
import { searchQuestionCurriculumSelector } from '../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { removeNodeLevel4Selected } from '../slice';
import { SelectField } from 'components';
import { loadingRef } from 'components/Loading';
import QuestionPopup from '../QuestionPopup';
import { getQuestionSearch } from '../thunk';
import { useAppDispatch } from 'hooks';
import { Button } from '../styles';
import * as Types from 'types';
import Item from '../Item';

const { Option } = Select;

type Props = {
  selectedQuestion: Array<{
    i_id: string;
    name: string;
    code: string;
  }>;
  setSelectedQuestion: React.Dispatch<
    React.SetStateAction<Array<{ i_id: string; name: string; code: string }>>
  >;
  setOpenModalCreateQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
      onSubmit?: () => void;
    }>
  >;
};

const QuestionSearch: React.FC<Props> = ({
  selectedQuestion,
  setSelectedQuestion,
  setOpenModalCreateQuestion,
}) => {
  const [lvl1Option, setLvl1Option] = useState<Types.CurriculumOptionType[]>([]);
  const [lvl2Option, setLvl2Option] = useState<Types.CurriculumOptionType[]>([]);
  const [lvl3Option, setLvl3Option] = useState<Types.CurriculumOptionType[]>([]);
  const [lvl4Option, setLvl4Option] = useState<Types.CurriculumOptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataPrimitive, setDataPrimitive] = useState<Types.TreeItem<Types.CurriculumItemType>[]>(
    []
  );

  const { curricullum, filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    questionSearch,
    curriculumOption,
    nodeLevel4Selected,
    level1Option,
    level2Option,
    level3Option,
    level4Option,
    creatorOption,
  } = useSelector(searchQuestionCurriculumSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.QuestionSearchFormik>({
    initialValues: {
      curriculum_name: '',
      level1_name: '',
      level2_name: '',
      level3_name: '',
      level4_name: '',
      created_by: '',
      question_name: '',
    },
    onSubmit: async (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        let search_value = values[key as keyof typeof values];
        if (search_value) {
          switch (key) {
            case 'curriculum_name':
              search_value = curriculumOption.find((c) => c.i_id === search_value)?.name || '';
              break;
            case 'level1_name':
              search_value =
                (values.curriculum_name ? lvl1Option : level1Option).find(
                  (lvl) => lvl.i_id === search_value
                )?.name || '';
              break;
            case 'level2_name':
              search_value =
                (values.curriculum_name ? lvl2Option : level2Option).find(
                  (lvl) => lvl.i_id === search_value
                )?.name || '';
              break;
            case 'level3_name':
              search_value =
                (values.curriculum_name ? lvl3Option : level3Option).find(
                  (lvl) => lvl.i_id === search_value
                )?.name || '';
              break;
            case 'level4_name':
              search_value =
                (values.curriculum_name ? lvl4Option : level4Option).find(
                  (lvl) => lvl.i_id === search_value
                )?.name || '';
              break;
            default:
              break;
          }
          conditions.push({
            id: key,
            search_value: [search_value],
            exact_match: (key as keyof typeof values) !== 'question_name',
          });
        }
      });
      await dispatch(
        getQuestionSearch({
          conditions,
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
    },
  });

  const showModalCreateEditQuestion = () => {
    setOpenModalCreateQuestion({ visible: true, type: 'create', onSubmit: formik.handleSubmit });
  };

  const handleLinkQuestion = async (type?: 'all') => {
    setLoading(true);
    if (type === 'all') {
      await Promise.all(
        questionSearch.map(
          (item, i) =>
            !nodeLevel4Selected?.children?.some(({ code }) => code === item.question_code) &&
            dispatch(
              createLinkQuestionAssignLevel({
                item: {
                  company_id: userInfo?.company_id,
                  level4_code: nodeLevel4Selected?.code,
                  code: item.question_code,
                  sort_order: Number(nodeLevel4Selected?.sort_order) + i,
                },
              })
            )
        )
      );
    } else {
      await Promise.all(
        selectedQuestion.map(
          (item, i) =>
            !nodeLevel4Selected?.children?.some(({ code }) => code === item.code) &&
            dispatch(
              createLinkQuestionAssignLevel({
                item: {
                  company_id: userInfo?.company_id,
                  level4_code: nodeLevel4Selected?.code,
                  code: item.code,
                  sort_order: Number(nodeLevel4Selected?.sort_order) + i,
                },
              })
            )
        )
      );
    }
    setLoading(false);
    setSelectedQuestion([]);
    Promise.all([
      dispatch(removeNodeLevel4Selected()),
      dispatch(
        getCurriculum({
          conditions: [
            ...filter_conditions.conditions,
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
    ]);
    formik.handleSubmit();
  };

  const handleDeleteQuestions = async () => {
    if (selectedQuestion.length) {
      await Promise.all(selectedQuestion.map((i) => dispatch(deleteQuestion({ id: i.i_id }))));
      formik.handleSubmit();
    }
  };

  const handleSelectCurriculumName = (value: string) => {
    setLvl1Option([]);
    setLvl2Option([]);
    setLvl3Option([]);
    setLvl4Option([]);
    formik.setFieldValue('level1_name', undefined);
    formik.setFieldValue('level2_name', undefined);
    formik.setFieldValue('level3_name', undefined);
    formik.setFieldValue('level4_name', undefined);

    const newDataPrimitive = memoizedGetFlatDataFromTree({
      treeData: curricullum.find((curr) => curr.i_id === value),
    });
    setDataPrimitive(newDataPrimitive);

    for (let index = 0; index < newDataPrimitive.length; index++) {
      const treeData: Types.TreeItem<Types.CurriculumItemType> = newDataPrimitive[index];
      const newOption = {
        code: treeData.node?.code!,
        company_id: treeData.node?.company_id!,
        i_id: treeData.node?.i_id!,
        name: treeData.node?.name!,
      };
      switch (treeData.columnIndex) {
        case 1:
          setLvl1Option((prevState) => [...prevState, newOption]);
          break;
        case 2:
          setLvl2Option((prevState) => [...prevState, newOption]);
          break;
        case 3:
          setLvl3Option((prevState) => [...prevState, newOption]);
          break;
        case 4:
          setLvl4Option((prevState) => [...prevState, newOption]);
          break;
        default:
          break;
      }
    }
  };

  const handleSelectCurriculumLevel = (level: number, value: string) => {
    if (!formik.values.curriculum_name) return;
    switch (level) {
      case 1:
        formik.setFieldValue('level2_name', undefined);
        formik.setFieldValue('level3_name', undefined);
        formik.setFieldValue('level4_name', undefined);
        setLvl2Option([]);
        setLvl3Option([]);
        setLvl4Option([]);
        break;
      case 2:
        formik.setFieldValue('level3_name', undefined);
        formik.setFieldValue('level4_name', undefined);
        setLvl3Option([]);
        setLvl4Option([]);
        break;
      case 3:
        formik.setFieldValue('level4_name', undefined);
        setLvl4Option([]);
        break;
      case 4:
        break;
      default:
        break;
    }

    const children = dataPrimitive.find((d) => d.node?.i_id === value)?.node?.children;
    if (!children) return;

    for (let index = 0; index < children.length; index++) {
      const node: Types.TreeItem<Types.CurriculumItemType> = children[index];
      const newOption = {
        code: node.code || '',
        company_id: node.company_id || '',
        i_id: node.i_id || '',
        name: node.name || '',
      };
      switch (level) {
        case 1:
          setLvl2Option((prevState) => [...prevState, newOption]);
          if (node.children) {
            for (let index2 = 0; index2 < node.children.length; index2++) {
              const node2: Types.TreeItem<Types.CurriculumItemType> = node.children[index];
              if (node2) {
                setLvl3Option((prevState) => [
                  ...prevState,
                  {
                    code: node2.code || '',
                    company_id: node2.company_id || '',
                    i_id: node2.i_id || '',
                    name: node2.name || '',
                  },
                ]);
                if (node2.children) {
                  for (let index3 = 0; index3 < node2.children.length; index3++) {
                    const node4: Types.TreeItem<Types.CurriculumItemType> = node2.children[index];
                    if (node4) {
                      setLvl4Option((prevState) => [
                        ...prevState,
                        {
                          code: node4.code || '',
                          company_id: node4.company_id || '',
                          i_id: node4.i_id || '',
                          name: node4.name || '',
                        },
                      ]);
                    }
                  }
                }
              }
            }
          }
          break;
        case 2:
          setLvl3Option((prevState) => [...prevState, newOption]);
          if (node.children) {
            for (let index2 = 0; index2 < node.children.length; index2++) {
              const node2: Types.TreeItem<Types.CurriculumItemType> = node.children[index];
              if (node2) {
                setLvl4Option((prevState) => [
                  ...prevState,
                  {
                    code: node2.code || '',
                    company_id: node2.company_id || '',
                    i_id: node2.i_id || '',
                    name: node2.name || '',
                  },
                ]);
              }
            }
          }
          break;
        case 3:
          setLvl4Option((prevState) => [...prevState, newOption]);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    loadingRef.current?.isLoading(loading);
  }, [loading]);

  return (
    <FormikProvider value={formik}>
      <Form
        labelCol={{
          flex: '27%',
        }}
        colon={false}
        className="form"
      >
        <p className="title">条件を絞って設問を検索できます。</p>
        <Form.Item
          name="curriculum_name"
          label={<span className="label">カリキュラム</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            className="select-input"
            name="curriculum_name"
            onChange={handleSelectCurriculumName}
            onClear={() => handleSelectCurriculumName('')}
          >
            {curriculumOption?.map((curr, index) => (
              <Option key={index} value={curr.i_id}>
                {curr.name}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level1_name"
          label={<span className="label">第１階層</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level1_name"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(1, value)}
            onClear={() => handleSelectCurriculumLevel(1, '')}
          >
            {(formik.values.curriculum_name ? lvl1Option : level1Option).map((lvl, index) => (
              <Option key={index} value={lvl.i_id}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level2_name"
          label={<span className="label">第２階層</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level2_name"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(2, value)}
            onClear={() => handleSelectCurriculumLevel(2, '')}
          >
            {(formik.values.curriculum_name ? lvl2Option : level2Option).map((lvl, index) => (
              <Option key={index} value={lvl.i_id}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level3_name"
          label={<span className="label">第３階層</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level3_name"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(3, value)}
            onClear={() => handleSelectCurriculumLevel(3, '')}
          >
            {(formik.values.curriculum_name ? lvl3Option : level3Option).map((lvl, index) => (
              <Option key={index} value={lvl.i_id}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level4_name"
          label={<span className="label">第４階層</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level4_name"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(4, value)}
            onClear={() => handleSelectCurriculumLevel(4, '')}
          >
            {(formik.values.curriculum_name ? lvl4Option : level4Option).map((lvl, index) => (
              <Option key={index} value={lvl.i_id}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="created_by"
          label={<span className="label">作成者</span>}
          className="form-input"
        >
          <SelectField allowClear className="select-input" name="created_by">
            {unionBy(creatorOption, 'creator').map(({ creator }, index) => (
              <Option key={index} value={creator}>
                {creator}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="question_name"
          label={<span className="label">フリーワード</span>}
          className="form-input"
        >
          <Input className="select-input" name="question_name" style={{ maxHeight: '34px' }} />
        </Form.Item>
        <div className="wrap-center">
          <SubmitButton className="btn-search" loading={false}>
            <SearchOutlined className="icon-search" />
            検索
          </SubmitButton>
          <ResetButton className="label-reset">リセット</ResetButton>
        </div>
      </Form>
      <div className="result-search">
        <div className="left-side">
          <Button
            active={!selectedQuestion.length && !!questionSearch?.length && !!nodeLevel4Selected}
            disabled={!!selectedQuestion.length || !questionSearch?.length || !nodeLevel4Selected}
            onClick={() => handleLinkQuestion('all')}
          >
            <DoubleLeftOutlined className="label-icon" />
          </Button>
          <Button
            active={!!selectedQuestion.length && !!nodeLevel4Selected}
            disabled={!selectedQuestion.length || !nodeLevel4Selected}
            onClick={() => handleLinkQuestion()}
          >
            <LeftOutlined className="label-icon" />
          </Button>
          <Popover
            overlayClassName="tooltip-QA"
            content={QuestionPopup}
            overlayStyle={{
              width: 300,
            }}
            trigger="click"
            placement="bottom"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Popover>
        </div>
        <div className="right-side">
          <div className="result">
            {questionSearch && questionSearch.length > 0 ? (
              questionSearch.map((item, index) => (
                <Item
                  key={index}
                  onSubmit={formik.handleSubmit}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                  item={{
                    i_id: item.item_ref.question_code.i_id,
                    code: item.question_code,
                    name: item.question_name,
                  }}
                />
              ))
            ) : (
              <p className="text-result">検索してください</p>
            )}
          </div>
          <div className="footer">
            <Button active className="btn" onClick={showModalCreateEditQuestion}>
              <PlusOutlined className="icon" />
              設問新規作成
            </Button>
            <Button
              active={!!selectedQuestion.length}
              className="btn"
              onClick={handleDeleteQuestions}
            >
              <DeleteOutlined className="icon" />
              設問削除
            </Button>
          </div>
        </div>
      </div>
    </FormikProvider>
  );
};

export default QuestionSearch;
