import yup from 'libs/yup';

export const uploadQuestionCsvSchema = yup.array().of(
  yup.object().shape({
    name: yup
      .string()
      .required('設問名称は必須項目です。')
      .max(60, '設問名称は60以内で入力してください。'),
    description: yup
      .string()
      .required('設問内容は必須項目です。')
      .max(480, '設問内容は480以内で入力してください。'),
    problems1: yup
      .string()
      .required('選択肢Aは必須項目です。')
      .max(60, '選択肢Aは60以内で入力してください。'),
    problems2: yup
      .string()
      .required('選択肢Bは必須項目です。')
      .max(60, '選択肢Bは60以内で入力してください。'),
    problems3: yup
      .string()
      .required('選択肢Cは必須項目です。')
      .max(60, '選択肢Cは60以内で入力してください。'),
    answer: yup
      .string()
      .required('解答は必須項目です。')
      .oneOf(['A', 'B', 'C'], '解答が不正です。'),
    comment: yup
      .string()
      .required('解説は必須項目です。')
      .max(1050, '解説は1050以内で入力してください。'),
    time_limit: yup.string().test('制限時間', '制限時間が不正です。', (value) => {
      if (!value) {
        return true;
      }
      const val = Number(value);
      if (isNaN(val)) {
        return false;
      }
      return val >= 10 && val <= 600 && val % 10 === 0;
    }),
    score: yup
      .string()
      .test(
        'スコア（設問難易度）',
        'スコア（設問難易度）が不正です。',
        (value) => (Number(value) > 0 && Number(value) < 4) || value === ''
      ),
  })
);
