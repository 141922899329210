import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  getDataEmployee,
  getSelectPosition,
  getSelectAffiliation,
  getSelectEmployee,
  getSelectRole,
} from './thunk';

export type InitialState = {
  total: number;
  loading: boolean;
  dataEmployee: Array<Types.EmployeeType>;
  dataSelectAffiliation: Array<Types.AffiliationHierarchy.ResponseType>;
  dataEmployeeNumber: Array<Types.EmployeeNumberRes>;
  affiliationDetail?: Types.AffiliationType;
  dataAffiliation: Array<Types.AffiliationType>;
  dataSelectRole: Array<Types.RoleSelectType>;
  dataSelectEmployeeName: Array<string>;
  dataSelectEmployeeCode: Array<string>;
  dataSkillCheck: Array<Types.SkillCheckEmployeeType>;
  dataSelectPosition: Array<Types.PositionSelectType>;
  dataCurriculumBatch: Array<Types.CurriculumBatchType>;
  dataSelectCurriculum: Array<Types.CurriculumSelectType>;
  dataSelectCreator: Array<string>;
  dataSelectSkillCheck: Array<Types.CurriculumSelectType>;
  dataSelectStartPeriod: Array<string>;
  dataSelectCategory: Array<string>;
  dataSelectGroupingCode: Array<string>;
  dataSelectUseStatus: Array<string>;
  dataSelectCreators: Array<string>;
  dataCurriculumUserCheck: Array<Types.CurriculumCheckType>;
  dataSkillUserCheck: Array<Types.SkillUserCheckType>;
};
const initialState: InitialState = {
  total: 0,
  loading: false,
  dataEmployee: [],
  dataEmployeeNumber: [],
  dataSkillCheck: [],
  dataSelectRole: [],
  dataAffiliation: [],
  dataSelectPosition: [],
  dataSelectAffiliation: [],
  dataSelectEmployeeName: [],
  dataSelectEmployeeCode: [],
  dataCurriculumBatch: [],
  dataSelectCurriculum: [],
  dataSelectCreator: [],
  dataSelectSkillCheck: [],
  dataSelectStartPeriod: [],
  dataSelectCategory: [],
  dataSelectGroupingCode: [],
  dataSelectUseStatus: [],
  dataSelectCreators: [],
  dataCurriculumUserCheck: [],
  dataSkillUserCheck: [],
};
export const employeeSlice = createSlice({
  name: 'employee-page',
  initialState,
  reducers: {},
  extraReducers(builder) {
    const startLoading = (state: InitialState) => {
      state.loading = true;
    };
    const stopLoading = (state: InitialState) => {
      state.loading = false;
    };
    builder
      .addCase(getDataEmployee.pending, startLoading)
      .addCase(getSelectRole.pending, startLoading)
      .addCase(getSelectEmployee.pending, startLoading)
      .addCase(getSelectPosition.pending, startLoading)
      .addCase(getSelectAffiliation.pending, startLoading);

    builder.addCase(getSelectEmployee.fulfilled, (state, action) => {
      const newDataSelectEmployeeName: Array<string> = [];
      const newDataSelectEmployeeCode: Array<string> = [];
      action.payload.report_results.forEach((item) => {
        newDataSelectEmployeeName.push(item.name);
        newDataSelectEmployeeCode.push(item.employee_code);
      });
      state.dataSelectEmployeeName = newDataSelectEmployeeName;
      state.dataSelectEmployeeCode = newDataSelectEmployeeCode;
      stopLoading(state);
    });

    builder.addCase(getSelectPosition.fulfilled, (state, action) => {
      state.dataSelectPosition = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getSelectAffiliation.fulfilled, (state, action) => {
      state.dataSelectAffiliation = action.payload.report_results;
      stopLoading(state);
    });
    builder.addCase(getDataEmployee.fulfilled, (state, action) => {
      state.dataEmployee = action.payload.report_results;
      state.total = action.payload.totalItems;
      stopLoading(state);
    });
    builder.addCase(getSelectRole.fulfilled, (state, action) => {
      state.dataSelectRole = action.payload.report_results;
      stopLoading(state);
    });

    builder
      .addCase(getDataEmployee.rejected, stopLoading)
      .addCase(getSelectAffiliation.rejected, stopLoading)
      .addCase(getSelectPosition.rejected, stopLoading)
      .addCase(getSelectEmployee.rejected, stopLoading)
      .addCase(getSelectRole.rejected, stopLoading);
  },
});

export default employeeSlice.reducer;
