import React, { useEffect, useMemo, useState } from 'react';
import { Button, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';

import { Rank1, Rank2, Rank3, StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { authSelector } from 'containers/Auth/selectors';
import { dashboardSelector } from '../selectors';
import OfficialCurriculumStyled from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getDataOfficialCompanyRanking,
  getDataOfficialCurriculum,
  getDataOfficialUserRanking,
} from '../thunk';

const { Option } = Select;

const OfficialCurriculum: React.FC = () => {
  const [publishSelected, setPublishSelected] = useState<string | number>();
  const [filtered, setFiltered] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);
  const { officialCurriculumData, officialCompanyRanking, officialUserRanking } =
    useSelector(dashboardSelector);
  const dispatch = useAppDispatch();

  const dataRanking = useMemo(() => {
    return (
      [...(officialCompanyRanking || []), ...officialUserRanking] as Array<Types.OfficialRankingRes>
    ).map((e, index) => ({
      company_id: e.company_id,
      curriculum_code: e.curriculum_code,
      curriculum_name: e.curriculum_name,
      number_of_company: e.user_company_id,
      number_of_user: e.login_id,
      rank: index + 1,
    }));
  }, [officialUserRanking, officialCompanyRanking]);

  const dataGoverment = useMemo(() => {
    if (userInfo) {
      return dataRanking.filter((e) => e.company_id === userInfo.company_id);
    }
  }, [dataRanking, userInfo]);

  useEffect(() => {
    dispatch(
      getDataOfficialCurriculum({
        conditions: [
          ...(!!publishSelected
            ? [
                {
                  id: 'publish_status',
                  search_value: [publishSelected],
                  exact_match: true,
                },
              ]
            : []),
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, publishSelected]);

  useEffect(() => {
    Promise.all([
      dispatch(
        getDataOfficialCompanyRanking({
          page: 1,
          per_page: 0,
          sort_fields: [{ id: 'user_company_id', order: 'desc' }],
        })
      ),
      dispatch(
        getDataOfficialUserRanking({
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, [dispatch]);

  return (
    <OfficialCurriculumStyled>
      <div className="left-side">
        <div className="wrap-header-left">
          <span className="title">OFFICIALカリキュラム情報</span>
          <div className="wrap-select">
            <span>公開ステータス：</span>
            <Select
              className="item-select"
              defaultValue="ALL"
              onChange={(e) => setPublishSelected(e)}
            >
              {publish_stasus.map(({ label, value }, index) => (
                <Option value={value} key={index}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Table
          pagination={false}
          className="table"
          rowKey="id"
          dataSource={officialCurriculumData}
          columns={columns}
          scroll={{
            y: '68vh',
          }}
        />
      </div>
      <div className="right-side">
        <div className="wrap-header-right">
          <div>
            <span className="text">OFFICIALカリキュラム</span>
            <br />
            <span className="text subtitle">利用企業数 ランキング</span>
          </div>
          <Button className="btn" onClick={() => setFiltered(!filtered)}>
            自社のみ表示
          </Button>
        </div>
        <Table
          className="table"
          rowClassName={(_, index) => `row-${index + 1}`}
          pagination={false}
          dataSource={filtered ? dataGoverment : dataRanking}
          columns={columnsRanking}
          onRow={(record) => ({
            className: record.company_id === userInfo?.company_id ? 'goverment' : '',
          })}
          scroll={{
            y: '68vh',
          }}
        />
      </div>
    </OfficialCurriculumStyled>
  );
};

const getRanking = (value: number) => {
  switch (value) {
    case 1:
      return <img src={Rank1} alt="" />;
    case 2:
      return <img src={Rank2} alt="" />;
    case 3:
      return <img src={Rank3} alt="" />;
    default:
      return <span>{value}</span>;
  }
};

const columns: ColumnsType<Types.OfficialCurriculumRes> = [
  {
    title: 'ID',
    dataIndex: 'curriculum_code',
    key: 'curriculum_code',
    width: '17%',
  },
  {
    title: 'OFFICIALカリキュラム名',
    dataIndex: 'curriculum_name',
    key: 'curriculum_name',
    width: '46%',
    ellipsis: true,
  },
  {
    title: '公開ステータス',
    dataIndex: 'publish_status',
    key: 'publish_status',
    align: 'center',
    render: (record: number) => getStatus(record),
  },
  {
    title: '設問数',
    dataIndex: 'probs_count',
    key: 'probs_count',
    width: '12%',
    align: 'center',
    render: (record) => record ?? 0,
  },
];

const columnsRanking: ColumnsType<Types.OfficialRankingRes> = [
  {
    title: <span className="title-ranking">Rank</span>,
    dataIndex: 'rank',
    key: 'rank',
    width: '10%',
    align: 'center',
    render: (record, _, index) => {
      return (
        <div className={(record ?? index) < 4 ? `ranking ranking-${record ?? index + 1}` : ''}>
          {getRanking(record ?? index + 1)}
        </div>
      );
    },
  },
  {
    title: (
      <>
        <span className="curriculum-top">OFFICIALカリキュラム名</span>
        <br />
        <span className="curriculum-bottom">作成者</span>
      </>
    ),
    dataIndex: 'curriculum_name',
    key: 'curriculum_name',
    render: (record, _, index) => (
      <>
        <span className={`${index + 1 < 4 ? 'curriculum' : 'curriculum-name'}`}>{record}</span>
        <br />
        <span
          style={{
            fontSize: 11,
            color: '#999999',
          }}
        >
          (株）RSTANDARD
        </span>
      </>
    ),
  },
  {
    title: '利用企業数',
    dataIndex: 'number_of_company',
    key: 'number_of_company',
    width: '15%',
    align: 'right',
    render: (record, _, index) => (
      <span className={`${index + 1 < 4 ? 'number-company' : 'number'}`}>{record ?? 0}</span>
    ),
  },
  {
    title: '利用ユーザー数',
    dataIndex: 'number_of_user',
    key: 'number_of_user',
    width: '20%',
    align: 'right',
    render: (record, _, index) => (
      <span className={`${index + 1 < 4 ? 'number-user' : 'number'}`}>{record ?? 0}</span>
    ),
  },
];

const publish_stasus = [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: '編集中',
    value: '0',
  },
  {
    label: '未公開',
    value: '1',
  },
  {
    label: '公開中',
    value: '2',
  },
  {
    label: '公開停止中',
    value: '3',
  },
];

export const getStatus = (value: number) => {
  switch (value) {
    case 0:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={WhiteEditing} alt="" />
          <span className="violet">編集中</span>
        </div>
      );
    case 1:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={UnPublished} alt="" />
          <span className="blue">未公開</span>
        </div>
      );
    case 2:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={TickComplete} alt="" />
          <span className="green">公開中</span>
        </div>
      );
    case 3:
      return (
        <div className="wrap-status">
          <img className="icon-status" src={StopPublic} alt="" />
          <span className="grey">公開停止中</span>
        </div>
      );
  }
};

export default OfficialCurriculum;
