import yup from '../yup';

export const createEditCurriculumSchema = yup.object().shape({
  curriculumName: yup.string().required(),
});

export const createEditQuestionSchema = yup.object().shape({
  name: yup.string().required('設問名は必須入力項目です。'),
  description: yup.string().required('設問内容は必須入力項目です。'),
  problems1: yup.string().required('選択肢Aは必須入力項目です。'),
  problems2: yup.string().required('選択肢Bは必須入力項目です。'),
  problems3: yup.string().required('選択肢Cは必須入力項目です。'),
  answer: yup.string().required('解答は必須入力項目です。'),
  time_limit: yup.string().required('制限時間は必須入力項目です。'),
  comment: yup.string().required('解説は必須入力項目です。'),
  score: yup
    .number()
    .required('スコア設定（設問難易度の設定）は必須入力項目です。')
    .test('val', (value) => Number(value) > 0 && Number(value) < 4),
});

export const uploadCSVSchema = yup.array().of(
  yup.object().shape({
    curriculum_code: yup.string(),
    curriculum_name: yup.string().max(50).required(),
    curriculum_description: yup
      .string()
      .max(1050, 'カリキュラムの説明を1050以内で入力してください。'),
    level1_name: yup.string().max(50, '長さは 50 文字を超えてはなりません'),
    level2_name: yup.string().max(50, '長さは 50 文字を超えてはなりません'),
    level3_name: yup.string().max(50, '長さは 50 文字を超えてはなりません'),
    level4_name: yup.string().max(50, '長さは 50 文字を超えてはなりません'),
    question_name: yup
      .string()
      .required('設問名称は必須項目です。')
      .max(60, '設問名称は60以内で入力してください。'),
    question_description: yup
      .string()
      .required('設問内容は必須項目です。')
      .max(480, '設問内容は480以内で入力してください。'),
    problems1: yup
      .string()
      .required('選択肢 A は必須項目です。')
      .max(60, 'オプションAには60以内の値を入力してください。'),
    problems2: yup
      .string()
      .required('選択肢 B は必須項目です。')
      .max(60, 'オプションBには60以内の値を入力してください。'),
    problems3: yup
      .string()
      .required('選択肢 C は必須です。')
      .max(60, 'オプションCには60以内の値を入力してください。'),
    answer: yup
      .string()
      .max(1)
      .required('回答は必須です。')
      .oneOf(['A', 'B', 'C'], '答えは間違っています。'),
    comment: yup
      .string()
      .required('説明は必須フィールドです。')
      .max(1050, '1050以内に説明を入力してください。'),
    score: yup
      .string()
      .test(
        'スコア（設問難易度）',
        'スコア（設問難易度）が不正です。',
        (value) => (Number(value) > 0 && Number(value) < 4) || value === ''
      ),
  })
);
