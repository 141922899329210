import { useMemo } from 'react';

import { Image, Document, Page, View, Text, Font } from '@react-pdf/renderer';
import { StopPublic, TickComplete, UnPublished, WhiteEditing } from 'assets';
import { map } from 'lodash';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';
import { Style } from '@react-pdf/types';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const pageSize = 'A4';
const pageOrientation = 'landscape';

interface TableRow {
  company_id: string;
  curriculum_name: string;
  publish: string;
  publish_end_date: string;
  updatedat: string;
  author: string;
}

export const getStatusText = (value: string) => {
  switch (value) {
    case '0':
      return '編集中';
    case '1':
      return '未公開';
    case '2':
      return '公開中';
    case '3':
      return '公開停止中';
    default:
      return '';
  }
};

export const getStatusImage = (value: string) => {
  switch (value) {
    case '0':
      return WhiteEditing;
    case '1':
      return UnPublished;
    case '2':
      return TickComplete;
    case '3':
      return StopPublic;
    default:
      return UnPublished;
  }
};

const PDFCurriculumDocument = ({
  listCurriculum,
  total,
}: {
  listCurriculum: Types.OfficialPublicRes[];
  total: number;
}) => {
  const columns: { title: string; width: string | number; type: string }[] = [
    {
      title: 'ID',
      width: 90,
      type: 'text',
    },
    {
      title: 'OFFICIALカリキュラム',
      width: 360,
      type: 'text',
    },
    {
      title: '公開ステータス',
      width: 90,
      type: 'number',
    },
    {
      title: '前回公開日時',
      width: 90,
      type: 'text',
    },
    {
      title: '最終更新日時',
      width: 90,
      type: 'text',
    },
    {
      title: '作成者',
      width: 90,
      type: 'text',
    },
  ];

  const rows = useMemo(() => {
    const curriculumList: TableRow[] = map(listCurriculum, (curriculum) => ({
      company_id: curriculum.company_id,
      curriculum_name: curriculum.curriculum_name,
      publish: curriculum.publish.toString(),
      publish_end_date: curriculum.publish_end_date || '',
      updatedat: curriculum.updatedat || '',
      author: curriculum.author,
    }));

    return curriculumList;
  }, [listCurriculum]);

  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>OFFICIALカリキュラム　公開管理</Text>
        </View>

        <View style={styles.subscription}>
          <Text style={styles.textSubscription}>
            作成したOFFICIALカリキュラムの公開管理を行う画面です。
          </Text>
        </View>
        <View style={styles.line}></View>
        <View style={styles.searchInput}>
          <Input required textLabel="OFFICIALカリキュラムID" value="" placeholder="指定なし" />
          <Input required textLabel="OFFICIALカリキュラム" value="" placeholder="指定なし" />
          <Input required textLabel="公開ステータス" value="" placeholder="指定なし" />
          <Input required textLabel="作成者" value="" placeholder="指定なし" />
        </View>
        <View style={styles.body}>
          <View style={styles.textCountGeneral}>
            <Text style={styles.textCountNumber}>{listCurriculum.length}</Text>
            <Text style={styles.textCountDescription}>件表示</Text>
            <Text style={styles.textCountNumber}>/</Text>
            <Text style={styles.textCountNumber}>{total}</Text>
            <Text style={styles.textCountDescription}>件中</Text>
          </View>

          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeaderRow]}>
              {columns.map((column, index) => (
                <View key={index} style={[styles.wrapCell, { width: column.width }]}>
                  <Text style={(styles.tableCell, styles.headerCell)}>{column.title}</Text>
                </View>
              ))}
            </View>

            {rows.map((row, rowIndex) => (
              <View
                key={rowIndex}
                style={[
                  styles.tableRow,
                  { borderBottom: rowIndex !== rows.length - 1 ? 'none' : '1px solid #e5e5e5' },
                ]}
              >
                {Object.keys(row).map((key, colIndex) => (
                  <View
                    key={colIndex}
                    style={[styles.wrapCell, { width: columns[colIndex].width }]}
                  >
                    <Image src={getStatusImage(row[key as keyof TableRow])} />
                    {columns[colIndex].type === 'text' ? (
                      <Text style={styles.tableCell}>{row[key as keyof TableRow]}</Text>
                    ) : columns[colIndex].type === 'number' ? (
                      <Text style={styles.tableCell}>
                        {getStatusText(row[key as keyof TableRow])}
                      </Text>
                    ) : null}
                  </View>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Input = ({
  styleInput,
  textLabel,
  required,
  value,
  width,
  placeholder,
}: {
  textLabel?: string;
  required?: boolean;
  styleInput?: Style;
  width?: number;
  value: string;
  placeholder?: string;
}) => (
  <View style={width ? [styles.wrapInput, { width: width }] : styles.wrapInput}>
    {textLabel && (
      <View style={styles.wrapLabel}>
        <Text style={styles.textLabel}>{textLabel}</Text>
      </View>
    )}

    <View style={styleInput ? [styles.input, styleInput] : styles.input}>
      {value ? (
        <Text style={styles.value}>{value}</Text>
      ) : (
        <Text style={[styles.placeholder, styles.value]}>{placeholder}</Text>
      )}
    </View>
  </View>
);
export default PDFCurriculumDocument;
