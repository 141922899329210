import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import { config } from 'configs';
import * as Types from 'types';

class CurriculumService {
  protected _axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this._axios = axios;
  }

  createLevel<T>(
    level: number,
    data: Types.CreateItemRequestType<T>
  ): AxiosPromise<Types.CreateItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/level${level}s/items/new`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  updateLevel<T>(
    level: number,
    { id, data }: Types.UpdateItemRequestType<T>
  ): AxiosPromise<Types.UpdateItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/level${level}s/items/edit/${id}`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }

  deleteLevel(
    level: number,
    { id, data = {} }: Types.DeleteItemRequestType
  ): AxiosPromise<Types.DeleteItemResponseType> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/datastores/level${level}s/items/delete/${id}`,
      method: 'DELETE',
      data,
    };

    return this._axios(request);
  }

  findAllCurriculumLevelOption(
    level: number,
    data: Types.ReportsItemRequestType
  ): AxiosPromise<Types.ReportsItemResponseType<Types.CurriculumOptionType>> {
    const request: AxiosRequestConfig = {
      url: `applications/${config.APP_ID}/reports/select_level${level}s/filter`,
      method: 'POST',
      data,
    };

    return this._axios(request);
  }
}

export default CurriculumService;
