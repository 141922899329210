import styled from 'styled-components';

export const SectionStyled = styled.div`
  margin: 8px 16px;
  .text-content {
    font-size: 11px;
    color: #777777;
  }
  .icon {
    font-size: 40px;
    color: #ffffff;
    left: 50%;
    transform: translateX(47%);
  }
  .ant-upload-text {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .label {
    font-family: Lato;
    font-size: 12px;
    color: #777777;
    text-align: center;
  }
  .form-upload {
    position: relative;
    border: 3px solid #ffffff;
    .icon-prev,
    .icon-next {
      color: #c4c4c4;
      font-size: 25px;
      cursor: pointer;
    }
    .icon-prev {
      position: absolute;
      left: -30px;
      top: calc(465px / 2);
      z-index: 9;
    }
    .icon-next {
      position: absolute;
      right: -30px;
      top: calc(465px / 2);
      z-index: 9;
    }
  }
  .flex-image-small {
    display: flex;
    flex-wrap: wrap;
    margin: 14px 0;
    .image {
      width: 100px;
      height: 56px;
      margin-right: 4px;
      margin-bottom: 4px;
      cursor: pointer;
      border: 1px solid #d9d9d9;
      .no-image {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #efefef;
        position: relative;
      }
      .image-small {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &_active {
        border: 2px solid #00a3a5;
      }
    }
  }
  .file-upload {
    display: flex;
    flex-direction: column;
    min-height: 465px;
    border: 2px dashed #d9d9d9;
    background-color: #efefef;
    justify-content: center;
    padding: 20px 0;
    .label-question {
      background-color: #424242;
      font-family: 'Lato';
      font-size: 12px;
      color: #ffffff;
      padding: 3px 5px;
      position: absolute;
      top: 3px;
      left: 3px;
      z-index: 99;
    }
    .info-file {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px 0;
      position: relative;
      .name-image {
        font-size: 13px;
        text-align: center;
        margin-bottom: 10px;
        color: #424242;
      }
      .image-file {
        height: 338px;
      }
      .ant-image-mask {
        .zoom-image {
          font-size: 18px;
          color: #c4c4c4;
          position: absolute;
          right: 20px;
          bottom: 20px;
          z-index: 99;
          cursor: pointer;
        }
      }
    }
  }
  .ant-upload-list {
    display: none;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    .icon-delete-outlined {
      color: #00a3a5;
      font-size: 15px;
      margin-right: 5px;
    }
    .text-delete-outlined {
      font-size: 12px;
      text-decoration: underline;
      color: #00989a;
    }
  }
  .btn-upload {
    background: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a3a5;
    padding: 6px 36px;
    cursor: pointer;
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .image {
    background-color: #efefef;
    border: 1px solid #eeeeee;
    width: 128px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 6px;
    cursor: pointer;
    .icon {
      color: #424242;
      font-size: 20px;
    }
    .text-no-image {
      font-family: 'Lato';
      font-size: 12px;
      color: #424242;
    }
  }
  .label {
    font-family: 'Lato';
    font-size: 12px;
    color: #777777;
    text-align: center;
  }
  .image-question {
    width: 100%;
    height: 100%;
  }
`;
