import styled from 'styled-components';

export const TableStyle = styled.div`
  .table {
    .ant-table-row:nth-child(even) {
      background: #f9f9f9;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0px 0px;
      background: #ebebeb;
      .ant-table-cell {
        color: #424242;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        padding: 7px;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        color: #424242;
        font-size: 14px;
        padding: 1.5px;
        /* line-height: 120%; */
      }
    }
    .ant-table-thead {
      .ant-table-cell.number-company {
        background: #777777;
        .title {
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          .text-small {
            font-size: 11px;
          }
        }
      }
      .ant-table-cell.name {
        text-align: center;
      }
      .ant-table-cell.code {
        text-align: center;
      }
    }
    .ant-table-tbody {
      .ant-table-cell.code {
        color: #777;
        font-size: 12px;
      }
      .ant-table-cell.date {
        text-align: right;
        span {
          margin-right: 10px;
        }
      }
    }
  }
`;
