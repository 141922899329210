import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import AuthLayout from 'containers/Auth/Layout';
import { routes } from 'navigations/routes';
import { Centering } from 'assets';
import Styled from './styles';

const ChangeEmailComplete: React.FC = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout>
      <Styled>
        <img src={Centering} alt="logo" className="logo" />
        <div className="title">メールアドレス変更</div>
        <CheckCircleOutlined className="logo-completed" />
        <span className="description">
          メールアドレスの変更が完了しました。
          <br />
          ログインしてください。
        </span>
        <Button className="redirect" onClick={() => navigate(routes.Login.path)}>
          ログイン画面へ
        </Button>
      </Styled>
    </AuthLayout>
  );
};

export default ChangeEmailComplete;
