import React from 'react';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormikProvider, useFormik } from 'formik';
import { MessageFormatElement } from 'react-intl';
import { Button, Upload, Image } from 'antd';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';
import { isEmpty } from 'lodash';

import { deletedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import { createEditCurriculumSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumSelector } from '../../selectors';
import { Input, Modal, TextArea } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  createCurriculum,
  updateCurriculum,
  createLevelCurriculum,
  getCurriculum,
  getCurriculumMaster,
  deleteFileAttach,
  createFileAttach,
} from '../../thunk';

interface Props {
  id?: string;
  name?: string;
  page?: number;
  visible: boolean;
  per_page?: number;
  description?: string;
  maxSortOrder?: number;
  title?: React.ReactNode;
  type: 'create' | 'edit';
  subTitle?: React.ReactNode;
  textSubmit?: string | MessageFormatElement[] | undefined;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  fileCurriculum?: UploadFile<File>;
  data?: Types.CurriculumItemType;
}

const CreateEditCurriculum: React.FC<Props> = ({
  id,
  type,
  name,
  title,
  page,
  visible,
  subTitle,
  per_page,
  textSubmit,
  setVisible,
  description,
  maxSortOrder,
  fileCurriculum,
  data,
}) => {
  const dispatch = useAppDispatch();

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const formik = useFormik<Types.AddCurriculumFormik>({
    initialValues: {
      curriculumName: name || '',
      description: description || '',
      file: fileCurriculum,
    },
    enableReinitialize: true,
    validationSchema: createEditCurriculumSchema,
    onSubmit: async (values) => {
      if (!values.curriculumName || !userInfo) return;
      let fileID = '';

      if (!isEmpty(values.file) && values.file.uid !== data?.fileID) {
        if (data?.fileID) {
          deletedFileInMinIO(data.fileID);
          dispatch(
            deleteFileAttach({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [data.fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
        const uploadToMinIo = await uploadFileToMinIO(values.file);
        await dispatch(
          createFileAttach({
            item: {
              fileID: uploadToMinIo,
              filename: values.file?.name,
              file_location: '1',
              file_extension: values.file?.type,
              file_size: `${values.file?.size}`,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        );
        fileID = uploadToMinIo;
      }
      if (isEmpty(values.file)) {
        if (data?.fileID) {
          deletedFileInMinIO(data.fileID);
          dispatch(
            deleteFileAttach({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [data.fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
      }
      if (type === 'create') {
        const resultAction = await dispatch(
          createCurriculum({
            item: {
              company_id: userInfo?.company_id,
              name: values.curriculumName,
              description: values.description,
              publish: 0,
              required_curriculum: 0,
              sort_order: maxSortOrder,
              fileID: fileID,
              createdby: userInfo.login_id,
              archive_flg: 0,
            },
            return_item_result: true,
            return_display_id: true,
          })
        );
        if (createCurriculum.fulfilled.match(resultAction) && resultAction.payload.item['code']) {
          const resultLevel1 = await dispatch(
            createLevelCurriculum({
              level: 1,
              item: {
                company_id: userInfo?.company_id,
                name: '',
                sort_order: 1,
                curricullum_code: resultAction.payload.item['code'],
              },
              return_item_result: true,
              return_display_id: true,
            })
          );
          if (
            createLevelCurriculum.fulfilled.match(resultLevel1) &&
            resultLevel1.payload.item['code']
          ) {
            const resultLevel2 = await dispatch(
              createLevelCurriculum({
                level: 2,
                item: {
                  company_id: userInfo?.company_id,
                  level1_code: resultLevel1.payload.item.code,
                  name: '',
                  sort_order: 1,
                  curricullum_code: resultAction.payload.item['code'],
                },
                return_item_result: true,
                return_display_id: true,
              })
            );
            if (
              createLevelCurriculum.fulfilled.match(resultLevel2) &&
              resultLevel2.payload.item['code']
            ) {
              const resultLevel3 = await dispatch(
                createLevelCurriculum({
                  level: 3,
                  item: {
                    company_id: userInfo?.company_id,
                    level2_code: resultLevel2.payload.item.code,
                    name: '',
                    sort_order: 1,
                    curricullum_code: resultAction.payload.item['code'],
                  },
                  return_item_result: true,
                  return_display_id: true,
                })
              );
              if (
                createLevelCurriculum.fulfilled.match(resultLevel3) &&
                resultLevel3.payload.item.code
              ) {
                await dispatch(
                  createLevelCurriculum({
                    level: 4,
                    item: {
                      company_id: userInfo?.company_id,
                      level3_code: resultLevel3.payload.item.code,
                      name: '',
                      sort_order: 1,
                      curricullum_code: resultAction.payload.item['code'],
                    },
                    return_item_result: true,
                    return_display_id: true,
                  })
                );
              }
            }
          }
        }
      } else {
        if (id) {
          await dispatch(
            updateCurriculum({
              id,
              data: {
                item: {
                  name: values.curriculumName,
                  description: values.description,
                  ...(fileID ? { fileID: fileID } : !values.file ? { fileID: '' } : null),
                  updatedat: new Date(),
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
        }
      }
      await Promise.all([
        dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCurriculumMaster({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: page || 1,
            per_page: per_page || 10,
          })
        ),
      ]);
      onClose();
    },
  });

  const onClose = () => {
    setVisible(false);
    formik.resetForm();
  };

  return (
    <Modal
      title={title}
      open={visible}
      width={720}
      okButton={{
        text: textSubmit,
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.isValid || !formik.dirty,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: onClose,
      }}
      onCancel={onClose}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled hasImage={!!formik.values.file}>
        <p className="sub-title">{subTitle}</p>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
            className="form"
          >
            <Form.Item
              name="curriculumName"
              label={
                <span className="text-label">
                  カリキュラム名
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="curriculumName"
                className="input"
                type="text"
                placeholder="全角：最大30文字"
                showCount
                maxLength={30}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={<span className="text-label"> 説明</span>}
              className="form-input"
            >
              <TextArea
                name="description"
                className="input-TextArea"
                rows={4}
                showCount
                maxLength={100}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item name="file" label="表紙画像">
              <div className="wrap-upload">
                {!formik.values.file ? (
                  <div className="no-image">
                    <CloudUploadOutlined className="icon" />
                    <p>
                      アップロードするファイルをここにドロップ <br /> または
                    </p>
                  </div>
                ) : (
                  <>
                    <p>Image 1</p>
                    <Image
                      src={URL.createObjectURL(
                        new Blob([
                          (formik.values.file.originFileObj
                            ? formik.values.file.originFileObj
                            : formik.values.file) as unknown as Blob,
                        ])
                      )}
                    />
                  </>
                )}

                <div className="flex">
                  <Upload
                    beforeUpload={() => false}
                    onChange={(e) => formik.setFieldValue('file', e.file)}
                    showUploadList={false}
                    className="upload"
                    name="file"
                  >
                    <Button className="btn">ファイルを選択</Button>
                  </Upload>
                  {formik.values.file && (
                    <Button
                      className="btn btn-delete"
                      onClick={() => formik.setFieldValue('file', undefined)}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: 18,
                        }}
                      />
                      <span>ファイルを削除</span>
                    </Button>
                  )}
                </div>
              </div>
              <span className="note">
                ※画像は16:4でjpegまたはpngで、10MBまで。
                <br />
                ※画像はユーザがOFFICIALカリキュラムを管理する際に表紙として表示されます。
              </span>
            </Form.Item>
          </Form>
        </FormikProvider>
      </SectionStyled>
    </Modal>
  );
};

export default CreateEditCurriculum;
