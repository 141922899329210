import styled from 'styled-components';

export default styled.section`
  position: relative;
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  padding: 30px;
  .text-note {
    font-size: 12px;
    color: #424242;
  }

  .wrap-basic-inf {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top: 10px;
    .header {
      height: 48px;
      font-size: 16px;
      color: #2a2a2a;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
    .body {
      padding: 40px;
    }
  }

  .wrap-basic-inf {
    .body {
      display: flex;
      justify-content: space-between;
      padding-right: 16px;
      align-items: center;
      .wrap-user {
        width: 40%;
        display: flex;
        padding: 30px 0 0 10px;
        .user-info {
          display: flex;
          flex-direction: column;
          margin-left: 40px;
          .mail-icon {
            color: #cccccc;
          }
          .mail-item {
            display: flex;
            align-items: center;
            margin-top: 13px;
            .mail-user {
              margin-left: 8px;
              font-family: Lato;
              font-size: 16px;
              color: #424242;
            }
          }
          .account-user {
            margin-top: 16px;
            font-family: Lato;
            font-size: 13px;
            color: #999999;
          }
          .divider {
            width: 20px;
            border: 0.5px solid #e0e0e0;
            margin-left: initial;
            margin-top: 32px;
          }
          .user-name {
            font-family: Lato;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: #bbbbbb;
          }
          .name-ja {
            margin-top: 16px;
            font-family: Lato;
            font-size: 24px;
            color: #2a2a2a;
          }
        }
        .avatar {
          .avatar-user {
            width: 180px;
            height: 180px;
            display: flex;
            flex-direction: column;
            background-color: #f0efef;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            .text-content {
              font-family: Lato;
              font-size: 16px;
              text-align: center;
              color: #bbbbbb;
            }
          }
          .icon-user {
            font-size: 78px;
            color: #cccccc;
          }
        }
        .text-help {
          width: 180px;
          font-size: 11px;
          line-height: 140%;
          text-align: center;
          color: #999999;
          padding-top: 12px;
        }
      }

      .form-right {
        width: 38%;
        height: 180px;
        display: flex;
        border-left: 1px solid #e0e0e0;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .form-right-btn {
          position: absolute;
          right: 10px;
          top: -10px;
          .bth-edit {
            width: 80px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            cursor: pointer;
            .icon-edit {
              margin-right: 3px;
            }
          }
        }
        .form-right-item {
          margin-left: 32px;
          .profile-item {
            padding-top: 1px;
            padding-bottom: 1px;
            .profile-item-title {
              font-size: 14px;
              color: #777777;
            }
            .profile-item-content {
              font-size: 14px;
              color: #2a2a2a;
            }
          }
          .profile-input {
            margin-top: 3px;
            .text-label {
              margin-right: 5px;
            }
            .birth-place-label {
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  .item-comment {
    position: relative;
    .comment {
      font-size: 13px;
      color: #222222;
      background: #fffcf4;
      border-radius: 0 0 5px 5px;
      border: 1px solid #f4e6c6;
      height: 80px;
      position: relative;
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        background-color: #fffcf4;
        margin-left: 10%;
        position: absolute;
        border: 1px solid #f4e6c6;
        transform: rotate(45deg);
        border-bottom: none;
        border-right: none;
        top: -10px;
      }
      .title-comment {
        font-family: Lato;
        font-size: 11px;
        color: #f6ac00;
        padding: 10px 0 0 10px;
      }
      .content {
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        padding-left: 40px;
      }
    }
  }
`;
